/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<g transform="translate(0 -290.65)" _fill="none" _stroke="#000"><rect pid="0" width="4.762" height="3.969" x=".794" y="291.973" rx=".529" ry=".529" stroke-width=".529"/><path pid="1" d="M.794 291.973l2.381 2.117 2.381-2.117" stroke-width=".265" stroke-linejoin="round"/></g>'
  }
})
