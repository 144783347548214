<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Publish History</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    
    <edc-data-grid :dataList="tableList" @onStatus="onStatus" @onDownload="onDownload" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="sortBy" @UpdateFilter="filterData"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
// import { post as postToServer } from './../../methods/serverCall.js';
import Breadcrumb from "../Breadcrumbs.vue"
import config from '../../config.json'
import { get as getToServer, post as postToServer } from './../../methods/serverCall.js';
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import _ from 'lodash'
import { PUBLISH_HISTORY, PUBLISH_HISTORY_CANCEL_REQUEST, EXPORT_PUBLISH_HISTORY} from '@/data/url_constants.js'
import {manageScreenDisplay} from '@/methods/special.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

  export default {
    name: "PublishHistory",
    
    data () {
      return {
        search: '',
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        tableList: {
                headers: [
                    { text: 'Object', value: 'object_name', options:[],cssClass:"w16", title: 'Object Name'},
                    { text: 'Object Type', value: 'object_type_desc',  options:[], title: 'Object Type' },
                    { text: 'Ver', value: 'object_version',  options:[], align:'right', title: 'Object Version', dataType:'int'},
                    { text: 'Source', value: 'source_env_name', options:[], title: 'Object Source Name' },
                    { text: 'Target', value: 'target_env_name', options:[], title: 'Object Target Name' },
                    { text: 'Target Type', value: 'target_type', options:[], title: 'Object Target Type' },
                    { text: 'Initiated By', value: 'requested_by',  title: 'Publish Request intiated by' },
                    { text: 'Host Name', value: 'host_name', options:[], title: 'Host name of the server from where object get published' },
                    { text: 'Host IP', value: 'host_ip', options:[], title: 'Host IP of the server from where object get published'},
                    { text: 'Requested By', value: 'action_taken_by',  title: 'Object Requested By' },
                    { text: 'Requested Date', value: 'timezone_aware_action_taken_date',dataType:'date',   options:[], title: 'Object Requested Date' },
                    { text: 'Requested Time', value: 'timezone_aware_action_taken_time',dataType:'time',  options:[], title: 'Object Requested Time', hideInlineFilter: true},
                    { text: 'Request ID', value: 'publish_request_id',  options:[], align:'right', title: 'Object Request ID', dataType:'number'},
                    { text: 'Status', value: 'action',  options:[], title: 'Object Status' },
                    { text: 'Object ID', value: 'object_id',  options:[], title: 'Object Id', align: 'right'}
                ],
                actions: [{'text':'status','key':"approval_request_id",selectType:"single"}],
                  // {'text':'revoke','key':"action",'cmpValue':"PENDING", selectType:"single"}],
                sorting_type: SERVER_SIDE,
                filterType: SERVER_SIDE,
                paginationType: SERVER_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                hideSelect:true,
                hideFilter:true,
                hideExport:false,
                hideShowColumns:true
            },
        envIpJson : {
          "filter": [],
          "sort": [{
            "column_name": "",
            "type": ""
          }],
          "page": 1,
          "page_size": 10
        },
        publish_history: [],
        loader:false,
        show_graph_template:false,
        graphtemplatedata:{},
        disableDefaltSorting:true,
      }
    },
    mounted() {
      this.envIpJson.page_size = manageScreenDisplay()
      this.getPublishHistory(this.envIpJson);
    },
    methods: {
      onDownload(downloadParams, filterArray){
        let _this = this; 
        _this.loader=true;
        let filter=[];
        filterArray.map((obj, index)=>{
            obj.stringArray.map((stringObj, stringIndex)=>{
                let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                    filter.push(cloneDeep(temp));
            })
        });
        let inputJson={
            "recordType":downloadParams.recordType,
            "row_option":downloadParams.recordType,
            "client_id": _this.$session.get('client_id'),
            "env_id": "",
            "file_format": downloadParams.fileType,
            "min_range": downloadParams.minRange|| null,
            "max_range": downloadParams.maxRange||null,
            "filter": filter
        }
        var url = config.PUBLISHER_URL+EXPORT_PUBLISH_HISTORY;    
        postToServer(_this, url, inputJson).then(Response => {
            var url = config.PUBLISHER_URL + "/static/" + Response;
            window.open(url, '_blank');
            _this.loader=false;
            
        }).catch(objError => {
            _this.loader=false;
          if(objError){
                _this.loader = null
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = objError;
              }
              else {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = SERVER_ERROR;
              }
          })
    },
      hideLoader(){
            let _this = this;
            setTimeout(function(){
                  _this.loader = false; 
              },500);
     },
     onStatus(record){
            // this.$router.push({ name: 'requestStatus', params: {'request_id': record.approval_request_id, 
            //     'objectName': record.object_name,
            //     'fromEnv':record.from_env,
            //     'toEnv':record.to_env}});
        //     this.graphtemplatedata = {"service_id":config.PROCESS_APPROVAL_URL, "endpoint":'/request/live_status/'+record.approval_request_id}
        // this.show_graph_template = true
        this.$router.push({name:"publishapprovalstatus", params: {'request_id': record.approval_request_id, 'objectName': record.object_name,'fromEnv':record.from_env,'toEnv':record.to_env}})
        },
      // onRevoke(record){
          
      //     this.revokeRequest(record.approval_request_id, [record.id])
      // },
      // revokeRequest(request_id, id_list){
      //       var data = {'request_id': request_id, 'service_id': 'publisher_service', 'endpoint': '/cancel_request', 'method': 'POST', 'url_data': {'id_list': id_list, 'request_id': request_id}}
      //       postToServer(this, config.PROCESS_APPROVAL_URL + PUBLISH_HISTORY_CANCEL_REQUEST, data).then(response => {
      //       // postToServer(this, config.PUBLISHER_URL + '/cancel_request', data).then(response => {
      //         var res = response
      //         if(res){
      //           this.snackbar = true
      //           this.colorValue = 'success'
      //           this.snackbartext = 'Request Cancelled Successfully'
      //         } else {
      //             this.publish_history = data
      //           }
      //       }).catch(ProcessDocError => {
      //         if(ProcessDocError){
      //           this.snackbar = true
      //           this.colorValue = 'error'
      //           this.publish_history = []
      //           this.snackbartext = ProcessDocError;
      //         }
      //         else {
      //           this.snackbar = true
      //           this.colorValue = 'error'
      //           this.snackbartext = SERVER_ERROR;
      //         }
      //     });
      //     },
      getPublishHistory(envIpJson) {
        let inputJson = this.envIpJson;
        var client_id = this.$session.get('client_id')
        var input_json = {
              "client_id":client_id,
              "filter": inputJson.filter,
              "page": inputJson.page,
              "page_size": inputJson.page_size,
              "sort": inputJson.sort,
            }
        this.loader = true; 
        postToServer(this, config.PUBLISHER_URL + PUBLISH_HISTORY, input_json
          ).then(response => {
            var data = response
            if(data){
            this.tableList.rows = data.result;
            this.tableList.total_count =data.total;
            this.publish_history = data.result
            } else {
                data = []
                this.tableList.rows = data.result;
                this.tableList.total_count = data.total;
                this.publish_history = data.result
              }
              this.hideLoader();
          }).catch(ProcessDocError => {
            if(ProcessDocError){
              this.snackbar = true
              this.colorValue = 'error'
              this.publish_history = []
              this.tableList.rows = [];
              this.tableList.total_count =0;
              this.snackbartext = ProcessDocError;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = SERVER_ERROR;
            }
            this.hideLoader();
        });
      },

      onPageChange(page, perPage) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.page = page;
        inputJson.page_size = perPage;
        this.getPublishHistory(inputJson);
      },

      onPerPageChange(perPage, page) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.page = page;
        inputJson.page_size = perPage;
        this.getPublishHistory(inputJson);
      },

      sortBy: function (sortJson) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.sort = sortJson
        this.getPublishHistory(inputJson);
      },

      filterData(filterArray, pageno) {
        let _this = this;
        let inputJson = _this.envIpJson;
        inputJson.filter=filterArray;
        inputJson.page=1
        this.getPublishHistory(inputJson);
      },
    }
  }
</script>
<style>
.iconFormat{
    margin-right: 5px;
    cursor: pointer;
}
</style>

