/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pathfinder_split_view': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M37.08 24.58v14.85H60V24.58H37.08zm2.57 13.93H38v-2.75h1.65v2.75zm0-3.67H38v-2.75h1.65v2.75zm0-3.66H38v-2.75h1.65v2.75zm0-3.67H38v-2.02h1.65v2.02zm5.13 11h-4.22v-2.75h4.22v2.75zm0-3.67h-4.22v-2.75h4.22v2.75zm0-3.66h-4.22v-2.75h4.22v2.75zm0-3.67h-4.22v-2.02h4.22v2.02zm7.7 11H45.7v-2.75h6.78v2.75zm0-3.67H45.7v-2.75h6.78v2.75zm0-3.66H45.7v-2.75h6.78v2.75zm0-3.67H45.7v-2.02h6.78v2.02zm6.6 11H53.4v-2.75h5.68v2.75zm0-3.67H53.4v-2.75h5.68v2.75zm0-3.66H53.4v-2.75h5.68v2.75zm0-3.67H53.4v-2.02h5.68v2.02zM31.5 12h1v40h-1zM4 39.42h22.92V24.58H4v14.84zm16.32-13.93H26v2.02h-5.68v-2.02zm0 2.94H26v2.75h-5.68v-2.75zm0 3.66H26v2.75h-5.68v-2.75zm0 3.67H26v2.75h-5.68v-2.75zm-7.7-10.27h6.78v2.02h-6.78v-2.02zm0 2.94h6.78v2.75h-6.78v-2.75zm0 3.66h6.78v2.75h-6.78v-2.75zm0 3.67h6.78v2.75h-6.78v-2.75zM7.48 25.49h4.22v2.02H7.48v-2.02zm0 2.94h4.22v2.75H7.48v-2.75zm0 3.66h4.22v2.75H7.48v-2.75zm0 3.67h4.22v2.75H7.48v-2.75zM4.92 25.49h1.65v2.02H4.92v-2.02zm0 2.94h1.65v2.75H4.92v-2.75zm0 3.66h1.65v2.75H4.92v-2.75zm0 3.67h1.65v2.75H4.92v-2.75z"/>'
  }
})
