/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'history_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M2.865.517a.264.264 0 00-.192.069l-.45.41-.452.41a.263.263 0 00-.077.266c.025.085.09.152.176.178l.582.183.582.181a.266.266 0 00.34-.31L3.305 1.6h.145A1.852 1.852 0 111.6 3.435l.003-.263-.53-.004-.002.264A2.382 2.382 0 103.451 1.07h-.263L3.11.72a.263.263 0 00-.245-.203zM4.43 2.38l-1.387.832v1.02h.264v-.87l1.26-.755-.137-.227z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
