/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Reject': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M60.005 36.972c1.108 0 2-.892 2-2v-24c0-1.108-.892-2-2-2h-12c-1.108 0-2 .892-2 2v24c0 1.108.892 2 2 2zm-7.975-3.026a3 3 0 01-3-3 3 3 0 013-3 3 3 0 013 3 3 3 0 01-3 3z"/><path pid="1" d="M9.005 38.972h13m-10-16a3 3 0 01-2.967-3c-.033-4 5.967-3 1.967-3h3c.669 0-2-.157-2-.157-1-.843-1.644-1.341-1.336-2.824.416-2.004 2.336-2.02 3.336-2.02h27v22h-4s-3.453 7.019-4.823 10.786c-1.674 3.563-.235 9.641-1 10-.945.321-3.568.523-5.662-.76-2.18-1.337-2.385-4.386-2.515-5.025v-11m-13-7A4 4 0 016.69 27.03c0-2.21 2.314-4.06 4-4.094.338-.007 2.314.035 1.314.035m-3 16c-2 0-4-1.987-4-4-.173-3.362 3.564-3.958 6-4 .047 0-.906 0-.859.002" _fill="none" _stroke="#000" stroke-width="2"/>'
  }
})
