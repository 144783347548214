/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user_unlock_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M2.117.53C1.243.53.794 1.242.794 2.116v1.058c-.005.358.534.358.529 0V2.117c0-.588.206-1.059.794-1.059.587 0 .793.471.793 1.059v.264h-.264c-.288 0-.529.24-.529.53v2.38c0 .29.24.53.53.53H5.29c.289 0 .53-.24.53-.53v-2.38c0-.29-.241-.53-.53-.53H3.44v-.264c0-.874-.45-1.588-1.323-1.588zm.53 2.38h.748a.265.265 0 00.086 0h1.81v2.382H2.646zm1.322.53a.53.53 0 00-.265.987v.6h.53v-.6a.53.53 0 00-.265-.987z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
