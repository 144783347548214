/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Database_Synonym': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M22.863 4.29c-1.582 0-2.855 1.301-2.855 2.917v.39c-.002.047-.016.089-.016.135v27.414c0 1.955 1.81 3.432 3.903 3.432h32.18c2.092 0 3.902-1.477 3.902-3.432V15.715h.015V7.207c0-1.616-1.273-2.918-2.855-2.918H22.863zM21.42 15.714h8.584v6.426H21.42v-6.426zm10.012 0h7.853v6.426h-7.853v-6.426zm9.283 0h7.853v6.426h-7.853v-6.426zm9.281 0h8.555v6.426h-8.555v-6.426zM21.42 23.568h8.584v5.711H21.42v-5.71zm10.012 0h7.853v5.711h-7.853v-5.71zm9.283 0h7.853v5.711h-7.853v-5.71zm9.281 0h8.555v5.711h-8.555v-5.71zM21.42 30.71h8.584v6.441h-6.11c-1.44 0-2.474-.948-2.474-2.004V30.71zm10.012 0h7.853v6.441h-7.853V30.71zm9.283 0h7.853v6.441h-7.853V30.71zm9.281 0h8.555v4.437c0 1.056-1.036 2.004-2.477 2.004h-6.078V30.71zM39.555 42.484l-2.487 4.235-2.486 4.232 3.633.03a7.647 7.647 0 01-1.893 3.69 7.647 7.647 0 01-7.92 2.048 7.647 7.647 0 0010.543-.588A7.647 7.647 0 0041 51l3.402.025-2.423-4.27-2.424-4.27zm-34.123.518c-.791 0-1.428.651-1.428 1.459v.197c-.001.023-.008.044-.008.067v13.707c0 .977.905 1.714 1.951 1.714h16.088c1.047 0 1.953-.737 1.953-1.714v-9.717h.008V44.46c0-.808-.638-1.459-1.43-1.459H5.432zm-.723 5.713h4.293v3.213H4.709v-3.213zm5.006 0h3.928v3.213H9.715v-3.213zm4.64 0h3.928v3.213h-3.928v-3.213zm4.643 0h4.275v3.213h-4.275v-3.213zM4.709 52.643h4.293v2.855H4.709v-2.855zm5.006 0h3.928v2.855H9.715v-2.855zm4.64 0h3.928v2.855h-3.928v-2.855zm4.643 0h4.275v2.855h-4.275v-2.855zM4.709 56.21h4.293v3.223H5.947c-.72 0-1.238-.474-1.238-1.002V56.21zm5.006 0h3.928v3.223H9.715V56.21zm4.64 0h3.928v3.223h-3.928V56.21zm4.643 0h4.275v2.22c0 .529-.517 1.003-1.238 1.003h-3.037V56.21z"/>'
  }
})
