<template>
	<v-container class="EDC-Container">
		<v-row class="EDC-Row">
			
		</v-row>
		<loading-panel :loader="loader"></loading-panel>
		<v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
	</v-container>
</template>
<script>
	import {mapActions} from 'vuex'

	import config from '../../config.json'
	import GET_WORKTABLE_LIST from '../../data/url_constants.js'
	import {Snackbar_Default_Timeout} from '@/constants/constants.js'
	import { post as postToServer } from './../../methods/serverCall.js';
	export default {
		name:"archivistLanding",
		components:{

		},
		data(){
			return {
				arc_data: '',
				env_ds_resp: '',
				snackbar:false,
				snackbartext:'',
				colorValue:'error',
				snackbartimeout: Snackbar_Default_Timeout,
				loader:false,
				archivistRedirectKey:this.$route.query.archivist_redirect_key,
				selectedDatasourceIndex:0
			}
		},
		computed:{
			queryStringObj(){
				return this.$route.query
			}
		},
		mounted(){
			// alert("Welcome to the archivist landing page.")
			if(this.queryStringObj && this.queryStringObj.product_name){
				var fetchedObj = this.queryStringObj 
				if(fetchedObj.product_name === 'sidekick'){

					fetchedObj['request_for'] = "reporting_data"
					// for test
					if(!fetchedObj.client_id)
						fetchedObj['client_id'] = 123321
				}
				this.fetchDatasourceDetailsForEnv(fetchedObj)
			}else
				this.fetchEdcDetails();
			
		},
		methods:{
			...mapActions(['setArchivistRedirectionObj']),
			fetchEdcDetails(){
				let _this = this
				var data_to_send = {"application_redirect_key": this.$route.query.redirection_key}
				var cache_url = config.EDC_CACHE_SERVICE_URL + "/fetch_dataviewer_redirect_key"
				_this.loader = true
				postToServer(_this, cache_url, data_to_send).then(response=>{
					if (response){
						_this.arc_data = response
						this.fetchDatasourceDetailsForEnv(_this.arc_data);
					}
					else{
						return
					}
				}).catch(error_response=>{
					_this.loader = false
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error_response;
				})
			},
			getFormattedDatasources(fetchedObj, env_details){
				let _this = this
				var env_url = config.DATA_VIEWER_URL + '/get_datasource_for_archivist'
				postToServer(_this, env_url, env_details).then(response=>{
					for(let i=0;i<response['all_available_datasources'].length;i++){
						let ds_obj = response['all_available_datasources'][i]
						ds_obj.datasource_index = i
						if(fetchedObj.request_for === 'worktable' 
						&& ds_obj.business_data.associate_archivist_details === 'engine_database'){
							ds_obj.isArchivistWorkTable = true
							ds_obj.disabledTableSelection = true
							ds_obj.resetArchivistObject = true // on searchengine page. we are reseting archivist object
							_this.selectedDatasourceIndex = i
						} else if(fetchedObj.request_for === 'reporting_data' && ds_obj.business_data.datasource_id === fetchedObj.datasource_id){
							ds_obj.business_data.is_reporting_ds =true
						}
					}
					env_details['all_datasource_details'] = response['all_available_datasources']
					_this.env_ds_resp = env_details
					console.log('env_ds_resp', env_details)
					console.log('selectedDatasourceIndex', _this.selectedDatasourceIndex)
					_this.preprocessDataviewerData(fetchedObj)
				}).catch(error_response=>{
					_this.loader = false
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error_response;
				})
			},
			fetchDatasourceDetailsForEnv(fetchedObj){
				let _this = this
				var env_url = config.ENVIRONMENT_API_URL + '/get_datasource_details_with_env_id_for_dv'
				let dataToSend = _.cloneDeep(fetchedObj)
				dataToSend['return_with_env_details'] = true
				postToServer(_this, env_url, dataToSend).then(response=>{
					if(response){
						_this.getFormattedDatasources(fetchedObj, response)
					}
				}).catch(error_response=>{
					_this.loader = false
					this.snackbar = true
					this.colorValue = 'error'
					this.snackbartext = error_response;
				})
			},
			preprocessDataviewerData(fetchedObj){
				// console.log('fetchedObj=>', fetchedObj)
				var _this = this
				if(fetchedObj.table_name){
					fetchedObj['table_list'] = [{
						"table_name":fetchedObj.table_name,
						"type":"table",
						"title":fetchedObj.table_name,
						"displaytext":fetchedObj.table_name
					}]
				}
				else{
					fetchedObj.table_list.map(function(obj){
						obj.env_id = fetchedObj.env_id
						let description = ''
						if(obj.output_table_reference)
							description = obj.output_table_reference
						obj.displaytext =  description +' ('+obj.step_type+')'
						obj.title = obj.output_table_name
						obj.table_name = obj.output_table_name
						obj.type = 'table'
	            	})
				}
				

				let disabledTableSearch = false
				let disableEnvironmentSelection=false
				let disableDatasourceSelection=false
				// if(fetchedObj.request_for === 'worktable'){
				disabledTableSearch = true
				disableEnvironmentSelection = true
				disableDatasourceSelection = true
				// }
				// _this.env_ds_resp.environment_details['env_name'] =_this.env_ds_resp.environment_details['name']
				
				let openType = "single"
				if(fetchedObj.request_for === 'business_data'){
					openType = 'split'
					disableDatasourceSelection=false
				}
				// 'wholepage':true,'left':true,'right':true this 3 props for the search engine
				let params = {"EnvironmentList":[_this.env_ds_resp],'all_datasource_details':_this.env_ds_resp.all_datasource_details,
					'source_table_list':fetchedObj.table_list,'job_details':fetchedObj,
					'disabledTableSearch':disabledTableSearch,
					'disableEnvironmentSelection':disableEnvironmentSelection,
					"disableDatasourceSelection":disableDatasourceSelection,
					'isCallFromArchivist':true,
					'archivistRequestFor':fetchedObj.request_for,'wholepage':true,'left':true,'right':true,
					'selected_table':!_.isEmpty(fetchedObj.selected_table)? fetchedObj.selected_table:fetchedObj.table_list[0], 
					'selectedDatasourceIndex':_this.selectedDatasourceIndex}
	             // alert("successfully fetched table details.")
    			_this.setArchivistRedirectionObj(params)
				console.log('final params=>',params)
                // Dataviewer
    			this.$router.push({name:"edcpathfinder",params:{'opentType':openType,'isFromArchivist':true,jobId:fetchedObj.job_id}})
			},
		}
	}
</script>