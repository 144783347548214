import {mapActions} from 'vuex'
export const mixinPathfinder={
    props:{

    },
    data(){
        return{
            selectedEnvironment:{},
            selectedDatasource:{},
            dsList:[],
            datasourceForBackend:[],
            loader: false,
            snackbar:false,
            snackbartext:'',
            snackbartimeout:5000,
            colorValue:"",
            clientId:0,
            userId:'',
            isSuperAdmin:false,
            securityModelByEnv:[],
        }
    },
    computed:{
        isSplitMode(){
            return this.openModeType === 'split'
        },
        isMerged(){
            return this.openModeType === 'merged'
        },
    },
    watch:{
        '$store.state.pathfinderObject':{
            handler(newvalue){
                
            }
        }
    },
    mounted(){
        this.clientId = this.$session.get('client_id')
        this.userId = this.$session.get('email')
    },
    methods: {
        ...mapActions(['setPathfinderObject']),
        showError(error){
            this.snackbar = true
            this.colorValue = "error" 
            this.snackbartext = error || "Unhandled error occured"
        },
        isEmptyGridObj(){
            let pathFinderObj = _.cloneDeep(this.$store.state.pathfinderObject)
            let leftSideObj = pathFinderObj['left'] || {}
            let rightSideObj = pathFinderObj['right'] || {}
            if(_.isEmpty(leftSideObj) && _.isEmpty(rightSideObj))
                return true
            return false
        }
    },
}
