/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Archive_Dispose': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<rect pid="0" width="16" height="4" x="32" y="35" rx="1.067" ry="1.6" _fill="#fff"/><g _fill="none" _stroke="#000"><path pid="1" d="M19 9c-3.799 0-6.857 4.08-6.857 9.145V25h56v-6.855c0-5.066-3.058-9.145-6.857-9.145zM64 41V31H16v25.904C16 60.281 18.62 63 21.877 63H42" stroke-width="2"/><path pid="2" d="M34 37h12" stroke-width="4" stroke-linecap="round"/><g stroke-width="2"><path pid="3" d="M46 51h23.127" stroke-linecap="round"/><path pid="4" d="M53.71 51v-3.855h7.708V51" stroke-linejoin="round"/><path pid="5" d="M49.855 54.855v15.418h15.418V54.855" stroke-linecap="round" stroke-linejoin="round"/><path pid="6" d="M54.673 56.782v9.636M60.455 56.782v9.636"/></g></g>'
  }
})
