/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M3.967.528v1.41c0 .243.202.443.445.443H5.82l-.224-.225L3.966.528zM2.693.53c-.284 0-.503.25-.503.543v.105h.263v-.105c0-.164.113-.28.24-.28h1.012V.53H2.693zm1.54.638l.95.95h-.77a.177.177 0 01-.18-.18v-.77zm-1.589.418v1.41c0 .244.2.444.444.444h1.41l-.226-.227-1.628-1.627zm-1.203.003c-.363 0-.636.319-.636.675v2.88c0 .357.273.677.636.677h2.42c.363 0 .637-.32.637-.676v-1.44h-.53v1.44c0 .1-.057.147-.107.147h-2.42c-.05 0-.107-.048-.107-.147V2.264c0-.1.058-.146.107-.146h1.01v-.53h-1.01zm1.733 0v.263h.53V1.59h-.53zm-.264.638l.951.949h-.773a.175.175 0 01-.178-.18v-.77zm.795.154l.142.135.122.129V2.38h-.264zm-1.853.264v.265h.529v-.265h-.53zm3.705 0v1.442c0 .163-.113.279-.24.279h-.409v.263h.408c.285 0 .504-.25.504-.542V2.645h-.263zm-1.325.265l.265.264h.264V2.91h-.529zm-2.38.53v.264h.794V3.44h-.794zm.007.793v.265h1.317v-.265H1.859z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
