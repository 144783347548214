<template>
    <div>
        <!-- this page is only for the redirection purpose -->
    </div>
</template>
<script>
import {mapActions} from 'vuex'
export default{
    name:"PageLander",
    mounted(){
        this.openInSameTab(this.$route.query.pathToRedirect)
    },
    methods:{
        ...mapActions(["setsameComponentRedirectObj"]),
        openInSameTab(path){
          var _this = this
          _this.setsameComponentRedirectObj({})
          if(path === '/objectsinrepo'){
            this.$router.push({"name":"objectmanagement",params:{"isPageForRepo":true}}).catch(()=>{})
            this.setsameComponentRedirectObj({"isPageForRepo":true})
          } else if(path === '/objectmanagement'){
            this.$router.push({"name":"objectmanagement",params:{"isPageForEnv":true}}).catch(()=>{})
            this.setsameComponentRedirectObj({"isPageForEnv":true})
          }
          else{
            this.$router.push(path).catch(()=>{});
          }
        },
    }
}
</script>