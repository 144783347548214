<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>{{env_name}}</li>
              <li>
                <router-link to="/objrolelist">Object Security Role</router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-card elevation-1>
        <v-col cols="12" class="EDC-Col">
          <v-row no-gutters align="start" class="EDC-Row EDC-TopRow">
            <v-col cols="6" class="EDC-Col EDC-ColsSpacing">
              <v-text-field label="Name of Role" v-model="role_name" @blur="untitle_environment" class="EDC-UIComponent" hide-details autocomplete="off" dense></v-text-field>
              <!-- <input v-model="role_name" type="text" autofocus :disabled="$route.params.type == 'edit'" @blur="untitle_environment"> -->
            </v-col>
            <!-- <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              <v-autocomplete :items="EnvironmentList" v-model="selected_env" item-text="name" return-object label="Select Environment" class="EDC-UIComponent" dense></v-autocomplete>
            </v-col> -->
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
              <v-textarea v-model="description" label="Description" value rows="1" class="EDC-UIComponent" hide-details dense/>
            </v-col>
            <v-col cols="2" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
              <vc-button itemText="Save" @click.native="saveRole"></vc-button>
            <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
            </v-col>
          </v-row>

          <v-divider class="EDC-UIComponent"/>

          <v-row no-gutters class="EDC-Row EDC-GridRow">
            <v-col class="EDC-Col" cols="12">
              <!-- <edc-data-grid :dataList="tableList"></edc-data-grid> -->
              <edc-data-grid :dataList="tableList" @onDelete="onDelete" @onEdit="onEdit" @onNew="onNew" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate" @performTableSearch="performTableSearch"></edc-data-grid>
            </v-col>
            
          </v-row>

          <!-- <v-row no-gutters class="EDC-Row EDC-ButtonRow" justify="end">
            <vc-button itemText="Save" @click.native="saveRole"></vc-button>
            <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
          </v-row> -->

        </v-col>
      </v-card>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    <loading-panel :loader="loader"></loading-panel>
  </div>
</template>
<script>
import { post as postToServer } from "./../../methods/serverCall.js";
import { get as getToServer } from "./../../methods/serverCall.js";
import config from "../../config.json";
import { SERVER_ERROR } from "../../data/client_message.js";
import {
  SERVICE_ACTION_DETAILS,
  SET_ROLE_ACTION
} from "../../data/url_constants.js";
import {
		getTableList,
		getTableColumns,
    getTablesByDVEnv,
	} from "../../methods/agent_api_call.js";
import {getEnvironmentList, getEnvListForDataviewer} from '../../methods/EnvironmentList.js'
import { COLOR_CODE, BTN_COLOR ,CLIENT_SIDE} from "@/data/macros.js";
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import getUserRole from '../../methods/GetUserRole.js'
import { SECURITY_SERVICE } from '../../data/macros.js';

export default {
  data: function () {
    return {
      radioGroup: "administrator",
      outlineColor: BTN_COLOR,
      colorCode: COLOR_CODE,
      description:'',
      administratorServiceList:[],
      environmentalServiceList:[],
      service_ids_to_exculde:['scheduler','policy_service','engine_service','job_calendar','job_plan_service','process_approval_service','coordinator_service'],
      snackbar: false,
      snackbartext: "",
      colorValue: "error",
      snackbartimeout: Snackbar_Default_Timeout,
      loader: null,
      loading: false,
      selectAll: false,
      action_type: "",
      isDisabled:false,
      connection_str: "",
			schema: "",
			db_type: "",
      datasource_error: "",
			datasource_timer: "",
			datasource_timer_sec: 10000,
      selected_env:{},
      EnvironmentList:[],
      DatasourceList: [],
      env_name: '',
      role_name: "Untitled Role Name",
      role_type: "administrator",
      // object_type_list:[{name:"BUSINESS VIEW",value:"BV"},{name:"BUSINESS OBJECT",value:"BO"},{name:"DATAMAP",value:"DM"},{name:"DATABASE OBJECTS",value:"T"}],
      object_type_list:[],
      action_type_list:[],
      envIpJson :{
          "filter": [],
          "sort": [{
              "column_name": "",
              "type": ""
          }],
          "page": 1,
          "page_size": 10000
      },
      userRole:{},
      tableList: {
                headers: [
                { text: 'Sequence Number', value: 'sequence', width:"20%", option:[], 'item_text':'object_name', 'item_value':'object_type'},
                    { text: 'Object Type', value: 'object_type_name', width:"20%", option:[], 'item_text':'object_name', 'item_value':'object_name', useAutoComplete:true, isRoleSetup:true},
                    { text: 'Datasource Details', value: 'datasource_name', width:"20%", option:[], 'item_text':'datasource_name', 'item_value':'datasource_name', useAutoComplete:true, isRoleSetup:true},
                    { text: 'Action Type', value: 'action_type', width:"20%",option:[], 'item_text':'action_type', 'item_value':'action_type', useAutoComplete:true}, 
                    { text: 'Object Name', value: 'display_name', width:"60%", option:[], 'item_text':'display_name', 'item_value':'display_name',isTableLoader:true}], 
                actions: [],//if we need conditional action in row then provide key
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                is_row_edit:true,
                rows: [],
                itemkey:'id',
                selected_rows: [],
                syncHeaderScroll:false,
                hideExport:true,
                hideFilter:true,
                hideShowColumns:true,
                tableLoading:false,
                disableDefaltSorting:true,
                is_grid_inline_edit:true
            },
    };
  },
  computed:{
    isForcopy(){
      if(this.$route.params && this.$route.params.copy_env)
				return true
      else
        return false
			},
  },
  mounted() {
    // this.tableList =_.cloneDeep(adminList)
    this.userRole = this.$session.get('user_role_mapping');
    this.tableList.headers[1].option = this.object_type_list
    this.tableList.headers[3].option = this.action_type_list
    this.GetAllObjectTypes()
    this.GetAllActionTypes()
     this.tableList.actions = [
            {'text':'edit','key':"object_type", selectType:"single",role:this.userRole.is_superadmin ||getUserRole(this.userRole,SECURITY_SERVICE,UPDATE_ACTION), index: 1},
            {'text':'new','key':"object_type", 'enabled':this.Environment, role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,CREATE_ACTION), index: 2},
            {'text':"save","key":"is_row_editable", selectType:"single", index: 3},
            {'text':"cancel","key":"is_row_editable", selectType:"single", index: 4},
            {'text':'delete','key':"object_type", selectType:"single",role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,UPDATE_ACTION), index: 5}]
    if(this.$route.params && this.$route.params.role_id){
      this.getRoleDetails(this.$route.params.role_id)
    }
    if(this.$session.get('selected_env')){
            this.selected_env = this.$session.get('selected_env')
    }
    if(this.$route.params && this.$route.params.copy_env){
      this.selected_env =  this.$route.params.copy_env
      this.$session.set('selected_env', this.selected_env)
      this.role_name = "Copy of "+ this.role_name
    }
    this.env_name = this.selected_env.name
    this.get_datasource_list()
  },
  methods: {
    getEnvironmentList() {
            let _this = this;
            getEnvListForDataviewer(this,false).then(response=>{
                    if(response){
                        _this.EnvironmentList = response;
                    }
                },response=>{
                    // this.snackbar = true;
                    // this.colorValue = "error";
                    // this.snackbartext = response;
                })
        },
    cancel () {
     this.$router.push('/objrolelist');
  
    },
    checkindeterminate(action_details) {
      let found = false;
      let count = 0;
      action_details.map(function(obj) {
        if (obj.is_map) {
          found = true;
          count++;
        }
      });
      if (found && count !== action_details.length) return true;
      return false;
    },
    untitle_environment() {
      if (!this.role_name.trim()) {
        this.role_name = "Untitled Role Name";
      }
    },
     onValueUpdate(record) {
      this.tableList.headers[2].option = []
      let client_id = this.$session.get('client_id');
      let _this = this;
      let t_data = {
          'env_id': this.selected_env.id, 
          'client_id': client_id, 
          "filter": this.envIpJson.filter,
          "page": this.envIpJson.page,
          "page_size": this.envIpJson.page_size,
          "sort": this.envIpJson.sort
      }
      this.loader = true;
      if(record.object_type_name === "BV" || record.object_type_name === "Business View"){
        postToServer(this, config.PUBLISHER_URL +'/published_objects_in_env_for_dv', t_data, false).then(async response => {
                 _.forEach(response.result,function(obj){
                   obj.display_name = obj.object_des ? obj.object_name + " "+ obj.object_des:obj.object_name
                })
                var data =_.filter(response.result, { 'object_type': 'BV'});
                data.unshift({
                    'object_name':'All',
                    'object_description':'All',
                    "display_name":'All'
                });
                if(data){                                        
                    this.tableList.headers[4].option = data;
                } else {
                    data = []
                    this.tableList.headers[4].option =  [];
                }
              this.tableList.headers[2].option =  []
            _this.loader = false;
            }).catch(ProcessDocError => {
                _this.loader = false;
                if(ProcessDocError){
                    this.loader = null                     
                   this.tableList.headers[4].option =  [];                   
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
      }
      if(record.object_type_name === "BO" || record.object_type_name === "Business Object"){
        this.tableList.headers[2].option = _.cloneDeep(this.DatasourceList)
        if(!record.datasource_name){
          this.loader = false
          return
        }
        postToServer(this, config.PUBLISHER_URL +'/published_objects_in_env_for_dv', t_data, false).then(async response => {
             _.forEach(response.result,function(obj){
               obj.display_name = obj.object_des ? obj.object_name + " "+ obj.object_des:obj.object_name
            })
            var data =_.filter(response.result, { 'object_type': 'BO', 'ds_name': record.datasource_name});
            
            if(data && data.length){      
                data.unshift({
                  'object_name':'All',
                  'object_description':'All',
                  "display_name":'All'
              });                                  
              this.tableList.headers[4].option = data;
            } else {
              this.$nextTick(()=>{
                this.tableList.headers[4].option =  [];
              })
            }
        _this.loader = false;
        }).catch(ProcessDocError => {
            _this.loader = false;
            if(ProcessDocError){
                this.loader = null                     
               this.tableList.headers[4].option =  [];                   
            }
            else {                    
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;                    
            }
        });
      }
      if(record.object_type_name === "DM" || record.object_type_name === "Data Map"){

        postToServer(this, config.PUBLISHER_URL +'/published_objects_in_env_for_dv', t_data, false).then(response => {
                _.forEach(response.result,function(obj){
                   obj.display_name = obj.object_des ? obj.object_name + " "+ obj.object_des:obj.object_name
                })
                var data =_.filter(response.result, { 'object_type': 'DM'});
                data.unshift({
                    'object_name':'All',
                    'object_description':'All',
                    "display_name":'All'
                });
                if(data){                                        
                    this.tableList.headers[4].option = data;
                } else {
                    data = []
                    this.tableList.headers[4].option =  [];
                }
            this.tableList.headers[2].option =  []
            _this.loader = false;
            }).catch(ProcessDocError => {
                _this.loader = false;
                if(ProcessDocError){
                    this.loader = null                     
                   this.tableList.headers[4].option =  [];                   
                }
                else {                    
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;                    
                }
            });
      }
      if(record.object_type_name === "DBO" || record.object_type_name == "Table"){
        this.tableList.headers[2].option = _.cloneDeep(this.DatasourceList)
        if(!record.datasource_name){
          this.loader = false
          return
        }
			var env_id = _this.selected_env.id;
			// _this.tableListLoader = true;
			_this.datasource_error = "Connecting to business datasource";
			clearInterval(_this.datasource_timer);
			 // dictionary parameters
			var search_types = ["table","view","alias"]
      		var kwargs = {"description_needed":true,
                    "check_in_description":false,
                    "object_type":search_types}
      this.loader = true
			getTablesByDVEnv(
				_this,{'env_id':env_id,"description_needed":true,
                "check_in_description":false,
				"client_id": this.$session.get('client_id'),'search_text':record.object}
			).then(tableResponse => {
        _this.loader = false
        var tableList = []
         _.forEach(tableResponse.available_table_list,function(obj){
                  if(obj.datasource_name != record.datasource_name)
                    return
                   _.forEach(obj.table_list,function(el){
                    tableList.push({"object_name":el.title,
                              "object_description":el.description,
                              "display_name":el.description?el.title + " "+ el.description:el.title})
                })
                    // tableList.push(list)
                })
         tableList.unshift({
                    'object_name':'All',
                    'object_description':'All',
                    "display_name":'All'
                });
				_this.tableList.headers[4].option = tableList
			})
			.catch(errorResponse => {
        _this.loader = false
				_this.tableListLoader = false;
				_this.datasource_error =
					"Enabled to connect business datasource.Retry in " +
					_this.datasource_timer_sec / 1000 +
					" seconds";
				_this.datasource_timer = setInterval(
					_this.Get_Tables,
					_this.datasource_timer_sec
				);
				_this.datasource_timer_sec = _this.datasource_timer_sec * 2;
			});
        this.tableList.headers[4].option = [];
      }
    },
    onSave(record){
           let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(!this.role_id)
            {
               this.snackbar = true;
               this.colorValue = "error";
               this.snackbartext = "Save role first"
              return
            }
            if(!record.display_name || !record.object_type_name || !record.action_type)
            {
              this.snackbar = true;
              this.colorValue = "error";
              this.snackbartext = "Fill all the columns first"
              return
            }
            if(obj)
              delete obj.is_row_editable;
            var data = {}
            // this.tableList.rows[0]=obj
            var client_id = this.$session.get('client_id');
            let obj_details = _.find(this.tableList.headers[4].option,["display_name",record.display_name])
            if(!obj_details && this.$route.params && this.$route.params.copy_env)
                obj_details = record.object_details
            if(!record.object_details.object_based_master_id){
              
              var url = config.Security_URL + "/add_object_mapping_in_role";
              record.object_details = {"name":obj_details.object_name,
                                      "description":obj_details.object_description?obj_details.object_description:obj_details.object_desc?obj_details.object_desc:''}
              if(!record.object_type_name != "DBO")                                      
                record.object_details["id"]= obj_details.object_id
              else
                record.object_details["id"]= obj_details.object_name

              }
            else{
              var url = config.Security_URL + "/update_object_mapping_in_role";
              var master_id = record.object_details.object_based_master_id
              record.object_details = {"name":obj_details.object_name,
                                      "description":obj_details.object_description?obj_details.object_description:obj_details.object_desc?obj_details.object_desc:''}
              if(!record.object_type_name != "DBO")                                      
                record.object_details["id"]= obj_details.object_id
              else
                record.object_details["id"]= obj_details.object_name

              data["object_based_master_id"] = master_id
            }
            // this.tableList.headers[2].option = data;
           data["role_id"] = this.role_id
           data["client_id"] = parseInt(client_id)
           data["product_name"]= window.sessionStorage.getItem('product') 
           let object_type = _.find(this.tableList.headers[1].option,["object_name",record.object_type_name])
          //  data["product_name"] = window.sessionStorage.getItem('product') ,
           data["object_mapping_details"] = {"object_type":object_type.object_type,
                                              "action_type":record.action_type,
                                              "object_details":record.object_details}
            if(this.DatasourceList.length > 0 && record.datasource_name){
              data["datasource_name"] = record.datasource_name
              data["datasource_id"] = _.find(this.DatasourceList,['datasource_name',record.datasource_name]).datasource_id
            }
            else{
              data["datasource_name"] = null
              data["datasource_id"] = null
            }

            record.object = record.object_details
            postToServer(this, url, data)
                .then(response => {
                  this.snackbar = true;
                  if(this.$route.params &&this.$route.params.copy_env)
                    return
                  this.getRoleDetails(this.role_id)
                })
                .catch(DatasourceError => {
                  if (DatasourceError) {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = DatasourceError;
                  } else {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = SERVER_ERROR;
                  }
                });
        },
      onDelete(record){
              var data = {"object_based_master_id": record.object_details.object_based_master_id,
                          "client_id":this.$session.get('client_id'),
                          "role_id":record.role_id,
                          "product_name" :window.sessionStorage.getItem('product')}
              var url = config.Security_URL + "/remove_object_mapping_in_role";
            postToServer(this, url, data)
                .then(response => {
                  this.snackbar = true;
                  this.getRoleDetails(this.role_id)
                })
                .catch(DatasourceError => {
                  if (DatasourceError) {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = DatasourceError;
                  } else {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = SERVER_ERROR;
                  }
                });
      },
      getRoleDetails(role_id){
            var _this= this
            var client_id = this.$session.get('client_id');
              var data = {"role_id": role_id,
                "client_id": client_id,
                "product_name" :window.sessionStorage.getItem('product')}
              var url = config.Security_URL + "/get_object_based_roles_details";
            postToServer(this, url, data)
                .then(response => {
                  this.role_id = response.role_id
                  this.role_name = response.role
                  this.description = response.role_description
                  var index = 0
                  response.object_list.map(function(obj){
                    obj.object = obj.object_details.object_name
                    if(obj.object_details.object_description && obj.object_details.object_description == "All")
                      obj.display_name = obj.object_details.object_name
                    else
                      obj.display_name = obj.object_details.object_description? obj.object_details.object_name+ " "+obj.object_details.object_description:obj.object_details.object_name
                    index += 1
                    obj.sequence = index
                  })
                  response["object_name"]=response.object_list.object_name
                  this.tableList.rows = response.object_list
                })
                .catch(DatasourceError => {
                  if (DatasourceError) {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = DatasourceError;
                  } else {
                    this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = SERVER_ERROR;
                  }
                });
        },
     
     onNew(record){
            let obj = _.find(this.tableList.rows,["is_row_editable",true])
            if(this.selected_env.id){
                if(obj)
                {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = 'First fillup the open row';
                    return
                }
                this.tableList.rows.unshift({
                    'object_type':'',
                    'datasource_id': '',
                    'action_type':'',
                    'object_details':'',
                    'is_row_editable' : true
                });
            }
            else{
                this.snackbar = true;
                    this.colorValue = "error";
                    this.snackbartext = "Select Environment First";
            }              
        },
    onEdit(record){
        },
    onCancel(record){
      let obj = _.find(this.tableList.rows,["is_row_editable",true])
      if(record.id){
        delete obj.is_row_editable
        this.getRoleDetails(this.role_id)
        }
      else 
        this.tableList.rows.splice(0,1)
        },
    GetAllObjectTypes() {
      var _this = this
      var client_id = this.$session.get('client_id')
      var url = config.Security_URL + "/get_all_object_type";
      postToServer(_this, url).then(response => {
        if(response){
              // response.forEach(function(obj,idx){
              //   obj['unique_id'] = idx+1
              // })
              this.object_type_list = response;
               this.tableList.headers[1].option = this.object_type_list
        }
      }).catch(error_response => {
      }); 
    },
    GetAllActionTypes() {
      var _this = this
      var client_id = this.$session.get('client_id')
      var url = config.Security_URL + "/get_all_action_type";
      postToServer(_this, url).then(response => {
        if(response){
              // response.forEach(function(obj,idx){
              //   obj['unique_id'] = idx+1
              // })
              this.action_type_list = response;
               this.tableList.headers[3].option = this.action_type_list
        }
      }).catch(error_response => {
      }); 
    },
    saveRole() {
      var _this = this
      var client_id = this.$session.get('client_id');
      var data = {"role_name": this.role_name,
        "client_id": client_id,
        "role_type": "object_based",
        "description": this.description,
        "env_id": this.selected_env.id,
        "product_name" :window.sessionStorage.getItem('product') ,
        "env_name": this.selected_env.name,}
      if(!this.role_id || this.isForcopy)
        var url = config.Security_URL + "/set_object_based_role";

      else{
        var url = config.Security_URL + "/update_object_based_role";
        data["role_id"] = this.role_id
      }
      postToServer(this, url, data)
        .then(response => {
          this.snackbar = true;
          this.colorValue = "success";
          this.role_id = response.role_id
          this.snackbartext = "Role save Successfully";
           if(this.$route.params && this.$route.params.copy_env){
            this.tableList.rows .map(function(obj){   
                obj.role_id =  _this.role_id
                delete obj.object_details.object_based_master_id
                delete obj.object_based_master_id            
                   _this.onSave(obj)
                  })
            //when every rule is added remove copy_env params so it breaks copy loop
            delete this.$route.params.copy_env
           }
        })
        .catch(DatasourceError => {
          if (DatasourceError) {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = DatasourceError;
          } else {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = SERVER_ERROR;
          }
        });
    },
    performTableSearch(event){
				var _this = this
        let obj = _.find(this.tableList.rows,["is_row_editable",true])
        //search only for tables
        if(obj.object_type_name != "Table")
          return
        if(!obj.datasource_name)
          return
        let datasource_name = obj.datasource_name
        var data = {}
				data['search_text'] = event
				data['description_needed'] = true
				data['check_in_description'] = true
        data['env_id'] = _this.selected_env.id
        data['client_id'] = this.$session.get('client_id')
        _this.tableList.tableLoading = true
				getTablesByDVEnv(
						_this,data
				).then(tableResponse => {
					 _this.tableList.tableLoading = false
        var tableList = []
         _.forEach(tableResponse.available_table_list,function(obj){
                  if(obj.datasource_name!=datasource_name)
                    return
                   _.forEach(obj.table_list,function(el){
                    tableList.push({"object_name":el.title,
                              "object_description":el.description,
                              "display_name":el.description?el.title + " "+ el.description:el.title})
                })
                    // tableList.push(list)
                })
         tableList.unshift({
                    'object_name':'All',
                    'object_description':'All',
                    "display_name":'All'
                });
         _this.$nextTick(()=>{
				    _this.tableList.headers[4].option = tableList
         })
				})
				.catch(errorResponse => {
          _this.tableList.tableLoading = false
						this.indeterminate = false
						alert('error in server serach')
					});
			},
    
    get_datasource_list(){
      var _this = this
      this.DatasourceList = []
      _this.loader = true
      getToServer(this, config.ENVIRONMENT_API_URL+'/get_ds_id_name_using_env_id/'+this.selected_env.id, true).then(response =>{
          this.DatasourceList = response
          _this.loader = false
        }).catch(ProcessDocError => {
            _this.loader = false;
            if(ProcessDocError){
                this.loader = null                     
               this.tableList.headers[2].option =  [];                   
            }
            else {                    
                this.colorValue = 'error'
                this.snackbartext = SERVER_ERROR;                    
            }
        });
    }
  }
};
</script>
<style scoped>
.customCheckbox >>> label {
  top: 5px !important;
}
.v-expansion-panel >>> .v-expansion-panel__header {
  padding-bottom: 0px !important;
}

.EDC-UIComponent {
  margin: 0px !important;
  padding: 0px !important;
}

.radioClass >>> label {
  top: 3px !important;
}

.EDC-TopRow {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.EDC-ColDescription {
  margin-top: -7px !important;
}

.EDC-GridRow {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.EDC-ButtonRow {
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}
</style>