import io from 'socket.io-client';
import config from '../config.json';

export function live_details(that){
	try
	{
		if(!that || !that.$session || !that.$session.get('WEB_SOCKET_URL'))
			return false
		var socket = ""
		var socket_url = that.$session.get('WEB_SOCKET_URL')
		console.log('call to socket',socket_url)
		if(config.PROTOCOL === "HTTPS")
			socket =io(socket_url, {  secure: true, reconnect: true,rejectUnauthorized: false } );
		else
			socket = io(socket_url)
	    
		socket.on(that.$session.get('client_id'),function(data){
			var dataDetails = data.details	
			console.log('unhandled client level notification channel =>'+data.notificationType)
		})

		socket.on(that.$session.get('email'),function(data){
			switch(data.notificationType){
				case 'fileDownloadStatusUpdate':
					that.setPendingDownloadStatusUpdate(data)
					break;
				case 'fileDownloadPublic':
					that.setGeneralDownloadNotification(data)
					break;
				case 'secondary_count_update':
					that.setSecondaryCountUpdate(data)
				default:
					console.log('unhandled user level notification channel =>'+data.notificationType)
			}
		})

	}
	catch(error){

	}
}