/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Run': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M32.143 4.066a28 28 0 00-28 28 28 28 0 0028 28 28 28 0 0028-28 28 28 0 00-28-28zm-5.475 14.819a4.778 4.778 0 012.275.638l7.223 4.17 7.223 4.17a4.778 4.778 0 010 8.274l-7.223 4.17-7.223 4.17a4.778 4.778 0 01-7.166-4.137V23.66a4.778 4.778 0 014.891-4.775zm4.664 13.05v.129l.057-.033.054-.031-.054-.031-.057-.033z"/>'
  }
})
