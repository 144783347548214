<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>Agent List</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onAgentPing="onAgentPing"></edc-data-grid>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios';
import Breadcrumb from "../Breadcrumbs.vue"
import config from '../../config.json'
import {SERVER_ERROR} from '../../data/client_message.js'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../methods/serverCall.js';
import getUserRole from './../../methods/GetUserRole.js'
import { GETAGENTLIST, GETAGENTQUEUELIST, PINGAGENTQUEUE} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import _ from 'lodash';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
    name: 'Agentlist',
    
    data: function () {
        return {
            userRole:{},
            loader:false,
            tableList: {
                headers: [
                    { text: 'Machine Name', value: 'machine_name', sortable: false, width: '20%', options:[], cssClass:'w16' },
                    { text: 'OS Name', value: 'os_name', sortable: false, width: '20%', options:[], cssClass:'w16' },
                    { text: 'Host', value: 'ip_address', sortable: false, width: '20%', options:[], title: 'Agent Host' },
                    { text: 'Release Date', value: 'release_date', sortable: false, width: '20%', title: 'Release Date', dataType:'date'},
                    { text: 'Release Version', value: 'release_version', sortable: false, width: '20%', options:[], title: 'Release Version'},
                   
                ],
                actions:[{'text':'PingAgent','key':"machine_name", selectType:"single",index:1}],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                envIpJson : {
                    "filter": [],
                    "sort": {
                        "column": "",
                        "type": ""
                    },
                    "page": 1,
                    "page_size": 5
                },
                 hideFilter:true,
                hideExport:true,
                hideShowColumns:true,
            },
             snackbar:false,
             snackbartext:'',
             colorValue:'success',
             snackbartimeout: Snackbar_Default_Timeout,
             valid:true,
             isloading:false,
             menu:false,
            
        }
    },
    mounted() {
              this.get_agent_list();
    },
    methods: {
      get_agent_list(){
        let _this = this;
        var client_id = _this.$session.get('client_id')
        var input_data = {"client_id": client_id}
        var fetch_agent_endpoint = config.AGENT_MANAGEMENT_URL + GETAGENTLIST
        postToServer(this, fetch_agent_endpoint, input_data
          ).then(response => {
          if(response){
                _this.tableList.rows = response;
                _this.tableList.total_count =response.length; 
            }else{
                _this.tableList.rows = [];
                _this.tableList.total_count = 0; 
            }
          
        }).catch(error_response => {
          if(error_response){
              _this.loader = null 
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = error_response;
            }
            else {
              _this.snackbar = true
              _this.colorValue = 'error'
              _this.snackbartext = SERVER_ERROR;
            }
        });
      },
      
      onAgentPing(record){
           let _this = this;
           var client_id = _this.$session.get('client_id')
           var agent_id = record.id
            var input_data = {"client_id":client_id,
                              "agent_id":agent_id}
            var ping_agent_endpoint = config.AGENT_MANAGEMENT_URL + PINGAGENTQUEUE
            postToServer(this, ping_agent_endpoint, input_data
              ).then(response => {
                  _this.loader = null 
                  _this.snackbar = true
                  _this.colorValue = 'success'
                  _this.snackbartext = response;
            }).catch(error_response => {
              if(error_response){
                  _this.loader = null 
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = error_response;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
            });
            }
    }
}
</script>
<style scoped>
.smaller{
    width:20px;
    height:20px;
    font: 10px
}
</style>