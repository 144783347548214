/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Notification': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M30 7.383c-1.108 0-2 .892-2 2V12c-6.96 1.735-12 7.885-12 15.383 0 5 .025 7.326 0 12.617 0 0-11.543 7-8 7h48c3.543 0-8-7-8-7 .027-5.215 0-7.617 0-12.617C48 19.885 42.96 13.735 36 12V9.383c0-1.108-.892-2-2-2z" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M38 50H26a6 6 0 006 6 6 6 0 006-6z"/>'
  }
})
