<template>
	<v-card>
		<v-row class="EDC-Row">
			<v-toolbar dense flat class="EDC-Toolbar" elevation="1" style="border-radius:0px !important;">
				<v-toolbar-title>{{title || 'Column details'}}</v-toolbar-title>
			<v-spacer></v-spacer>
                <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
			</v-toolbar>
				<!-- <span style="float:right !important;text-align: right;padding-right:2%;padding-top:2%;"><v-icon small title="close" clickable @click="closePanel()">close</v-icon></span> -->
		</v-row>
		<v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:8px !important;">
			<v-col class="EDC-Col">
				<edc-data-grid gridFor="UdcLookup" @onRowSelected="onRowSelected" :dataList="UDCDataList" @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @radioSelectionChange="onRowSelected" @onSort="onSort"></edc-data-grid>
			</v-col>
		</v-row>
		<v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:8px !important;">
			<v-col class="EDC-Col" self-align="center">
				<vc-button style="margin-top:5px;" :disabled="!selectedRows.length" itemText="Select" @click.native="applyUDCFilter()"></vc-button>
			</v-col>
		</v-row>
	    <loading-panel :loader="loader"></loading-panel>

	</v-card>
</template>
<script>
	import {CLIENT_SIDE,SERVER_SIDE,EDC_GRID_PROPS} from '../data/macros.js'
	export default {
		name:'UDCViewer',
		props:{
			title:{
				type:String,
				default:''
			},
			firstHeader:{
				type:String,
				default:''
			},
			loader:{
				type:Boolean,
				default:false
			},
			rows:{
				type:Array,
				default:()=>{
					return []
				}
			},
			udcHeader:{
				type:Object,
				default:()=>{
					return {}
				}
			},
			gridUniqueID:{
				type:String
			},
			udcGridObj:{
				type:Object
			}
		},
		data(){
			return {
				UDCDataList:{
					headers:[
						{text:'',value:'radio',useRadio:true,hideSorting:true,hideInlineFilter:true,width:'20px'},
						{ text: 'FRDTAI',displayHeader:'FRDTAI', value: 'drky',  title: 'Alias',width:'150px'},
						{ text: 'Description1',displayHeader:'Description1', value: 'drdl01',  title: 'description1'},
						{ text: 'Description2', displayHeader:'Description2', value: 'drdl02',  title: 'description2'},
					],
			      	itemkey:'rn',
			      	rows:[],
			      	total_count:0,
			      	hideCaption:true,
			      	tableLoading:true,
			      	selected_rows:[],
			      	tableDescription:'',
			      	sorting_type: SERVER_SIDE,
			      	filterType: SERVER_SIDE,
			      	paginationType: SERVER_SIDE,
			      	is_in_tab:true,
			      	hideToggle:true,
			      	hideActions:true,
			      	hideFilter:true,
			      	hideExport:true,
			      	hideShowColumns:true,
			      	hideSelect:true,
					showRadio:true,
					showDeleteInlineFilterIcon: true
				},
				selectedRows:[],
				gridProps:{
			      "filter": [],
			      "filter_data":[],
			      "sort": [],
			      "page": 1,
			      "page_size": 10
			    },

			}
		},
		watch:{
			'title':{
				handler(newValue){
				},
			},
			// 'rows':{
			// 	handler(newValue){
			// 		this.UDCDataList.total_count = newValue.length
			// 		this.UDCDataList.rows = newValue
			// 	}
			// },
			'loader':{
				handler(newValue){

				}
			},
			udcHeader:{
				handler(newValue){
					this.gridProps =_.cloneDeep(EDC_GRID_PROPS)
					this.UDCDataList.headers[1].displayHeader = newValue.description
				}
			},
			udcGridObj:{
				handler(newValue){
					this.UDCDataList.total_count = newValue.total_count
					this.UDCDataList.rows = newValue.rows
				}
			},
			gridUniqueID:{
				handler(newValue){
					
				}
			}
		},
		mounted(){
			// this.loader = true
		},
		methods:{
			UpdateFilter(filters){
				this.gridProps['filter'] = filters
				this.$emit('getMoreUDCData',this.udcHeader,'multiple',this.gridProps)
			},
			onSort(sortJson){
				this.gridProps['sort'] = sortJson
				this.$emit('getMoreUDCData',this.udcHeader,'multiple',this.gridProps)
			},
			onRowSelected(selectedRow){
				this.selectedRows = []
				if(selectedRow && (selectedRow.rn || selectedRow.total_count))
					this.selectedRows =_.cloneDeep([selectedRow])
			},
			applyUDCFilter(){
				this.$emit('applyUDCFilter',this.selectedRows,this.udcHeader.column_name,'drky',this.gridProps)
			},
			onPageChange(pageNumber,perPage){
				this.gridProps['page'] = pageNumber
				this.gridProps['page_size'] = perPage
				this.$emit('getMoreUDCData',this.udcHeader,'multiple',this.gridProps)
			},
			onPerPageChange(perPage,pageNumber){
				this.gridProps['page'] = pageNumber
				this.gridProps['page_size'] = perPage
				this.$emit('getMoreUDCData',this.udcHeader,'multiple',this.gridProps)
			},
			closePanel(){
				this.$emit('closeUDCViewer')
			}
		}
	}
</script>
<style>
	.v-toolbar{
		height:24px;
	}
	.v-toolbar__content{
		height:24px;
	}
	.v-toolbar__title {
		font-size:15px;
	}
</style>