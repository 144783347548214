/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Download_PDF': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M54.797 43.964c0 6.083-4.931 11.014-11.014 11.014-6.083 0-11.014-4.93-11.014-11.014 0-6.082 4.931-11.014 11.014-11.014 6.083 0 11.014 4.932 11.014 11.014zM44 55H12.782a3.902 3.902 0 01-3.902-3.919V11.896c0-2.17 1.74-3.918 3.902-3.918H44a3.902 3.902 0 013.902 3.918V33.64" _fill="none" _stroke="#000" stroke-width="2"/><path pid="1" d="M44 52l3-4 3-4H38l3 4z"/><path pid="2" d="M45 48V36h-2v12z"/><path pid="3" d="M14.45 35.374c-1.268-1.268.104-3.011 3.829-4.862l2.343-1.165.913-1.997a78.645 78.645 0 001.666-3.984l.752-1.986-.518-1.47c-.638-1.807-.865-4.523-.46-5.5.547-1.323 2.342-1.187 3.053.231.555 1.108.498 3.113-.16 5.642l-.54 2.074.476.806c.261.444 1.024 1.497 1.696 2.34l1.262 1.57 1.57-.205c4.989-.65 6.697.456 6.697 2.042 0 2.002-3.917 2.168-7.206-.143-.74-.52-1.249-1.036-1.249-1.036s-2.06.42-3.075.693c-1.047.282-1.57.459-3.104.977 0 0-.538.782-.89 1.35-1.304 2.114-2.828 3.866-3.916 4.504-1.218.714-2.495.762-3.139.12zm1.991-.71c.713-.441 2.156-2.148 3.155-3.733l.405-.641-1.842.926c-2.844 1.43-4.146 2.778-3.469 3.593.38.458.835.42 1.751-.146zm18.477-5.187c.697-.489.596-1.473-.193-1.87-.613-.309-1.108-.372-2.702-.349-.98.067-2.554.265-2.821.325 0 0 .865.598 1.25.818.51.292 1.753.834 2.66 1.112.896.274 1.413.245 1.806-.036zm-7.43-3.088c-.422-.443-1.14-1.37-1.594-2.058C25.298 23.551 25 23 25 23s-.435 1.4-.792 2.241l-1.114 2.753-.322.624s1.716-.563 2.59-.79c.924-.242 2.801-.612 2.801-.612zm-2.394-9.601c.108-.903.154-1.806-.137-2.26-.805-.88-1.776-.147-1.612 1.946.056.704.23 1.907.464 2.649l.425 1.349.3-1.016c.164-.559.416-1.76.56-2.668z" _stroke="#000" stroke-width=".96"/>'
  }
})
