/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'More': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" cx="32.038" cy="12.039" r="8"/><circle pid="1" r="8" cy="32" cx="32.038"/><circle pid="2" r="8" cy="52.079" cx="32.038"/>'
  }
})
