<template>
	<v-card class="rounded-card">
		<!-- <v-row class="EDC-Row"> -->
			<!-- <v-col class="EDC-Col" align="start">
				<h6>Grid View For Table {{table_name}}</h6>
			</v-col>
			<v-col cols="1" class="EDC-Col pt-1" align="end">
				<span style="float:right !important;"><v-icon small title="close" clickable @click="closePanel">close</v-icon></span>
			</v-col> -->
            <v-toolbar class="EDC-Toolbar" dense elevation="1" style="border-radius:0px !important;">
                <v-toolbar-title class="EDC-ToolbarTitle">Grid View For Table {{table_name}}</v-toolbar-title>

                <v-spacer></v-spacer>
                <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
            </v-toolbar>
		<!-- </v-row> -->
		<v-row class="EDC-Row EDC-RowsSidePadding" style="padding-top:8px !important;">
			<v-col class="EDC-Col" cols="7">
				<v-text-field autocomplete="off" id="viewnametext"
				type="text" label="Enter View Name" v-model="view_name" hide-details></v-text-field>

				
			</v-col>
			<v-col class="EDC-Col" cols="5">

            </v-col>
		</v-row>
		<v-row class="EDC-Row EDC-RowsSidePadding ">
			<v-col class="EDC-Col">
				<v-tabs v-model="activeTab" non-linear @change="tabChanged">
            		<v-tab class="EDC-Tabs EDC-TabsTextAlign" key="tab1" title="Specify Columns For Selection">Select Columns</v-tab>
            		<v-tab class="EDC-Tabs EDC-TabsTextAlign" key="tab2" title="Specify Order">Row Order</v-tab>
                    <v-tab class="EDC-Tabs EDC-TabsTextAlign" key="tab3" title="Specify Filter">Filter</v-tab>
                    <v-tab class="EDC-Tabs EDC-TabsTextAlign" key="tab4" title="Specify Aggregate Filter">Agg. Filter</v-tab>
            		<v-tab class="EDC-Tabs EDC-TabsTextAlign" key="tab5" title="Other Options">Options</v-tab>
            		<!-- Tab item for Select Columns Tab-->
            		<v-tab-item>
            			<v-row class="EDC-Row">
            				<v-col class="EDC-Col" cols="5">
            					<v-card class="list-card">
                                    <v-row class="EDC-Row">
                                        <v-col class="EDC-Row" cols="12">
                                            <edc-toolbar class="EDC-Custom-Toolbar" key="available_columns_toolbar" customLabel="Search An Available Column" usedFor="list" bindWithElement="sourceColumnList" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>
                                        </v-col>
                                    </v-row>
            						<v-list dense id="sourceColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" height="400">
                                        <draggable v-model="sourceColumnList" @start="dragListStart($event,sourceColumnList,'source')" :options="dragOptions" :move="onMove" @end="selectDragEnd" id="dragSourceColumnList" group="AA">
            								<v-list-item dense selectable v-for="(item, i) in sourceColumnList" :key="i+'sourcecolumns'" :class="{'custom-list-item':true, 'selected-list-item':item.is_selected,'not-moved-item':!item.is_moved,'deselected-list-item':!item.is_selected}" @click="clickOnListItem($event,item,'sl',i,sourceColumnList)" v-show="!item.is_moved" :title="item.description">
            								{{item.display_name}}
            								</v-list-item>
                                        </draggable>
            						</v-list>
                             		<v-row class="EDC-Row" style="padding-top:8px !important;">
						                <v-col cols="6" class="EDC-Col" align="center">
						                </v-col>
						                <v-col cols="6" class="EDC-Col" align="right">
							               Total Available Columns: {{sourceColumnList.filter(item => item.is_moved === false).length}}
						                </v-col>
            					    </v-row>   
            					</v-card>
            				</v-col>
            				<v-col class="EDC-Col" cols="1" align-self="center">
            					<vc-button  itemText=">>" :disabled="!isSourceSelected" @click.native="moveSourceToTarget"></vc-button>
								<vc-button  itemText="<<" :disabled="!isTargetSelected" @click.native="moveTargetToSource"></vc-button>
            				</v-col>
            				<v-col class="EDC-Col" cols="6">
            					<v-card class="list-card" id="targetColumnListCard">
                                     <edc-toolbar class="EDC-Custom-Toolbar" key="selected_columns_toolbar" customLabel="Search A Selected Column" usedFor="list" bindWithElement="targetColumnList" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>

            						<v-list dense id="targetColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" max-height="300">
            							<draggable v-model="targetColumnList" @start="dragListStart($event,targetColumnList,'target')" :options="dragOptions" :move="onMove"  id="dragTargetColumnList"  @end="selectDragEnd" group="BB">
            								<v-list-item dense selectable v-for="(item, i) in targetColumnList" :key="i+'targetcolumns'" :class="{'custom-list-item':true, 'selected-list-item':item.is_selected,'not-moved-item':item.column_name,'deselected-list-item':!item.is_selected}">
                                                <v-col class="EDC-Col" @click="clickOnListItem($event,item,'tl',i,targetColumnList)" :title="item.description">
            								        {{item.display_name}}
                                                </v-col>
                                                <v-col class="EDC-Col" cols="1"  title="Show Related Description">

                                                    <svgicon  :class="{'svg-icon-grid':true, 'svg-fill-selected':item.has_description && item.show_desc,'svg-fill-not-selected':item.has_description && !item.show_desc,'svg-fill-disabled':!item.has_description}" name="document_v2"  title="Show Description" @click="item.has_description?item.show_desc=!item.show_desc:item.show_desc=false;"></svgicon>

                                                </v-col>
                                                <v-col cols="4" class="EDC-Col">
                                                    <v-autocomplete :disabled="!item.is_numeric_field" clearable dense  v-model="item.func" item-value="id" item-text="name" :items="funcList" autocomplete="off" hide-details></v-autocomplete>
                                                </v-col>
            								</v-list-item>
            							</draggable>
            						</v-list>
                                    <v-row class="EDC-Row" style="padding-top:8px !important;">
						                <v-col cols="8" class="EDC-Col" align="center">
						                </v-col>
						                <v-col cols="4" class="EDC-Col" align="right">
							               Selected Columns: {{targetColumnList.length}}
						                </v-col>
            					    </v-row>  
            					</v-card>
            				</v-col>
            			</v-row>
            		</v-tab-item>
            		<!-- Tab item for Specify Order Tab-->
            		<v-tab-item eager>
            			<v-row class="EDC-Row">
            				<v-col class="EDC-Col" cols="5">
            					<v-card class="list-card">
                                    <edc-toolbar class="EDC-Custom-Toolbar" key="available_order_columns_toolbar" customLabel="Columns Available For Order" usedFor="list" bindWithElement="sourceOrderColumnList" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>
            						<v-list dense id="sourceOrderColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" max-height="300">
                                        <draggable v-model="sourceOrderColumnList" @start="dragListStart($event,sourceOrderColumnList,'sourceorder')" :options="dragOptions" :move="onMove" id="dragSourceOrderColumnList"  @end="selectDragEnd" group="CC">
            							     <v-list-item dense selectable v-for="(item, i) in sourceOrderColumnList" :key="i+'sourcecolumnsfororder'" :class="{'custom-list-item':true, 'selected-list-item':item.is_selected, 'not-moved-item':!item.is_moved,'deselected-list-item':!item.is_selected}" v-show="!item.is_moved" @click="clickOnListItem($event,item,'osl',i,sourceOrderColumnList)" :title="item.description">
            								{{item.display_name}}
            							     </v-list-item>
                                        </draggable>
            						</v-list>
                                    <v-row class="EDC-Row" style="padding-top:8px !important;">
						                <v-col cols="6" class="EDC-Col" align="center">
							                    <!-- Selected Object: {{selectedNode.name || 'N/A'}} -->
						                </v-col>
						                <v-col cols="6" class="EDC-Col" align="right">
							               Total Available Columns: {{sourceOrderColumnList.filter(item => item.is_moved === false).length}}
						                </v-col>
            					    </v-row>   
            					</v-card>
            				</v-col>
            				<v-col class="EDC-Col" cols="1" align-self="center">
            					<vc-button  itemText=">>" :disabled="!isSourceOrderSelected" @click.native="addToOrderBy"></vc-button>
								<vc-button  itemText="<<" :disabled="!isColumnSelectedFromOrderBy" @click.native="removeFromOrderBy"></vc-button>
            				</v-col>
            				<v-col class="EDC-Col" cols="6">
            					<v-card class="list-card">
                                    <edc-toolbar class="EDC-Custom-Toolbar" key="selected_order_columns_toolbar" customLabel="Selected Order Columns" usedFor="list" bindWithElement="orderByColumnList" @perform-serach="performSearch" :clearTooltipIndex="clearTooltipIndex"></edc-toolbar>

            						<v-list dense id="orderByColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" max-height="300">
            							<draggable v-model="orderByColumnList" @start="dragListStart($event,orderByColumnList,'orderby')" :options="dragOptions" group="DD" :move="onMove" id="dragOrderByColumnList"  @end="selectDragEnd">
            								<v-list-item dense selectable v-for="(item, i) in orderByColumnList" :key="i+'ordercolumnList'" :class="{'custom-list-item':true, 'selected-list-item':item.is_selected,'not-moved-item':item.column_name,'deselected-list-item':!item.is_selected}" @click="clickOnListItem($event,item,'otl',i,orderByColumnList)" :title="item.description">
            									<v-col cols="8" class="EDC-Col">
            										{{item.display_name}}
            									</v-col>
            									<v-col cols="4" class="EDC-RowPadding" style="text-align:right !important;">
            										<toggle-button :key="i+'ordertoggle'"  :labels="{checked: 'ASC', unchecked: 'DESC'}"
                        							style="margin-bottom:0px !important;margin-right:5px !important;" v-model="item.order_type" :sync="true"  :width="64" :height="20" :color="{checked: '#607D8B', unchecked: '#607D8B', disabled: '#CCCCCC'}"/>
            									</v-col>
            								</v-list-item>
            							</draggable>
            						</v-list>
                                    <v-row class="EDC-Row" style="padding-top:8px !important;">
						                <v-col cols="8" class="EDC-Col" align="center">
							                    <!-- Selected Object: {{selectedNode.name || 'N/A'}} -->
						                </v-col>
						                <v-col cols="4" class="EDC-Col" align="right">
							               Selected Columns: {{orderByColumnList.length}}
						                </v-col>
            					    </v-row> 
            					</v-card>
            				</v-col>
            			</v-row>
            		</v-tab-item>
                    <!-- Tab item for Filter Tab-->
                    <v-tab-item>
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col" cols="12">
                                <new-edc-criteria @savedata="manageCriteria" :columns_for_filter="appliedFilter"  :column_name_list="sourceColumnList" :new_columns_for_filter="main_filterUI" eager :secondaryfilter="secondary_filterUI"></new-edc-criteria>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- Tab item for Having Tab-->
                    <v-tab-item>
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col" cols="12">
                                <edc-having  :functionList="funcList"  :availableColumnList="columnListForHaving" :table_name="table_name" @saveHaving="saveHaving" :havingColumnList="havingColumnList" eager></edc-having>
                            </v-col>
                        </v-row>
                    </v-tab-item>
            		<!-- Tab item for Default Tab-->
            		<v-tab-item>
            			<v-row class="EDC-Row">
            				<v-col class="EDC-Col" cols="12">
            					<v-card class="list-card" style="margin: 0px !important;padding-top: 8px !important;">
                                    <v-row class="EDC-Row">
                                        <v-col cols="4" align="start" justify="start" class="EDC-RowPadding">
                                            Description As Header
                                        </v-col>
                                        <v-col cols="2" align="start" justify="start" class="EDC-RowPadding">
                                            <toggle-button key="coltoggle" :labels="{checked: 'Yes', unchecked: 'No'}"
                                            style="margin-bottom:0px !important;" v-model="showDescriptionAsHeader" :sync="true" :width="60" :color="{checked: '#607D8B', unchecked: '#607D8B', disabled: '#CCCCCC'}"/>
                                        </v-col>
                                    </v-row>
            						<v-row class="EDC-Row EDC-RowPadding">
                                        <v-col cols="4" align="start" justify="start" class="EDC-RowPadding">
                                            Perform Summarization 
                                        </v-col>
                                        <v-col cols="2" align="start" justify="start" class="EDC-RowPadding">
                                            <toggle-button key="summarytoggle"  :labels="{checked: 'Yes', unchecked: 'No'}"
                                            style="margin-bottom:0px !important;margin-right:5px !important;" v-model="showSummaryRow" :sync="true" :width="60" :color="{checked: '#607D8B', unchecked: '#607D8B', disabled: '#CCCCCC'}"/>
                                        </v-col>
                                    </v-row>
                                    <v-row class="EDC-Row EDC-RowPadding">
                                        <v-col cols="4" align="start" justify="start" class="EDC-RowPadding">
                                            Freeze Selection & Attachment Columns
                                        </v-col>
                                        <v-col cols="2" align="start" justify="start" class="EDC-RowPadding">
                                            <toggle-button key="summarytoggle"  :labels="{checked: 'Yes', unchecked: 'No'}"
                                            style="margin-bottom:0px !important;margin-right:5px !important;" v-model="freezeSelectionAndAttachementColumns" :sync="true" :width="60" :color="{checked: '#607D8B', unchecked: '#607D8B', disabled: '#CCCCCC'}"/>
                                        </v-col>
                                    </v-row>
            					</v-card>
            				</v-col>
            			</v-row>
            		</v-tab-item>
            	</v-tabs>

			</v-col>
		</v-row>
		<v-row class="EDC-Row" style="padding-top:16px !important;padding-bottom:4px !important;">
			<v-col class="EDC-Col" cols="12">
				<!-- <vc-button  itemText="Cancel" style="float:right;"></vc-button> -->
				<!-- <vc-button  itemText="Apply" :disabled="!isSelectionValidForSave" @click.native="applyCustomization" v-if="showApplyButton"></vc-button> -->
				<vc-button  itemText="Save" :disabled="!isSelectionValidForSave" @click.native="validateObject"></vc-button>
			</v-col>
		</v-row>
		<loading-panel :loader="loader"></loading-panel>

	</v-card>
</template>
<script>
	import draggable from 'vuedraggable'
    import config from '../../config.json'
    import edctoolbar from './../../views/edctoolbar.vue'
    import { post as postToServer } from './../../methods/serverCall.js';
    import edccriteria from './../../views/newcriteria.vue'
    import edchaving from './../../views/edchaving.vue'
	export default {
		name:'edccustomview',
		components:{
			draggable,
            'edc-toolbar':edctoolbar,
            'new-edc-criteria':edccriteria,
            'edc-having':edchaving,
		},
		props:{
			objectForCustomize:{
				type:Object
			},
            selectedEnvironment:{
                type:Object
            }
		},
		data(){
			return {
                main_filterUI:[{
                    's_parentheses': '',
                    'column_name': '',
                    'operator': '',
                    'v_type1': 'Value',
                    'v_type2': 'Value',
                    'value1': '',
                    'value2':'',
                    'e_parentheses': '',
                    'is_drv_table': false,
                    'ref_row_id':'',
                    'ref_step_id':''
                }],
                secondary_filterUI:{groupList:[]},
                needToLoadSecondaryFilter:false, // some how secondary filter not get loaded firsttime
				availableViewList:[],
				selectedView:'',
				table_name:'Test',
				activeTab:0,
				sourceColumnList:[],
				targetColumnList:[],
				orderByColumnList:[],
				sourceOrderColumnList:[],
				gridUniqueId:'',
				clickOnCreateNew:false,
				view_name:'Unititled View',
				loader:false,
                showSummaryRow:false,
                showDescriptionAsHeader:true,
                funcList:[{'id':'sum','name':'Sum'},{'id':'count','name':'Count'},{'id':'avg','name':'Avg'},{'id':'min','name':'Min'},{'id':'max','name':'Max'}],
                appliedFilter:[],
                savedFilter:[],
                columnListForHaving:[],
                havingColumnList:[],
                savedHaving:[],
                clearTooltipIndex:0,
                lastSelectedItem:{},
                dragStartBy:'',
                freezeSelectionAndAttachementColumns:true
			}
		},
		computed:{
			dragOptions(){
			    return  {
			      animation: 0,
			      group: 'description',
			    };
			},
			isSourceSelected(){
				if(_.find(this.sourceColumnList,['is_selected',true]))
					return true
				return false
			},
			isTargetSelected(){
				if(_.find(this.targetColumnList,function(obj){
					return obj && obj.is_selected
				}))
					return true
				return false
			},
			isColumnSelectedFromOrderBy(){
				if(_.find(this.orderByColumnList,['is_selected',true]))
					return true
				return false
			},
			isSelectionValidForSave(){
                // as per the Nirav suggestion, we should not restrict to the user to create the gridview
                // 18th Aug 2023 meeting
                return true
				// return  (this.targetColumnList && this.targetColumnList.length>0) 
                // || (this.savedFilter && this.savedFilter.length >0 && this.savedFilter[0]['column_name']) 
                // || (this.orderByColumnList && this.orderByColumnList.length>0)
			},
			isSourceOrderSelected(){
				if(_.find(this.sourceOrderColumnList,['is_selected',true]))
					return true
				return false
			},
			isCreateNewReq(){
				return !this.availableViewList.length || this.clickOnCreateNew
			},
            showApplyButton(){
                return !this.selectedView || this.selectedView == 'GV00' || this.selectedView ==='GV01' || this.objectForCustomize.action ==='delete' 
            }
		},
		watch:{
			objectForCustomize:{
				handler(newvalue){
					this.ResetAll()
                    this.clearTooltipIndex++
					if(newvalue.available_columns){
						this.applyDefaultProps(_.cloneDeep(newvalue.available_columns),newvalue.primary_keys)
					}
					if(newvalue.table_name){
						this.table_name = newvalue.table_name
                        this.view_name = 'Unititled View For '+this.table_name
                    }
					if(newvalue.gridUniqueId)
						this.gridUniqueId = newvalue.unique_id
                    if(newvalue.selectedView){
                        this.selectedView = newvalue.selectedView
                        if(this.selectedView != 'GV00' && this.selectedView !='GV01' && newvalue.action !='delete')
                            this.fetchViewDetailsById(newvalue.selectedView,newvalue.action)
                    }
				}
			},
            selectedEnvironment:{
                handler(newvalue){

                }
            },
            targetColumnList:{
                handler(newvalue){
                    // here we need to remove description column from order by if the actual column or its description is not a part of the target row selection.

                },
                deep:true
            },
		},
		mounted(){
            var _this = this
            var htmlTargetColumnList = document.getElementById("targetColumnList");
            htmlTargetColumnList.addEventListener("drop", dropOnTarget);
            htmlTargetColumnList.addEventListener("dragover", dragOverTarget);
            function dropOnTarget(event){
                if(_this.dragStartBy === 'source')
                    _this.moveSourceToTarget()
                else
                    _this.clearSelectedCols(_this.targetColumnList)
            }
            function dragOverTarget(event){
                if(_this.dragStartBy === 'source')
                    event.preventDefault()
            }

            var htmlSourceColumnList = document.getElementById("sourceColumnList");
            htmlSourceColumnList.addEventListener("drop", dropOnSource);
            htmlSourceColumnList.addEventListener("dragover", dragOverSource);
            function dropOnSource(event){
                if(_this.dragStartBy === 'target')
                    _this.moveTargetToSource()
                else
                    _this.clearSelectedCols(_this.sourceColumnList)
            }
            function dragOverSource(event){
                if(_this.dragStartBy === 'target')
                    event.preventDefault()
            } 
            
            var htmlOrderByColumnList = document.getElementById("orderByColumnList");
            htmlOrderByColumnList.addEventListener("drop", dropOnOrderByColumn);
            htmlOrderByColumnList.addEventListener("dragover", dragOverOrderByColumn);
            function dropOnOrderByColumn(event){
                if(_this.dragStartBy === 'sourceorder')
                    _this.addToOrderBy()
                else
                    _this.clearSelectedCols(_this.orderByColumnList)
            }
            function dragOverOrderByColumn(event){
                if(_this.dragStartBy === 'sourceorder')
                    event.preventDefault()
            }   


            var htmlSourceOrderByColumnList = document.getElementById("sourceOrderColumnList");
            htmlSourceOrderByColumnList.addEventListener("drop", dropOnSourceOrderBy);
            htmlSourceOrderByColumnList.addEventListener("dragover", dragOverOrderBy);
            function dropOnSourceOrderBy(event){
                if(_this.dragStartBy === 'orderby')
                    _this.removeFromOrderBy()
                else
                    _this.clearSelectedCols(_this.sourceOrderColumnList)
            }
            function dragOverOrderBy(event){
                if(_this.dragStartBy === 'orderby')
                    event.preventDefault()
            }
            
		},
		methods:{
            clearSelectedCols(columnList){
                // on drag start is performing item.is_selected = true. If user change the item order inside the same list then that item remain selected
                _.forEach(columnList,(obj)=>{
                    obj.is_selected = false
                })
            },
			closePanel(){
				this.$emit('closePanel')
			},
			tabChanged(index){
                let _this = this
                _this.columnListForHaving = _.filter(_this.targetColumnList,(obj)=>{
                    return obj.func && obj.func != ''
                })
                if(index === 1){
                    // requirement on hold. Because there are the columns which work with late binding.
                    return

                    // remove all _is_temp_desc columns from sourceOrderColunmList
                    _.remove(_this.sourceOrderColumnList,(obj)=>{
                        return obj.is_temp_desc_col
                    })

                    // We have to manage description column in the source order column list
                    _.forEach(_this.targetColumnList,(obj)=>{
                        if(!obj.show_desc)
                            return
                        // if now find the index of actual column and then add that column with description property next to that column
                        let col_index = _.findIndex(_this.sourceOrderColumnList,(ind)=>{
                            return ind.column_name === obj.column_name
                        })
                        if(col_index < 0)
                            return
                        let descColObj = _.cloneDeep(_this.sourceOrderColumnList[col_index])
                        descColObj.column_name = obj.column_name+'_DESCRIPTION'
                        descColObj.is_temp_desc_col = true
                        descColObj.display_name = _this.getColumnDisplayName(descColObj)
                        _this.sourceOrderColumnList.splice(col_index+1,0,descColObj)
                    })
                }
			},
			clickOnListItem(event,item,itemBelongTo,itemIndex,itemList){
                /*Item belong to
                    sl - source list
                    tl - target list
                    osl - order source list
                    otl - order target list
                */
                let _this = this
				item.is_selected = !item.is_selected
                if(!item.is_selected)
                {
                    delete _this.lastSelectedItem[itemBelongTo]
                    return
                }
                /*
                    **Multiselect functionality implementation.There has to all below assumptions should be TRUE
                        1) There has to be event object and event.shiftKey = True
                        2) Last selected item for that list, should be selected true
                        3) itemList length should be > 1
                
                    **Logic
                        If above assumptions are true , then make select all columns which come between current and last selected. For traversing, go from lower to upper index.
                */

                if(event && event.shiftKey && itemList.length > 1 && _this.lastSelectedItem[itemBelongTo]>=0){
                    let lowerIndex = _this.lastSelectedItem[itemBelongTo]
                    let upperIndex = itemIndex
                    if(lowerIndex > upperIndex){
                        lowerIndex = itemIndex
                        upperIndex = _this.lastSelectedItem[itemBelongTo]
                    }
                    for(let i=lowerIndex;i<=upperIndex;i++)
                        itemList[i].is_selected = true
                }
                _this.lastSelectedItem[itemBelongTo] = itemIndex
			},
			startDrag(event){

			},
			onMove ({relatedContext, draggedContext}) {

    		},
    		moveSourceToTarget(defined_sequence=[],removed_columns=[]){
    			// first fetch the all selected
    			var selectedItems = _.filter(this.sourceColumnList,function(obj){
    				if(obj.is_selected){
    					obj.is_moved = true
                    }
    				return obj.is_selected
    			})

    			// before moving the value to the target make all selected null
    			_.forEach(selectedItems,(obj)=>{
    				obj.is_selected = false
                    obj.is_numeric_field = false
                    // align 'right' means its number
                    if(obj['align'] === 'right'){
                        obj.is_numeric_field = true
                    }
    			})

                selectedItems = this.rearrangeItemsInSeqeunce(defined_sequence, selectedItems)
                
    			this.targetColumnList = _.union(this.targetColumnList,_.cloneDeep(selectedItems))
    		},
            rearrangeItemsInSeqeunce(defined_sequence,items,input_type='object'){
                if(!defined_sequence || !defined_sequence.length)
                    return items

                let itemsInSequence = []
                _.forEach(defined_sequence,(obj)=>{
                    if(input_type === 'object')
                        var item = _.find(items,['column_name',obj.column_name])
                    else if(input_type === 'list')
                        var item = _.find(items,['column_name',obj[0]])

                    itemsInSequence.push(item)
                })
                return itemsInSequence
            },
    		moveTargetToSource(){
    			let _this = this
    			var selectedItems = _.remove(this.targetColumnList,function(obj){
    				return obj.is_selected 
    			})
    			_.forEach(selectedItems,function(obj){
    				let sourceObj = _.find(_this.sourceColumnList,['text',obj.text])
    				if(sourceObj){
    					sourceObj.is_moved = false
    					obj.is_selected = false
    				}
    			})
    		},
    		addToOrderBy(defined_sequence=[]){
    			let _this = this
    			let selectedForOrderBy = _.filter(this.sourceOrderColumnList,function(obj){
    				if(obj.is_selected)
    					obj.is_moved = true
    				return obj.is_selected
    			})

    			// before moving the value to the target make all selected null
    			_.forEach(selectedForOrderBy,(obj)=>{
    				obj.is_selected = false
                    if(obj.is_desc)
                    {
                        obj.order_type = false
                        delete obj.is_desc
                    }
                    else
    				    obj.order_type = true
    			})
                selectedForOrderBy = _this.rearrangeItemsInSeqeunce(defined_sequence,selectedForOrderBy,'list')
    			this.orderByColumnList = _.union(this.orderByColumnList,_.cloneDeep(selectedForOrderBy))
    		},
    		removeFromOrderBy(){
    			let _this = this
                var selectedItems = _.remove(this.orderByColumnList,function(obj){
                    return obj.is_selected 
                })
                _.forEach(selectedItems,function(obj){
                    let sourceObj = _.find(_this.sourceOrderColumnList,['text',obj.text])
                    if(sourceObj){
                        sourceObj.is_moved = false
                        obj.is_selected = false
                    }
                })
    		},
    		applyDefaultProps(columns){
    			let _this = this
                var nonTempCols = _.remove(columns,function(obj){
                    return !obj.is_temp_col || obj.is_secondary_col
                })
    			_.forEach(nonTempCols,(obj)=>{
                    obj.is_moved = false
                    obj.is_selected = false
                    obj.func = ''
                    obj.show_desc = false
                    obj.display_name = _this.getColumnDisplayName(obj)
    			})
                _this.sourceColumnList = _.cloneDeep(nonTempCols)
                _this.sourceOrderColumnList = _.cloneDeep(nonTempCols)
    		},
    		getColumnDisplayName(item){
    			return item.description + ' ('+item.column_name+')'    			
    		},
    		applyOrderBy(columns,is_default=false){
    			// is_default is for apply default order on primary keys. primary keys is the list of string. But in database we are saving a list of json which contain the column_name and order_type {"column_name":'PHKCOO','order_type':'asc'}
    			let _this = this
    			_.forEach(columns,(obj)=>{
    				let column_name = obj
    				if(!is_default)
    					column_name = obj.column_name
    				let orderByCol = _.find(_this.sourceOrderColumnList,['column_name',column_name])
    				if(orderByCol){
    					orderByCol.is_moved = true
    					orderByCol.order_type = true
    					if(!is_default && obj.order_type === 'desc')
    						orderByCol.order_type = false
    					this.orderByColumnList.push(_.cloneDeep(orderByCol))
    				}
    			})
    		},

    		ResetAllList(){
    			this.sourceColumnList = []
    			this.targetColumnList = []
    			this.orderByColumnList = []
    			this.sourceOrderColumnList = []
    		},
    		ResetAll(){
                this.showSummaryRow = false
                this.activeTab = 0
    			this.table_name = ''
				this.gridUniqueId = ''
                this.selectedView = ''
                this.main_filterUI = [{
                's_parentheses': '',
                'column_name': '',
                'operator': '',
                'v_type1': 'Value',
                'v_type2': 'Value',
                'value1': '',
                'value2':'',
                'e_parentheses': '',
                'is_drv_table': false,
                'ref_row_id':'',
                'ref_step_id':''
              }]
              this.secondary_filterUI = {groupList:[]} 
              this.needToLoadSecondaryFilter = false
              this.appliedFilter = []
              this.savedFilter = []
				this.ResetAllList()
    		},
    		applyCustomization(){
    			let customisedData = this.getCustomizeData()
                customisedData['typeOfCustomization'] = 'onlyApply'
    			this.$emit('applyCustomization',customisedData)
    		},
    		getCustomizeData(){
    			let _this = this
                let columnsForSelect = []

                // New flow as per the Ed's suggestion user can only apply filters or sort order. In that case we
                // have to map all columns as a selected columns
                var isWithoutTargetSelection = false
                if(!this.targetColumnList && this.isSelectionValidForSave){
                    this.targetColumnList = _.cloneDeep(this.sourceColumnList)
                    isWithoutTargetSelection = true
                }

                _.forEach(_this.targetColumnList,function(obj){
                    let table_name = ''
                    if(obj.table_name && !obj.is_delete)
                        table_name = obj.table_name
                    columnsForSelect.push({"column_name":obj.column_name,'func':obj.func,'show_desc':obj.show_desc,"table_name":table_name,'datasource_index':obj.datasource_index})
                })

                let columnsForOrder = []
                _.forEach(this.orderByColumnList,(obj)=>{
                    let type = 'asc'
                    if(!obj.order_type)
                        type = 'desc'
                    columnsForOrder.push([obj.column_name,type])
                })

                let revisedColumnsForOrder = this.validateOrderBy(columnsForOrder,columnsForSelect)
                let revisedHaving = this.ValidateHaving(this.savedHaving,columnsForSelect)
    			return {"table_name":this.table_name,"columnsForSelect":columnsForSelect,'columnsForOrder':revisedColumnsForOrder,"showSummaryRow":this.showSummaryRow,"showDescriptionAsHeader":this.showDescriptionAsHeader,
                    'view_filter':this.savedFilter,"view_having":revisedHaving,"main_filterUI":this.main_filterUI,"secondary_filterUI":this.secondary_filterUI,
                    'freezeSelectionAndAttachementColumns':_this.freezeSelectionAndAttachementColumns,"is_without_target_selection":isWithoutTargetSelection}
    		},
            validateOrderBy(columnsForOrder,selectedColumns){
                // database not support order by on the column , if that column not specified in selection & one of the selection column contain the aggregate function. Here we will remove that column if order by column not in selection and selection has aggregate
                if(!columnsForOrder || !columnsForOrder.length)
                    return []
                let isAnySelectHasAggregate = _.filter(selectedColumns,(obj)=>{
                    return obj.func && obj.func != ''
                })
                if(!isAnySelectHasAggregate || !isAnySelectHasAggregate.length)
                    return columnsForOrder

                let revisedColumnsForOrder = []
                for(var i=0;i<columnsForOrder.length;i++){
                    let obj = _.find(selectedColumns,["column_name",columnsForOrder[i][0]])
                    if(obj)
                        revisedColumnsForOrder.push(columnsForOrder[i])
                }
                return revisedColumnsForOrder
            },
            ValidateHaving(having,selectedColumns){
                // database not support having on the column , if that column not specified in selection . Here we will remove that column if  that column not specified in selection 
                if(!having || !having.length)
                    return []
                let revisedHaving = []
                for(var i=0;i<having.length;i++){
                    let obj = _.find(selectedColumns,["column_name",having[i].column_name])
                    if(obj)
                        revisedHaving.push(having[i])
                }
                if(revisedHaving.length)
                    delete revisedHaving[revisedHaving.length-1]['operand']
                return revisedHaving
            },
            validateObject(){
            	let _this = this
            	let view_id = ''
    			if(this.selectedView != 'GV00'){
    				view_id = this.selectedView
                }
                let dataToPass = {
                	"object_id":view_id, "object_name":this.view_name, "client_id":this.$session.get("client_id"),"table_name":this.table_name
                }
                postToServer(this,config.DATA_VIEWER_URL+'/validate_gridview',dataToPass).then(response=>{
                	_this.saveCustomView()
                }).catch(error=>{
                	if(!error)
                		error = " Unable to save gridview"
                	alert(error)
                })
            },
    		saveCustomView(){
    			let _this = this
    			let view_id = ''
                let addNew = true
    			if(this.selectedView != 'GV00' && this.selectedView != 'GV01'){
    				view_id = this.selectedView
                    addNew = false
                }
                let customisedData = _this.getCustomizeData()
    			let viewDataForRepository = {
    				"object_name":this.view_name, "client_id":this.$session.get("client_id"),
                    "object_id":view_id,"object_type_code":'GV',"object_revision":"1", 
                    "object_json": customisedData,"description":"", 
                    "added_by":this.$session.get('email'), 
                    'erp_code':'CST','erp_version':'001',
                    'env_id':_this.selectedEnvironment.id,
                    'env_name':_this.selectedEnvironment.name
                }
                _this.loader = true
                postToServer(this, config.REPOSITORY_API_URL + '/manage_object',viewDataForRepository,true).then(response=>{
                _this.loader = false
                let object_id = response.object_id
                let object_name = response.object_name
                customisedData["object_name"]= object_name
                customisedData["client_id"]= _this.$session.get("client_id")
                customisedData["object_id"] = object_id
                customisedData["view_id"] =_this.selectedView
                customisedData['typeOfCustomization'] = 'saveAndApply'
                // this call we are passing for add/edit because use can change the name of view as well
                _this.addViewMapping(object_id)
                }).catch(error=>{
                    this.loader = false
                })
    		},
    		addViewMapping(object_id){
                var _this =this
                let viewTableMapping = {
    				"object_name":this.view_name, 
                    "client_id":this.$session.get("client_id"),
                    "object_id":object_id,
                    "created_by":this.$session.get('email'),
                    'env_id':_this.selectedEnvironment.id,
                    "table_name":this.table_name
                }
                this.loader = true
                postToServer(_this,config.DATA_VIEWER_URL+'/manage_table_view_mapping',viewTableMapping).then(response=>{
                    this.loader = false
                    _this.$emit('applyCustomization',object_id)
                }).catch(error=>{
                    this.loader = false
                })
            },
            performSearch(searchText,listid){
                var columnList = document.getElementById(listid)
                var list_items = columnList.getElementsByClassName('not-moved-item')
                var filter = searchText.toUpperCase()
                for(var i = 0; i < list_items.length; i++){
                    if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1) 
                      list_items[i].style.display = "";
                    else
                      list_items[i].style.display = "none";
                }
            },
            fetchViewDetailsById(view_id,action){
                let _this = this
                let data = {"object_id":view_id,"object_revision":"1","client_id":this.$session.get('client_id')}
                _this.loader = true
                postToServer(this, config.REPOSITORY_API_URL + '/fetch_object_details',data,true).then(response=>{
                    _this.loader = false
                    console.log('repo resp',response)
                    if(action === 'copy'){
                        this.selectedView = ''
                        this.view_name = "Copy Of "+response.object_name
                    }
                    else{
                        this.view_name = response.object_name
                    }
                    _this.manageServerResponse(response.object_json)
                }).catch(error=>{
                    _this.loader = false
                    alert('error in fetch')
                })

            },
            manageServerResponse(object_json){
                let _this = this
                
                _this.showSummaryRow = object_json.showSummaryRow
                _this.freezeSelectionAndAttachementColumns = true
                if(object_json.freezeSelectionAndAttachementColumns === false)
                    _this.freezeSelectionAndAttachementColumns = object_json.freezeSelectionAndAttachementColumns

                _this.showDescriptionAsHeader = object_json.showDescriptionAsHeader
                if(object_json.view_filter && object_json.view_filter.length){
                    _this.appliedFilter = object_json.view_filter
                    if(!object_json.main_filterUI)
                        _this.main_filterUI = object_json.view_filter
                    else
                        _this.main_filterUI = object_json.main_filterUI
                    if(object_json.secondary_filterUI){
                        _this.secondary_filterUI = object_json.secondary_filterUI
                        _this.needToLoadSecondaryFilter = true
                    }
                    _this.savedFilter =  _.cloneDeep(object_json.view_filter)
                }
                if(object_json.view_having && object_json.view_having.length){
                    _this.havingColumnList = object_json.view_having
                    _this.savedHaving = _.cloneDeep(object_json.view_having)
                }
                let columns_for_select = []
                let columns_for_order  = []
                let removed_column_from_select=[]
                let removed_column_from_order_by=[]
                /* In case of business view, user can remove the column after grid view creation , so we need to more specific about the selected column + order column*/
                for (let i=0;i<object_json.columnsForSelect.length;i++){
                    // check column exist in sourcelist
                    let column_name = object_json.columnsForSelect[i].column_name
                    let obj = _.find(_this.sourceColumnList,['column_name',column_name])
                    if(obj)
                        columns_for_select.push(object_json.columnsForSelect[i])
                    else
                        removed_column_from_select.push(object_json.columnsForSelect[i])

                }
                for (let i=0;i<object_json.columnsForOrder.length;i++){
                    // check column exist in sourcelist
                    let column_name = object_json.columnsForOrder[i][0]
                    let obj = _.find(_this.sourceColumnList,['column_name',column_name])
                    if(obj)
                        columns_for_order.push(object_json.columnsForOrder[i])
                    else
                        removed_column_from_order_by.push(object_json.columnsForOrder[i])
                }
                // create target list based on the columns for select
                _.forEach(columns_for_select, function(obj){
                    for(let i=0;i<_this.sourceColumnList.length;i++){
                        if(_this.sourceColumnList[i].column_name === obj.column_name){
                            _this.sourceColumnList[i].is_selected = true
                            _this.sourceColumnList[i].func = obj.func
                            _this.sourceColumnList[i].show_desc = obj.show_desc
                            break;
                        }
                    }
                })
                if(!object_json.is_without_target_selection)
                    _this.moveSourceToTarget(columns_for_select,removed_column_from_select)

                _.forEach(columns_for_order, function(obj){
                    for(let i=0;i<_this.sourceOrderColumnList.length;i++){
                        if(_this.sourceOrderColumnList[i].column_name === obj[0]){
                            _this.sourceOrderColumnList[i].is_selected = true
                            if(obj[1] === 'desc')
                                _this.sourceOrderColumnList[i].is_desc = true
                            break;
                        }
                    }
                })
                _this.addToOrderBy(columns_for_order)
            },
            manageCriteria(updated_criteria,main_filter,secondary_filter){
                this.savedFilter = []
                for(var i=0;i<updated_criteria.length;i++){
                    if(updated_criteria[i].column_name && updated_criteria[i].operator)
                        this.savedFilter.push(_.cloneDeep(updated_criteria[i]))
                }
                if(this.savedFilter.length > 0)
                    delete this.savedFilter[this.savedFilter.length-1]['operation']
                this.main_filterUI = main_filter
                if(this.needToLoadSecondaryFilter){
                    this.needToLoadSecondaryFilter = false
                    let tempSecondaryFilter = _.cloneDeep(this.secondary_filterUI)
                    this.secondary_filterUI = secondary_filter
                    this.secondary_filterUI = tempSecondaryFilter
                }
                else
                    this.secondary_filterUI= secondary_filter
            },
            saveHaving(havingClause){
                this.savedHaving = havingClause
            },
            dragListStart(event,eventInitiaterList,dragStartBy){
                // here we are identifing the column on which user has start the dragging -> event.oldDraggableIndex return the list index.So we are selecting that item. If user drop that on target list, automatically onTargetDrop() function from mounted will get called.
                this.dragStartBy = dragStartBy
                if(event && event.oldDraggableIndex > -1 && eventInitiaterList[event.oldDraggableIndex])
                    eventInitiaterList[event.oldDraggableIndex].is_selected = true
            },
            dragTargetStart(event){
                // here we are identifing the column on which user has start the dragging -> event.oldDraggableIndex return the list index.So we are selecting that item. If user drop that on source list, automatically onSourceDrop() function from mounted will get called.
                if(event && event.oldDraggableIndex > -1 && this.targetColumnList[event.oldDraggableIndex])
                    this.targetColumnList[event.oldDraggableIndex].is_selected = true
            },
            selectDragEnd(event){
                return
                // this event is not properly functioning. So columns are getting missed after performing drag drop heavily.
                event.preventDefault()
                console.log('end')
                if(!event || !event.to || !event.from || !event.to.id ||!event.from.id || event.from.id === event.to.id)
                    return
                if(event.from.id === 'dragSourceColumnList'){
                    this.moveSourceToTarget()
                    // after drop, one item remain selected in the target
                    this.targetColumnList.forEach((item)=>{
                        item.is_selected = false
                    })
                }
                else if(event.from.id === 'dragTargetColumnList'){
                    this.moveTargetToSource()
                    // after drop, one item remain selected in the target
                    this.sourceColumnList.forEach((item)=>{
                        item.is_selected = false
                    })
                }
            }
		}
	}
</script>
<style scoped>
.list-card{
	margin-top:2% !important;
	margin-bottom:2% !important;
	height:368px !important;
}

.list-card-for-filter{
    margin-top:2% !important;
    margin-bottom:2% !important;
    height:348px !important;
}

.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 16px important;
}

.custom-list-item {
	margin-bottom: 0px !important;
    color:#000000;
    cursor:pointer !important;
}

.v-list-item {
	padding-left:16px !important;
	padding-top:0px !important;
	padding-right:8px !important;
	padding-bottom:0px !important;
}

.selected-list-item {
	background-color:var(--v-secondary-base) !important;
}

.EDC-list-for-columns {
	max-height:336px !important;
	min-height:336px !important;
}

.rounded-card {
    max-height: 700px !important;
}

.EDC-Custom-Toolbar {
    padding:0px !important;
}

.not-moved-item {
    /* empty class for hide col perpose*/
}

.customCheckbox{
    width: 16px;
    height: 16px;
}

.svg-fill-disabled {
    fill:white !important;
    stroke:#DCDCDC !important;
}

.svg-fill-selected {
    fill:white !important;
    stroke:#2B2B2B !important;
}

.svg-fill-not-selected {
    fill:white !important;
    stroke:#747474 !important;
}
.EDC-ToggleShape >>> input {
    width: 60px !important;;
    height: 22px !important;;
    background-color: rgb(96, 125, 139) !important;; 
    border-radius: 0px 0px !important;;
    margin: 0px !important;
}

.EDC-Toolbar >>> .v-sheet {
  border-radius: 0px !important;
}

/* Here we need apply css for all non selected items. Work around logic is here. This logic will force 
apply css to the items which are not deselected-list-item.In short we are applying css forcefully on user action.(reverse css formatting)
*/
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):not(.deselected-list-item) {
    color:white !important;
}

</style>

