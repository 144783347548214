/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive_restore_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<g color="#000" font-weight="400" font-family="sans-serif"><path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M.53.53v1.588h5.29V.53H.53zm.529.529H5.29v.53H1.06v-.53zM.816 2.416v1.288h.53V2.416h-.53zm4.235 0v2.938c0 .035-.011.03 0 .03H3.44v.528h1.61c.305 0 .53-.268.53-.558V2.416h-.53zm-2.67.229v.53h1.588v-.53H2.38zM3.175 3.437s-.513.721-.885.935c-.147.084-.373.104-.495.114l-.426-.53-.408.94-.408.94 1.019-.116 1.017-.117-.266-.381c.185-.104.443-.43.58-.7.169-.332.272-1.085.272-1.085z" overflow="visible"/></g>'
  }
})
