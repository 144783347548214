/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Document_Edit_old': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" _stroke="#000"><path pid="0" d="M36.506 8.678H13.168c-2.345 0-4.232 1.869-4.232 4.19v37.708c0 2.321 1.887 4.19 4.232 4.19h29.624c2.344 0 4.232-1.869 4.232-4.19v-12.57" stroke-width="1.912"/><path pid="1" d="M50.475 13.04l2.828 2.828a3.991 3.991 0 010 5.657L31.03 43.8c-11.313 2.828-11.313 2.828-8.485-8.485L44.818 13.04a3.991 3.991 0 015.657 0zM40.636 17.707l8 8" stroke-width="2"/><path pid="2" d="M17 20h14M17 28h7M30 36l11-11" stroke-width="2" stroke-linecap="round"/></g>'
  }
})
