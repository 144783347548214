import Vue from 'vue'
import Router from 'vue-router'

import PageNotFound from '../views/pageNotFound.vue'
import Login from '../components/authorization/login.vue'
import SelectViewMode from '../components/dataviewer/landingpage.vue'
import Dataviewer from '../components/dataviewer/container.vue'

import ChangePassword from '../components/authorization/change_password.vue'
import UserProfile from '../components/authorization/userProfile.vue'

import ResetUserPassword from '../components/authorization/reset_user_password.vue'
import ObjectManagement from '../components/object_management/processDefListNew.vue'
import deleteduserlist from '../components/configuration/deleteduserlist.vue'
import ClientSettings from '../components/configuration/client_settings.vue'
import ManageAccount from '../components/configuration/manage_account.vue'
import FileUpload from "../components/object_management/import_package.vue"
import PublishHistory from '../components/object_management/publish_history.vue'
import ClearCache from "../components/object_management/clear_cache.vue"

import Environmentlist from '../components/edcenvironment/environmentlistNew.vue'
import ManageEnvironment from '../components/edcenvironment/manageenvironment.vue'
import erpAcceleratorList from '../components/edcenvironment/erpAcceleratorList.vue'
import manageErpAccelerator from '../components/edcenvironment/manageErpAccelerator.vue'

import datasourcelist from '../components/configuration/datasourceListNew.vue'
import ManageDataSource from '../components/configuration/manageDatasource.vue'
import dblinkMapping from '../components/configuration/dblinkMapping.vue'

import userlist from '../components/configuration/userListNew.vue'
import UserObjRoleSet from '../components/configuration/userobjroleset.vue'
import ObjRoleList from '../components/configuration/ObjRoleList.vue'
import ManageObjRole from '../components/configuration/ManageObjRoleNew.vue'
import UserRoleSet from '../components/configuration/userroleset.vue'
import RoleList from '../components/configuration/RoleList.vue'
import ManageRole from '../components/configuration/ManageRole.vue'
import AddUser from '../components/configuration/manage_user.vue'
import UpdateUser from '../components/configuration/manage_user.vue'
import object_list_for_pkg from '../components/configuration/object_list_for_pkg.vue'
import PackageList from "../components/configuration/PackageList.vue"
import PackageObject from "../components/configuration/PackageObject.vue"

import SelectEnvTypePublish from '../components/object_management/select_env_type_publish.vue'
import SelectDsTypePublish from '../components/object_management/select_ds_type_publish.vue'
import PublishRequestDetails from '../components/object_management/publish_request_details.vue'
import PublishRuleList from '../components/object_management/publishrulelist.vue'
import PublishRuleListForDs from '../components/object_management/publishrulelistfords.vue'

import AgentList from '../components/agentmanagement/agentList.vue'
import AgentQueueList from '../components/agentmanagement/agent_queue_list.vue'
import FileExport from '../components/customs/fileExports.vue'

import SMTPSetting from '../components/configuration/configureSMTP.vue'
import ArchivistLander from '../components/pathfinder/archivistLanding.vue'

import edcPathfinder from '../components/pathfinder/edcPathfinder.vue'
import edcLander from '../components/edcLander.vue'
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/Login'
        },
        {
            path: "*",
            component: PageNotFound,
            meta:   {
                breadcrumb: 'Home / pageNotFound'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                breadcrumb: 'Home / Login'
            }
        },
        {
            path: '/userprofile',
            name: 'userprofile',
            component: UserProfile,
            meta: {
                breadcrumb: 'Home / UserProfile'
            }
        },
        {
            path: '/changepassword',
            name: 'ChangePassword',
            component: ChangePassword,
            meta: {
                breadcrumb: 'Home / Change Password'
            }
        },
        {
            path: '/clientsettings',
            name: 'ClientSettings',
            component: ClientSettings,
            meta: {
                breadcrumb: 'Home / ClientSettings'
            }
        },
        {
            path: '/manageaccount',
            name: 'ManageAccount',
            component: ManageAccount,
            meta: {
                breadcrumb: 'Home / Account Preference'
            }
        },
        {
            path: '/deleteduserlist',
            name: 'deleteduserlist',
            component: deleteduserlist,
            meta: {
                breadcrumb: 'Home / Deleted Users List'
            }
        },
        {
            path: '/selectviewmode',
            name: 'SelectViewMode',
            component: SelectViewMode,
            meta: {
                breadcrumb: 'Home / Select View Mode'
            }
        },
        {
            path: '/dataviewer',
            name: 'Dataviewer',
            component: Dataviewer,
            meta: {
                breadcrumb: 'Home / Dataviewer'
            }
        },
        {
            path: '/objectmanagement',
            name: 'objectmanagement',
            component: ObjectManagement,
            meta: {
                breadcrumb: 'Home / Object Mangement'
            }
        },
        {
            path: '/environmentlist',
            name: 'environmentlist',
            component: Environmentlist,
            meta: {
                breadcrumb: 'Home / Environment List'
            }
        },
        {
            path: '/manageenvironment',
            name: 'AddEnvironment',
            component: ManageEnvironment,
            meta: {
                breadcrumb: 'Home / Add Environment'
            }
        },
        {
            path: '/manageenvironment/:env_id/:type',
            name: 'manageenvironment',
            component: ManageEnvironment,
            meta: {
                breadcrumb: 'Home / AddEnvironment'
            }
        },
        {
            path: '/datasourcelist',
            name: 'datasourcelist',
            component: datasourcelist,
            meta: {
                breadcrumb: 'Home / Datasource List'
            }
        },
        {
            path: '/dblinkmapping',
            name: 'dblinkmapping',
            component: dblinkMapping,
            meta: {
                breadcrumb: 'Home / DBLink Mapping'
            }
        },
        {
            path: '/managedatasource',
            name: 'AddDatasource',
            component: ManageDataSource,
            meta: {
                breadcrumb: 'Home / Add Datasource'
            }
        },
        {
            path: '/managedatasource/:datasourceid/:type',
            name: 'managedatasource',
            component: ManageDataSource,
            meta: {
                breadcrumb: 'Home / Data Sources / Edit Data Source'
            }
        },
        {
            path: '/userlist',
            name: 'userlist',
            component: userlist,
            meta: {
                breadcrumb: 'Home / Users List'
            }
        },
        {
            path: '/userobjroleset',
            name: 'UserObjRoleSet',
            component: UserObjRoleSet,
            meta: {
                breadcrumb: 'Home / userobjroleset'
            }
        },
        {
            path: '/ManageObjRole',
            name: 'ManageObjRole',
            component: ManageObjRole,
            meta: {
                breadcrumb: 'Home / ManageObjRole'
            }
        },
        {
            path: '/userroleset',
            name: 'UserRoleSet',
            component: UserRoleSet,
            meta: {
                breadcrumb: 'Home / userroleset'
            }
        },
        {
            path: '/ManageRole',
            name: 'ManageRole',
            component: ManageRole,
            meta: {
                breadcrumb: 'Home / ManageRole'
            }
        },
        {
            path: '/rolelist',
            name: 'rolelist',
            component: RoleList,
            meta: {
                breadcrumb: 'Home / RoleList'
            }
        },
        {
            path: '/objrolelist',
            name: 'objrolelist',
            component: ObjRoleList,
            meta: {
                breadcrumb: 'Home / ObjRoleList'
            }
        },
        {
            path: '/adduser',
            name: 'AddUser',
            component: AddUser,
            meta: {
                breadcrumb: 'Home / AddUser'
            }
        },
        {
            path: '/update_user',
            name: 'update_user',
            component: UpdateUser,
            meta: {
                breadcrumb: 'Home / update_user'
            }
        },
        {
            path: '/package_builder',
            name: 'object_list_for_pkg',
            component: object_list_for_pkg,
            meta: {
                breadcrumb: 'Home / Package builder'
            }
        },
        {
            path: '/PackageList',
            name: 'PackageList',
            component: PackageList,
            meta: {
                breadcrumb: 'Home / PackageList'
            }
        },
        {
            path: '/PackageObject',
            name: 'PackageObject',
            component: PackageObject,
            meta: {
                breadcrumb: 'Home / PackageObject'
            }
        },
        {
            path: '/publish_history',
            name: 'PublishHistory',
            component: PublishHistory,
            meta: {
                breadcrumb: 'Home / Publish History'
            }
        },
        {
            path: '/import_package',
            name: 'fileupload',
            component: FileUpload,
            meta: {
                breadcrumb: 'Home / fileupload'
            }
        },
        {
            path: '/clear_cache',
            name: 'ClearCache',
            component: ClearCache,
            meta: {
                breadcrumb: 'Home / ClearCache'
            }
        },
        {
            path: '/select_env_type_publish',
            name: 'SelectEnvTypePublish',
            component: SelectEnvTypePublish,
            meta: {
                breadcrumb: 'Home / select env business obj'
            }
        },
        {
            path: '/select_ds_type_publish',
            name: 'SelectDsTypePublish',
            component: SelectDsTypePublish,
            meta: {
                breadcrumb: 'Home / select ds business obj'
            }
        },
        {
            path: '/publish_request_details',
            name: 'PublishRequestDetails',
            component: PublishRequestDetails,
            meta: {
                breadcrumb: 'Home / Publish request'
            }
        },
		{
            path: '/agent_list',
            name: 'AgentList',
            component: AgentList,
            meta: {
                breadcrumb: 'Home / AgentList'
            }
        },
        {            
            path: '/agent_queue_list',
            name: 'AgentQueueList',
            component: AgentQueueList,
            meta: {
                breadcrumb: 'Home / AgentQueueList'
            }
        },
        {
            path: '/publishruleList',
            name: 'publishruleList',
            component: PublishRuleList,
            meta: {
                breadcrumb: 'Home / Publish Rule List'
            }
        },
        {
            path: '/publishruleListfords',
            name: 'publishruleListfords',
            component: PublishRuleListForDs,
            meta: {
                breadcrumb: 'Home / Publish Rule List'
            }
        },
        {
            path: '/resetuserpassword',
            name: 'ResetUserPassword',
            component: ResetUserPassword,
            meta: {
                breadcrumb: 'Home / Reset User Password'
            }
        },
        {            
            path: '/fileexport',
            name: 'FileExport',
            component: FileExport,
            meta: {
                breadcrumb: 'Home / FileExport'
            }
        },
        {
            path: '/erpacceleratorlist',
            name: 'erpAcceleratorList',
            component: erpAcceleratorList,
            meta: {
                breadcrumb: 'Home / erpAcceleratorList'
            }
        },
        {
            path: '/manageErpAccelerator',
            name: 'manageErpAccelerator',
            component: manageErpAccelerator,
            meta: {
                breadcrumb: 'Home / manageErpAccelerator'
            }
        },
        {
            path: '/smtpsetting',
            name: 'smtpsetting',
            component: SMTPSetting,
            meta: {
                breadcrumb: 'Home / SMTP Setting'
            }
        },
        {
            path: '/archivist_landing',
            name: 'archivist_landing',
            component: ArchivistLander,
            meta: {
                breadcrumb: 'Home / Archivist Landing'
            }
        },
        {
            path: '/pathfinder_product_landing',
            name: 'pathfinder_product_landing',
            component: ArchivistLander,
            meta: {
                breadcrumb: 'Home / Pathfinder Product Landing'
            }
        },
        {
            path:'/edcpathfinder',
            name:'edcpathfinder',
            component:edcPathfinder,
            meta:{
                breadcrumb:'Home / EDC Pathfinder'
            }
        },
        {
            path:'/edclander',
            name:'edclander',
            component:edcLander,
            meta:{
                breadcrumb:'Home / EDC Lander'
            }
        }
    ]
})
