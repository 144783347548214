/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M3.176.53a.265.265 0 00-.266.263v3.064l-.67-.67a.265.265 0 00-.195-.075.265.265 0 00-.18.076.265.265 0 000 .375l1.123 1.123a.265.265 0 00.01.01.265.265 0 00.034.025.265.265 0 00.005.002.265.265 0 00.267.006.265.265 0 00.036-.025.265.265 0 00.022-.018l1.122-1.123a.265.265 0 000-.375.265.265 0 00-.373 0l-.671.672V.793A.265.265 0 003.176.53zM.793 3.704a.265.265 0 00-.264.265v1.588a.265.265 0 00.264.264h4.764a.265.265 0 00.263-.264V3.97a.265.265 0 00-.263-.265.265.265 0 00-.266.265v1.323H1.06V3.969a.265.265 0 00-.266-.265z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
