/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Archive_Forget': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<rect pid="0" width="16" height="4" x="32" y="35" rx="1.067" ry="1.6" _fill="#fff"/><g _fill="none" _stroke="#000"><path pid="1" d="M19 9c-3.799 0-6.857 4.08-6.857 9.145V25h56v-6.855c0-5.066-3.058-9.145-6.857-9.145zM64 41V31H16v25.904C16 60.281 18.62 63 21.877 63H42" stroke-width="2"/><path pid="2" d="M34 37h12" stroke-width="4" stroke-linecap="round"/><circle pid="3" cx="56.12" cy="55.728" r="11.006" stroke-width="2"/><path pid="4" d="M92 47s0 0 0 0 0 0 0 0c0-.002.002-.002.003-.001.004.001.004.007.002.01-.005.009-.019.008-.026.002-.019-.012-.015-.04-.003-.055.027-.033.077-.026.106 0 .055.049.04.135-.007.184-.083.086-.222.06-.3-.02-.127-.132-.086-.345.041-.462.2-.184.514-.12.684.073.257.292.162.739-.119.977-.411.349-1.03.214-1.356-.183-.464-.564-.277-1.401.269-1.837.756-.604 1.864-.351 2.435.381.774.994.44 2.434-.524 3.169-1.283.979-3.125.543-4.057-.702-1.22-1.631-.663-3.954.922-5.12 2.045-1.506 4.938-.802 6.38 1.188 1.836 2.533.96 6.096-1.508 7.86-3.104 2.218-7.447 1.138-9.583-1.888-2.658-3.766-1.342-9.01 2.334-11.575 4.529-3.16 10.808-1.57 13.862 2.854 3.73 5.402 1.827 12.863-3.455 16.474-5.201 3.556-12.294 2.796-16.95-1.316" transform="rotate(45 25.929 41.707) scale(.62278 .60769)" stroke-width="2"/></g>'
  }
})
