/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Business_Object': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M20 7c-2.216 0-4 1.784-4 4v4h-5v27.055H9A2.96 2.96 0 006.055 45v8A2.96 2.96 0 009 55.945h8A2.96 2.96 0 0019.945 53v-8A2.96 2.96 0 0017 42.055h-4V17h3v6c0 2.216 1.784 4 4 4h11v15.055h-2A2.96 2.96 0 0026.055 45v8A2.96 2.96 0 0029 55.945h8A2.96 2.96 0 0039.945 53v-8A2.96 2.96 0 0037 42.055h-4V27h11c2.216 0 4-1.784 4-4v-2h3v11.055h-4A2.96 2.96 0 0044.055 35v8A2.96 2.96 0 0047 45.945h8A2.96 2.96 0 0057.945 43v-8A2.96 2.96 0 0055 32.055h-2V19h-5v-8c0-2.216-1.784-4-4-4H20zm27 26.945h8c.6 0 1.055.454 1.055 1.055v8c0 .6-.454 1.055-1.055 1.055h-8c-.6 0-1.055-.454-1.055-1.055v-8c0-.6.454-1.055 1.055-1.055zm-38 10h8c.6 0 1.055.454 1.055 1.055v8c0 .6-.454 1.055-1.055 1.055H9c-.6 0-1.055-.454-1.055-1.055v-8c0-.6.454-1.055 1.055-1.055zm20 0h8c.6 0 1.055.454 1.055 1.055v8c0 .6-.454 1.055-1.055 1.055h-8c-.6 0-1.055-.454-1.055-1.055v-8c0-.6.454-1.055 1.055-1.055z"/>'
  }
})
