/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Home': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M32 10L2.012 33.99 7 38l5-4 20-16 20 16 5 4 5-4-11-8.8V14c0-1.108-.892-2-2-2h-3c-1.108 0-2 .892-2 2v5.6L32 10z"/><path pid="1" d="M32 23L12 39v16c0 2.216 1.784 4 4 4h12V47h8v12h12c2.216 0 4-1.784 4-4V39L32 23z"/>'
  }
})
