/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand_panel_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="-inkscape-stroke:none" d="M5.29.793a.8.8 0 01.796.795v3.173a.8.8 0 01-.795.796H1.058a.8.8 0 01-.794-.796V1.588a.8.8 0 01.794-.795zm0 .53H1.059a.26.26 0 00-.265.265v.396h4.764v-.396a.26.26 0 00-.266-.266zm.267.925h-.663v2.78h.397a.261.261 0 00.266-.267zm-.928 0H.793V4.76a.26.26 0 00.265.267h3.57zm-1.72.662l.53.397.53.397-.53.397-.53.397v-.53H1.588V3.44H2.91z" color="#000"/>'
  }
})
