/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Approve_old1': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" r="28" cy="31.5" cx="32"/><path pid="1" d="M14.388 29.813a1.746 1.746 0 000 2.475l12.375 12.374c.685.686 1.789.686 2.474 0l.62-.619 1.855-1.856 17.943-17.943 1.856-1.856a1.746 1.746 0 000-2.475l-2.475-2.475a1.746 1.746 0 00-2.474 0l-1.857 1.857L28 36l-8.662-8.662a1.746 1.746 0 00-2.475 0z" _fill="#fff"/>'
  }
})
