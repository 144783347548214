export const mixinDataviewer={
    props:{
        dataObject:{
            type:Object,
            default:()=>{
                return {
                    objectTitle:'',
                    objectDisplayName:'',
                    objectId:'',
                    objectType:'',
                    uniqueId:'',
                    parentId:'',
                    datasourceIndex:0,
                    selectedEnv:{},
                    selectedDS:{},
                    selectedObj:{},
                    allDatasources:[],
                    joinDetails:[],
                    childrenList:[],
                    pageSize:10,
                    parentRows:[],
                    edcCustomObjectProps:{}
                }
            }
        },
    },
    data(){
        return{
            archivistObj:{}
        }
    },
    computed:{
        isDataviewerGridObjLoaded(){
            return !_.isEmpty(this.$store.state.dataviewerGridObject)
        },
        isSplitMode(){
            return this.openModeType === 'split'
        },
        isMerged(){
            return this.openModeType === 'merged'
        },
        isArchivistCall(){
            return !_.isEmpty(this.archivistObj)
        },
        isArchivistBusinessDataCall(){
        // in case of archivist business data call we have to show source and target data into the single frame.
            return this.isArchivistCall && this.archivistObj.request_for === 'business_data'
        },
        isArchivistWorkTableCall(){
            return this.isArchivistCall && this.archivistObj.request_for === 'worktable'
        },
    }
}