<template>
	<v-container class="EDC-Row">
    <v-card style="padding:8px !important;" v-if="isSplitMode?mainDataList.length===0:!isDataviewerGridObjLoaded">
      <v-row align="center" style="height:85vh;">
        <v-col cols="isSplitMode?1:3" align-self="center"></v-col>
        <v-col :cols="isSplitMode?11:9" align-self="center" align="center">
          <edc-search-engine :key="whichSide" :whichSide="whichSide" :isSplitMode="isSplitMode" :isMerged="isMerged"></edc-search-engine>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="EDC-Row">
      <v-col class="EDC-Col">
        <v-card class="EDC-Datagrid-Main-Container" v-if="mainDataList.length >0">
          <template v-for="(datalistLevel1,index1) in mainDataList">
            <!------------------------------- LEVEL 1 ------------------------------------------->
            <v-card :key="'datalistLevel1Row'+index1">
              <v-row class="EDC-Row">
                <v-col class="EDC-Col">
                  <edc-data-grid  gridFor="dataviewer" @onNewTicket="onNewTicket(datalistLevel1)" :dataList="datalistLevel1" 
                  @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="onSort" 
                  @onDownload="onDownload" @contextMenuClick="onContextMenuClick" :allTableDescriptions="allTableDescriptions"
                  :tooltipObj="datalistLevel1.tooltipObj" @mouseEnterOnTd="loadMoreInfoOfTd" @mouseLeaveTd="mouseLeaveTd"
                    :key="'datalistLevel1Grid'+index1" :id="datalistLevel1.unique_id" 
                    @onRowSelected="onRowSelected" @rowCopyToClipboard= "rowCopyToClipboard" 
                    @onClickAdvanceFilter="onClickAdvanceFilter" @loadSummaryFooter="loadSummaryFooter" 
                    @changeGridView="customizeGridView" @loadMediaAttachmentDetails="loadMediaAttachmentDetails" 
                    :availableDatasources="ds_list_for_backend"  
                     @openTableInOtherDS="openTableInOtherDS" 
                    @checkForDynamicMenu="checkForDynamicMenu" @closeGrid="closePanel($event)" 
                    :downloadLimit="downloadLimit" :keepFirstColFixed="datalistLevel1.keepFirstColFixed" 
                    :keepSecondColFixed="datalistLevel1.keepSecondColFixed">
                  </edc-data-grid>
                </v-col>
              </v-row>
              <v-row class="EDC-Row" v-if="datalistLevel1.childrenDataList.length">
                <v-col class="EDC-Col EDC-Data-Child-Card">
                  <template v-for="(datalistLevel2,index2) in datalistLevel1.childrenDataList">
                    <!------------------------------- LEVEL 2 ------------------------------------------->
                    <v-card :key="'datalistLevel2Row'+index2">
                      <v-row class="EDC-Row">
                        <v-col class="EDC-Col">
                          <edc-data-grid  gridFor="dataviewer" @onNewTicket="onNewTicket(datalistLevel2)" :dataList="datalistLevel2" 
                          @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="onSort" 
                          @onDownload="onDownload" @contextMenuClick="onContextMenuClick" :allTableDescriptions="allTableDescriptions"
                          :tooltipObj="datalistLevel2.tooltipObj" @mouseEnterOnTd="loadMoreInfoOfTd" @mouseLeaveTd="mouseLeaveTd"
                          :key="'datalistLevel2Grid'+index2" :id="datalistLevel2.unique_id" 
                          @onRowSelected="onRowSelected" @rowCopyToClipboard= "rowCopyToClipboard" 
                          @onClickAdvanceFilter="onClickAdvanceFilter" @loadSummaryFooter="loadSummaryFooter" 
                          @changeGridView="customizeGridView" @loadMediaAttachmentDetails="loadMediaAttachmentDetails" 
                          :availableDatasources="ds_list_for_backend"  
                           @openTableInOtherDS="openTableInOtherDS" 
                          @checkForDynamicMenu="checkForDynamicMenu" @closeGrid="closePanel($event)" 
                          :downloadLimit="downloadLimit" :keepFirstColFixed="datalistLevel2.keepFirstColFixed" 
                          :keepSecondColFixed="datalistLevel2.keepSecondColFixed">
                          </edc-data-grid>
                        </v-col>
                      </v-row>
                      <v-row class="EDC-Row" v-if="datalistLevel2.childrenDataList.length">
                        <v-col class="EDC-Col EDC-Data-Child-Card">
                          <template v-for="(datalistLevel3,index3) in datalistLevel2.childrenDataList">
                            <!------------------------------- LEVEL 3 ------------------------------------------->
                            <v-card :key="'datalistLevel3Row'+index3">
                              <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                  <edc-data-grid  gridFor="dataviewer" @onNewTicket="onNewTicket(datalistLevel3)" :dataList="datalistLevel3" 
                                  @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="onSort" 
                                  @onDownload="onDownload" @contextMenuClick="onContextMenuClick" :allTableDescriptions="allTableDescriptions"
                                  :tooltipObj="datalistLevel3.tooltipObj" @mouseEnterOnTd="loadMoreInfoOfTd" @mouseLeaveTd="mouseLeaveTd"
                                  :key="'datalistLevel3Grid'+index3" :id="datalistLevel3.unique_id" 
                                  @onRowSelected="onRowSelected" @rowCopyToClipboard= "rowCopyToClipboard" 
                                  @onClickAdvanceFilter="onClickAdvanceFilter" @loadSummaryFooter="loadSummaryFooter" 
                                  @changeGridView="customizeGridView" @loadMediaAttachmentDetails="loadMediaAttachmentDetails" 
                                  :availableDatasources="ds_list_for_backend"  
                                   @openTableInOtherDS="openTableInOtherDS" 
                                  @checkForDynamicMenu="checkForDynamicMenu" @closeGrid="closePanel($event)" 
                                  :downloadLimit="downloadLimit" :keepFirstColFixed="datalistLevel3.keepFirstColFixed" 
                                  :keepSecondColFixed="datalistLevel3.keepSecondColFixed">
                                  </edc-data-grid>
                                </v-col>
                              </v-row>
                              <v-row class="EDC-Row" v-if="datalistLevel3.childrenDataList.length">
                                <v-col class="EDC-Col EDC-Data-Child-Card">
                                  <template v-for="(datalistLevel4,index4) in datalistLevel3.childrenDataList">
                                    <!------------------------------- LEVEL 4 ------------------------------------------->
                                    <v-card :key="'datalistLevel4Row'+index4">
                                      <v-row class="EDC-Row">
                                        <v-col class="EDC-Col">
                                          <edc-data-grid  gridFor="dataviewer" @onNewTicket="onNewTicket(datalistLevel4)" :dataList="datalistLevel4" 
                                          @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="onSort" 
                                          @onDownload="onDownload" @contextMenuClick="onContextMenuClick" :allTableDescriptions="allTableDescriptions"
                                          :tooltipObj="datalistLevel4.tooltipObj" @mouseEnterOnTd="loadMoreInfoOfTd" @mouseLeaveTd="mouseLeaveTd"
                                          :key="'datalistLevel4Grid'+index4" :id="datalistLevel4.unique_id" 
                                          @onRowSelected="onRowSelected" @rowCopyToClipboard= "rowCopyToClipboard" 
                                          @onClickAdvanceFilter="onClickAdvanceFilter" @loadSummaryFooter="loadSummaryFooter" 
                                          @changeGridView="customizeGridView" @loadMediaAttachmentDetails="loadMediaAttachmentDetails" 
                                          :availableDatasources="ds_list_for_backend"  
                                           @openTableInOtherDS="openTableInOtherDS" 
                                          @checkForDynamicMenu="checkForDynamicMenu" @closeGrid="closePanel($event)" 
                                          :downloadLimit="downloadLimit" :keepFirstColFixed="datalistLevel4.keepFirstColFixed" 
                                          :keepSecondColFixed="datalistLevel4.keepSecondColFixed">
                                          </edc-data-grid>
                                        </v-col>
                                      </v-row>
                                      <v-row class="EDC-Row" v-if="datalistLevel4.childrenDataList.length">
                                        <v-col class="EDC-Col EDC-Data-Child-Card">
                                          <template v-for="(datalistLevel5,index5) in datalistLevel4.childrenDataList">
                                            <!------------------------------- LEVEL 5 ------------------------------------------->
                                            <v-card :key="'datalistLevel5Row'+index5">
                                              <v-row class="EDC-Row">
                                                <v-col class="EDC-Col">
                                                  <edc-data-grid  gridFor="dataviewer" @onNewTicket="onNewTicket(datalistLevel5)" :dataList="datalistLevel5" 
                                                  @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @onSort="onSort" 
                                                  @onDownload="onDownload" @contextMenuClick="onContextMenuClick" :allTableDescriptions="allTableDescriptions"
                                                  :tooltipObj="datalistLevel5.tooltipObj" @mouseEnterOnTd="loadMoreInfoOfTd" @mouseLeaveTd="mouseLeaveTd"
                                                  :key="'datalistLevel5Grid'+index5" :id="datalistLevel5.unique_id" 
                                                  @onRowSelected="onRowSelected" @rowCopyToClipboard= "rowCopyToClipboard" 
                                                  @onClickAdvanceFilter="onClickAdvanceFilter" @loadSummaryFooter="loadSummaryFooter" 
                                                  @changeGridView="customizeGridView" @loadMediaAttachmentDetails="loadMediaAttachmentDetails" 
                                                  :availableDatasources="ds_list_for_backend"  
                                                   @openTableInOtherDS="openTableInOtherDS" 
                                                  @checkForDynamicMenu="checkForDynamicMenu" @closeGrid="closePanel($event)" 
                                                  :downloadLimit="downloadLimit" :keepFirstColFixed="datalistLevel5.keepFirstColFixed" 
                                                  :keepSecondColFixed="datalistLevel5.keepSecondColFixed">
                                                  </edc-data-grid>
                                                </v-col>
                                              </v-row>
                                            </v-card>
                                          </template>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </template>
                                </v-col>
                              </v-row>
                            </v-card>
                          </template>
                        </v-col>
                      </v-row>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <loading-panel :loader="loader"></loading-panel>
    <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
      {{ snackbartext }}
    </v-snackbar>

    <v-dialog v-model="showUDCViewer"  style="overflow-y:none" :width="800" eager>
      <udc-viewer :title="lookupTitle" :udcGridObj="udcGridObj" :loader="lookupLoader" @getMoreUDCData="getUDCDetails" :udcHeader="lookupHeader" :gridUniqueID="lookupGridUniqueId" @applyUDCFilter="applyUDCFilter" @closeUDCViewer="showUDCViewer=false;lookupLoader=false"></udc-viewer>
    </v-dialog>

    <v-dialog v-model="showValueViewer"  style="overflow-y:none" :width="300" eager>
      <value-viewer :title="lookupTitle" :gridUniqueID="lookupGridUniqueId" :valueHeader="lookupHeader" :loader="lookupLoader" :lookups="valueLookupList" @applyVALUEFilter="applyVALUEFilter" @closeVALUEViewer="showValueViewer=false;lookupLoader=false"></value-viewer>
    </v-dialog>


    <v-dialog v-model="showFileViewer"  style="overflow-y:none" :width="fileViewerWidth" eager>
      <file-viewer :title="lookupTitle" :fileGridObj="fileGridObj" :loader="lookupLoader" @getMoreFILEData="getFILEDetails" :fileHeader="lookupHeader" :gridUniqueID="lookupGridUniqueId" @applyFILEFilter="applyFileFilter" :lookupFor="lookupFor" @closeFILEViewer="showFileViewer=false;lookupLoader=false" :errorInDataFetch="errorInFileLookupFetch"></file-viewer>
    </v-dialog>

     <v-dialog v-model="showCustomViewer" persistent style="overflow-y:visible !important" width="850" height="800" eager>
      <customize-data-view :objectForCustomize="objectForCustomizeView" @closePanel="showCustomViewer=false" @applyCustomization="applyCustomization" :selectedEnvironment='selected_env'></customize-data-view>
    </v-dialog>

    <v-dialog v-model="showDocumentViewer" persistent style="overflow-y:hidden" width="600" :height="460"  eager>
      <edc-document-viewer :documentObject="edcDocumentObject" :documentList="edcDocumentList" @closePanel="showDocumentViewer=false" @documentSelectionChange="loadAttachment" :rowDetails="edcMediaRowDetails"></edc-document-viewer>
    </v-dialog>

    <!-- <v-dialog v-model="show_add_dialog" width="auto">
      <v-col cols="12" class="EDC-Col">
        <v-card elevation-1 class="EDC-TicketCard">
          <v-row no-gutters class="EDC-Row">
            <v-toolbar dark dense absolute class="EDC-Toolbar" style="width:100%; padding-bottom:10px;border-radius:0px !important;">
              <v-toolbar-title class="EDC-ToolbarTitle">Create Ticket</v-toolbar-title>
              <v-spacer></v-spacer>
              <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="show_add_dialog=false" title="Close"></svgicon>
            </v-toolbar>
          </v-row>
          <v-row class="EDC-Row" style="padding-top:28px !important;width: 1100px !important">
            <create-ticket :ticketObj="ticketObj" @onCreated="show_add_dialog=false" callFrom='create_dialog' @onCancled='show_add_dialog=false'></create-ticket>
          </v-row>
        </v-card>
      </v-col>
    </v-dialog> -->

    <simplert :useRadius="true" :useIcon="true" ref="simplert">
    </simplert>

  </v-container>
</template>
<style scoped>
    .customCheckbox >>> label{
    top: 4px !important;
  }
  .EDC-Access-Denied {
    color:red;
    padding-left:8px;
  }
  </style>
  <style>
  .v-list-item__title:hover{
  align:left;
  }

  .colpadding{
    /* padding:2px !important; */
  }
  table.v-table thead tr, table.v-table tbody tr{
    height: 24px;
  }
  .sticky {
    position: absolute;
  }
  #filter_column_table .v-input__slot{
    margin-bottom: -10px;
    margin-top: -5px;
  }
  #select_column_table .v-select__slot{
    margin-bottom: -6px;
  }
  #filter_column_table .v-input__slot input, .v-select-list .v-list__tile__content{
    font-size: 12px;
  }

  .v-input__slot input, .v-select-list .v-list__tile__content{
    font-size: 12px;
  }

  #select_column_table td{
    font-size: 12px;
  }

  tbody {
    overflow-y: scroll !important;
  }

  .v-input__slot{
    font-size: 14px;
    margin-bottom: -8px;
    margin-top: -5px;
  }
  #select_column_table .v-input__append-inner i{
    /*font-size: 15px;*/
  }
  #select_column_table .v-text-field__slot{
    width: 65px;
  }
  #select_column_table .v-text-field__slot{
    margin-top: -5px;
  }
  .handle {
    cursor: move !important;
    cursor: -webkit-grabbing !important;
  }
  .v-radio label{
    font-size: 15px;
  }
  .v-radio i{
    font-size: 20px;
  }
  .v-dialog__activator{
    margin-top: -10px;
  }
  .scrollit {
      overflow:scroll !important;
      height:100px;
  }

  #togglebtn label{
    margin-bottom:0px !important;
  }

  .v-input__control {
    min-height: 20px !important;
  }

  .v-expansion-panel-content__wrap{
    padding-left:4px !important;
    padding-right:4px !important;
  }
  .v-expansion-panel--active>.v-expansion-panel-header {
      min-height: 24px !important;
  }

  .children_expansion_panel{
    padding-left:24px !important;
    /*padding-right:24px !important;*/
    padding-top:8px !important;
    padding-bottom:0px !important;
  }

  .v-expansion-panel-header {
    padding-top: 8px !important;
    padding-right: 16px !important;
    padding-bottom: 8px !important;
    padding-left: 16px !important;
  }

  .EDC-TicketCard {
    min-width: 1100px !important;
  }

  .CENTERED-Left-ShiftArrow {
    position:absolute;
    top:20vh;
    left:47vw;
    transform: rotate(90deg);
  }

  .CENTERED-Right-ShiftArrow {
    position:absolute;
    top:23vh;
    left:644px;
    transform: rotate(270deg);
  }

  .LEFTINETEDED-Left-ShiftArrow {
    position:absolute;
    top:20vh;
    left:0.8vw;
    transform: rotate(90deg);
  }

  .RIGHTINETEDED-Right-ShiftArrow {
    position:absolute;
    top:20vh;
    left:96.5vw;
    transform: rotate(270deg);
  }

  .grid-tittle {
    padding-left: 16px !important;
  }

  .EDC-Datagrid-Main-Container{
    height: 80vh !important;
  }

  .EDC-Data-Child-Card{
    margin-top:4px !important;
    margin-bottom:4px !important;
    margin-left:8px !important;
    margin-right:8px !important;
    padding: 8px !important;
  }

</style>
<script>
var moment = require("moment-timezone")
import {mapActions} from 'vuex'
import {mixinDataviewer} from '../../mixins/dataviewer.js'
import config from '../../config.json'
import { BTN_COLOR } from '@/data/macros.js';
import { post as postToServer } from './../../methods/serverCall.js';
import UDCViewer from './../../views/UDCViewer.vue'
import VALUEViewer from './../../views/VALUElookupViewer.vue'
import FILEViewer from './../../views/FilelookupViewer.vue'
import * as JDEFormatterObj from "../../methods/JDEFormatter.js"
import {manageScreenDisplay} from '@/methods/special.js'
import * as commonFuncObj from "../../methods/commonFunc.js"
import * as dateFormatObj from "../../methods/DateFormat.js"
import {getDataviewerTbleObj} from "../../constants/dataviewerconstants.js"
import CustomizeDataView from "./customizedataview.vue"
import edcDocumentViewer from "./../../views/edc_document_viewer.vue"
import Simplert from 'vue2-simplert'
import edcSearchEngine from  './../../views/searchengine.vue'
import { v4 as EDCuuidv4 } from 'uuid';
import {Ticket_management} from '../../constants/constants.js'
// import ticketDetails from './../ticketmanagement/ticketDetailsNew.vue'
import {
  CLIENT_SIDE
} from '../../data/macros.js'
import { POLICY_LIST_BY_ENV, ALL_PUBLISHED_BUSINESS_OBJ_LIST, GET_ALL_POLICY_TYPE_LIST,GET_ALL_PUBLISHESD_PROCESS_DEF_LIST} from '../../data/url_constants.js';


export default {
 name:'dataviewer',
  components:{
    'udc-viewer':UDCViewer,
    'value-viewer':VALUEViewer,
    'file-viewer':FILEViewer,
    'customize-data-view':CustomizeDataView,
    'simplert':Simplert,
    'edc-document-viewer':edcDocumentViewer,
    'edc-search-engine':edcSearchEngine,
    // 'create-ticket':ticketDetails
  },
  mixins:[mixinDataviewer],
  props:{
    openModeType:{
      type:String,
      default:"single"
    },
    whichSide:{
      type:String,
      default:'wholepage',
    },
    isCompacted:{
      type:Boolean,
      default:false
    },
    startOverCount:{
      type:Number,
      default:0
    },
    userActionToPerform:{
      type:String,
      default:''
    }
  },
 data(){
  return {
    main_filterUI:[{
      's_parentheses': '',
      'column_name': '',
      'operator': '',
      'v_type1': 'Value',
      'v_type2': 'Value',
      'value1': '',
      'value2':'',
      'e_parentheses': ''
    }],
    secondary_filterUI:{groupList:[]},
    filter_column_tbl_obj: {
      's_parentheses': '',
      'column_name': '',
      'operator': '',
      'v_type1': 'Value',
      'v_type2': 'Value',
      'value1': '',
      'value2':'',
      'e_parentheses': ''
    },
    tableList:{
      
    },
    buttonColor:BTN_COLOR,
    mainDataList:[],
    uniqueIdList:[],
    EnvironmentList:[],
    selected_env:{},
    selected_ds:{},
    selected_bo_tbl:{},
    loading:false,
    tableLoading:false,
    is_column_details_required:true,
    loader: false,
    snackbar:false,
    snackbartext:'',
    snackbartimeout:5000,
    colorValue:'success',
    filter_data:[],
    envIpJson:{
      "filter": [],
      "filter_data":[],
      "sort": [],
      "page": 1,
      "page_size": 10,
      "primary_keys":[]
    },
    selected_bo:{},
    bo_uniquekeys:[],
    uniquekey_filters:[],
    showCriteria:false,
    updatedUniqueKeyFilters:[],
    selectChips:[],
    openPanel:[0,1],
    dictionary_data:{},
    object_librarian_data:{},
    control_data:{},
    acceleration_type:'',
    bo_tree:[],
    isBOSelected:false,
    allTableDescriptions:[],
    udcServerCallTimer:null,
    loadChildCallTimer:null,
    serverCallDelay:1000,
    tooltipValues:[],
    tooltipObj:{},
    selectedRowsServerCallTimer:null,
    lastSelectedRows:[],
    lookupGridUniqueId:'',
    lookupLoader:false,
    lookupHeader:{},
    lookupTitle:'',
    lookupFor:'',

    udcGridObj:{},
    showUDCViewer:false,

    valueLookupList:[],
    showValueViewer:false,

    fileGridObj:{},
    showFileViewer:false,
    errorInFileLookupFetch:'',
    fileViewerWidth:800,

    objectForCustomizeView:{},
    showCustomViewer:false,
    callTableTimer:null,
    table_key:1, // to refresh the auto complete for the table selection
    edcDocumentObject:{},
    edcDocumentList:[],
    edcMediaRowDetails:[],
    showDocumentViewer:false,
    showMoreActions:false,
    searchForBusinessView:true,
    searchForBusinessObject:true,
    searchForTable:false,
    searchForView:false,
    searchForAlias:false,
    ds_list_for_backend:[],
    show_add_dialog:false,
    ticketObj:{},
    tableResponse:[],
    ds_list:[],
    normalGridCols:12,
    compareGridCols:6,
    associate_constant:'_edc001',
    associatedDataList:[],
    virtualBOSelected:false,
    downloadLimit:'',
    archivistWorktableRelatedStuff:{}, // this variable is used for the keep details of worktable related open table so we no need to load everthing each time.Right now this will store table_name, unique id and child list
  }
},

  watch:{
    '$store.state.secondaryCountUpdate':{
      handler(newVal){
        if(newVal && newVal.body){
          this.manageSecondaryCount(newVal.body)
        }
      }
    },
    '$store.state.dataviewerProps':{
      handler(newVal){
        if(newVal && newVal.whichSide === this.whichSide){
          this.loadData(newVal)
        }
      }
    },
  'openModeType':{
    handler(newVal){
      // this.manageStoreData(_.cloneDeep(this.$store.state.dataviewerGridObject))
      if(newVal != 'right'){
        this.storeInDataviewerGridObject()
      }
    },
  },
  mainDataList:{
    handler(newValue){

    }
  },
  isCompacted:{
    handler(newValue){
      this.changePropOfEachGrid(this.mainDataList,'isCompacted',newValue)
    }
  },
  'startOverCount':{
    handler(newVal){
      this.mainDataList = []
      this.setDataviewerGridObject({})
    }
  },
  'userActionToPerform':{
    handler(newVal){
      if(newVal){
        this.userChangedViewOnTheFly(newVal)
      }
    }
  },
  'selected_bo_tbl':{
    handler(newValue, OldValue){
      this.resetOtherData()
    }
  },
  'mainDataList':{
    handler(newValue, oldValue){
      if(!newValue || !newValue.length){
        this.resetOtherData()
      }
    }
  }
},
computed:{
  isByTicketID(){
    return true && this.$route.query.ticket_id
  },
  disableSelection(){
    return this.isByTicketID
  },
  showCreateTicket(){
    let product_list = this.$session.get('product_list')
    return product_list.includes(Ticket_management)
  },
  showSearchEngine(){
    return !this.mainDataList.length

  },
  tblBONotSelected(){
    return !this.selected_bo_tbl || !this.selected_bo_tbl.type
  },
  isBusinessViewSelected(){
    return this.selected_bo_tbl && this.selected_bo_tbl.type === 'BV'
  }

},
mounted(){
  var _this =this
  _this.tableList = getDataviewerTbleObj()
  if(this.isByTicketID)
    this.tableList.actions = []
  if(this.showCreateTicket){
     this.tableList.actions=[
           {'text':'createticket','key':'rn', selectType:"multiple", role:true, index:1,showAlways:true,
        'display_text':'Create New Ticket',selectType:'multiple'}
            ];
  }
  if(this.tableList.paginationType == CLIENT_SIDE)
    this.envIpJson.page_size="*";
  // this.envIpJson.page_size = manageScreenDisplay()

  // if(_this.isDataviewerGridObjLoaded){
  //   // alert('in mmounted', this.openModeType)
  //   _this.loadData(_this.$store.state.dataviewerProps,false)
  //   _this.manageStoreData(_.cloneDeep(_this.$store.state.dataviewerGridObject))
  //   _this.selected_bo['alldetails'] = _this.$store.state.dataviewerGridObject.selected_bo.alldetails
  //   _this.selected_bo['joindetails'] = _this.$store.state.dataviewerGridObject.selected_bo.joindetails
  //   _this.bo_tree = _this.$store.state.dataviewerGridObject.bo_tree
  // }
  if(this.whichSide !='right')
    this.setDataviewerGridObject({})
  this.getDownloadLimit()
},

methods:{
  ...mapActions(['setDataviewerGridObject']),
  hideShowEngine(){
    if(this.isSplitMode){
      return this.mainDataList.length===0
    }
    else
     return !this.isDataviewerGridObjLoaded
  },
  manageStoreData(savedObj){
    if(!savedObj || !savedObj[this.whichSide] || !savedObj[this.whichSide].gridData || this.mainDataList.length)
      return
    this.applyStoredDataList(savedObj[this.whichSide].gridData)
  },
  applyStoredDataList(availableList,mainDataList){
    var _this = this
    if(!mainDataList)
      mainDataList = _this.mainDataList
    for(let i=0;i<availableList.length;i++){
      let gridObj = _.cloneDeep(availableList[i])
      gridObj['childrenDataList'] = []
      //if you notice any property not working then copy that property into the variable and reassign it.Same as rows,availableViewList.

      let rows =  _.cloneDeep(gridObj.rows)
      let availableViewList = _.cloneDeep(gridObj.availableViewList)
      let selectedView = gridObj.selectedView
      gridObj.selectedView = ''
      gridObj.rows = []
      gridObj.availableViewList = []
      mainDataList.push(gridObj)

      _this.assignHeadersForcefully(gridObj,gridObj.headers,true)

      _this.assignViewListForcefully(gridObj,availableViewList,selectedView)

      _this.assignRowForcefully(gridObj,rows,gridObj.loadSummary)

      _this.assignGroupByForcefully(gridObj,{"otherDetails":{"group_by":gridObj.group_by}})


      _this.assignOtherPropsForcefully(gridObj,
          {"join_for_description":gridObj.join_for_description,
          "view_filter":gridObj.view_filter,
          "view_having":gridObj.view_having,
          "columnsForSelect":gridObj.columnsForSelect,
          "media_object_details":gridObj.mediaObjDetails,
          "business_view_details":gridObj.business_view_details,
          "node_filters":gridObj.node_filters,
          "merge_tables_detail":gridObj.merge_tables_detail},false)

      _this.assignInputJsonForcefully(gridObj,gridObj.inputJson,true)

      _this.assignActualRowCountForcefully(gridObj.actualRowCount>0?gridObj.actualRowCount:gridObj.total_count,gridObj,gridObj.showPlus)

      // load childrenDataList functionality not working right now. Have to work on it if require
      // if(availableList[i].childrenDataList && availableList[i].childrenDataList.length){
      //   _this.applyStoredDataList(availableList[i].childrenDataList,gridObj['childrenDataList'])
      // }
    }
  },
  getTicketDetails(ticket_id){
    var _this = this
    var data = {ticket_id:ticket_id}
    var request_url = config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/get_ticket_url_data"
    postToServer(_this, request_url, data).then(response => {
                  this.ticketObj = JSON.parse(response.request_data)
                  this.performAutoEnvSelection(this.EnvironmentList,this.ticketObj.env_id)
            }).catch(error_response => {
                if(error_response){
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = error_response
            }
            else{
                // this.snackbar = true
                // this.colorValue = "error"
                // this.snackbar = SERVER_ERROR
            }
        });
  },
  onNewTicket(gridObj){
    let paramsData={
      'env_id':this.selected_env.id,
      'ds_id':this.selected_ds.id,
      'selected_object':this.selected_bo_tbl,
      'inputJson':gridObj.inputJson,
      'selectedView':gridObj.selectedView,
      'table_name':gridObj.table_name,
      'selected_rows':gridObj.selected_rows
    }
    let url = '/dataviewer?from=ticket&ticket_id='
    this.ticketObj = {
      'url':url,
      'postData':paramsData,
      'url_type':'POST'
    }
    let _this = this
    let data_to_send = {
      'url':url,
      'postData':JSON.stringify(paramsData)
    }
     postToServer(this, config.EDC_TICKET_MANAGEMENT_SERVICE_URL + "/encrypt_url_data", data_to_send
        ).then(response  => {
            this.snackbartext = response
            let redirect_url = this.$session.get('protocol')+'://'
            let queryString = '?product_key='+this.$session.get('access_token')+'&edcurl='+encodeURIComponent(response.url)+ '&call_for=TM' + '&postData=' + response.postData +'&url='+'POST'
            let domain_name = this.$session.get('erp_cloud_host') ? this.$session.get('erp_cloud_host') : "localhost"
            if(config.DOMAIN_NAME)
              redirect_url = redirect_url +'tickets.'+config.DOMAIN_NAME+'/login'+queryString
            else
              redirect_url = redirect_url + domain_name+":"+config.TICKETMANAGEMENT_PORT+'/login'+queryString
            window.open(redirect_url, '_blank')
            _this.loader = false;
        }).catch(ticketResponseError => {
                _this.loader = false;
                if(ticketResponseError){
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = ticketResponseError
                }
                else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                }
            });
  },
onPageChange(pageNumber,perPage,table_name,unique_id,inputJson){
  var serverJson =inputJson
  if(!inputJson)
    serverJson = _.cloneDeep(this.envIpJson)
  serverJson["page"] = pageNumber
  serverJson["page_size"] = perPage
  this.GetDataFromDB([{"table_name":table_name}],unique_id,serverJson)
},
onPerPageChange(perPage,pageNumber,table_name,unique_id,inputJson){
  var serverJson =inputJson
  if(!inputJson)
    serverJson = _.cloneDeep(this.envIpJson)
  serverJson["page"] = pageNumber
  serverJson["page_size"] = perPage
  this.GetDataFromDB([{"table_name":table_name}],unique_id,serverJson)
},
onSort(sortJson,table_name,unique_id,inputJson){
  var serverJson =inputJson
  if(!inputJson)
    serverJson = _.cloneDeep(this.envIpJson)
  serverJson["sort"] = sortJson
  this.GetDataFromDB([{"table_name":table_name}],unique_id,serverJson)
},

UpdateFilter(filterArray,pageno,table_name,unique_id,inputJson){
  var serverJson =inputJson
  if(!inputJson)
    serverJson = _.cloneDeep(this.envIpJson)
  serverJson['page'] = pageno
  serverJson["filter_data"] = filterArray
  this.GetDataFromDB([{"table_name":table_name}],unique_id,serverJson)
},
onContextMenuClick(childs,parent_rows,loadType,parentHeaders){
  clearInterval(this.loadChildCallTimer)
  this.onLoadChildData(childs,parent_rows,loadType,parentHeaders)
},
onRowSelected(selectedRows,unique_id,loadedChilds,parentHeader){
let _this = this
if(!selectedRows.length){
  clearInterval(_this.loadChildCallTimer)
  this.lastSelectedRows = []
  _this.clearChildGrids(loadedChilds)
  return
}

if(_.isEqual(selectedRows,_this.lastSelectedRows) || !loadedChilds.length)
  return
this.lastSelectedRows = _.cloneDeep(selectedRows)
clearInterval(_this.loadChildCallTimer)



_this.loadChildCallTimer = setTimeout(function(){
  _this.onLoadChildData(loadedChilds,selectedRows,'recursive',parentHeader)
},_this.serverCallDelay)

},
onLoadChildData(childs,parent_rows,loadType,parentHeaders){
  let _this = this
  if(!loadType)
    childs = [childs]
  for(var i=0;i<childs.length;i++){
    let child_details = childs[i]
    if(childs[i].isDummy)
      continue
    let serverJson = _.cloneDeep(this.envIpJson)
    let jfrom_id = child_details.parent_id
    let jto_id = child_details.node_id
    let join = this.getJoin(jfrom_id,jto_id)
    if(_.isEmpty(join) && !this.isArchivistCall){
      if(jto_id.includes('edcParentAsChild')){
        // It means child is opening the parent
        jto_id = jto_id.replace('_edcParentAsChild','')
        join = this.getJoin(jto_id, jfrom_id)
        join = _this.getReverseJoin(join)

      }
      if(_.isEmpty(join)){
        alert("error in getJoin")
        return
      }
    }
    let parentRowsCopy = _.cloneDeep(parent_rows)
    let parentHeadersCopy = _.cloneDeep(parentHeaders)
    if(this.isArchivistCall && (child_details.is_dataitem_wrktbl || join.is_dataitem_wrktbl)){
      parentRowsCopy = []
      for(let j=0;j<parent_rows.length;j++){
        parentRowsCopy.push(this.sliptDataItemPrimaryKey(parent_rows[j]))
      }
    }
    let keyMappings = this.getJoinKeys(join.condition)
    if(!keyMappings.length){
      alert("error in getJoinKeys")
      return
    }


    let parent_filter = this.getParentFilter(keyMappings,_.uniq(parentRowsCopy),parentHeadersCopy, join.condition)

    if(!parent_filter){
      alert("error in fetching parent filter")
      return
    }
    serverJson = _this.manageParentDetails(jfrom_id, serverJson, join, parent_filter,parentRowsCopy)
    // now check child is already loaded or not. If yes then work with unique id else treat as fresh child request
    let table_name = child_details.table_name
    var tablelist = [child_details]
    let unique_id = ''
    let gridObj =  _this.getGridObjectById(child_details.unique_id,_this.mainDataList)
    let is_fresh_child_req = true
    if(gridObj && gridObj.table_name){
      is_fresh_child_req = false
      unique_id = gridObj.unique_id
      serverJson = gridObj.inputJson
      serverJson = _this.manageParentDetails(jfrom_id, serverJson, join, parent_filter,parentRowsCopy)
    }
    _this.GetDataFromDB(tablelist,unique_id,serverJson,is_fresh_child_req,loadType)
  }
},
manageParentDetails(parent_unique_id, serverJson,  join, parent_filter, parent_rows){
  var gridObj = this.getGridObjectById(parent_unique_id,this.mainDataList)
  serverJson['parentDetails'] = {'parentHeaders':gridObj.headers,'parentInputJson':gridObj.inputJson, 'view_filter':gridObj.view_filter, 'view_having':gridObj.view_having,"parent_table_name":gridObj.table_name,'parent_rows':parent_rows}
  serverJson['join'] = join
  serverJson['loadParentFilter'] = false
  serverJson['filter'] = []
  if(parent_filter === 'Missing mapped column'){
    serverJson['loadParentFilter'] = true
  }
  else
    serverJson['filter'] = parent_filter
  return serverJson
},
GetDataFromDB(tablelist,unique_id,serverJson,is_fresh_child_req,load_type,){
  let _this = this;
  if(!this.selected_env || !this.selected_env.id || !this.selected_ds || !this.selected_ds.id || !this.selected_bo_tbl || !this.selected_bo_tbl.title){
    alert('Please select the Environment, Datasource and Business object/Table.')
    return
  }
  console.log(tablelist)
  // return
  // TODO pass associated business object
  let context_id = _this.getGridContext() // business object id (table open in business object) / self(table open independently)
  let column_details = {}
  let all_headers = []

  // we will calculate per page based on the number of grid opened and how many rows are in the grid.
  let perPage = 0

  var gridObj = _this.getGridObjectById(unique_id,_this.mainDataList)
  var currentUniqueId = unique_id // this unique id used for create childlist for archivist
  let inputJson = _.cloneDeep(this.envIpJson)
  let is_fresh_request = true
  var is_column_details_required = true
  var check_access_request = true
  if(gridObj && gridObj.table_name){
    // its means request for only existing desinged table
    is_column_details_required = false
    inputJson = serverJson
    column_details = gridObj.headers
    all_headers = gridObj.allheaders
    is_fresh_request = false
    check_access_request = false
  }
  else {
    gridObj = {}

  }

  if(is_fresh_child_req) // in fresh child requiest we create filter based on parent. So we have to assign that filter
    inputJson = serverJson

  if(this.isByTicketID)
    inputJson = this.ticketObj.inputJson

  this.resetObj(gridObj)

    var job_data = _this.getAllDSInfo()
    job_data['is_column_details_required']=is_column_details_required
    job_data['check_access_request']=check_access_request
    job_data['context_id'] = context_id
    job_data['object_type'] = tablelist[0].object_type
    job_data['product_name'] =window.sessionStorage.getItem('product')
    job_data['security_model'] = this.securityModelByEnv
    job_data['is_super_admin'] = this.isSuperAdmin
    job_data['open_type'] = this.openModeType
    job_data['is_archivist_worktbl_req'] = this.isArchivistWorkTableCall && this.selected_ds.isArchivistWorkTable
    if(tablelist[0].is_bv_selected){
      job_data['business_view_details'] = {"business_view_id":tablelist[0].object_id}
    }
    else if(gridObj.business_view_details)
      job_data['business_view_details'] = gridObj.business_view_details

    // this is a new request
    if(is_fresh_request){
      perPage = _this.getPerPage(tablelist)
      if(tablelist[0].asscoiated_grid_perPage)
        perPage = tablelist[0].asscoiated_grid_perPage
      
      inputJson.page_size = perPage        
    }

    var table_data = []
    _.forEach(tablelist,function(obj){

    let node_filters = obj.node_filters || (gridObj && gridObj.node_filters)
    let self_join = obj.self_join || (gridObj && gridObj.self_join)
    let merge_tables_detail = obj.merge_tables_detail || (gridObj && gridObj.merge_tables_detail)
    let table_obj = {
      "table_name":obj.table_name,
      "page_size": inputJson.page_size,
      "page": inputJson.page,
      "sort": inputJson.sort,
      "filter": inputJson.filter,
      "filter_data":inputJson.filter_data,
      "join":inputJson.join,
      "column_details":column_details,
      "all_columns":all_headers,
      "primary_keys":inputJson.primary_keys,
      "customizedData":gridObj.customizedData,
      "is_customization_applied":gridObj.is_customization_applied,
      "cust_col_for_order_by":inputJson.cust_col_for_order_by,
      "is_fresh_request":is_fresh_request,
      "view_list":gridObj.availableViewList,
      "last_selected_view":gridObj.selectedView,
      "view_selection_change":gridObj.view_selection_change,
      "group_by":gridObj.group_by,
      "view_filter":gridObj.view_filter,
      "view_having":gridObj.view_having,
      "parentDetails":inputJson.parentDetails,
      "loadParentFilter":inputJson.loadParentFilter,
      "columnsForSelect":gridObj.columnsForSelect,
      "media_object_details":gridObj.mediaObjDetails,
      "showSummaryRow":gridObj.loadSummary,
      "node_filters":node_filters,
      "self_join":self_join,
      "merge_tables_detail":merge_tables_detail,
			"associated_business_object":obj.associated_business_object,
			"associated_business_object_name":obj.associated_business_object_name,
			"associated_datasource_index":obj.associated_datasource_index,
      "additional_table_info":obj.additional_table_info
    }
		let ds_index = gridObj.datasourceIndex>-1?gridObj.datasourceIndex:obj.associated_datasource_index
		if(!(ds_index >-1)){
      ds_index = _this.selected_ds.datasource_index
    }
    
    table_obj['associated_datasource_index'] = ds_index
		table_obj['datasource_index'] = ds_index
		table_obj['table_level_ds_info'] = _this.getAllDSInfo(ds_index)['ds_info']
		
		if(!unique_id && !obj.unique_id){
      let uniqueId = EDCuuidv4()
      currentUniqueId = uniqueId
			// every table should have an unique_id
			obj['unique_id'] = uniqueId
      if(obj['is_bv_selected'])
        table_obj['unique_id'] = uniqueId
    }

    table_data.push(table_obj)
    })
    job_data["table_data"] = table_data
    _this.loader = true
    var requestSentAt = new Date()
    postToServer(_this, config.DATA_VIEWER_URL + '/fetch_driver_table_data',job_data).then(response => {
     _this.loader = false
     console.log('response received from server',new Date(),new Date().getMilliseconds())
     let responseReceivedAt=new Date()
     var data = response
      if(response.length === 0)
        return
      let dynamicChildList = []
      let refreshDynamicChildList = false // in case of the dataitem we might get new join table everytime.but not for the worktable.
      if(_this.isArchivistWorkTableCall && response[0].data && response[0].data.data 
      && response[0].data.data.length){
        if(_this.isDataItemSearchTable(response[0].data.data[0])){
          refreshDynamicChildList = true
          dynamicChildList = _this.getDataItemChildList(response[0].data.data, currentUniqueId)
        }
        else
          dynamicChildList = _this.getArchivistWorktableChildList(response[0].data, currentUniqueId)
      }
      if(gridObj && gridObj.table_name)
      {
        if(_this.isArchivistWorkTableCall && _this.isCurrentObjectSameAsRootObject(gridObj) && refreshDynamicChildList)
          gridObj.children = dynamicChildList

        let otherDetails = {}
        if(gridObj.view_selection_change || gridObj.is_customization_applied){
          var column_list =  response[0].data.columns_details
          // var column_list =  obj.data.columns_details.split(',')
          console.log('column_list',column_list)
          _.forEach(column_list,function(colObj){
            colObj = JDEFormatterObj.formatColumn(colObj,true,
							_this.getSingleDSProp(gridObj.datasourceIndex, 'db_type'))
          })
          gridObj.allheaders = _.cloneDeep(column_list)
        }

        if(gridObj.view_selection_change){

          otherDetails = response[0].data.other_details
          _this.assignHeadersForcefully(gridObj,response[0].data.selected_column_details)
          gridObj.view_selection_change = false // make false because we no need to do this every time
        }

        if(gridObj.is_customization_applied){

          if(gridObj.customizedData.typeOfCustomization === 'deleteAndApply')
            gridObj['selectedView'] = ''

          _this.assignHeadersForcefully(gridObj,response[0].data.selected_column_details)

          otherDetails = {"showDescriptionAsHeader":gridObj.customizedData.showDescriptionAsHeader, "showSummaryRow":gridObj.customizedData.showSummaryRow,"view_filter":gridObj.customizedData.view_filter,"columnsForSelect":gridObj.customizedData.columnsForSelect,
          "view_having":gridObj.customizedData.view_having,"freezeSelectionAndAttachementColumns":gridObj.customizedData.freezeSelectionAndAttachementColumns === false?false:true}
          _this.assignViewListForcefully(gridObj,response[0].data.available_view_list)
          _this.assignSelectedForcefully(gridObj,response[0].data.last_selected_view)
          gridObj.is_customization_applied = false
          gridObj.customizedData = {}
        }


        let data = response[0].data.data
        gridObj.tableLoading = false
        gridObj.rows = data
        // if(inputJson.filter.length || inputJson.filter_data.length)
        //   gridObj.loadSummary = true

        gridObj.total_count = 0
        if(response[0].summary_footer)
          gridObj['summaryFooter'] = response[0].summary_footer[0]
        if(data.length > 0){
          gridObj.total_count = data[0].total_count;

          if(gridObj.childrenDataList && gridObj.childrenDataList.length > 0 && load_type)
            return _this.onLoadChildData(_.cloneDeep(gridObj.childrenDataList),data,'recursive',gridObj.headers)
        }
        _this.assignGroupByForcefully(gridObj,response[0].data.other_details)
        _this.applyBVDrillDown(gridObj,response[0].data.other_details)
        if(!_.isEmpty(otherDetails)){
          _this.assignOtherPropsForcefully(gridObj,otherDetails)
        }
        else
          _this.loadMediaObjectDetails(gridObj)

        _this.assignInputJsonForcefully(gridObj,inputJson)
        _this.showResponseTime(response[0].data.query_executed_in,requestSentAt,responseReceivedAt,gridObj)
        return _this.storeInDataviewerGridObject()
      }


    // This is fresh entry. So need add this to mainDataList.
    _.forEach(response,function(obj){
      // when we open multiple tables , inputJson get over written if we not create clonedeep
      let copyOfinputJson = _.cloneDeep(inputJson)
      var new_mainlist_obj = _.cloneDeep(_this.tableList)
      if(is_fresh_request && perPage >0 && perPage !=new_mainlist_obj.perPage )
        new_mainlist_obj.perPage = perPage
      let tbl_obj = _.find(tablelist,["table_name",obj.table_name])
      if(!tbl_obj && _this.isBusinessViewSelected){
        tbl_obj = _.find(tablelist,["object_id",obj.table_name])
        // add drilldown properties here to tbl_obj
        if(obj.data && obj.data.other_details){
          _this.applyBVDrillDown(tbl_obj,obj.data.other_details)
        }
      }
		  let associated_datasource_index = _this.selected_ds.datasource_index
      if(tbl_obj)
      {
        new_mainlist_obj['is_bv_selected'] = tbl_obj.is_bv_selected
        new_mainlist_obj['unique_id'] = tbl_obj.unique_id
        new_mainlist_obj.children = tbl_obj.children || dynamicChildList
        // console.log('dynamicChildList',dynamicChildList)
        new_mainlist_obj.hierarchy = tbl_obj.hierarchy
        new_mainlist_obj.node_id = tbl_obj.node_id
        new_mainlist_obj.parent_id = tbl_obj.parent_id
        new_mainlist_obj.node_filters = tbl_obj.node_filters
        new_mainlist_obj.self_join = tbl_obj.self_join
				new_mainlist_obj.associated_grid_unique_id = tbl_obj.associated_grid_unique_id
        new_mainlist_obj.datasourceName = _this.selected_ds.ds_name
        if(tbl_obj.associated_business_object_name)
					new_mainlist_obj.associatedBusinessObjectName = tbl_obj.associated_business_object_name
        if(_this.isArchivistCall && !new_mainlist_obj.associatedBusinessObjectName)
        new_mainlist_obj.associatedBusinessObjectName = tbl_obj.table_name // just to show the tablename.
        if(tbl_obj.associated_datasource_index >-1){

					associated_datasource_index = tbl_obj.associated_datasource_index
          if(!_this.isMerged)
            new_mainlist_obj.datasourceName = _this.ds_list[tbl_obj.associated_datasource_index].ds_name
          else
            new_mainlist_obj.datasourceName ="Multiple"
        }

        if(_this.isArchivistWorkTableCall && tbl_obj.table_name === _this.selected_bo_tbl.title){
          new_mainlist_obj.stepType = _this.selected_bo_tbl.step_type
        }
        new_mainlist_obj.tableDescription = tbl_obj.additional_caption || ''
      }
      
      new_mainlist_obj.caption = obj.table_name
      new_mainlist_obj['table_name'] = obj.table_name
      new_mainlist_obj['isDummy'] = false
      new_mainlist_obj['tooltipObj'] = {}
      new_mainlist_obj['openPanel'] = []
      new_mainlist_obj.tableLoading = false
      new_mainlist_obj['childrenDataList'] = []
      
      _this.assignViewListForcefully(new_mainlist_obj,obj.data.available_view_list)
      _this.assignSelectedForcefully(new_mainlist_obj,obj.data.last_selected_view)
      let locationToPushNewGrid = _this.GetLocationForGridPush(new_mainlist_obj.parent_id, _this.mainDataList)
      // this means parent available
      let indexToPush = 0
			var new_grid_obj = {}
      if(locationToPushNewGrid.childrenDataList){
          indexToPush = locationToPushNewGrid.childrenDataList.length
          locationToPushNewGrid.openPanel.push(indexToPush)
          locationToPushNewGrid.childrenDataList.push(new_mainlist_obj)
      }
      else{
        indexToPush = _this.mainDataList.length
        _this.openPanel.push(indexToPush)
        locationToPushNewGrid.push(new_mainlist_obj)
      }
        new_mainlist_obj['listIndex'] = indexToPush
      // assign hierarchy and childs to mainlistobj
      if(obj.access_error){
        if(new_mainlist_obj.is_bv_selected || !obj.data.columns_details || !obj.data.columns_details.length){
          new_mainlist_obj.tableDescription = _this.selected_bo_tbl.object_name
        }
        else
          new_mainlist_obj.tableDescription = obj.data.columns_details[0].table_description

        new_mainlist_obj.caption = obj.table_name
        new_mainlist_obj.access_error = obj.access_error
        return
      }

      var column_list =  obj.data.columns_details
     // var column_list =  obj.data.columns_details.split(',')
     console.log('column_list',column_list)
      _.forEach(column_list,function(colObj){
        if(!new_mainlist_obj.tableDescription && !colObj.is_temp_col){
          new_mainlist_obj.tableDescription = _this.getTableTitle(colObj.table_description || '',new_mainlist_obj,obj)
	        if(new_mainlist_obj.is_bv_selected){
	          new_mainlist_obj.tableDescription = _this.selected_bo_tbl.object_name
	          new_mainlist_obj.caption = obj.table_name
            new_mainlist_obj.datasourceName = ''
	        } else{
						new_mainlist_obj.showMenuForOtherDSTable = true
					}
        }

        colObj = JDEFormatterObj.formatColumn(colObj,true,
					_this.getSingleDSProp(associated_datasource_index,'db_type'))

        if(!new_mainlist_obj.itemkey)
          new_mainlist_obj['itemkey'] = "rn"
      })
       new_mainlist_obj.total_count = 0
      if(obj.data.data.length > 0){
        new_mainlist_obj.total_count = obj.data.data[0].total_count;
      }
      copyOfinputJson['primary_keys'] = obj.data.primary_keys
      copyOfinputJson['cust_col_for_order_by'] = obj.data.cust_col_for_order_by

      if(locationToPushNewGrid.childrenDataList){
				new_grid_obj = locationToPushNewGrid.childrenDataList[indexToPush]
      }else{
				new_grid_obj = locationToPushNewGrid[indexToPush]
        
      }
			new_grid_obj.headers = _this.getCustmisedHeader(column_list,obj.data.selected_column_details)
			new_grid_obj.allheaders = _.cloneDeep(column_list)
			_this.assignRowForcefully(new_grid_obj,obj.data.data)
			_this.assignDatasourceIndexForcefully(new_grid_obj, associated_datasource_index)
			_this.assignGroupByForcefully(new_grid_obj,obj.data.other_details)
			_this.assignOtherPropsForcefully(new_grid_obj,obj.data.other_details)
			_this.assignInputJsonForcefully(new_grid_obj,copyOfinputJson)
      _this.showResponseTime(obj.data.query_executed_in,requestSentAt, responseReceivedAt,new_grid_obj)
      // new_mainlist_obj['inputJson'] = inputJson
      
      // Same code we can use for load childs of all tables , NOT only for the archivist (remove isArchivistCall from IF).
      // Only we have to take care only what if the loaded object is busness object and 
      // it has more than one 1 root element (This scenario should get handled in isCurrentObjectSameAsRootObject()).

      // && (!new_grid_obj.children || !new_grid_obj.children.length) this condition is added to avoid redunt fetch call for children. 
      // If that childs are loaded then we no need to reload again.
      // IF MIGHT NEED TO REMOVE THIS CONDTION WHEN WE ARE GOING TO DO IT GENRIC (NOT ARHCHIVST SPECIFIC)
      if(_this.isArchivistCall && !_this.isCurrentObjectSameAsRootObject(new_grid_obj) && (!new_grid_obj.children || !new_grid_obj.children.length)){
        _this.getBORelatedTableData(true, _.cloneDeep(new_grid_obj), associated_datasource_index)
      }
    })
    _this.storeInDataviewerGridObject()
    }).catch(JobStepError => {
       _this.loader = false
      if(JobStepError){
        this.loader = null
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = JobStepError;
      }
      else {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Error occurred while fetching the details.';
      }
    })
  },
  storeInDataviewerGridObject(){
    var _this = this
    // Right now we are not storing any data for right side more smooth transmission of single,split and merge
    if(this.whichSide === 'right')
      return
    // var gridObj = _.cloneDeep(_this.$store.state.dataviewerGridObject)
    var gridObj = {}
    gridObj[_this.whichSide]={"gridData":_.cloneDeep(_this.mainDataList),"bo_tree":_this.bo_tree,"selected_bo":_this.selected_bo}
    gridObj['openModeType'] = _this.openModeType
    setTimeout(()=>{
				if(!_.isEmpty(_this.mainDataList)){
					_this.setDataviewerGridObject(gridObj)
				}
      },1000)
  },
  getTableTitle(description,gridObj,responseObj){
    if(!responseObj.data || !responseObj.data.other_details || !responseObj.data.other_details.merge_tables_detail || !responseObj.data.other_details.merge_tables_detail.merged_tables || responseObj.data.other_details.merge_tables_detail.merged_tables.length <=1)
      return description

    let newDescription = ''
    let tables = responseObj.data.other_details.merge_tables_detail.merged_tables
    for(let i=0;i<tables.length;i++){
      let table_description = tables[i].table_description
      if(!table_description){
        let descObj = _.find(this.allTableDescriptions,["title",tables[i].table_name])
        if(descObj)
          table_description = descObj.description
        else
          table_description = tables[i].table_name
      }
      if(tables[i].table_name === gridObj.table_name || newDescription.includes(table_description))
        continue
      if(newDescription)
        newDescription =newDescription + ' / '

      if(table_description)
        newDescription = newDescription+table_description +' ('+tables[i].table_name+')'
    }
    if(newDescription)
      newDescription = newDescription + ' / ' + description
    else
      newDescription = description
    return newDescription
  },
  getCustmisedHeader(column_list,onlySelectedColumns){
    // this function is used to re-arange the column order. We need to show data in the order which user has specified in the customized view. Logic is if we have the only selected columns, then we are arranging the columns by that order
    let header = _.cloneDeep(column_list)
    let orderedHeader = [] // user might changed the sequnce in the selected col
    if (onlySelectedColumns && onlySelectedColumns.length){
      _.forEach(onlySelectedColumns,function(obj){
        let colObj = {}
        if(obj.col_alias)
          colObj = _.find(header,['value',obj.col_alias.toLowerCase()])

        if(_.isEmpty(colObj))
          colObj = _.find(header,['column_name',obj.column_name])

        if(!_.isEmpty(colObj)){
          colObj.func = ''
          colObj.func = obj.func
        }
        // some of the useful properties are bind to the onlySelectColumn, that we need to pass to the grid.for example show_Desc
        orderedHeader.push(_.merge(colObj,obj))
      })
      return orderedHeader
    }
    return header
  },

  assignHeadersForcefully(gridObj,headers,isFromPageRedirection){
    let _this = this
    let customisedHeaders = headers
    if(!isFromPageRedirection)
      customisedHeaders = _this.getCustmisedHeader(gridObj.allheaders,headers)
    _this.$nextTick(function () {
          gridObj.headers = customisedHeaders
        })
  },


  assignOtherPropsForcefully(gridObj,otherProps,loadMediaObject=true){
    let _this = this
    _this.$nextTick(function () {
      gridObj.loadSummary = otherProps && otherProps.showSummaryRow?true:false
      // default showDescriptionAsHeader should be TRUE. thats why !otherProps condition added
      gridObj.showDescriptionAsHeader = !_.isEmpty(otherProps) && otherProps.showDescriptionAsHeader === false?false:true
      gridObj['join_for_description'] = otherProps.join_for_description
      gridObj['view_filter'] = otherProps.view_filter
      gridObj['view_having'] = otherProps.view_having
      gridObj['columnsForSelect'] = otherProps.columnsForSelect
      if(otherProps.media_object_details)
        gridObj['mediaObjDetails'] = otherProps.media_object_details
      if(otherProps.business_view_details)
        gridObj['business_view_details'] = otherProps.business_view_details
      if(otherProps.node_filters)
        gridObj['node_filters'] = otherProps.node_filters
      if(otherProps.merge_tables_detail)
        gridObj['merge_tables_detail'] = otherProps.merge_tables_detail
      gridObj['keepFirstColFixed'] = otherProps.freezeSelectionAndAttachementColumns
      if(loadMediaObject){
        _this.loadMediaObjectDetails(gridObj)
      }

      if(otherProps.missing_columns && otherProps.missing_columns.length){
        // here if missing column length is greater than > 5 we need to show multiple column missing message else show column names in the error message
        let common_message =  " columns are missing from the business view which are used in gridview"
        let error_message = otherProps.missing_columns.toString() + common_message
        if(otherProps.missing_columns.length > 5)
          error_message = 'Multiple'+ common_message
        _this.$nextTick(function(){
          _this.snackbar = true
          _this.colorValue = 'error'
          _this.snackbartext = error_message
        })
      }
    })
  },
	assignDatasourceIndexForcefully(gridObj,datasourceIndex){
		if(datasourceIndex < -1)
			return
		this.$nextTick(function(){
			gridObj['datasourceIndex'] = datasourceIndex
		})
	},
  assignGroupByForcefully(gridObj,otherProps){
    if(otherProps && otherProps.group_by){
      this.$nextTick(function(){
        gridObj['group_by'] = otherProps.group_by
      })
    }
  },
  assignRowForcefully(gridObj,rows,loadSummary){
    let _this = this
    _this.$nextTick(function () {
          gridObj.rows = rows
          gridObj.loadSummary = loadSummary
        })
  },
  assignViewListForcefully(gridObj,view_list,selected_view){
    let _this = this
    if(!selected_view)
      selected_view = 'GV01'
    _this.$nextTick(function () {
      gridObj['availableViewList'] = view_list
      gridObj['selectedView'] = selected_view
    })
  },
  assignSelectedForcefully(gridObj,selected_view){
    let _this = this
    _this.$nextTick(function () {
        gridObj['selectedView'] = selected_view
      })
  },
  assignInputJsonForcefully(gridObj,inputJson,isFromPageRedirection){
    let _this = this
    gridObj['inputJson'] = {}
    _this.$nextTick(function () {
        gridObj['inputJson'] = inputJson
      })
    /* make a server call to the count * in following circumstances
      1) If inputJson.page = 1
      2) gridObj.total_count > inputJson.page_size.
      3) If second condition failed, then gridObj.actualRowCount = gridObj.rows.length

      Note: Before making server call, make gridObj.actualRowCount = 0 and use setTimeout to make sure all grid properties get bind properly
    */
    if(inputJson.page != 1 || isFromPageRedirection)
      return
    if(gridObj.total_count <= inputJson.page_size){
      _this.assignActualRowCountForcefully(gridObj.total_count,gridObj)
      return
    }
    else{
      _this.assignActualRowCountForcefully(0,gridObj,true)
      setTimeout(function(){
        _this.loadSummaryFooter(gridObj.unique_id,true)
      },2000)
    }
  },
  assignActualRowCountForcefully(count,gridObj,showPlus=false){
    let _this = this
    _this.$nextTick(function(){
      gridObj['actualRowCount'] = count
      gridObj['showPlus'] = showPlus
    })
    _this.storeInDataviewerGridObject()
  },
  gridContainAttachementColumns(gridObj,attachement_columns){
    let grid_columns = _.map(gridObj.headers,"column_name")
    let only_media_headers = []
    for (var i = 0; i < attachement_columns.length; i++) {
      if(grid_columns.indexOf(attachement_columns[i].toUpperCase()) === -1)
        return false
      only_media_headers.push(_.find(gridObj.headers,['column_name',attachement_columns[i].toUpperCase()]))
    }
    return only_media_headers
  },
  getAttachementAssociateValues(gridObj,media_obj_cols){
    let revised_rows = []
    let _this = this
    for(let i=0;i<gridObj.rows.length;i++){
      let current_row = gridObj.rows[i]
      let temp_json = {}
      for(let j=0;j<media_obj_cols.length;j++){
        temp_json[_this.getColumnValueInProperCase(media_obj_cols[j],gridObj.datasourceIndex)] = current_row[_this.getColumnValueInProperCase(media_obj_cols[j],gridObj.datasourceIndex)]
      }
      revised_rows.push(temp_json)
    }
    return revised_rows
  },
  getColumnValueInProperCase(column_name,datasourceIndex){
		let db_type = this.getSingleDSProp(datasourceIndex, 'db_type')
    if(db_type === 'oracle' || db_type === 'db2i')
      return column_name.toLowerCase()
    return column_name.toUpperCase()
  },
  loadMediaObjectDetails(gridObj){
    let _this = this
    if(!gridObj.rows.length || !gridObj.mediaObjDetails || !gridObj.mediaObjDetails.length)
      return
    
    let attachment_data_list = []
    for(let i=0;i<gridObj.mediaObjDetails.length;i++){

      let only_media_headers = _this.gridContainAttachementColumns(gridObj,gridObj.mediaObjDetails[i].columns_in_associate_join)
      if(!only_media_headers || !only_media_headers.length)
        continue
      let attachment_data = {}
      attachment_data["media_object_details"] = gridObj.mediaObjDetails[i]
      attachment_data["associate_values"] = _this.getAttachementAssociateValues(gridObj,gridObj.mediaObjDetails[i].columns_in_associate_join)
      attachment_data["only_media_headers"] = only_media_headers
      attachment_data_list.push(attachment_data)
    }
    if(!attachment_data_list.length)
      return
    
    gridObj['keepSecondColFixed'] = gridObj['keepFirstColFixed']
    let attachment_data = _this.getAllDSInfo(gridObj.datasourceIndex)
    attachment_data['merge_tables_detail'] = gridObj.merge_tables_detail
    attachment_data['attachment_details'] = attachment_data_list
    postToServer(_this, config.DATA_VIEWER_URL + '/check_for_attachment',attachment_data).then(response =>{
      // code returned by assuming user not going to sort the table unless attachment get loaded. even if he sort new attachement request will get bind. THIS IS VERY RARE CASE
      response = response.data
      let rows = _.cloneDeep(gridObj.rows)
      for(let j=0;j<response.length;j++){
        let each_response = response[j]
        for(let i=0;i<rows.length;i++){
          if(!rows[i]['has_attachment'])
            rows[i]['has_attachment'] = false
          if(each_response[i] && each_response[i].has_attachment){
              rows[i]['has_attachment'] = true
              let attachment_details = {"attachment_details":each_response[i].attachment_details,'media_obj_details':each_response[i].media_obj_details}

              if(!rows[i]['attachment_details'])
                rows[i]['attachment_details'] = [attachment_details]
              else
                rows[i]['attachment_details'].push(attachment_details)
          }
        }
      }
      gridObj.rows = rows
      _this.storeInDataviewerGridObject()
    }).catch(error=>{

    })
  },
  loadMediaAttachmentDetails(unique_id,attachment_media_details,associate_row){
    let _this = this
    let revised_attachment_list = []
    let columnsInMediaJoin = []
    let mediaRowValue = {}
		let gridObj = _this.getGridObjectById(unique_id)
    let datasource_index = gridObj.datasourceIndex
    if(this.openModeType == 'merged')
      datasource_index = parseInt(associate_row[_this.getColumnValueInProperCase('datasource', gridObj.datasourceIndex)])

    for(let i=0;i<attachment_media_details.length;i++){
      let list_of_attachment_details = attachment_media_details[i]['attachment_details']
      if(_.isEmpty(mediaRowValue) && list_of_attachment_details.length)
        mediaRowValue = _.cloneDeep(list_of_attachment_details[0])
      let mediaObjDetails = attachment_media_details[i]['media_obj_details']
      if(!columnsInMediaJoin.length)
        columnsInMediaJoin = mediaObjDetails.columns_in_associate_join

      // let gridObj = _this.getGridObjectById(unique_id,_this.mainDataList)

      _.forEach(list_of_attachment_details,function(obj){
        if([1,2,"1","2"].indexOf(obj[_this.getColumnValueInProperCase('gdgtmotype', gridObj.datasourceIndex)])>-1 )
          obj['name'] = obj[_this.getColumnValueInProperCase('gdgtfilenm', gridObj.datasourceIndex)] + ' ('+mediaObjDetails.media_object_name+')'
        else
          obj['name'] = obj[_this.getColumnValueInProperCase('gdgtitnm', gridObj.datasourceIndex)] + ' ('+mediaObjDetails.media_object_name+')'

        obj['media_obj_details'] = mediaObjDetails
        if(datasource_index>-1)
          obj['datasourceIndex'] = datasource_index
        else
				  obj['datasourceIndex'] = gridObj.datasourceIndex
        revised_attachment_list.push(obj)
      })
    }
    let dataToPass = []
    if(!_.isEmpty(mediaRowValue) && columnsInMediaJoin.length){

      if(gridObj){
        let headers = gridObj.headers
        for(let i=0;i<columnsInMediaJoin.length;i++){
          let columnObj = _.find(headers,['column_name',columnsInMediaJoin[i]])
          if(columnObj && columnObj.description)
            columnObj = columnObj.description.trim()
          else
            columnObj = columnsInMediaJoin[i]
          dataToPass.push({'key':columnObj,'value':mediaRowValue[_this.getColumnValueInProperCase(columnsInMediaJoin[i],gridObj.datasourceIndex)],
					'datasourceIndex':gridObj.datasourceIndex})
        }
      }
    }
    this.edcMediaRowDetails = dataToPass
    this.showDocumentViewer = true
    this.edcDocumentList = revised_attachment_list
    // this.mediaObjDetails = _.cloneDeep(gridObj.mediaObjDetails)
  },
  loadAttachment(list_of_attachment_details,media_object_details,is_download){
    let _this = this
		let datasourceIndex=list_of_attachment_details[0].datasourceIndex
    var attachment_data = _this.getAllDSInfo(datasourceIndex)
    attachment_data["list_of_attachment_details"] = list_of_attachment_details
    attachment_data['media_object_details']=media_object_details
    attachment_data['is_download'] = is_download
    postToServer(_this, config.DATA_VIEWER_URL+'/load_attachment_data',attachment_data).then(response=>{
        _this.edcDocumentObject = {"success":true,"data":response.list_of_attachment_response[0],"is_download":is_download,'datasourceIndex':datasourceIndex}
    }).catch(Error=>{
        _this.edcDocumentObject = {"success":false,"error":Error.toString()}
        console.log(Error.toString())
    })
  },
  getAllDSInfo(datasource_index){
		let selected_ds = _.cloneDeep(this.selected_ds)
		let dict_obj = _.cloneDeep(this.dictionary_data)
		let lib_obj = _.cloneDeep(this.object_librarian_data)
		let ctl_obj = _.cloneDeep(this.control_data)
		let acceleration_type = this.acceleration_type
		let db_type = this.selected_ds.database_type
		let datasource_id = this.selected_ds.datasource_id

		if(datasource_index > -1 && datasource_index < this.ds_list_for_backend.length){
			selected_ds = this.ds_list_for_backend[datasource_index].engine_data
			dict_obj = this.ds_list_for_backend[datasource_index].dictionary_data
			lib_obj = this.ds_list_for_backend[datasource_index].object_librarian_data
			ctl_obj = this.ds_list_for_backend[datasource_index].control_data
			acceleration_type = this.ds_list_for_backend[datasource_index].acceleration_type
			db_type = this.ds_list_for_backend[datasource_index].db_type
			datasource_id = this.ds_list_for_backend[datasource_index].datasource_id
		}
		return {"ds_info":{"engine_data":selected_ds,"business_data":selected_ds,
		"dictionary_data":dict_obj,"object_librarian_data":lib_obj,
		"control_data":ctl_obj,"acceleration_type":acceleration_type,
		'all_available_datasources':this.ds_list_for_backend, "db_type":db_type,
		"database_type":db_type},"client_id":this.$session.get('client_id'),
		"db_type":db_type,"env_id":this.selected_env.id,"database_type":db_type,
		"user_id":this.$session.get('email'),'user_name':this.$session.get('email'),
	 	"datasource_id":datasource_id}
  },
	getSingleDSProp(datasource_index,key){
		let ds_obj = this.getAllDSInfo(datasource_index)["ds_info"]
		return ds_obj[key]
	},
  loadSummaryFooter(unique_id,isCountQueryRequest=false){
    let _this = this
    var  gridObj = _this.getGridObjectById(unique_id,_this.mainDataList)
    if(!gridObj)
      gridObj = {}
    gridObj.edcRequestIdForSummary = EDCuuidv4()
    if(isCountQueryRequest)
      gridObj.edcRequestIdForCount = EDCuuidv4()
    let distinct = false
    // in fetch summarization called get call 2 times.To avoid that adding one label to the grid Obj
    if(!isCountQueryRequest){
      if(gridObj.isSummaryInProgress)
        return
      if(gridObj.inputJson && gridObj.inputJson.page!=1)
        return
      gridObj['summaryFooter'] = {}
      gridObj.isSummaryInProgress = true
    }
    else{
      if(gridObj.business_view_details && gridObj.business_view_details.show_distinct_records)
        distinct = true
    }
    let inputJson = gridObj.inputJson
    let table_obj = {
      "table_name":gridObj.table_name,
      "page_size": 10,
      "page": 1,
      "sort": inputJson.sort,
      "filter": inputJson.filter,
      "filter_data":inputJson.filter_data,
      "view_filter":gridObj.view_filter,
      "column_details":gridObj.headers,
      "primary_keys":inputJson.primary_keys,
      'all_columns':gridObj.allheaders,
      "node_filters":gridObj.node_filters,
      "group_by":gridObj.group_by,
      "view_having":gridObj.view_having,
      "is_count_query_request":isCountQueryRequest,
      "distinct":distinct,
      "join_for_description":gridObj.join_for_description,
      "merge_tables_detail":gridObj.merge_tables_detail,
      "edcRequestId":isCountQueryRequest ? gridObj.edcRequestIdForCount:gridObj.edcRequestIdForSummary,
      "db_object_type":_this.selected_bo_tbl.type
    }
    if(gridObj.business_view_details)
      table_obj['business_view_details'] = gridObj.business_view_details
			var job_data = this.getAllDSInfo(gridObj.datasourceIndex)
			job_data['table_details'] = table_obj

    /* We dont need a loader in case of count query*/
    if(!isCountQueryRequest)
      _this.loader = true
    postToServer(_this, config.DATA_VIEWER_URL + '/fetch_table_summary',job_data).then(response => {
      gridObj.isSummaryInProgress = false
      _this.loader = false
      if(isCountQueryRequest){
        let showPlus = false
        if(response.all_count_fetched === false)
          showPlus = true
        let count = response.data[0].count_1
        if(count < gridObj.total_count)
          count = gridObj.total_count
        _this.assignActualRowCountForcefully(count,gridObj,showPlus)
      }
      else
        gridObj['summaryFooter'] = response.data[0]
    }).catch(JobStepError => {
        gridObj.isSummaryInProgress = false
       _this.loader = false
      if(JobStepError){
        this.loader = null
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = JobStepError;
      }
      else {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = SERVER_ERROR;
      }
    })

  },

  GetLocationForGridPush(parent_id,dataList){
    var _this = this
    if(!dataList)
      dataList = _this.mainDataList
    for(var i=0;i<dataList.length;i++){
      let currentObj = dataList[i]
      if(currentObj.unique_id === parent_id){
        return currentObj
      }
      else{
        if(currentObj.childrenDataList.length){
          return _this.GetLocationForGridPush(parent_id,currentObj.childrenDataList)
        }
      }
    }
    return dataList

  },

  getGridObjectById(unique_id,dataList,is_delete_request=false){
    let _this = this
    if(!unique_id && !_this.isBOSelected && _this.mainDataList.length === 1 
    && !this.virtualBOSelected && !this.isArchivistCall){
      if(is_delete_request)
        _this.mainDataList = []
      return _this.mainDataList[0]
    }

    if(!dataList)
      dataList = _this.mainDataList
    for(var i=0;i<dataList.length;i++){
      let currentObj = dataList[i]
      if(currentObj.unique_id === unique_id){
        if(is_delete_request){
          dataList.splice(i,1)
          return true
        }
        else
          return currentObj
      } 
      else
        if(!currentObj.childrenDataList || !currentObj.childrenDataList.length)
          continue
        let is_obj_found = _this.getGridObjectById(unique_id,currentObj.childrenDataList,is_delete_request)
				if (is_obj_found === true || !_.isEmpty(is_obj_found)){
					return is_obj_found
				}
    }
    return {}
  },
  clearChildGrids(childs){
    let _this = this
    for(var i=0;i<childs.length;i++){
      let currentObj = childs[i]
      _this.resetObj(currentObj)
      if(!currentObj.childrenDataList || !currentObj.childrenDataList.length)
          continue
        return _this.clearChildGrids(currentObj.childrenDataList)
    }
  },
  closePanel(panel_id){
    this.getGridObjectById(panel_id,this.mainDataList,true)
    if(this.mainDataList.length === 0){
      // TODO: here we need to take of spit part as well.
      // delete this.$store.state.dataviewerGridObject[this.whichSide]
      this.setDataviewerGridObject({})
    }
  },

  loadData(data,fetchData=true){
    // immediate calling fetchdata after business object or table selection throwing an error.
    let _this = this
    _this.selected_env = data.selected_env
    _this.selected_ds = data.selected_ds
    _this.selected_bo_tbl = data.selected_bo_tbl
    _this.dictionary_data = data.dictionary_data
    _this.object_librarian_data = data.object_librarian_data
    _this.control_data = data.control_data
    _this.EnvironmentList = data.EnvironmentList
    _this.ds_list_for_backend = data.ds_list_for_backend
    _this.isSuperAdmin = data.isSuperAdmin
    _this.securityModelByEnv = data.securityModelByEnv
    _this.acceleration_type = data.acceleration_type
    _this.ds_list = data.ds_list
    _this.archivistObj = data.archivistObj
    if(fetchData)
      _this.fetchData()
  },
    fetchData(){
      var _this = this
      _this.ResetGrid()
      if(_this.tblBONotSelected)
        return
      if(["table","t","view","v"].indexOf(this.selected_bo_tbl.type.toLowerCase())>-1){
        this.ResetGrid()
        this.isBOSelected =false
        if(this.isArchivistCall){
          let unique_id = ''
          if(this.isArchivistBusinessDataCall)
            unique_id = _this.selected_bo_tbl.unique_id
          this.GetDataFromDB([{"table_name":_this.selected_bo_tbl.title,
          "object_type":'DBO',"additional_caption":_this.selected_bo_tbl.additional_caption 
          || _this.selected_bo_tbl.output_table_reference,
          'ds_index':_this.selected_bo_tbl.ds_index,
          'additional_table_info':_this.selected_bo_tbl.table_column_data}],unique_id)
        } else
          this.getBORelatedTableData(true)
        // this.GetDataFromDB([{"table_name":_this.selected_bo_tbl.title,"object_type":'DBO'}])
      }
      else{
        this.isBOSelected =true
        if(this.selected_bo_tbl.type==="BO")
          this.getBORelatedTableData()
        if(_this.isBusinessViewSelected){
          this.GetDataFromDB([{"table_name":_this.selected_bo_tbl.title,"is_bv_selected":true,
          "object_id":_this.selected_bo_tbl.object_id,"object_type":'BV'}])
        }
      }
  },
  getBORelatedTableData(is_for_table=false, gridObj={},datasourceIndex=-1){
    var _this =this
    if(!this.selected_bo_tbl || !this.selected_bo_tbl.type)
      return
    let objectName = is_for_table?this.selected_bo_tbl.title:this.selected_bo_tbl.object_name
    let objectId = is_for_table?this.selected_bo_tbl.title:this.selected_bo_tbl.object_id
    let datasourceId = _this.selected_ds.datasource_id
    if(datasourceIndex === -1)
      datasourceIndex = _this.selected_ds.datasource_index
    let uniqueId = ''
    let replace_unique_id = false
    /*
      strickly_use_datasource_id is used to work around to fetch object details when user came to pathfinder from the archivist
      Because datasource id might not get match by index in case of the Datamap. Variable used in publisher.
    */
    let strickly_use_datasource_id = ''
    if(!_.isEmpty(gridObj)){
      uniqueId = gridObj.unique_id
      objectName = gridObj.table_name

      if(_this.archivistWorktableRelatedStuff[objectName] && _this.archivistWorktableRelatedStuff[objectName].children){
        gridObj = _this.getGridObjectById(uniqueId)
        gridObj['children'] = _this.archivistWorktableRelatedStuff[objectName].children
        gridObj['associated_business_object'] = _this.archivistWorktableRelatedStuff[objectName].associated_business_object
        gridObj['associated_business_object_name'] = _this.archivistWorktableRelatedStuff[objectName].associated_business_object_name
        gridObj['associatedBusinessObjectName'] = gridObj['associated_business_object_name']
        return
      }
      objectId  = gridObj.table_name
      if(datasourceIndex > -1){
        datasourceId = this.ds_list_for_backend[datasourceIndex].business_data.datasource_id
      }
      if(_this.isArchivistCall){
        strickly_use_datasource_id = datasourceId
      }
      replace_unique_id = true
    }
    if(!objectName)
      return
    var _this = this
    var  request_for = ''
    var data_to_send = {
      'object_id':objectId,
      'env_id':this.selected_env.id,
      'object_name':objectName,
      'request_for':request_for,
      'datasource_id':datasourceId,
			"client_id":this.$session.get('client_id'),
			"all_ds_info":this.ds_list_for_backend,
			"is_from_ui":true,
      "is_for_table":is_for_table,
      "datasource_index":datasourceIndex,
      "unique_id":uniqueId,
      // "strickly_use_datasource_id":strickly_use_datasource_id,
      "replace_unique_id":replace_unique_id
    }

    var get_bo_details_url = config.DATA_VIEWER_URL + '/fetch_object_details'
    postToServer(_this, get_bo_details_url, data_to_send).then(response => {
      if(response){
        this.virtualBOSelected = is_for_table
        if(!request_for){
          _this.bo_tree = []
          _this.selected_bo['alldetails'] = response.node_data
           _this.addJoinToJoinList(response.join_data)
           let table_names = _.sortBy(_.map(response.node_data,"node_name"))
           if(response.merge_tables_detail){
             for(let i=0;i<response.merge_tables_detail.length;i++){
               for(let j=0;j<response.merge_tables_detail[i].merged_tables.length;j++){
                 table_names.push(response.merge_tables_detail[i].merged_tables[j].table_name)
               }
             }
           }
          // it means request is for all hierarchy
          var tables_attached_to_bo = []
          _this.getTableDescriptions(_.uniq(table_names))
          var bo_tree = _this.GetBOTree(response.node_data,response.merge_tables_detail)
          if(!_.isEmpty(gridObj) && bo_tree.length){
            gridObj = _this.getGridObjectById(uniqueId)
            gridObj['children'] = bo_tree[0]['children']
            gridObj['associated_business_object'] = bo_tree[0].associated_business_object
            gridObj['associated_business_object_name'] = bo_tree[0].associated_business_object_name
            gridObj['associatedBusinessObjectName'] = gridObj['associated_business_object_name']
            _this.archivistWorktableRelatedStuff[gridObj.table_name]['children'] = _.cloneDeep(bo_tree[0]['children'])
            _this.archivistWorktableRelatedStuff[gridObj.table_name]['associated_business_object'] = bo_tree[0].associated_business_object
            _this.archivistWorktableRelatedStuff[gridObj.table_name]['associated_business_object_name'] = bo_tree[0].associated_business_object_name
            return
          }
          /* code commented by Siddhesh On 5th Nov. Nothing wrong with code. Its just not needed right now. */
          // _this.addParentAsChild(bo_tree)
          console.log('bo_tree', bo_tree)
          _.forEach(bo_tree,function(obj){
              tables_attached_to_bo.push({"table_name":obj.name,"hierarchy":obj.hierarchy,
              "node_id":obj.node_id,"unique_id":obj.node_id,"children":obj.children,
              "parent_id":obj.parent_id,"node_filters":obj.node_filters,
              "self_join":obj.self_join,"object_type":'BO',
              'merge_tables_detail':obj.merge_tables_detail,
              "associated_business_object":obj.associated_business_object,
              "associated_business_object_name":obj.associated_business_object_name,
              "associated_datasource_index":obj.associated_datasource_index
            })
          })
          is_for_table = false
          _this.GetDataFromDB(tables_attached_to_bo)
        }
        else if(request_for === 'unique_identifier'){
          _.forEach(response,function(obj){
            _this.bo_uniquekeys.push({'column_name':obj})
          })
        }
      }
    }).catch(error_response => {
      if(is_for_table && _.isEmpty(gridObj))
         return this.GetDataFromDB([{"table_name":_this.selected_bo_tbl.title,"object_type":'DBO'}])
    });
  },
  applyBVDrillDown(gridObj,otherDetails){
    let _this = this
    
    if(!otherDetails.business_view_details || !otherDetails.business_view_details.drilldown_details)
      return
    _this.addJoinToJoinList([],true)
    gridObj['children'] = []
    let drilldownDetails = otherDetails.business_view_details.drilldown_details
    let bv_node = drilldownDetails.node_details
    let bv_joins =  drilldownDetails.joins
    if(bv_node){
      _this.bindChildToGridObj(gridObj, bv_node[0].children)
      if(!gridObj.node_id)
          gridObj.node_id = bv_node[0].node_id
    }
    _this.addJoinToJoinList(bv_joins,true)
    _this.getTableDescriptions(drilldownDetails.only_tables)
  },
  bindChildToGridObj(gridObj,children){
    if(!gridObj['children'])
      gridObj['children'] = []
    gridObj['children'] = [].concat(gridObj['children'],children)
  },
  addJoinToJoinList(joins,isReplace){
    var _this =this
    if(isReplace || !_this.selected_bo.joindetails)
      _this.selected_bo['joindetails'] = []
    _this.selected_bo['joindetails'] = [].concat(_this.selected_bo['joindetails'],joins)
  },
  addParentAsChild(currentTree,parentObj){
    var _this = this
    for(let i=0;i<currentTree.length;i++){
      let currentObj = _.cloneDeep(currentTree[i])
      currentObj.children =[]
      currentObj.node_id = currentObj.node_id+'_edcParentAsChild'
      currentObj.unique_id = currentObj.node_id+'_edcParentAsChild'
      _this.addParentAsChild(currentTree[i].children, currentObj)
      if(parentObj){
        let copyOfParent = _.cloneDeep(parentObj)
        copyOfParent.parent_id = currentTree[i].node_id
        currentTree[i].children.push(copyOfParent)

      }
    }
  },
  GetBOTree(list,merge_tables_detail,parent_id,node_name,id,tree_parent_id){
    if(!merge_tables_detail)
      merge_tables_detail = []
    var _this =this
    var new_data_list = []
    var obj = []
    var parent_index = 1
    if(!parent_id){
      obj = list[0]
      id = 1
    }
    else
    {
      for (var i = 0; i < list.length; i++) {
        if(list[i].parent_id === parent_id && list[i].node_name === node_name){
          obj = list[i]
          parent_index = i +1
          break;
        }
      }
    }
    var child_list = obj.child_nodes
    if(!child_list)
      child_list = []

    for(var i=0;i<child_list.length;i++){
        id =id +1+ parent_index
        var node_details = {}
        for(var j=0;j<list.length;j++){
          if(list[j].parent_id === obj.node_id && list[j].node_name === child_list[i])
          {
            node_details = list[j]
            break;
          }
        }
        let node_filters = []
        if(node_details && node_details["filters"] && node_details["filters"].filter_rows_list)
          node_filters = node_details["filters"].filter_rows_list

        new_data_list.push({
          'id':id,
          'name':child_list[i],
          'table_name':child_list[i],
          "merged_tables":_this.getOnlyMergedTables(child_list[i], merge_tables_detail),
          'tree_parent_id':tree_parent_id,
          'parent_id':obj.node_id,
          'node_id':node_details.node_id,
          'unique_id':node_details.node_id,
          'parent_nodes':node_details.parent_nodes,
          'hierarchy':node_details.hierarchy,
					"associated_business_object":node_details.associated_business_object,
					"associated_business_object_name":node_details.associated_business_object_name,
					"associated_datasource_index":node_details.associated_datasource_index,
          "node_filters":node_filters,
          "self_join":node_details.self_join,
          "merge_tables_detail":_.find(merge_tables_detail,['base_table_name',child_list[i]]),
          children:[]
        })
    }

    for (var i = 0; i < new_data_list.length; i++) {
      var new_obj = new_data_list[i]
      let has_access = true
      // for tables which is not root, for that we have to check access.
      // if(new_obj.tree_parent_id)
      //   has_access = _this.hasObjectAccess('DBO',new_obj.name)
      // if(has_access)
      let children_list = _this.GetBOTree(list,merge_tables_detail,obj.node_id,new_obj.name,id,new_obj.id)
      let childListWithAccess = []
      for(let k=0;k<children_list.length;k++){
        if(_this.hasObjectAccess('DBO',children_list[k].name))
          childListWithAccess.push(children_list[k])
      }
      new_obj['children'] = childListWithAccess
    }
    _this.bo_tree = new_data_list

    return new_data_list
  },
  getOnlyMergedTables(table_name,merge_tables_detail){
    if(!merge_tables_detail)
      return []
    let tblObj = _.find(merge_tables_detail,['base_table_name',table_name])
    if(!tblObj)
      return []
    let tables = [tblObj.base_table_name]
    for (let i=0;i<tblObj.merged_tables.length;i++){
      tables.push(tblObj.merged_tables[i].table_name)
    }
    return tables
  },
  hasObjectAccess(object_type,object_id){
    // Same function has been written in the dataviewer service. If you add any condition here , then also add condition there.
    let _this = this
    if(_this.isSuperAdmin)
        return true
    let key_to_check = 'object_id'
    if(object_type === 'DBO')
        key_to_check ='object_name'

    let hasObjectLevelSecurity = _.filter(_this.securityModelByEnv,(obj)=>{
      return obj[key_to_check] === object_id && (!obj.datasource_id || obj.datasource_id === _this.selected_ds.datasource_id)
    })
    if(hasObjectLevelSecurity.length)
      hasObjectLevelSecurity = hasObjectLevelSecurity[0]

    if(hasObjectLevelSecurity && hasObjectLevelSecurity.action_type === 'Grant' && (!hasObjectLevelSecurity.datasource_id || hasObjectLevelSecurity.datasource_id === _this.selected_ds.datasource_id))
      return true
    if(hasObjectLevelSecurity && hasObjectLevelSecurity.action_type === 'Deny' && (!hasObjectLevelSecurity.datasource_id || hasObjectLevelSecurity.datasource_id === _this.selected_ds.datasource_id))
      return false

    // there is no object level security specified. Now check security specified at bulk level (All with object type)
    let hasObjectTypeHasSecurity = _.filter(_this.securityModelByEnv,(obj)=>{
        return obj.object_type === object_type && obj.object_name === 'All' && (!obj.datasource_id || obj.datasource_id === _this.selected_ds.datasource_id)
    })

    return hasObjectTypeHasSecurity.length && hasObjectTypeHasSecurity[0].action_type === 'Grant' && (!hasObjectTypeHasSecurity.datasource_id || hasObjectTypeHasSecurity.datasource_id === _this.selected_ds.datasource_id)
  },
  getJoin(jfrom_id,jto_id){
    let _this = this
    
    // we have to find join incase of associate join is not going to present
    jfrom_id = jfrom_id.replace(_this.associate_constant,'')
    jto_id = jto_id.replace(_this.associate_constant,'')
    let join_list = _.filter(_this.selected_bo['joindetails'], function(join) {
      return join.jfrom_id === jfrom_id && join.jto_id === jto_id;
    });
    if(join_list.length > 0)
      return _.cloneDeep(join_list[0])
    else
      return {}
  },
  getJoinKeys(joincondition=[]){
    let keyMappings = []
    for (var i = 0; i < joincondition.length; i++) {
      keyMappings.push({"from_column":joincondition[i]['from_column'],"to_column":joincondition[i]['to_column']})
    }
    return keyMappings
  },
  getParentFilter(mappedKeys,parent_rows,parent_headers,jobConditionObject){
    // check all columns are present with the parent header
    var _this = this
    let fromCols = _.map(mappedKeys,function(obj){
			// Here we are not passing datasourceIndex as a parameter
			// assuming the first loaded table belongs to selected datasource
      return _this.getColumnValueInProperCase(obj.from_column)
    })

    let parentCols = _.keys(parent_rows[0])
    if(_.intersectionBy(fromCols,parentCols).length <fromCols.length){
      // alert('Required mapping columns not found')
      return 'Missing mapped column'
    }

    let parentFilter = []
    let filter_obj = {
      's_parentheses': '',
      'column_name': '',
      'operator': '_eq_',
      'v_type1': 'Value',
      'v_type2': 'Value',
      'value1': '',
      'value2':'',
      'e_parentheses': '',

    }
    for(let i=0;i<parent_rows.length;i++){
      let current_row = parent_rows[i]
      for(let j=0;j<mappedKeys.length;j++){
          let column_name = mappedKeys[j]['to_column']

          let parent_col_name = mappedKeys[j]['from_column']
					// Here we are not passing datasourceIndex as a parameter
					// assuming the first loaded table belongs to selected datasource
          let value1 = current_row[_this.getColumnValueInProperCase(parent_col_name)]

					let conditionObj = _.find(jobConditionObject,['to_column',column_name])
					if (conditionObj){
						// here we will check the datatype of to and from column if that not match then we need to
						// write conversion.
						// Write now we will do the conversion if jfrom (parent col) is number and jto is character
						if(conditionObj.jfrom_datatype != conditionObj.jto_datatype){
							if(commonFuncObj.isNumericDataType(conditionObj.jto_datatype)){
								value1 = commonFuncObj.removeNonNumericText(value1)
							}
						}
					}

          let revised_filter = _.cloneDeep(filter_obj)
          if(i===0 && j===0)
            revised_filter['s_parentheses'] = '((' // grouping of parent filter at first filter started
          else if(j===0)
            revised_filter['s_parentheses'] = '('
          revised_filter['column_name'] = column_name
          if(value1 === null){
            // if(commonFuncObj.isNumericDataType(conditionObj.jto_datatype))
              value1 = '0'
            // else
            //   revised_filter['operator'] = '_is_n_'
          }
          revised_filter['value1'] = value1

          let parentHeaderObj = _.find(parent_headers,["column_name",parent_col_name])
          if(parentHeaderObj){
            if(parentHeaderObj.is_jde_date){
              revised_filter['value1'] = dateFormatObj.convertDateToJde(value1,this.$session.get('UI_date_format'))
            }
            else if(commonFuncObj.isNumericDataType(parentHeaderObj.data_type))
            {
              revised_filter['value1'] = commonFuncObj.replaceText(revised_filter['value1'])
            }
          }
          
          revised_filter['operation'] = false
          if(j===mappedKeys.length-1)
            revised_filter['e_parentheses'] = ')'
          parentFilter.push(revised_filter)
      }
      parentFilter[parentFilter.length-1]['operation'] = true
    }
    parentFilter[parentFilter.length-1]['e_parentheses'] = '))' // grouping of parent filter at last filter ended
    delete parentFilter[parentFilter.length-1]['operation']
    return parentFilter
  },
  getTableDescriptions(table_list){
    let _this = this
    var job_data = {
    "client_id":this.$session.get('client_id'),"env_id":this.selected_env.id,"object_librarian_data":this.object_librarian_data,"acceleration_type":this.acceleration_type,"table_list":table_list
    }
    let url = config.AGENT_API_URL +'/get_table_description'
    postToServer(_this, url,job_data).then(response => {
        _this.allTableDescriptions = response
      }).catch(JobStepError => {

      })
  },
  loadMoreInfoOfTd(table_name,header,item_value,item_row,position,unique_id){
    let _this = this
    clearInterval(this.udcServerCallTimer)
    let gridObj = _this.getGridObjectById(unique_id,_this.mainDataList)
    if(!gridObj.table_name)
      return
    if(!item_value || (typeof item_value === 'string' && _.isEmpty(item_value.trim())))
      return

    gridObj.tooltipObj = {}
    if(JDEFormatterObj.isUDClookup(header)){
        let obj = _.find(this.tooltipValues,['drky',item_value.trim()])
        if(obj){
          let new_Obj = _.cloneDeep(obj)
          new_Obj['item'] = item_row
          new_Obj['position'] = position
          new_Obj['valueFortooltip'] = obj.drdl01
          gridObj.tooltipObj = new_Obj
          return
        }
        _this.udcServerCallTimer = setTimeout(function(){
          _this.getUDCValues(table_name,header,item_value,item_row,position,gridObj)
        },_this.serverCallDelay)
    }
    else if(JDEFormatterObj.isFILEloopup(header)||JDEFormatterObj.isSERVERloopup(header)){
      let obj = _.find(this.tooltipValues,['file_lookup_item_value',item_value])
      if(obj){
          let new_Obj = _.cloneDeep(obj)
          new_Obj['item'] = item_row
          new_Obj['position'] = position
          new_Obj['valueFortooltip'] = obj.valueFortooltip
          gridObj.tooltipObj = new_Obj
          return
        }
      _this.udcServerCallTimer = setTimeout(function(){
        _this.getFILEDetails(header,unique_id,{},false,item_value,item_row,position,gridObj)
      },_this.serverCallDelay)
    }
  },
  mouseLeaveTd(unique_id){
    let _this = this
    clearInterval(_this.udcServerCallTimer)
  },
  ResetGrid(){
    this.mainDataList = []
    this.bo_uniquekeys = []
    this.is_column_details_required = true
    // this.envIpJson = {
    //   "filter": [],
    //   "filter_data":[],
    //   "sort": [],
    //   "page": 1,
    //   "page_size": 10
    // }
    this.uniquekey_filters=[]
    this.showCriteria=false
    this.updatedUniqueKeyFilters=[]
    this.selectChips = []
    this.openPanel = [0]
    this.allTableDescriptions=[]
    this.tooltipValues = []
    this.udcGridObj = {}
    this.fileGridObj = {}
    this.errorInFileLookupFetch = ''
    this.valueLookupList = []
  },
  resetObj(gridObj){
    gridObj.tableLoading = true
    gridObj.rows = []
    // gridObj.loadSummary = false
    // gridObj.tableDescription = ''
    gridObj.total_count = 0
  },
  resetOtherData(){
    this.selected_bo = {}
    this.archivistWorktableRelatedStuff = {}
  },
    onDownload(downloadParams,unique_id,datalist,selected_rows){
      /* TODO:
        Email on download
        Zip file
        Top 10000 based on the download gridType
      */
      var _this = this
      if(downloadParams.gridType === 'current'){
        datalist.childrenDataList = []
      }
      else{
        // clear all rows from the list
        let temp = _this.clearChildGrids([datalist])
      }
      // in case join or key mapping missing we have to create filter in the backend
      let create_filter_using_row = false
      if(downloadParams.recordType === 'selected_rows'){
        let jfrom_id = datalist.parent_id
        let jto_id = datalist.node_id
        let join = _this.getJoin(jfrom_id,jto_id)
        if(!join){
          create_filter_using_row = true
          // alert("error in getJoin")
          // return
        }
        else{
          let keyMappings = _this.getJoinKeys(join.condition)
          if(!keyMappings.length){
            create_filter_using_row = true
          }
          else{
              let parent_filter = _this.getParentFilter(keyMappings,_.uniq(selected_rows),datalist.headers, join.condition)
              if(typeof(parent_filter) === 'string')
                create_filter_using_row = true
              else
                datalist.inputJson = _this.manageParentDetails(jfrom_id, datalist.inputJson, join, parent_filter,selected_rows)
            }
          }
        }

      let associate_object_id = datalist.caption
      let associate_object_name = datalist.tableDescription
      if(!associate_object_name)
        associate_object_name = associate_object_id
      if(downloadParams.gridType!='current'){
        // incase of all child grid , we need to bind businsess object id
        associate_object_id = this.selected_bo.object_id
      }
      var job_data = this.getAllDSInfo()
			job_data['download_props'] = downloadParams
			job_data['associate_object_id'] = associate_object_id
			job_data['associate_object_name'] = associate_object_name
			job_data['create_filter_using_row'] = create_filter_using_row
      job_data['open_type'] = _this.openModeType
			job_data['datalist'] = datalist
			for(let i=0;i<datalist.length;i++){
				if(datalist[i].datasourceIndex >=0){
					datalist[i]['datasource_index'] = datalist[i].datasourceIndex
					datalist[i]['table_level_ds_info'] = this.getAllDSInfo(datalist[i].datasourceIndex)
				}
			}
      _this.loader = true
      postToServer(_this, config.DATA_VIEWER_URL + '/data_download',job_data).then(response => {
        // _this.loader = false
        // var url = config.DATA_VIEWER_URL + "/static/" + response.filepath;
        // window.open(url, '_blank');
      }).catch(JobStepError => {
        _this.loader = false
      })
      setTimeout(function(){
        _this.loader = false
        _this.snackbar = true
        _this.colorValue = 'success'
        _this.snackbartext = 'Your request has been successfully submitted. Please check download status under My Download menu.'
      },1000)
    },
    beforeDestroy() {
      clearInterval(this.udcServerCallTimer)
    },
    rowCopyToClipboard(message,type){
      let _this = this
      _this.snackbar = true
      _this.colorValue = type
      _this.snackbartext = message;
    },
    onClickAdvanceFilter(header,unique_id){
        this.lookupGridUniqueId = unique_id
        this.lookupTitle = header['description']
        this.lookupFor =header['frerul']
      if(JDEFormatterObj.isUDClookup(header)){
        this.showUDCViewer = true
        if(!JDEFormatterObj.isSameLookupOpenLastTime(header,this.lookupHeader)){
          this.getUDCHeader(header,unique_id)
          this.getUDCDetails(header,'multiple',unique_id)
        }
      }
      else if(JDEFormatterObj.isVALUElookup(header)){
        this.showValueViewer = true
        if(!JDEFormatterObj.isSameLookupOpenLastTime(header,this.lookupHeader)){
          this.getValueLookUp(header, unique_id)
        }
      }
      else if(JDEFormatterObj.isFILEloopup(header) || JDEFormatterObj.isSERVERloopup(header)){
        this.showFileViewer = true
        if(!JDEFormatterObj.isSameLookupOpenLastTime(header,this.lookupHeader) || _.isEmpty(this.fileGridObj)){
          this.fileGridObj = {}
          this.errorInFileLookupFetch =''
          this.getFILEDetails(header,unique_id,{},true)
        }
      }
    },
    getUDCHeader(header,unique_id){
      let _this = this
      let dtsy = header.frero1
      let dtrt = header.frero2
			let datasourceIndex = _this.getDatasourceIndex(unique_id,{})
      var job_data = this.getCommonCallData(_this.getSingleDSProp(datasourceIndex, 'control_data'))
      job_data['dtsy'] =dtsy
      job_data['dtrt'] = dtrt
      this.lookupLoader = true
      this.lookupHeader = _.cloneDeep(header)
      postToServer(_this, config.JDE_FORMATER_URL + '/get_udc_header',job_data).then(response => {
        this.lookupLoader = false
        this.lookupTitle = response[0].udc_description
      }).catch(JobStepError => {
        this.lookupLoader = false
      })
    },
    getUDCValues(table_name,header,item_value,item_row,position,gridObj){
    this.getUDCDetails(header,'single','',{},item_value,item_row,position,gridObj)
  },

    getUDCDetails(header,return_type='multiple',unique_id='',udcGridProps={},drky='',item_row={}, position='',gridObj){
      let _this = this
      let drsy = header.frero1
      let drrt = header.frero2
      if(header['frower'] === 'UDC'){
        drsy = header.froer1
        drrt = header.froer2
      }
			let datasourceIndex = _this.getDatasourceIndex(unique_id,gridObj)
      var job_data = this.getCommonCallData(_this.getSingleDSProp(datasourceIndex, 'control_data'))

      job_data['drky'] =drky

      job_data['drsy'] =drsy
      job_data['drrt'] = drrt
      job_data['return_type'] = return_type
      // job_data['drky'] = drky
      // job_data['drdl01'] = drdl01
      // job_data['drdl02'] = drdl02
      job_data['filter'] = udcGridProps['filter']
      job_data['page'] = udcGridProps['page']
      job_data['sort'] = udcGridProps['sort']
      job_data['page_size'] = udcGridProps['page_size']
      if(return_type === 'multiple'){
        this.udcGridObj = {"rows":[],"total":0}
        this.lookupLoader = true
      }
      postToServer(_this, config.JDE_FORMATER_URL + '/get_udc_details',job_data).then(response => {
        if(return_type === 'multiple'){
          this.lookupLoader = false
          let total_count = 0
          if(response.length){
            total_count = response[0].total_count
          }
          this.udcGridObj = {"rows":response,"total_count":total_count}
        }
        else{
            if(response && response.length===1){
              response[0]['item'] = item_row
              response[0]['position'] = position
              response[0]['valueFortooltip'] = response[0].drdl01
              _this.tooltipValues.push(response[0])
              gridObj.tooltipObj = response[0]
            }
        }
      }).catch(JobStepError => {
        this.lookupLoader = false

      })
    },
    applyUDCFilter(unique_id,rows,column_name,udc_column){
      let _this = this
      let gridObj =  _this.getGridObjectById(unique_id,_this.mainDataList)
      if(!gridObj && !gridObj.table_name){
        alert('error in applying udc filter')
        return
      }
      let filtertext = rows[0][udc_column]
      if(!filtertext)
        // udc has valid 2 spaces. back return null.
        filtertext = '  '
      gridObj.updateInlineFilter = {'column_name':column_name,'filtertext':filtertext}
      this.showUDCViewer = false
    },
    getValueLookUp(header, unique_id){
      let _this = this
      let frdtai = header.frdtai
      // this.lookupTitle = frdtai
			let datasourceIndex = _this.getDatasourceIndex(unique_id,{})
      var job_data = this.getCommonCallData(_this.getSingleDSProp(datasourceIndex, 'dictionary_data'))
      job_data['frdtai'] = header.frdtai
      _this.lookupLoader = true
      _this.lookupHeader = _.cloneDeep(header)
      postToServer(_this, config.JDE_FORMATER_URL + '/get_value_lookup_details',job_data).then(response => {
        _this.lookupLoader = false
        _this.valueLookupList = response
      }).catch(JobStepError => {
        _this.lookupLoader = false
      })

    },
    applyVALUEFilter(unique_id,column_name,value){
      let _this = this
      let gridObj =  _this.getGridObjectById(unique_id,_this.mainDataList)
      if(!gridObj && !gridObj.table_name){
        alert('error in applying value filter')
        return
      }
      gridObj.updateInlineFilter = {'column_name':column_name,'filtertext':value}
      this.showValueViewer = false
    },
    getFILEDetails(header,unique_id='',fileGridProps={},is_column_details_req=false,single_value_for_search='',item_row={},position='',gridObj={}){
      let _this = this
      let frdtai = header.frdtai
			let datasourceIndex = this.getDatasourceIndex(unique_id,gridObj)

      // this.lookupTitle = frdtai
      var job_data = {}
      job_data['data_ds'] =this.getCommonCallData(_this.getSingleDSProp(datasourceIndex, 'engine_data'))
      job_data['dd_source'] = this.getCommonCallData(_this.getSingleDSProp(datasourceIndex, 'dictionary_data'))
      job_data['ol_source'] = this.getCommonCallData(_this.getSingleDSProp(datasourceIndex, 'object_librarian_data'))
      job_data['frdtai'] = header.frdtai
      job_data['filter'] = fileGridProps['filter']
      job_data['page'] = fileGridProps['page']
      job_data['sort'] = fileGridProps['sort']
      job_data['page_size'] = fileGridProps['page_size']
      job_data['acceleration_type']=this.acceleration_type
      job_data['is_column_details_req'] = is_column_details_req
      job_data['frerul'] = this.lookupFor

      job_data['single_value_for_search'] = single_value_for_search
      if(!single_value_for_search){
        _this.lookupLoader = true
        if(!_.isEqual(_this.lookupHeader,header)){
          _this.lookupHeader = _.cloneDeep(header)
        }
      }
      else{
        job_data['frerul'] = header['frerul']
      }
      postToServer(_this, config.JDE_FORMATER_URL + '/get_file_lookup_details',job_data).then(response => {
        if(single_value_for_search){
          if(response.data && response.data.length===1){
              response.data[0]['item'] = item_row
              response.data[0]['position'] = position
              response.data[0]['valueFortooltip'] = response.data[0][response.column_for_tooltip] || response.data[0][response.column_for_tooltip.toUpperCase()]
              response.data[0]['file_lookup_item_value'] = single_value_for_search
              _this.tooltipValues.push(response.data[0])
              gridObj.tooltipObj = response.data[0]
            }
          return
        }
        _this.lookupLoader = false
        let total_count = 0
        if(response.data && response.data.length){
          total_count = response.data[0].total_count
        }
        let file_headers = []
        _this.fileViewerWidth = 800
        if(is_column_details_req){
          if(response.columns && response.columns.length >4)
            _this.fileViewerWidth = 1000
          if(response.columns && response.columns.length >6)
            _this.fileViewerWidth = 1200
          file_headers = JDEFormatterObj.getFILEGridHeaders(response.columns,_this.selected_ds.database_type)
        }
        _this.fileGridObj = {"rows":response.data,"total_count":total_count,"headers":file_headers,"column_to_pass":response.column_to_pass}
      }).catch(JobStepError => {
        _this.lookupLoader = false
        _this.errorInFileLookupFetch = JobStepError+'' // sometime job error come as exception type (TypeError)
      })
    },
    applyFileFilter(unique_id,rows,column_name,file_column){
      let _this = this
      let gridObj =  _this.getGridObjectById(unique_id,_this.mainDataList)
      if(!gridObj && !gridObj.table_name){
        alert('error in applying udc filter')
        return
      }
      let filtertext = rows[0][file_column] || rows[0][file_column.toUpperCase()]

      // if(!filtertext)
      //   // udc has valid 2 spaces. back return null.
      //   filtertext = '  '

      gridObj.updateInlineFilter = {'column_name':column_name,'filtertext':filtertext}
      this.showFileViewer = false
    },
    getCommonCallData(otherDetails){
      otherDetails['client_id'] = this.$session.get('client_id')
      otherDetails['env_id'] = this.selected_env.id
      return otherDetails
    },
    getFilterByOperator(rows,column_name,udc_column,operator = '_in_'){
      let filter_obj = {
        's_parentheses': '',
        'column_name': column_name,
        'operator': operator,
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',

      }
      if(operator === '_in_'){
        let value_list= []
        for(let i=0;i<rows.length;i++){
          value_list.push(rows[i][udc_column])
        }
        if(value_list.length){
          filter_obj['value1'] = value_list
          return [filter_obj]
        }
      }
      return []
    },
    clearCache(){
      let _this = this
      // this.lookupTitle = frdtai
      var job_data = {"client_id":this.$session.get('client_id')}
      postToServer(_this, config.EDC_CACHE_SERVICE_URL + '/clean_cache',job_data).then(response => {
        alert('cleared')
      }).catch(JobStepError => {
      })
    },
    customizeGridView(datalist,selectedView,action){
      let gridObj = this.getGridObjectById(datalist.unique_id)

      let columns = []
      _.forEach(datalist.allheaders,function(obj){
        if(obj.is_attachment_col || obj.LVL)
          return
        columns.push(obj)
      })

      this.objectForCustomizeView = {"available_columns":columns,"table_name":datalist.table_name,"unique_id":datalist.unique_id,"primary_keys":datalist.inputJson.primary_keys,'selectedView':selectedView,'action':action}

      if(selectedView === 'GV00' || action === 'add' || action === 'edit' || action === 'copy'){
        this.showCustomViewer = true
      }
      else if(action === 'delete'){
        this.deleteCustomView(gridObj,selectedView,'deleteAndApply')
      }
      else if(selectedView === 'GV01'){
        gridObj['selectedView'] = ''
        this.deleteCustomView(gridObj,selectedView,'withoutView')
      }
      else{
        // user changed the selection
        gridObj['selectedView'] = selectedView
        gridObj['view_selection_change'] = true
        gridObj = this.ResetGridForCustization(gridObj)
        this.GetDataFromDB([{"table_name":datalist.table_name}],datalist.unique_id,gridObj.inputJson)
      }
    },
    ShowConfirm(gridObj,selectedView){
      let confirmFn = function () {
      this.$parent.deleteCustomView(gridObj,selectedView,'deleteAndApply')
      }
      let obj = {
        title: 'Unpublish Grid View',
        message: 'Are you sure you want to unpublish this gridview?',
        type: 'info',
        useConfirmBtn: true,
        onConfirm: confirmFn,
        customCloseBtnText:'Cancel',
        customConfirmBtnText:'Unpublish'
      }
      this.$refs.simplert.openSimplert(obj)
    },
    deleteCustomView(gridObj,view_for_delete,typeOfCustomization){
      let _this = this
      let object_name = ''
      if(gridObj && gridObj.availableViewList){
        let viewObj = _.find(gridObj.availableViewList,["object_id",view_for_delete])
        if(viewObj)
          object_name = viewObj.object_name
      }
      _this.loader = true
      let data = {"object_id":view_for_delete,'object_name':object_name,"client_id":this.$session.get('client_id'),"user_id":this.$session.get('email'),context_id:_this.getGridContext(),'typeOfCustomization':typeOfCustomization,'object_revision':'1','env_id':this.selected_env.id,'env_name':this.selected_env.name}
      postToServer(_this,config.DATA_VIEWER_URL+'/delete_view_mapping',data).then(response=>{
        if(typeOfCustomization === 'deleteAndApply'){
          gridObj.selectedView = ''
          let freshenvIpJson = _.cloneDeep(_this.envIpJson)
          // we need to maintain the parent filter as it is if user delete the gridview.
          if(gridObj.inputJson){
            freshenvIpJson['filter'] = gridObj.inputJson.filter
          }
          gridObj['inputJson'] = freshenvIpJson
        }
        let customizedData = {
          'is_customization_applied':true,"view_filter":[],"view_having":[],columnsForOrder:[],"columnsForSelect":gridObj.allheaders,'typeOfCustomization':typeOfCustomization,'view_selection_change':true}
        _this.applyCustomization(customizedData)
      }).catch(error=>{
        _this.loader = false
      })

    },
    applyCustomization(customizedData){
      let unique_id = this.objectForCustomizeView.unique_id
      let typeOfCustomization = customizedData['typeOfCustomization'] // onlyApply, CreateNewAndApply, EditExistingAndApply
      let gridObj = this.getGridObjectById(unique_id)

      gridObj["is_customization_applied"] = true
      gridObj = this.ResetGridForCustization(gridObj)
      gridObj['view_filter'] = customizedData.view_filter
      gridObj['view_having'] = customizedData.view_having
      gridObj['columnsForSelect'] = customizedData.columnsForSelect
      if(customizedData.view_selection_change)
        gridObj['view_selection_change'] = true
      // gridObj["showDescriptionAsHeader"] = true
      gridObj["customizedData"] = _.cloneDeep(customizedData)
      gridObj.inputJson['cust_col_for_order_by'] = customizedData.columnsForOrder
      gridObj.inputJson['primary_keys'] = this.objectForCustomizeView.primary_keys
      gridObj.inputJson['sort'] = []
      gridObj.inputJson['page'] = 1
      this.GetDataFromDB([{"table_name":this.objectForCustomizeView.table_name}],unique_id,gridObj.inputJson)
      this.showCustomViewer = false
    },
    getGridContext(){
      let context_id = 'self' // business object id (table open in business object) / self(table open independently)
      if(this.isBOSelected)
        context_id = this.selected_bo_tbl.object_id
      return context_id
    },
    ResetGridForCustization(gridObj){
      gridObj["loadSummary"] = false
      gridObj['group_by'] = []
      gridObj['view_filter'] = []
      gridObj['view_having'] = []
      gridObj['columnsForSelect'] = []
      gridObj.inputJson['sort'] = []
      gridObj.inputJson['cust_col_for_order_by'] = []
      return gridObj
    },
    manageSecondaryCount(data){
      let gridObj = _.find(this.mainDataList,['edcRequestIdForCount',data.edc_request_id])
      if(gridObj)
        this.assignActualRowCountForcefully(data.current_count,gridObj,true)
    },
    getPerPage(tablelist){
      let defaultScreenSize = manageScreenDisplay()
      /*
        first check how many table are getting displayed and its row count
      */

      let gridCount = this.getRowCount(this.mainDataList,{"row_count":0,"item_count":0})

      /*
        now check how many entries in the table list and multiply it by manageScreenDisplay()
      */
      let totalAvailableItem = gridCount.item_count + tablelist.length

      /*
        formula is sum of availableAllgridRowcount & defaultScreenSize, divided by sum of number of current displayed grid  & the current request tablelist.
      */
      if (!gridCount.row_count)
        gridCount.row_count = defaultScreenSize

      let perPage =  ((gridCount.row_count)/totalAvailableItem) // -5 to adjust the screen because we are showing entries in the grid

      if(perPage < 5)
        perPage=5
      else{
        // we need keep per page in the multiply by 5
        perPage = Math.ceil(perPage/5)*5
      }
      return perPage
    },
    getRowCount(gridList,gridCount){
      var _this =this
      if(!gridList){
        gridList = _this.mainDataList
        gridCount = {"row_count":0,"item_count":0}
      }
      for(let i=0;i<gridList.length;i++){
        if(gridList[i] && gridList[i].rows && gridList[i].rows.length)
          gridCount.row_count = gridCount.row_count + gridList[i].rows.length
          gridCount.item_count = gridCount.item_count + 1
        if(gridList[i].childrenDataList && gridList[i].childrenDataList.length)
          gridCount = _this.getRowCount(gridList[i].childrenDataList,gridCount)
      }
      return gridCount
    },
		getDatasourceIndex(unique_id,gridObj){
			let _this =this
			let datasourceIndex = 0
			if(gridObj && gridObj.datasourceIndex)
				datasourceIndex = gridObj.datasourceIndex
			else{
				let newGrid = _this.getGridObjectById(unique_id)
				if(newGrid)
					datasourceIndex = newGrid.datasourceIndex
			}
			return datasourceIndex
		},
    openTableInOtherDS(obj){
      this.changeCompareGridProps(obj, obj.associated_datasource_index)
      obj['parent_id'] = obj['parent_id']+this.associate_constant
      return this.GetDataFromDB([obj],obj['unique_id'])
    },
    changeCompareGridProps(obj,datasource_index){
      // here we will change the all props which will help grid to indentify uniquely and 
      // will maintain hierarchy properly
      var _this = this
      // this associated_grid_unique_id we have to change first as we are changing the unique id afterward.
      obj['associated_grid_unique_id'] = obj['node_id']
      obj['node_id'] = obj.node_id + _this.associate_constant
      obj['unique_id'] = obj.node_id
      // here we need to check datasource_index
      obj['associated_datasource_index'] = datasource_index
      // todo: node filter,merge_tables_detail
      if(obj.children){
        for(let i=0;i<obj.children.length;i++){
          obj.children[i]['parent_id'] = obj.unique_id
          obj.children[i]['datasource_name'] = obj.datasource_name
          _this.changeCompareGridProps(obj.children[i], datasource_index)
        }
      }
    },
    changePropOfEachGrid(dataList,key,value){
      var _this = this
      for(let i=0;i<dataList.length;i++){
        dataList[i][key]=value
        if(dataList[i].childrenDataList){
          _this.changePropOfEachGrid(dataList[i].childrenDataList, key, value)
        }
      }
    },
    showResponseTime(query_executed_in,requestSentAt, responseReceivedAt,gridObj){
      if(!query_executed_in)
        return
      let currentTime = new Date()
      let roundTripTime =  moment(currentTime).diff(moment(requestSentAt), 'milliseconds')
      let uiTimeDiff = moment(currentTime).diff(moment(responseReceivedAt), 'milliseconds')
      let fetchTimeDetails = ' Fetched In '+Math.round(roundTripTime)+'ms (+'+Math.round(query_executed_in)+'ms)'
      gridObj['fetchTimeDetails'] = fetchTimeDetails
    },
    getReverseJoin(join){
      let newJoin = {}
      newJoin['jto'] = join.jfrom
      newJoin['jto_id'] = join.jfrom_id
      newJoin['jfrom'] = join.jto
      newJoin['jfrom_id'] = join.jto_id
      newJoin['condition'] = []
      for(let i=0;i<join.condition.length;i++){
        let contObj = {}
        contObj['jto'] = join.jfrom
        contObj['jfrom'] = join.jto
        contObj['from_column'] = join.condition[i]['to_column']
        contObj['to_column'] = join.condition[i]['from_column']
        newJoin['condition'].push(contObj)
      }
      return newJoin
    },
    checkForDynamicMenu(unique_id,selectedRows){
      //=============================================NOT USED========================================//
      let new_unique_id = EDCuuidv4()
      if(this.isArchivistCall){
        if(this.archivistObj.request_for === 'worktable' 
        && this.isDataItemSearchTable(selectedRows[0])){
          let tableObj = {
            "table_name":selectedRows[0].table_name || selectedRows[0].TABLE_NAME,
            "object_type":'DBO',
            "ds_index":1,
            "associated_datasource_index":1,
            "parent_id":unique_id
          }
          this.GetDataFromDB([tableObj],new_unique_id)
        }
      }
    },
    isDataItemSearchTable(record){
      return (!_.isEmpty(record.table_name) && !_.isEmpty(record.primary_keys_value))
       || (!_.isEmpty(record.TABLE_NAME) && !_.isEmpty(record.PRIMARY_KEYS_VALUE))
    },
    getDataItemChildList(rows,parent_id){
      var _this = this
      let tableList = []
      let childList = []
      let joins = []
      let key = 'TABLE_NAME'
      let description = "DESCRIPTION"
      if(!rows[0][key]){
        key = "table_name"
        description = "description"
      }
      for(let i=0;i<rows.length;i++){
        let table_name = rows[i][key]
        if(tableList.indexOf(table_name)>-1)
          continue
        tableList.push(table_name)
        let table_description = rows[i][description]
        let currentTableUniqueId = EDCuuidv4()

        let childObj = _this.getChildNodeForArchivist(table_name,currentTableUniqueId,parent_id,table_description,[],true)
          if(table_description)
            this.allTableDescriptions.push({"title":table_name, "description":table_description})
        childList.push(childObj)
        let joinObj = _this.getJoinObjForArchivist(table_name,parent_id,currentTableUniqueId,true)
        let cols = this.sliptDataItemPrimaryKey(rows[i],'onlycols')
        for(let j=0;j<cols.length;j++){
          joinObj.condition.push({"jfrom":table_name,"jto":table_name,
          'from_column':cols[j],"to_column":cols[j]})
        }
        joins.push(joinObj)
      }
      _this.addJoinToJoinList(joins)
      return childList
    },
    getArchivistWorktableChildList(response_obj,parent_id){
      var _this = this
      // if any archivist worktable child opened. we have to maintain childlist of parent
      if(this.whichSide ==='right')
        return []
      if(response_obj.table_name != this.selected_bo_tbl.title)
        return this.mainDataList[0].children
      else{
        // we no need to reload child list as its already loaded.
        if(this.mainDataList.length && this.mainDataList[0].children && this.mainDataList[0].children.length)
          return this.mainDataList[0].children
      }
      
      console.log(response_obj)
      let all_headers = response_obj.columns_details
      let tables_involoved = _.uniq(_.map(response_obj.columns_details,'table_name_for_archivist'))
      if (!tables_involoved.length)
        return []
      let childList = []
      let joins = []
      let allTables = []
      let mergeStepAllColumns = [] // this column will used in the join in case of merge.
      let isMergeDetailsPresent = false
      for(let i=0;i<tables_involoved.length;i++){
        let tableObj = {"table_name":tables_involoved[i]}
        mergeStepAllColumns = _.filter(all_headers,(obj)=>{
          return obj.table_name_for_archivist === tables_involoved[i]
        })
        tableObj['used_cols'] = _.map(mergeStepAllColumns,'column_name')
        mergeStepAllColumns = _.map(mergeStepAllColumns,'column_name')
        allTables.push(tableObj)
      }

      /* We are treating merge table sepratly because in sales order there is a merge step where
      4201 and 4211 merged. So columns length for the both tables are diffrent */
      if(_this.selected_bo_tbl.table_column_data && _this.selected_bo_tbl.table_column_data[0] 
      && _this.selected_bo_tbl.table_column_data[0].merge_details 
      && _this.selected_bo_tbl.table_column_data[0].merge_details.length){
        let mergeDetails = _this.selected_bo_tbl.table_column_data[0].merge_details  
        for(let i=0;i<mergeDetails.length;i++){
            let currentTable = mergeDetails[i]
            if(tables_involoved.indexOf(currentTable.table_name)>-1)
              continue
            tables_involoved.push(currentTable.table_name)
            if(_this.isTableMergePossible(currentTable,allTables))
              continue
            isMergeDetailsPresent = true
            allTables.push(currentTable)
          }
      }
      _this.getTableDescriptions(tables_involoved)

      for(let k=0;k<allTables.length;k++){
        let table_name = allTables[k].table_name
        // in some case table name might be undefined. So ignore it.
        if(!table_name)
          continue
        let currentTableUniqueId = allTables[k].currentTableUniqueId || EDCuuidv4()
        let merge_tables_detail = allTables[k].merge_tables_detail || []
        let childObj = _this.getChildNodeForArchivist(table_name,currentTableUniqueId,parent_id,'',merge_tables_detail,false,true)
        childList.push(childObj)

        _this.archivistWorktableRelatedStuff[table_name] = {"unique_id": currentTableUniqueId}


        // create join object
        
        let joinObj = _this.getJoinObjForArchivist(table_name,parent_id,currentTableUniqueId,false,true)
        for(let j=0;j<allTables[k].used_cols.length;j++){
          joinObj.condition.push({"jfrom":table_name,"jto":table_name,
          'from_column':isMergeDetailsPresent? mergeStepAllColumns[j]:allTables[k].used_cols[j],"to_column":allTables[k].used_cols[j]})
        }
        joins.push(joinObj)
      }
      this.addJoinToJoinList(joins)
      return childList
    },
    getChildNodeForArchivist(table_name,currentTableUniqueId,parent_id,table_description,merge_tables_detail,is_dataitem_wrktbl=false,is_other_wrktbl=false){
      let childObj = {"table_name":table_name,"hierarchy":[],
        "node_id":currentTableUniqueId, 
        "unique_id":currentTableUniqueId,"children":[],
        "parent_id":parent_id,"node_filters":[],
        "self_join":[],"object_type":'DBO',
        'merge_tables_detail':merge_tables_detail,
        "associated_datasource_index":0,
        "title":table_name,"display_name":table_name,"name":table_name,
        "table_description":table_description,"is_archivist_dynamic":true,
        'is_dataitem_wrktbl':is_dataitem_wrktbl,'is_other_wrktbl':is_other_wrktbl
      }
      if(merge_tables_detail)
        childObj['merged_tables'] = merge_tables_detail.merged_table_names_for_context_menu
      return childObj
    },
    getJoinObjForArchivist(table_name,parent_id,currentTableUniqueId,is_dataitem_wrktbl=false,is_other_wrktbl=false){
      return {
          "jfrom_id":parent_id,"jfrom":table_name,
          "jto_id":currentTableUniqueId,"jto":table_name,
          "is_dataitem_wrktbl":is_dataitem_wrktbl,"is_other_wrktbl":is_other_wrktbl,
          "condition":[]
        }
    },
    isTableMergePossible(currentTable,allTables){
      // Here assumption is if column names matches then column count should get match. If assumption failed then 
      // just return false or dont call this function.
      let currentUsedCols = currentTable.used_cols
      for(let i=0;i<allTables.length;i++){
        let usedColumns = allTables[i].used_cols
        if(_.intersectionBy(currentUsedCols,usedColumns).length <currentUsedCols.length)
          continue
        if(!allTables[i]['merge_tables_detail']){
          let uniqueID= EDCuuidv4()
          allTables[i]['currentTableUniqueId'] = uniqueID
          allTables[i]['base_table_name'] = allTables[i].table_name
          allTables[i]['merge_tables_detail'] = {"base_table_id":uniqueID,"table_name":allTables[i].table_name,
          "merged_tables":[],"merged_table_names_for_context_menu":[allTables[i].table_name]}
        }
        allTables[i]['merge_tables_detail'].merged_tables.push({"table_name":currentTable.table_name})
        allTables[i]['merge_tables_detail'].merged_table_names_for_context_menu.push(currentTable.table_name)
        return true
      }
      return false

    },
    sliptDataItemPrimaryKey(row,returnType){
      let columns = []
      let columnwithDataRow = {}
      let primary_key_data = row.primary_keys_value || row.PRIMARY_KEYS_VALUE
      let dataValues = primary_key_data.split(',')
      for(let j=0;j<dataValues.length;j++){
        let col = dataValues[j].split(':')[0]
        let val = dataValues[j].split(':')[1]
        columns.push(col)
        columnwithDataRow[col] = val
      }
      if(returnType === 'onlycols')
        return columns
      else
        return columnwithDataRow
    },
    getDownloadLimit(){
      var _this = this
      let requestData = {"client_id":this.$session.get('client_id')}
      postToServer(_this,config.DATA_VIEWER_URL+'/get_download_options',requestData).then(response=>{
        this.downloadLimit = response.rounded_limit_in_mil
      }).catch(error=>{

      })
    },
    userChangedViewOnTheFly(actionNeedToPerform){
      var _this = this
      let gridObjCopy = _.cloneDeep(_this.mainDataList)
      _this.mainDataList = []
      if(actionNeedToPerform === 'ClearGrid')
        return
      this.fetchData()
    },
    isCurrentObjectSameAsRootObject(gridObj){
    // We have to take care only what if the loaded object is busness object and 
    // it has more than one 1 root element
       return gridObj && gridObj.table_name && gridObj.table_name === this.selected_bo_tbl.title
    },
  }
}

</script>
