/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Password_Reset': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M54.773 38.035A23 23 0 0135.79 54.854a23 23 0 01-22.914-10.871" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/><path pid="1" d="M24 43l-7.936 4.728-7.936 4.728-.126-9.236-.127-9.237 8.062 4.508z"/><path pid="2" d="M9.227 25.965A23 23 0 0128.21 9.146a23 23 0 0122.914 10.871" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/><path pid="3" d="M40 21l7.936-4.728 7.936-4.728.126 9.236.127 9.237-8.062-4.508z"/><g _fill="none" _stroke="#000" stroke-width="4" stroke-linecap="round"><rect pid="4" ry="5.145" y="23.569" x="19.947" height="12.863" width="9.241"/><path pid="5" d="M30 30h16.299M37.244 30.905v4.528M44 30.662V37"/></g>'
  }
})
