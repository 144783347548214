/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Search': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M26.824 3.62c-11.586 0-21 9.413-21 21 0 11.585 9.414 21 21 21 5.082 0 9.744-1.813 13.38-4.823a2 2 0 00.206.236l14 14a2 2 0 102.828-2.828l-14-14A2 2 0 0043 38a20.906 20.906 0 004.824-13.38c0-11.587-9.414-21-21-21zm0 2c10.505 0 19 8.494 19 19 0 10.504-8.495 19-19 19s-19-8.496-19-19c0-10.506 8.495-19 19-19zm1.809 4.993a15.48 15.48 0 00-9.395 2.832 15.507 15.507 0 00-5.71 17.72.5.5 0 10.945-.327 14.496 14.496 0 015.341-16.574 14.496 14.496 0 0117.407.506.5.5 0 00.705-.069.5.5 0 00-.082-.713 15.473 15.473 0 00-9.211-3.375z"/>'
  }
})
