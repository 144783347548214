<template>
  <div id="environmentlist">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li><router-link to="/userlist">User List</router-link></li>
              <li>Reset Password</li>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form v-model="valid" ref="form">
        <v-card elevation-1>
          <v-row no-gutters class="EDC-Row">
            <v-col cols="3"></v-col>
            <v-col cols="6" class="EDC-Col">
              <v-row no-gutters class="EDC-Row" style="margin-top:20px !important">
                <v-col cols="12" class="EDC-Col">
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                    <v-text-field label="Password" v-model="password" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" class="input-group--focused" required :rules="passwordRules" ref='password' @click:append="show1 = !show1" @keyup="checkwithcurrent_password" autocomplete="off" dense></v-text-field>
                  </v-row>
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                    <v-text-field label="Confirm Password" v-model="confirmpassword" :rules=" confirmPasswordRules" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" class="input-group--focused" required @click:append="show1 = !show1" ref='confirmpassword' @keyup="checkwithpassword" autocomplete="off" dense></v-text-field>
                  </v-row>
                  <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                    <vc-button itemText="Reset Password By Email" @click.native="change_password_by_email"></vc-button>
                    <vc-button itemText="Reset User Password" @click.native="submit"></vc-button>
                    <!-- <v-btn :color="outlineColor"@click.native="loader = 'loading'" @click="submit" outline style="border-radius:10px">Reset User Password </v-btn> -->
                    <vc-button itemText="Cancel" @click.native="cancle"></vc-button>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3"></v-col>
          </v-row>
        </v-card>
      </v-form>
      <loading-panel :loader="loader"></loading-panel>
      <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </v-container>
  </div>
</template>

<script>
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import {SERVER_ERROR} from '../../data/client_message.js'
import {RESET_USER_PASSWORD} from '../../data/url_constants.js'
import {COLOR_CODE, BTN_COLOR} from '../../data/macros.js'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  data () {
    return { 
      outlineColor:BTN_COLOR,
      colorCode:COLOR_CODE,
      valid: true,
      snackbar:false,
      snackbartext:'',
      snackbartimeout: Snackbar_Default_Timeout,
      colorValue:'error',
      loader: false,
      loading: false,
      show1: false,
      show2: false,
      password: '',
      current_password:'',
      confirmpassword: '',
      change_password_input:'',
      password_policy: {},
      passwordRules: [v => !!v || 'Password is required.',
      v => v != this.current_password || 'Old Password and Password should not be same.'],
      confirmPasswordRules: [v => !!v || 'Confirm Password is required.',
      v=> v == this.password || 'Password and Confirm Password should be same.'],
    }
  },

  mounted (){
    this.getClient()
  },

  methods:{
    checkwithcurrent_password(){
      this.$refs.password.validate()
    },
    cancle () {
     this.$router.push('/userlist');
  
    },
    checkwithpassword(){
      this.$refs.confirmpassword.validate()
    },
    
    submit () {
      if (this.$refs.form.validate()) {
        this.validatePassword()
      }
    },

    change_password(){
      const l = this.loader
      this[l] = !this[l]
      var _this = this
      var change_password_endpoint = config.USER_PROVISION_URL + RESET_USER_PASSWORD
      var change_password_input = {
        'username': this.$route.params.username,
        'new_password': this.password,
        'first_name': this.$route.params.first_name,
        'last_name': this.$route.params.last_name
      }
      this.loader = true
      postToServer(this, change_password_endpoint, change_password_input).then(user_provisioning_response  => {
        this.snackbar = true
        this.colorValue = 'info'
        this.snackbartext = user_provisioning_response
        setTimeout(function() {
          _this.$router.go(-1)
        }, 1000);
        this[l]  = false
        this.loader = null
      }).catch(error_response => {
         this.loader = false
        if(error_response){            
          this.snackbartext = error_response
        }
        else {
          this.snackbartext = SERVER_ERROR
        } 
        this.snackbar = true
        this.colorValue = 'error'
        this[l]  = false
        this.loader = null
      });
    },
    change_password_by_email(){
      const l = this.loader
      this[l] = !this[l]
      var _this = this
      var change_password_endpoint = config.USER_PROVISION_URL + RESET_USER_PASSWORD
      var change_password_input = {
        'is_reset_by_email': true,
        'username': this.$route.params.username,
        'first_name': this.$route.params.first_name,
        'last_name': this.$route.params.last_name
      }
      this.loader = true
      postToServer(this, change_password_endpoint, change_password_input).then(user_provisioning_response  => {
        this.snackbar = true
        this.colorValue = 'info'
        this.snackbartext = user_provisioning_response
        setTimeout(function() {
          _this.$router.go(-1)
        }, 1000);
        this[l]  = false
        this.loader = null
      }).catch(error_response => {
        this.loader = false
        if(error_response){            
          this.snackbartext = error_response
        }
        else {
          this.snackbartext = SERVER_ERROR
        } 
        this.snackbar = true
        this.colorValue = 'error'
        this[l]  = false
        this.loader = null
      });
    },

    getClient() {
      var client_id = this.$session.get('client_id')
      getToServer(this, config.ACCOUNT_MANAGEMENT_URL + '/client_account_preferences/' + client_id).then(response => {
        this.password_policy = response
        }).catch(error_response => {
          if(error_response){
              this.loader = null
              this.snackbar = true
              this.copw1PUCIb3lorValue = 'error'
              this.snackbartext = error_response;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Something went wrong. Try Again';
            }
        })
    },

    validatePassword(){
      if(this.password.length > this.password_policy.max_password_length){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Maximum ' + this.password_policy.max_password_length + ' characters';
      }
      else if(this.password.length < this.password_policy.min_password_length){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Minimum ' + this.password_policy.min_password_length + ' characters';
      }
      else if(this.password_policy.password_allow_numeric = 1 && !this.password.match(/[0-9]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Numeric digit is missing';
      }
      else if(this.password_policy.password_must_contain_alphabates = 1 && !this.password.match(/[a-z]/i)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Alphabate is missing';
      }
      else if(this.password_policy.password_must_contain_uppercase = 1 && !this.password.match(/[A-Z]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Uppercase Character is missing';
      }
      else if(this.password_policy.password_must_contain_lowercase = 1 && !this.password.match(/[a-z]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'Lowercase Character is missing';
      }
      else if(this.password_policy.password_must_contain_special_character = 1 && !this.password.match(/[!@#$%^&*]/g)){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = "Special Character is missing. Use '!', '@', '#', '$', '%', '^', '&' or '*'";
      }
      else{
        this.change_password()
      }
    },

    EmailSent(){
      var user_details_url = config.USER_PROVISION_URL + '/create_and_send_user_password'
      var user_details = {
        'username': this.$route.params.username,
        'first_name': this.$route.params.first_name,
        'last_name': this.$route.params.last_name
      }
      postToServer(this, user_details_url, user_details).then(user_provisioning_response  => {
        this.loader = null
        this.snackbar = true
        this.colorValue = 'info'
        this.snackbartext = user_provisioning_response;
      }).catch(error_response => {
        if(error_response){
          this.loader = null
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Something went wrong. Try Again';
        }
      })
    }
  }
}
</script>

<style>


</style>
