<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <v-card style="margin-bottom:4px !important;padding:4px !important;">
                    <edc-data-toolbar @redirectToDataviewer="manageOpenMode($event)" @startOver="startOver" 
                    :breadscrumString="breadscrumString" @userChangedViewOnTheFly="userChangedViewOnTheFly"
                    :jobId="jobId"></edc-data-toolbar>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" :cols="getGridCols('left')">
                <v-row class="EDC-Row">
                    <v-col :cols="expandTo==='left'?'9':'12'" class="EDC-Col">
                        <edc-dataviewer :key="'grid1'" :whichSide="isSplitMode?'left':'wholepage'" 
                        :openModeType="openModeType" :isCompacted="expandTo==='left'?true:false" :startOverCount="startOverCount" :userActionToPerform="userActionToPerform"></edc-dataviewer>
                    </v-col>
                    <v-col cols="1" v-if="expandTo==='left'" class="EDC-Col" align-self="center" align="center">
                        <svgicon class="svg-icon-grid svg-fill-grid" name="expand_panel_right" title="Expand Left Grid" @click.native="expandTo?expandTo='':expandTo='right'" v-if="expandTo!='right'"></svgicon>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="EDC-Col" :cols="getGridCols('right')" v-if="isSplitMode">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" :cols="expandTo==='right'?'3':'1'" align-self="center" align="center" v-if="isSplitMode && expandTo!='left'">
                        <svgicon class="svg-icon-grid svg-fill-grid" name="expand_panel_right" title="Expand Left Grid" @click.native="expandTo?expandTo='':expandTo='right'" v-if="!expandTo"></svgicon><br>
                        <svgicon class="svg-icon-grid svg-fill-grid" name="expand_panel_left"  title="Exapand Right Grid" @click.native="expandTo?expandTo='':expandTo='left'" v-if="expandTo!='left'"></svgicon>
                    </v-col>
                    <v-col class="EDC-Col" :cols="expandTo==='right'?'9':expandTo==='left'?'12':'11'">
                        <edc-dataviewer :key="'grid2'" :whichSide="'right'" :openModeType="openModeType" 
                        :isCompacted="expandTo==='right'?true:false" :startOverCount="startOverCount"></edc-dataviewer>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <simplert :useRadius="true" :useIcon="true" ref="simplert">
        </simplert>
    </v-container>
</template>
<style scoped>

</style>
<script>
import {mixinDataviewer} from '../../mixins/dataviewer.js'
import dataviewer from  './dataviewer.vue'
import datatoolbar from '../../views/edcDataToolbar.vue'
import Simplert from 'vue2-simplert'

export default {
    name:'DataviewerContainer',
    components:{
        'edc-dataviewer':dataviewer,
        'edc-data-toolbar':datatoolbar,
        'simplert':Simplert
    },
    mixins:[mixinDataviewer],
    data(){
        return{
            openModeType:this.$route.params.opentType || 'single',
            expandTo:'',
            startOverCount:1,
            breadscrumString:'',
            userActionToPerform:'',
            jobId:0,
        }
    },
    watch:{
        '$store.state.dataviewerGridObject':{
            handler(newVal){
                if(!_.isEmpty(this.$store.state.dataviewerGridObject)
                || !this.$store.state.dataviewerGridObject.gridData){
                    this.breadscrumString = ''
                }
                else{
                    this.createBreadScrumString()
                }
            }
        }
    },
    mounted(){
        if(this.isDataviewerGridObjLoaded){
            this.openModeType = this.$store.state.dataviewerGridObject.openModeType
        }
        // in case of archivist call
        if(this.$route.params.jobId)
            this.jobId = this.$route.params.jobId
    },
    methods:{
        getGridCols(whichSideGrid){
            if(!this.isSplitMode)
                return 12
            
            if(!this.expandTo)
                return 6
            if(whichSideGrid === 'left'){
                if(this.expandTo === 'left')
                    return 1
                else
                    return 11
            } 
            if(whichSideGrid === 'right'){
                if(this.expandTo === 'right')
                    return 1
                else
                    return 11
            }
        },
        startOver(){
            if(!this.isDataviewerGridObjLoaded)
                return
            let confirmFn = function () {
            this.$parent.startOverCount++
            }
            let obj = {
                title: 'Start Over',
                message: 'Are you sure you want to start over?',
                type: 'info',
                useConfirmBtn: true,
                onConfirm: confirmFn,
                customCloseBtnText:'Cancel',
                customConfirmBtnText:'Start Over'
            }
            this.$refs.simplert.openSimplert(obj)
        },
        createBreadScrumString(){
            for(let i=0;i<this.$store.state.dataviewerGridObject.gridData.length;i++){
                let currentObj = this.$store.state.dataviewerGridObject.gridData[i]
                if(this.breadscrumString)
                    this.breadscrumString = this.breadscrumString+'=>'
                this.breadscrumString = this.breadscrumString + currentObj.tableDescription 

            }
        },
        manageOpenMode(mode){
            this.openModeType = mode
        },
        userChangedViewOnTheFly(mode, actionNeedToPerform){
            var _this = this
            this.manageOpenMode(mode)
            this.userActionToPerform = actionNeedToPerform
            _this.setTimeout(()=>{
                this.userActionToPerform = ''
            }, 500)
            
        }
    }
}
</script>