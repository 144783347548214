<template>
	<v-container class="EDC-Container" style="background-color:white;">
		<v-row class="EDC-Row" align-content="start">
			<v-toolbar dense flat tile class="column-search-toolbar">
              <v-toolbar-title style="width:100% !important;"> 
              	<v-row class="EDC-Row">
              <v-col class="EDC-Col" cols="11" align="start">
                <v-text-field  style="width:100% !important;" class="column-search-text" :label="customLabel" v-model="searchText" autocomplete="off" id="tbl_searchbox" @click:clear="clearSearch" clearable v-on:keyup="perform_serach" dense hide-details></v-text-field> 
            </v-col>
            <v-col class="EDC-Col" align="end">
              	<svgicon class="svg-icon-mini svg-fill-mini" style="cursor:pointer;" name="Search"  :original="true" ref="tbl_searchicon_ref"></svgicon>
            </v-col>
              </v-row>
            </v-toolbar-title>
            </v-toolbar>
		</v-row>
	</v-container>
</template>
<script>
	export default {
		name:'edctoolbar',
		props:{
			customLabel:String,
			usedFor:String, // this prop for future reference. Idea is we can using this toolbar for other than list too.
			bindWithElement:String,
			clearTooltipIndex:Number,
		},
		data(){
			return{
				searchText:'',
			}
		},
		watch:{
			customLabel:{
				handler(newvalue){

				}
			},
			usedFor:{
				handler(newvalue){

				}
			},
			bindWithElement:{
				handler(newvalue){

				}
			},
			clearTooltipIndex:{
				handler(newvalue){
					this.clearSearch()
				}
			}
		},
		methods:{
			perform_serach(){
				this.$emit('perform-serach',this.searchText,this.bindWithElement)
			},
			clearSearch(){
				this.searchText = ''
				this.perform_serach()
			}
		}
	}
</script>
<style scoped>	
.column-search-text{    
    padding-bottom: 16px;
}

.column-search-toolbar {
    height:32px !important;
    background-color:#E0E0E0 !important;
}

</style>