/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Synchronize': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M26 4v7H8a1 1 0 00-1 1v40a1 1 0 001 1h12v-2H9V13h17v7l6-4 6-4-6-4-6-4zM37 60v-7h18a1 1 0 001-1V12a1 1 0 00-1-1H43v2h11v38H37v-7l-6 4-6 4 6 4z"/>'
  }
})
