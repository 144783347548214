import config from '../config.json'
import {Archivist_Product,Workflow_Product,Dataviewer_Product,Develop_Product,Ticket_management,Ticket_Management_Domain} from '../constants/constants.js'

export function getIntialURL(){
	let url = this.$session.get('protocol')
	let domain_name = this.$session.get('erp_cloud_host')
	return url +'://'+domain_name
}

export function getURLByDomainByProduct(_this,productDetails,route_path,query_string){
    let url = _this.$session.get('protocol')+'://'+productDetails.host_name
    if(productDetails.running_port)
    	url = url+':'+productDetails.running_port
    url = url + '/'+route_path

    url = url+'?product_key='+_this.$session.get('access_token')
    if(query_string)
    	url = url + query_string
    return url
}
