/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Job_Suspend': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" cx="31.774" cy="31.651" r="24.712" _fill="none" _stroke="#000" stroke-width="2"/><rect pid="1" width="8" height="24" x="20" y="20" rx="4" ry="4"/><rect pid="2" ry="4" rx="4" y="20" x="36" height="24" width="8"/>'
  }
})
