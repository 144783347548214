/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cancel_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M3.176.53A2.65 2.65 0 00.529 3.174a2.651 2.651 0 002.647 2.647A2.649 2.649 0 005.82 3.174 2.648 2.648 0 003.176.53zm0 .529a2.11 2.11 0 012.115 2.115 2.112 2.112 0 01-2.115 2.118 2.114 2.114 0 01-2.117-2.118c0-1.172.945-2.115 2.117-2.115zm-.795 1.058a.265.265 0 00-.026 0 .265.265 0 00-.158.454l.605.604-.605.605a.265.265 0 10.373.373l.605-.605.604.605a.265.265 0 10.373-.373l-.604-.605.604-.604a.265.265 0 00-.191-.453.265.265 0 00-.182.08l-.604.604-.605-.604a.265.265 0 00-.19-.081z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
