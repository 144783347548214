/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Reverse_Operation': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M34.5 4.932C26.982 10.784 19.198 16.835 12.557 22c7.525 5.847 15.302 11.904 21.943 17.066V30.58c6.119 1.845 8.884 4.125 9.566 5.89.777 2.01-.174 4.511-2.425 7.264-2.242 2.742-6.488 6.233-10.157 9.006A614.775 614.775 0 0123 59s13.769-3.706 20.715-7.922c3.411-2.07 6.852-4.519 9.525-7.305 2.672-2.785 4.69-6.016 4.655-9.648-.03-3.16-1.75-5.913-3.995-8.223-2.244-2.31-5.11-4.285-7.976-5.957-2.867-1.672-5.74-3.032-7.985-4.023-1.178-.52-2.316-.988-3.439-1.46zm-2 4.09v6.755c4.288 1.786 8.807 3.804 12.416 5.895 2.772 1.617 5.503 3.518 7.549 5.623 2.046 2.105 3.406 4.412 3.43 6.848.027 2.865-1.617 5.661-4.098 8.248-2.481 2.586-5.8 4.96-9.121 6.976-3.88 2.355-7.443 3.845-10.155 4.856 3.01-2.021 7.324-5.136 10.666-9.223 2.453-3 3.908-6.248 2.747-9.25-1.157-2.99-4.9-5.72-12.811-7.717l-.623-.158v7.104L15.814 22zm1 20.177c-22.333 23.2-11.167 11.6 0 0z"/>'
  }
})
