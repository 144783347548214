/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'User - Admin': {
    width: 201.063,
    height: 201.19,
    viewBox: '0 0 53.198 53.232',
    data: '<g transform="translate(-1.942 2.661)"><path pid="0" d="M9.872 24.364c-1.316.04-3.662 5.374-3.725 8.452-.07 3.397 2.372 8.757 3.725 9.487H39.987c1.353-.73 3.794-6.09 3.724-9.487-.063-3.078-2.409-8.413-3.724-8.452 0 0-.688-.05-1.027 0-4.829.703-9.151 4.175-14.03 4.176-4.883 0-9.21-3.473-14.04-4.176-.337-.05-1.018 0-1.018 0z"/><path pid="1" d="M49.542 24.887c-1.445-2.834-5.647 2.851-9.493-.994-3.898 3.898-7.83-1.765-9.232.994-2.826 5.566 6.624 14.636 9.363 16.217 2.47-1.776 12.197-10.656 9.362-16.217z" _stroke="#fff" stroke-width="1.7666477999999999" stroke-linecap="round"/><path pid="2" d="M41.234 26.933v9.909l.003.002c1.431-1.03 6.256-5.874 5.954-9.478-.094-1.136-4.32 1.572-5.957-.107z" _fill="#fff"/><ellipse pid="3" ry="9.827" rx="9.88" cy="15.434" cx="24.929"/></g>'
  }
})
