<template>
  <div id="configuresmtp">
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
          <v-row class="EDC-Row breadcrumbRow">
              <v-col cols="4" class="EDC-Col">
                  <ul class="breadcrumb breadcrumbUL">
                      <li>Configure SMTP</li>
                  </ul>
              </v-col>
              <v-col cols="8"></v-col>
          </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-form ref="configure_smtp_form" v-model="valid">
        <v-card elevation-1>
          <v-container>
            <v-col cols="12" class="EDC-Col">
              <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="smtp_host" label="SMTP Host" :rules="nameRules" required dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="smtp_port" label="SMTP Port" :rules="nameRules" required dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="from_email" :rules="usernameRules" required label="From Email Id" dense/>
                </v-col>
              </v-row>

              <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col">
                  <v-checkbox class="customCheckbox EDC-UIComponent" v-model="use_ssl" name="User" label="Use SSL" :checked="use_ssl == true"  dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="user_id" required label="From User Id" :rules="nameRules" class="EDC-UIComponent" dense/>
                </v-col>
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="password" label="Password" :append-icon="show1 ? 'visibility_off' : 'visibility'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" class="EDC-UIComponent" dense/>
                </v-col>
              </v-row>

              <v-row no-gutters class="EDC-Row EDC-RowsSpacing">
                <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                  <v-text-field v-model="smtp_file_size" required label="Attachment size (In MB)" class="EDC-UIComponent" dense/>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4"></v-col>
              </v-row>

              <v-row no-gutters class="EDC-Row EDC-RowsSpacing" justify="end">
                <vc-button itemText="Send Test Email" @click.native="dialog=true"></vc-button>
                <!-- <v-btn outline :color="outlineColor" style="border-radius:10px" @click="dialog=true">Send Test Email</v-btn> -->
                <vc-button itemText="Test Connection" :loading="validateConnectionLoader" @click.native="PerformClick('true')"></vc-button>
                <!-- <v-btn outline :color="outlineColor" :loading="validateConnectionLoader" style="border-radius:10px" @click="PerformClick('true')">Test Connection</v-btn> -->
                <vc-button itemText="Save" :loading="saveLoader" @click.native="PerformClick()"></vc-button>
                <!-- <v-btn outline :loading="saveLoader" :color="outlineColor" style="border-radius:10px" @click="PerformClick()">Save</v-btn> -->
                <vc-button itemText="Cancel" @click.native="$router.go(-1)"></vc-button>
                <!-- <v-btn outline :color="outlineColor" style="border-radius:10px" @click="$router.go(-1)">Cancel</v-btn> -->
              </v-row>
            </v-col>
          </v-container>
        </v-card>
      </v-form>
    </v-container>

    <v-dialog v-model="dialog" width="500">
      <v-form v-model="valid">
        <v-col>
          <v-card elevation-1>
            <v-col>
              <v-container>
                <v-text-field v-model="send_to_email" required label="Send To Email" dense/>
                <vc-button :loading="testConnectionLoader" itemText="Send" @click.native="SendTestEmail"></vc-button>
              </v-container>
            </v-col>
          </v-card>
        </v-col>
      </v-form>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    
  </div>
</template>

  <style scoped>
  /*.v-text-field.v-text-field--solo .v-input__control{
    min-height: 20px;
  }
  #adduser .v-input--checkbox, label {
    margin-top:2%;
  }*/
  .customCheckbox >>> label{
  top: 5px !important;
  }
  .customRadio>>> label{
    top: 5px !important;
  }
  .EDC-UIComponent{
      margin: 0px !important;
      padding: 0px !important;
  }
  </style>

  <script>
  import config from "../../config.json"
  import Breadcrumb from "../Breadcrumbs.vue"
  import { post as postToServer } from './../../methods/serverCall.js';
  import { get as getToServer } from './../../methods/serverCall.js';
  import { GET_SMTP_SETTING,MANAGE_SMTP_SETTING,VALIDATE_SMTP_SETTING } from '../../data/url_constants.js'
  import {COLOR_CODE, BTN_COLOR} from '@/data/macros.js'
  import {
    DATE_TIME_FORMAT
  } from '@/data/manage_account_constants.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'

  export default {
    data () {
      return {
        date_formatitems: DATE_TIME_FORMAT,
        testConnectionLoader:false,
        saveLoader:false,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        valid: false,
        snackbar:false,
        show1:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        loader: null,
        loading: false,
        smtp_host:'',
        smtp_port:'',
        base_dn:'',
        nameRules:[
        v => !!v || 'Field is required'
        ],
        user_name:'',
        password:'',
        usernameRules:[v => !!v || 'E-mail is required',
                      v => /.+@.+/.test(v) || 'E-mail must be valid'],
        send_to_email:'',
        smtp_file_size:'',
        dialog:false,
        validateConnectionLoader:false,
      }
    },
    mounted () {
      this.GetSMTPSetting()
    },
    methods: {
      PerformClick(is_validate_call) {
        this.snackbar = false
        if (this.$refs.configure_smtp_form.validate()) {
          var client_id = this.$session.get('client_id')
          var data_to_pass = {
            "client_id":client_id,
            "smtp_host":this.smtp_host,
            "smtp_port":this.smtp_port,
            "created_by":this.$session.get("email"),
            'user_id':this.user_id,
            "password":this.password,
            "from_email": this.from_email,
            "use_ssl": this.use_ssl,
            "smtp_file_size":this.smtp_file_size,
            "is_active":true
          }
          if(is_validate_call){
            return this.ValidateConnection(data_to_pass)
          }
          this.SaveData(data_to_pass)
        }
      },
      SendTestEmail(){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(this.send_to_email).toLowerCase())){
          this.testConnectionLoader =false;
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = 'Please provide the valid email address'
          return
        }
        var client_id = this.$session.get('client_id')
        this.testConnectionLoader = true;
        var data_to_pass = {
            "client_id":client_id,
            "smtp_host":this.smtp_host,
            "smtp_port":this.smtp_port,
            "created_by":this.$session.get("email"),
            'user_id':this.user_id,
            "password":this.password,
            "from_email": this.from_email,
            "use_ssl": this.use_ssl,
            "is_active":true,
            "send_to_email":this.send_to_email
          }
        postToServer(this, config.ACCOUNT_MANAGEMENT_URL + '/send_test_email', data_to_pass
          ).then(response  => {
            this.testConnectionLoader =false;
            this.snackbar = true
            this.colorValue = 'success'
            this.snackbartext = response
            this.dialog = false
  

          }).catch(e => {
            this.testConnectionLoader = false;
            console.log('error occured')
            console.log(e)
          })
      },
      GetSMTPSetting(){
        var client_id = this.$session.get('client_id')
        var data_to_fetch = {
          "client_id":client_id
        }
        postToServer(this, config.ACCOUNT_MANAGEMENT_URL + GET_SMTP_SETTING, data_to_fetch
          ).then(response  => {
            console.log('response',response)
            this.smtp_host = response.smtp_host
            this.smtp_port = response.smtp_port
            this.user_id = response.user_id
            this.password =  response.password
            this.from_email = response.from_email
            this.use_ssl = response.use_ssl
            this.smtp_file_size = response.smtp_file_size

          }, (response) => {
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(e => {
            console.log('error occured')
            console.log(e)
          })
        },
        SaveData(data_to_save){
          this.saveLoader = true
          console.log('data',data_to_save)
          postToServer(this, config.ACCOUNT_MANAGEMENT_URL + MANAGE_SMTP_SETTING, data_to_save
            ).then(response  => {
              this.saveLoader = false
              // window.location.href = "/dashboard"
              this.snackbar = true
              this.colorValue = 'success'
              this.snackbartext = "Saved successfully";
            }, (response) => {
              this.saveLoader = false
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = response;
            }).catch(e => {
              this.saveLoader = false
              console.log('error occured')
              console.log(e)
            })
          },
          ValidateConnection(data_to_validate){
            this.validateConnectionLoader = true;
            console.log('data',data_to_validate)
            postToServer(this, config.ACCOUNT_MANAGEMENT_URL + VALIDATE_SMTP_SETTING, data_to_validate
              ).then(response  => {
                this.validateConnectionLoader = false;
                this.snackbar = true
                this.colorValue = 'success'
                this.snackbartext = response;
              }, (response) => {
                this.validateConnectionLoader = false;
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = response;
              }).catch(e => {
                this.validateConnectionLoader = false;
                console.log('error occured')
                console.log(e)
              })
            },
          }
        }
        </script>