<template> 
  <div id="client-settings">
    <v-container>
      <v-form v-model="valid" ref="form">
        <v-card elevation-1>     
          <v-container class="EDC-Container">
            <v-col cols="12" class="EDC-Col">
              <v-row no-gutters align="center" class="EDC-Row">
                <v-tabs v-model="inactive" non-linear>
                  <v-tab class="EDC-Tabs">Account Preference</v-tab>
                  <!-- <v-tab class="EDC-Tabs">Subscription</v-tab>
                  <v-tab class="EDC-Tabs">Activity Logs</v-tab>
                  <v-tab class="EDC-Tabs">Auditor</v-tab> -->
                </v-tabs>
              </v-row>
            </v-col>
          </v-container>

          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card elevation-1>
                  <v-card-title>
                    <v-toolbar dark dense absolute height="30px">
                      <v-col dark dense absolute class="right">Details And Preferences</v-col>
                        <v-btn icon @click="manageClient" v-if="has_update_right">
                          <v-icon v-if="has_update_right">fa-edit</v-icon>
                        </v-btn>
                    </v-toolbar>
                    </v-card-title>
                  <v-card-text>
                  <v-data-table  :headers="headers" dense hide-default-footer items-per-page = 15 :items="org_details" >
                    <template v-slot:body="{ items }">
                        <tbody>
                          <tr v-for="(item,index) in items" :key="index" >
                            <td class="text-md-left text-capitalize" width="200px">{{ item.name }}</td>
                            <td class="text-md-left">{{ item.value }}</td>
                          </tr>
                        </tbody>
                    </template>
                  </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

          
          </v-container>
        </v-card>
      </v-form>
    </v-container>
    <loading-panel :loader="loader"></loading-panel>
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<style>
.v-tabs__div{
  text-transform:none;
}
.v-toolbar__content{
 height: 37px;
}
</style>

<script>
  import config from '../../config.json'
  import { get as getToServer } from './../../methods/serverCall.js';
  import { GET_CLIENT_DETAILS, GET_USER } from '../../data/url_constants.js';
  import {
    ACCOUNT_MANAGEMENT_SERVICE,UPDATE_ACTION
  } from "../../data/macros.js"
  import getUserRole from '../../methods/GetUserRole.js'
  import {Snackbar_Default_Timeout} from '@/constants/constants.js'
  import moment from 'moment'

export default {
  data () {
      return {
        preferences: [],
        loader: false,
        loading: false,
        org_details: [],
        userRole:{},
        has_update_right:false,
        snackbar:false,
        snackbartext:'',
        snackbartimeout: Snackbar_Default_Timeout,
        colorValue:'',
      }
    },
    mounted() {
      this.userRole = this.$session.get('user_role_mapping');
      this.has_update_right = this.userRole.is_superadmin || getUserRole(this.userRole,ACCOUNT_MANAGEMENT_SERVICE,UPDATE_ACTION)
      this.getClient();
    },
    methods: {
      getClient() {
        const l = this.loader
        this[l] = !this[l]
        var _this = this
        var Authorization = this.$session.get('access_token')
        this.Organization = this.$session.get('client_id')
        var client_id = this.$session.get('client_id')
        this.loader = true
        getToServer(this, config.ACCOUNT_MANAGEMENT_URL + GET_CLIENT_DETAILS + client_id,
         {
           headers: {
             'Authorization': Authorization,
             'Content-Type': 'application/json'
           }
         }).then(response => {
          if(response){
          var data = response;
          this.loader = false
          // client details
          var foo = []
          var data1 = []
          var date_format = this.$session.get('UI_date_format')
          let keys = Object.keys(data.client)
          var constants = ['domain_name', 'phone', 'created_date', 'client_name']
          keys.map(function(object){
            if(object === 'phone'){
              foo.push({'name': object.split("_").join(" "), 'value': data.client[object], "index":2})
            }
             else if(object === 'domain_name'){
              foo.push({'name': object.split("_").join(" "), 'value': data.client[object], "index":2})
            }
            else if(object === 'client_name'){
              foo.push({'name': object.split("_").join(" "), 'value': data.client[object], "index":1})
            }
            else if(object === 'created_date'){
              foo.push({'name': object.split("_").join(" "), 'value': moment(String(data.client[object])).format(date_format),"index":4})
            }
          
          });
          // client preferences
          // var bar = []
          var c_constants = ['password_cannot_be_same_as_previous_n_passwords', 'min_password_length', 'user_email_verification', 'password_must_contain_special_character', 'password_must_contain_uppercase', 'password_must_contain_lowercase', 'password_allow_numeric','password_policy', 'max_password_length','wrong_password_attempts']
          let acc_keys = Object.keys(data.account_preference);
          acc_keys.map(function(object){
              if (c_constants.includes(object)) {
                 if(object === 'password_cannot_be_same_as_previous_n_passwords'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":7})
                }
                else if(object === 'user_email_verification'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":5})
                }
                else if(object === 'min_password_length'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":8})
                }
                else if(object === 'max_password_length'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":9})
                }
                else if(object === 'password_must_contain_special_character'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":10})
                }
                else if(object === 'password_must_contain_uppercase'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":11})
                }
                else if(object === 'password_must_contain_lowercase'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":12})
                }
                else if(object === 'password_allow_numeric'){
                foo.push({'name': object.split("_").join(" "), 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":13})
                }
                 else if(object === 'password_policy'){
                foo.push({'name': "Password Expiry In Days", 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":6})
                }
                else if(object === 'wrong_password_attempts'){
                foo.push({'name': object.split("_").join(" ") + ' (Lock Account)', 'value': data.account_preference[object]=== 0 ? 'No' : data.account_preference[object]=== 1 ? 'Yes' : data.account_preference[object],"index":14})
                }
              }
          });
          this.org_details = _.sortBy(foo,"index")
          // this.preferences = bar
          }else{
          data = {}
          this.desserts = [];  
          }
          if(data)
          this.desserts = []
          this.desserts = data
        }).catch(error_response => {
          this.loader = false
          if(error_response){
              this.loader = null 
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Something went wrong.Try Again';
            }
        });
      },
       getUserByid() {
        var _this = this
        getToServer(this, config.USER_PROVISION_URL + GET_USER + this.$session.get('user_id')).then(response => {
        if(response.date_format){
          return response.date_format
        }else{
          return
        }
      }).catch(error_response => {
          this.loader = false
          if(error_response){
              this.loader = null 
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = error_response;
            }
            else {
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = 'Something went wrong.Try Again';
            }
        })
    },
      manageClient(){
        let _this = this;
          _this.$router.push('/manageaccount');
       
      }
    }
}
</script>
