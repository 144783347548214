import edcDocumentViewer from "./../views/edc_document_viewer.vue"
import { post, post as postToServer } from '../methods/serverCall.js';
import config from '../config.json'


export const mediaObjectMixin={
    components:{
        'edc-document-viewer':edcDocumentViewer,
    },
    data(){
        return{
            edcDocumentObject:{},
            edcDocumentList:[],
            edcMediaRowDetails:[],
            showDocumentViewer:false,
        }
    },
    methods: {
        getAttachementAssociateValues(gridObj,media_obj_cols){
            let revised_rows = []
            let _this = this
            for(let i=0;i<gridObj.rows.length;i++){
            let current_row = gridObj.rows[i]
            let temp_json = {}
            for(let j=0;j<media_obj_cols.length;j++){
                let currentColName = media_obj_cols[j].column_name
                let columnInCase = _this.getColumnValueInProperCase(currentColName,media_obj_cols[j].column_db_type)
                temp_json[columnInCase] = current_row[columnInCase]
            }
            revised_rows.push(temp_json)
            }
            return revised_rows
        },
        gridContainAttachementColumns(gridObj,attachement_columns){
            let grid_columns = _.map(gridObj.headers,"column_name")
            let only_media_headers = []
            for (var i = 0; i < attachement_columns.length; i++) {
            if(grid_columns.indexOf(attachement_columns[i].toUpperCase()) > -1)
                only_media_headers.push(_.find(gridObj.headers,['column_name',attachement_columns[i].toUpperCase()]))
            }
            return only_media_headers
        },
        loadMediaObjectDetails(){
            let _this = this
            let attachment_data_list = []
            var gridObj = _this.tableGridObj
            if (gridObj.rows.length ===0 || _.isEmpty(_this.mediaObjDetails))
                return
            for(let i=0;i<_this.mediaObjDetails.length;i++){
                let only_media_headers = _this.gridContainAttachementColumns(gridObj,_this.mediaObjDetails[i].columns_in_associate_join)
                if(!only_media_headers || !only_media_headers.length || only_media_headers.length < _this.mediaObjDetails[i].columns_in_associate_join.length)
                    continue
                let attachment_data = {}
                attachment_data["media_object_details"] = _this.mediaObjDetails[i]
                attachment_data["associate_values"] = _this.getAttachementAssociateValues(gridObj,only_media_headers)
                attachment_data["only_media_headers"] = only_media_headers
                attachment_data_list.push(attachment_data)
            }
            if(!attachment_data_list.length)
                return
    
            _this.keepSecondColFixed = gridObj['keepFirstColFixed']
            let attachment_data = _this.getCommonRequestData()
            attachment_data['merge_tables_detail'] = gridObj.merge_tables_detail
            attachment_data['attachment_details'] = attachment_data_list
            postToServer(_this, config.DATA_VIEWER_URL + '/check_for_attachment',attachment_data).then(response =>{
            // code returned by assuming user not going to sort the table unless attachment get loaded. even if he sort new attachement request will get bind. THIS IS VERY RARE CASE
                response = response.data
                let rows = _.cloneDeep(gridObj.rows)
                for(let j=0;j<response.length;j++){
                    let each_response = response[j]
                    for(let i=0;i<rows.length;i++){
                        if(!rows[i]['has_attachment'])
                            rows[i]['has_attachment'] = false
                        if(each_response[i] && each_response[i].has_attachment){
                            rows[i]['has_attachment'] = true
                            let attachment_details = {"attachment_details":each_response[i].attachment_details,'media_obj_details':each_response[i].media_obj_details}

                            if(!rows[i]['attachment_details'])
                                rows[i]['attachment_details'] = [attachment_details]
                            else
                                rows[i]['attachment_details'].push(attachment_details)
                        }
                    }
                }
                gridObj.rows = rows
            }).catch(error=>{

            })
        },
        loadMediaAttachmentDetails(attachment_media_details,associate_row){
            let _this = this
            let revised_attachment_list = []
            let columnsInMediaJoin = []
            let mediaRowValue = {}
		    let gridObj = _this.tableGridObj
            let datasource_index = _this.datasourceIndex
            if(this.openModeType == 'merged')
                datasource_index =parseInt(associate_row[_this.getColumnValueInProperCase('datasource')])

            for(let i=0;i<attachment_media_details.length;i++){
                let list_of_attachment_details = attachment_media_details[i]['attachment_details']
                if(_.isEmpty(mediaRowValue) && list_of_attachment_details.length)
                    mediaRowValue = _.cloneDeep(list_of_attachment_details[0])
                let mediaObjDetails = attachment_media_details[i]['media_obj_details']
                if(!columnsInMediaJoin.length)
                    columnsInMediaJoin = mediaObjDetails.columns_in_associate_join

                _.forEach(list_of_attachment_details,function(obj){
                    if([1,2,"1","2"].indexOf(obj[_this.getColumnValueInProperCase('gdgtmotype')])>-1 )
                        obj['name'] = obj[_this.getColumnValueInProperCase('gdgtfilenm')] + ' ('+mediaObjDetails.media_object_name+')'
                    else
                        obj['name'] = obj[_this.getColumnValueInProperCase('gdgtitnm')] + ' ('+mediaObjDetails.media_object_name+')'

                    obj['media_obj_details'] = mediaObjDetails
                    obj['datasourceIndex'] = datasource_index
                    revised_attachment_list.push(obj)
                })
            }
            let dataToPass = []
            if(_.isEmpty(mediaRowValue) || !columnsInMediaJoin.length)
                return
            let headers = gridObj.headers
            for(let i=0;i<columnsInMediaJoin.length;i++){
                let columnObj = _.find(headers,['column_name',columnsInMediaJoin[i]])
                if(columnObj && columnObj.description)
                    columnObj = columnObj.description.trim()
                else
                    columnObj = columnsInMediaJoin[i]

                dataToPass.push({'key':columnObj,'value':mediaRowValue[_this.getColumnValueInProperCase(columnsInMediaJoin[i],gridObj.datasourceIndex)],
                            'datasourceIndex':gridObj.datasourceIndex})
            }
            this.edcMediaRowDetails = dataToPass
            this.showDocumentViewer = true
            this.edcDocumentList = revised_attachment_list
        },
        loadAttachment(list_of_attachment_details,media_object_details,is_download){
            let _this = this
            let datasourceIndex=list_of_attachment_details[0].datasourceIndex
            var attachment_data = _this.getCommonRequestData()
            attachment_data["list_of_attachment_details"] = list_of_attachment_details
            attachment_data['media_object_details']=media_object_details
            attachment_data['is_download'] = is_download
            postToServer(_this, config.DATA_VIEWER_URL+'/load_attachment_data',attachment_data).then(response=>{
                _this.edcDocumentObject = {"success":true,"data":response.list_of_attachment_response[0],"is_download":is_download,'datasourceIndex':datasourceIndex}
            }).catch(error=>{
                _this.edcDocumentObject = {"success":false,"error":error.toString()}
            })
        },
    },
}