/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Package': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M31.857 5.16l-24 12V46l24 12 .511-.254L55.858 46V17.16L39.41 8.938l-.05-.1-.078.038zm0 2.554l4.824 2.413-4.386 2.11-4.741-2.37zM25 11.143l7.199 3.598.029.063.049-.023 8.051 4.025.04.082.06-.03 4.876 2.437L41 23.446l-15.326-7.663h-.002l-.04-.082-.06.031-4.876-2.437zm14.266.274l5.466 2.735-4.303 2.152-5.55-2.775zm-21.123 3.154l4.875 2.438-2.868 1.433-3.661 1.83-.063.032-4.872-2.438zm29.042.806l4.976 2.49-4.304 2.15-4.875-2.437 4.243-2.12zm-21.614 2.909l5.447 2.723-6.59 3.295-5.446-2.724zm-15.428 1.428l5.714 2.857v6.286L25 33.43v-6.286L30.714 30v24.875L10.143 44.589zm43.428 0V44.59L33 54.875V30l6.857-3.429v5.715L49 27.714V22zm-20.1 2.52l4.975 2.49-6.589 3.294-4.875-2.438 6.53-3.263zm13.243.909v3.16l-4.571 2.286v-3.16zm-28.571.571L22.714 26v3.732l-4.571-2.286z"/><path pid="1" d="M16 40l8 4" _fill="none" _stroke="#000" stroke-width="1.89"/>'
  }
})
