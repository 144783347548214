<template>
    <v-flex xs12 id="criteria_output_panel" style="overflow:auto;">
                <!-- {{columns_for_filter}} -->
        <table border="0" class="table table-striped table-bordered table-fixed" 
            id="filter_column_table" style="table-layout:fixed;margin-bottom:2px !important;">
                <draggable v-model="columns_for_filter" :element="'tbody'">
                <tr v-for="(filter_c, idx) in columns_for_filter" :key="idx">
                    <td class="handle" style="width: 24px !important;font-size:15px !important;">::</td>

                    <td :class="{highlight_tbl:filter_c.error_list && filter_c.error_list.column_name}" :title="filter_c.error_list && filter_c.error_list.column_name">
                        <v-autocomplete dense hide-details :items="column_name_list" v-model="filter_c.column_name" item-text="display_name" item-value="column_name"  style="padding:0px" :title="filter_c.column_name"
                            placeholder="Column name" @input="resetIsDrvTable(filter_c.column_name, idx);resetOperatorSelection(filter_c);addTableNameToFilter(filter_c,column_name_list)" hide-details :disabled="readonly"></v-autocomplete>
                        <input type="hidden" v-model="filter_c.is_drv_table">
                    </td>
                     <td :class="{highlight_tbl:filter_c.error_list && filter_c.error_list.operator}" :title="filter_c.error_list && filter_c.error_list.operator">
                       <v-autocomplete dense hide-details :items="isColumnBelongToDate(filter_c.column_name)?date_filter_operators:filter_operators" item-text="name" item-value="id" v-model="filter_c.operator"
                        @input="findBetween(filter_c)" placeholder="Operator" style="padding:0px" :title="getOperatorNameById(filter_c.operator)" hide-details :disabled="readonly" ></v-autocomplete>

                        <!-- <v-autocomplete v-else dense hide-details :items="filter_operators" item-text="name" item-value="id" v-model="filter_c.operator"
                            @input="findBetween(filter_c)" placeholder="Operator" style="padding:0px" :title="getOperatorNameById(filter_c.operator)" hide-details :disabled="readonly" ></v-autocomplete> -->
                    </td>
                    <td>
                        <v-layout row>
                            <v-flex xs10 pl-3>
                                <v-text-field v-model="filter_c.value1" :disabled="filter_c.operator == '_in_' || filter_c.operator == '_is_n_'  
                                    || filter_c.operator == '_is_nt_n_' || filter_c.operator == '_n_in_' || filter_c.v_type1=='Date' || isAdvanceDateOperator(filter_c.operator)" 
                                    placeholder="Value" @blur="savedata()" autocomplete="off" solo hide-details :readonly="readonly"></v-text-field>
                            </v-flex>
                            <v-flex xs2 style="padding-top:1px !important">
                                <span  v-if="!isAdvanceDateOperator(filter_c.operator)" @click="changeValueType(idx, 'value1', 'v_type1',filter_c.operator)" class="valueType"><i class="fa fa-edit"></i></span>
                            </v-flex>
                        </v-layout>
                    </td>
                    <td v-if="isBetween">
                        <v-layout row v-if="filter_c.operator == '_bet_'">
                            <v-flex xs10 pl-3>
                                <v-text-field v-model="filter_c.value2" placeholder="Value"
                                    @blur="savedata()" :disabled="filter_c.v_type2 !== 'Value'" solo hide-details autocomplete="off" :readonly="readonly"></v-text-field>
                            </v-flex>
                            <v-flex xs3 style="padding-top:1px !important">
                                <span @click="changeValueType(idx, 'value2', 'v_type2',filter_c.operator)" class="valueType"><i class="fa fa-edit"></i></span>
                            </v-flex>
                        </v-layout>
                    </td>

                    <td style="width:72px !important;">
                        <toggle-button :key="idx+'toggle'" v-if="columns_for_filter.length-1 != idx" :labels="{checked: 'OR', unchecked: 'AND'}"
                            style="margin-top:0px !important;margin-bottom:0px !important;" v-model="filter_c.operation" :sync="true" :width="60" :color="{checked: '#75C791', unchecked: '#75C791', disabled: '#CCCCCC'}"
                            @change="savedata()" :disabled="readonly" />
                    </td>
                    <td style="width:120px !important; text-align: right !important">
                        <svgicon class="svg-icon-mini svg-fill-mini" @click="add_new_filter_row()" v-if="(idx+1) == columns_for_filter.length"  name="add_v2" title="Add New Filter"></svgicon>
                        &nbsp;
                        <svgicon class="svg-icon-mini svg-fill-mini" v-if="(idx+1) == columns_for_filter.length && level<3" @click="addgroup" name="add_multiple_v2" title="Add New Group"></svgicon>
                        &nbsp;
                        <svgicon class="svg-icon-mini svg-fill-mini" @click="delete_filter_row(idx)" name="delete_v2" title="Delete Filter"></svgicon>

                    </td> 
                </tr>
                </draggable>
        </table>
        <v-dialog persistent eager v-model="valuetype" width="550" style="overflow-y:none;background:white">
            <value-type @close="closeValuePanel" :valuetype="valuetype" @saveValue="saveValue" 
               :columns_for_filter="columns_for_filter" :criteriaRowIndex="criteriaRowIndex" :policy_list="policy_list" 
               :showpolicies="showpolicies" :column_name_list="column_name_list" :currentKey="currentKey" 
               :currentValueTypeKey="currentValueTypeKey" :criteriaoperator='currentOperator' :parentsteptype="parentsteptype" :acceleratorObj="acceleratorObj" :readonly="readonly"></value-type>
        </v-dialog>
    </v-flex>
</template>
<script>
import ValueType from './ValueType'
import {JOIN_OPERATOR_ARRAY_JSON,JOIN_TYPE,AGGREGATE_FUNCTIONS, FLITER_JOIN_OPERATOR_ARRAY_JSON,GRAPH_TABLE_ERROR_MESSAGES,DATE_FILTER_OPERATORS,ADVANCED_DATE_FILTER_OPERATORS} from '@/methods/constants.js'
import draggable from 'vuedraggable'
export default {
      data () {
        return {
            v_type:"Value",
            criteriaRowIndex:0,
            currentKey:'',
            currentValueTypeKey:'',
            currentOperator:'',
            valuetype:false,
            start_parentheses_list: [{'value':'('}, {'value':'(('}, {'value':'((('}, {'value':'(((('}],
            end_parentheses_list: [')', '))', ')))', '))))'],
            filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
            date_filter_operators:DATE_FILTER_OPERATORS,
            advance_date_filter_operators:ADVANCED_DATE_FILTER_OPERATORS,
            isBetween:false,
        }
      },
      components:{
          'value-type':ValueType,
          draggable
      },
     props:{filtertablename:String,columns_for_filter: Array, column_name_list: Array, policy_list: Array, showpolicies: Boolean,parentsteptype:String,acceleratorObj:Object,readonly:Boolean,level:Number,grpindex:Number,parentid:Number},
          watch:{
              columns_for_filter:{
                  handler(newVal){
                    //   debugger;
                      this.findBetween();
                  },
                  deep:true
              },
              column_name_list:{
                handler(newVal){
                    //   debugger;
                    console.log('this.column_name_list',this.column_name_list)
                      this.column_name_list=newVal
                  },
                  deep:true
              },
              acceleratorObj(newvalue){

              },
              readonly(newvalue){

              },
              level(newvalue){
              },
              grpindex(newvalue){
              },
              parentid(nuwvalue){
              },
              policy_list(newvalue){
                console.log('criteriapolicy',newvalue)
              },
              showpolicies(newvalue){

              },
          },
    mounted() {
      var _this =this
      setTimeout(function(){
        _this.findBetween()
      },1000)
    },
     methods:{
         closeValuePanel(){
             this.valuetype = false;
         },
         saveValue(value, columns_for_filter, v_type){
            //  debugger;
             this.v_type = v_type;  
             this.columns_for_filter = columns_for_filter;
             this.columns_for_filter[this.criteriaRowIndex][this.currentKey] = value;
             this.columns_for_filter[this.criteriaRowIndex][this.currentValueTypeKey] = v_type;
             this.savedata();
         },
         changeValueType(index, key, v_type_Key,operator){
             this.valuetype = true;
             // this.currentValueTypeKey = "Value"
             this.criteriaRowIndex = index;
             this.currentKey = key;
             this.currentValueTypeKey = v_type_Key;
             this.currentOperator = operator
         },
         resetIsDrvTable(c_name, idx){
            this.savedata()
        },
        findBetween(obj){
            let _this = this;
            _this.isBetween = false;
            _this.columns_for_filter && _this.columns_for_filter.map(obj=>{
                if(obj.operator == '_bet_') 
                _this.isBetween = true;
            });
            if(obj && obj.operator == '_in_'){
               obj.value1 = "";
               obj.value2 = "";
            }
            this.savedata();
            return _this.isBetween
        },
        add_new_filter_row(){
            if(this.readonly)
              return
            var last_index = this.columns_for_filter.length
            if(last_index>0)

              if(!this.columns_for_filter[last_index-1].column_name || !this.columns_for_filter[last_index-1].operator)
                return

             this.$emit('new',this.level,this.grpindex,this.parentid);
        },
        delete_filter_row(idx){
            if(this.readonly)
              return
            this.$emit('delete', idx,this.level,this.grpindex,this.parentid);
        },
        savedata(){
            if(this.readonly)
              return

            // If user perform filter columns up down then its create an bug.
            // Bug is that operation key doesnot work with drag drop. its values not get change
            // delete operation key from last filter
            var last_index = this.columns_for_filter.length
            if(last_index>0){
              // if(!this.columns_for_filter[last_index-1].column_name || !this.columns_for_filter[last_index-1].operator)
              if(!this.columns_for_filter[last_index-1].column_name && !this.columns_for_filter[last_index-1].operator)
                return

              delete this.columns_for_filter[last_index-1].operation
            }
            // Asign operation key to all filter who has not operation key
            // -2 because we are not going to append operation for last one
            for (var i = last_index - 2; i >= 0; i--) {
              if(!this.columns_for_filter[i].operation)
                this.columns_for_filter[i]["operation"] = false
            }
            this.$emit('savedata',this.columns_for_filter,this.level,this.grpindex,this.parentid);
        },
        getFilterTableName(filtertablename)
        {
          if (!filtertablename)
            return false
          return filtertablename.substring(0,12) + '...'
        },
        getOperatorNameById(operator)
        {
          if (operator){
            var obj = _.find(this.filter_operators,['id',operator])
            if(!obj)
              obj = _.find(this.date_filter_operators,['id',operator])
            if(obj)
                return obj.name
          }
          return false
        },
        addgroup(){
          if(this.readonly)
              return
          var last_index = this.columns_for_filter.length
          if(last_index>0)
            if(!this.columns_for_filter[last_index-1].column_name || !this.columns_for_filter[last_index-1].operator)
              return

          this.$emit('addgroup',this.level,this.grpindex)
        },
        isColumnBelongToDate(column_name){
          if(!column_name)
            return false
          let col_obj = _.find(this.column_name_list,["column_name",column_name])
          return col_obj && col_obj.is_jde_date
        },
        isAdvanceDateOperator(operator){
          if(!operator)
            return false

          let advanceDateFilter = _.find(this.advance_date_filter_operators,["id",operator])
          if(advanceDateFilter)
            return true
          return false
        },
        resetOperatorSelection(filter){
          filter.operator = ''
          filter.value1 = ''
        },
        addTableNameToFilter(filter,column_list){
          let table_name = ''
          filter.table_name = ''
          let column_name = filter.column_name
          let obj = _.find(column_list,["column_name",column_name])
          if(obj && obj.table_name)
            filter.table_name = obj.table_name
        }
    }
}
</script>
