<template>
  <v-container class="EDC-Container" style="margin: 0px !important">
    <v-row class="EDC-Row EDC-RowsSpacing">
      <v-col class="EDC-Col">
        <v-card class="list-card EDC-MainFilterCard" style="padding-top: 16px !important;border: 0.5px !important">
          <table border="0" style="border: hidden !important">
            <tr v-for="(cols, idx) in havingColumnList" :key="idx">
              <td class="pa-2">
                  <v-autocomplete solo dense hide-details :items="availableColumnList" v-model="cols.column_name" item-text="display_name" item-value="column_name"  style="padding:0px" :title="cols.column_name" placeholder="Column name" hide-details @input="autoPopupFunc(idx,cols);saveHaving(idx)"></v-autocomplete>
              </td>
              <td class="pa-2">
                  <v-autocomplete solo dense hide-details :items="functionList" v-model="cols.func" item-text="name" item-value="id"  style="padding:0px"  title="function" placeholder="Function name" @input="saveHaving(idx)" hide-details></v-autocomplete>
              </td>
              <td class="pa-2">
                  <v-autocomplete solo dense hide-details :items="havingOperators" item-text="name" item-value="id" v-model="cols.operator" placeholder="Operator" style="padding:0px" autocomplete="off" hide-details @input="saveHaving(idx)"></v-autocomplete>
              </td>
              <td class="pa-2">
                  <v-text-field v-model="cols.value" placeholder="Value" solo hide-details dense type="number" autocomplete="off" @blur="saveHaving(idx)"></v-text-field>
              </td>
              <td class="pa-2">
                <toggle-button :key="idx+'toggle'" v-if="havingColumnList.length-1 != idx" :labels="{checked: 'OR', unchecked: 'AND'}"
                style="margin-top:0px !important;margin-bottom:0px !important;" v-model="cols.operand" :sync="true" :width="60" :color="{checked: '#75C791', unchecked: '#75C791', disabled: '#CCCCCC'}" @change="saveHaving(idx)"/>
              </td>
              <td class="pa-2">
                <svgicon class="svg-icon-mini svg-fill-mini"  v-if="(idx+1) == havingColumnList.length"  name="add_v2" title="Add New Having" @click="addNewHavingJson(idx)"></svgicon>

                <svgicon class="svg-icon-mini svg-fill-mini"  name="delete_v2" title="Delete Having" @click="deleteHavingJson(idx)"></svgicon>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import {HAVING_OPERATOR} from '@/methods/constants.js'
  export default {
    name:'edchaving',
    props:{
      table_name:{
        type:String,
        default:'',
      },
      havingColumnList:{
        type:Array,
        default:()=>{
          return []
        }
      },
      availableColumnList:{
        type:Array,
        default:()=>{
          return []
        }
      },
      functionList:{
        type:Array,
        default:()=>{
          return []
        }
      },
    },
    watch:{
      availableColumnList:{
        handler(newValue){

        }
      },
      havingColumnList:{
        handler(newValue){
          if(!newValue || !newValue.length)
            this.havingColumnList.push(this.getNewHavingJson())
        }
      },
      functionList:{
        handler(newValue){

        }
      },
      table_name:{
        handler(newValue){

        }
      }
    },
    data(){
      return {
        havingOperators:[],
      }
    },
    mounted(){
      this.havingOperators = HAVING_OPERATOR
      if(!this.havingColumnList.length)
        this.havingColumnList.push(this.getNewHavingJson())
    },
    methods:{
      getNewHavingJson(){
        return {
          "func":'',
          "column_name":'',
          "operator":'',
          "operand":false,
          "value":0,
          "table_name":'',
        }
      },
      addNewHavingJson(index){
        if(this.isValidRow(this.havingColumnList[index])){
            this.havingColumnList.push(this.getNewHavingJson())
        }
      },
      autoPopupFunc(index,cols){
        let _this = this
        if(cols.column_name){
          let columnObj =  _.find(_this.availableColumnList,["column_name",cols.column_name])
          if(columnObj && columnObj.func)
            _this.havingColumnList[index].func = columnObj.func
        }
      },
      isValidRow(row){
        if(row.func && row.column_name && row.operator && !isNaN(parseInt(row.value))){
          row.table_name = this.table_name
          return true
        }
        return false
      },
      saveHaving(index){
        if(!this.isValidRow(this.havingColumnList[index]))
          return
        this.$emit('saveHaving',this.havingColumnList)
      },
      deleteHavingJson(index){
        this.havingColumnList.splice(index, 1)
        this.$emit('saveHaving',this.havingColumnList)
        
        if(!this.havingColumnList.length)
          this.havingColumnList.push(this.getNewHavingJson())
      }
    }
  }
</script>