/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Schedule': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M36 20c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4zm12 0c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4zM36 32c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4zm12 0c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4zm-18.973.29a20 20 0 01-6.691 6.823c.358.535.969.887 1.664.887h4c1.108 0 2-.892 2-2v-4c0-.73-.391-1.362-.973-1.71zM12 44c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4zm12 0c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4zm12 0c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4zm12 0c-1.108 0-2 .892-2 2v4c0 1.108.892 2 2 2h4c1.108 0 2-.892 2-2v-4c0-1.108-.892-2-2-2h-4z"/><path pid="1" d="M5 40.828V57h53.934V15.066h-28.5" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/><circle pid="2" cx="16" cy="24" r="11.478" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M16 16v8l6 6" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/>'
  }
})
