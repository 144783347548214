/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Data_Management': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M8.286 45.748a24.891 11.063 0 00-.133.177v6.106a24.891 11.063 0 0023.908 8.001 24.891 11.063 0 0024.03-8.194v-5.7a24.891 11.063 0 00-.27-.377 24.891 11.063 0 01-23.76 7.818 24.891 11.063 0 01-23.775-7.83z"/><path pid="1" d="M55.984 34.48a24.891 11.063 0 01-23.923 8.036 24.891 11.063 0 01-23.908-8.003v6.012a24.891 11.063 0 0023.908 8.002 24.891 11.063 0 0024.03-8.195v-5.7a24.891 11.063 0 00-.107-.152z"/><path pid="2" d="M55.852 23.236A24.891 11.063 0 0132 31.178a24.891 11.063 0 01-23.807-7.874 24.891 11.063 0 00-.04.052v6.106a24.891 11.063 0 0023.908 8.002 24.891 11.063 0 0024.03-8.195v-5.7a24.891 11.063 0 00-.239-.333z"/><path pid="3" d="M32 4a24.891 11.063 0 00-23.908 8.018v6.106A24.891 11.063 0 0032 26.126a24.891 11.063 0 0024.03-8.195v-5.7A24.891 11.063 0 0032 4z"/>'
  }
})
