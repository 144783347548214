<style src="jointjs/dist/joint.min.css"></style>
<style src="material-icons/iconfont/material-icons.css"></style>
<style>
  @import "./assets/fontawesome-free-5.1.0-web/css/all.css";
  @import "./assets/css/googlefontmaterial.css";
  @import "./css/app.css";
  @import "./css/svg.css";
  @import "./css/font.css";
  @import "./css/designer.css";
  @import "./css/scrollbar.css";
</style>

<template>
  <div id="app">
    <v-app>
      <v-main v-if="isloggedin">
          <customHeader />
        <v-container
          id="dashboard-routing"
          class="background"
          :class="{'navigationPadding':!removeHeader, 'headerPadding':removeHeader}">
        <v-row no-gutters style="padding:0px !important;margin:0px;">
          <v-col cols="12">
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
          <router-view />
          </v-col>  
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
          <customFooter />
        </v-col>
        </v-row>
        </v-container>
      </v-main>
      <v-main v-else>
        <div
          class=""
          :style="{'background':'RGB(51,51,51)', 'background-size':'100% 100%', 'min-height':imageHeight }"
        >
          <v-container class="background">
            <router-view />      
          </v-container>
        </div> 
      </v-main>
    </v-app>
  </div>
</template>

<script>
import customHeader from './components/customHeader.vue'
import customFooter from './components/customFooter.vue'
// import Register from './components/authorization/register'
// import Schedule from './components/schedular/schedule'
import { sessioncheck } from './methods/user_session.js'
import {mapActions} from 'vuex'
import {live_details} from './methods/socket_handler.js'

export default {
  name: 'App',
  components: {
    'customHeader': customHeader,
    'customFooter': customFooter
    // 'register':Register,
    // 'schedule':Schedule
  },
  data() {
      return {
        imageHeight:'530px',
        backgroundImage: require('./assets/images/LasVegas.jpg'),
        isloggedin:false
      }
    },
    computed:{
      removeHeader() {
        return this.$route.path === '/businessobjectdesigner' || this.$route.path === '/Flowchart' || this.$route.path === '/workflowide'
      }
    },
  watch:{
    "$breadcrumbs":{
      handler(newVal){
        if(newVal)
        this.validate_routing();
      },
      deep:true
    },
  },
  mounted() {
    // this.isloggedin=true; 
    this.validate_routing();
    live_details(this);
  },
  methods: {
    ...mapActions(['setPendingDownloadStatusUpdate']),
    ...mapActions(['setGeneralDownloadNotification','setSecondaryCountUpdate']),

    validate_routing(){
      // check token exists in url query or the cookies.

      // 1st check in the queryString


      var breadcrumbsstring = this.$breadcrumbs[0].meta['breadcrumb'];
      var crump = breadcrumbsstring.split('/'),
      crumpLength = crump.length,
      key = crump[crumpLength-1];
      key =  key.replace(/ /g, '');
      let array = ['Register','Login', 'ForgotPassword', 'ResetPassword', 'ApprovalTask', 'ResendEmail'];
      let param = array.includes(key)
      if(!param){
        sessioncheck(this)
        this.isloggedin = true
      }
      else {
        if(!this.$session.exists()){
          this.isloggedin = false
          // provide dynamic height to background
          this.imageHeight = window.innerHeight + 'px';
        }
        else
          window.location.href = "/edcdataviewer"
        }
      },
  },
}
</script>
<style scoped>
.navigationPadding{
  padding-top:0px !important;
}
.headerPadding{
  padding-top:0px !important;
}

</style>

<style>
.vue-js-switch .v-switch-core {
   border-radius: 5px!important;
}
</style>