<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <v-row class="EDC-Row breadcrumbRow" align="center">
                    <v-col cols="4" class="EDC-Col">
                        <ul class="breadcrumb breadcrumbUL">
                            <li>File Export List</li>
                        </ul>
                    </v-col>
                    <v-col cols="8"></v-col>
                </v-row>
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onSort="SortBy" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @UpdateFilter="filterData" @onDelete="onDelete"  @ondblClick="onFileDownload" @onReportDownload="onFileDownload"></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>
<script>
import moment from 'moment'
import config from '../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from './../../methods/serverCall.js';
import {SERVER_ERROR} from '../../data/client_message.js'
import { GET_ALL_FILE_EXPORT_DATA} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {COLOR_CODE} from "../../data/macros.js"
import _ from 'lodash';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default {
    name: 'fileexportlist',
    data: function () {
        return {
            userRole:{},
            buttonColor:COLOR_CODE,
            loader:false,
            testConnectionLoader:false,
            dummyData:'test-1',
            tableList: {
                headers: [
                    // { text: 'Environment Type', value: 'type', sortable: false, width: '20%' },
                    { text: 'File Name', value: 'file_name', sortable: true, width: '15%', options:[], title: 'File Name' },
                    { text: 'File Type', value: 'file_type', width: '15%', title: 'File Type' },
                    { text: 'Total Rows Exported', value: 'total_rows_exported', sortable: true, width: '15%', options:[], cssClass:'w2', title: 'Total Rows Exported' ,align:'right' },
                    { text: 'Status', value: 'status', sortable: true, width: '15%', options:[], cssClass:'w2', title: 'Status' },
                    { text: 'Remark', value: 'remark', sortable: true, width: '15%', options:[], cssClass:'w2', title: 'Remark' },
                    { text: 'Associate Object Name', value: 'associate_object_name', sortable: true, width: '15%', title: 'Associate Object Name' },
                    { text: 'User Name', value: 'user_name', sortable: true, width: '20%', options:[], cssClass:'w16' },
                    { text: 'Export Started Date', value: 'timezone_aware_export_started_date', sortable: true, width: '15%', options:[], cssClass:'w2', title: 'Export Started Date' },
                    { text: 'Started Time', value: 'timezone_aware_export_started_time', sortable: true, width: '15%', options:[], cssClass:'w2', title: 'Export Started Time', hideInlineFilter: true},
                    { text: 'Export Ended Date', value: 'timezone_aware_export_ended_date', sortable: true, width: '15%', options:[], cssClass:'w2', title: 'Export Ended Date' },
                    { text: 'Ended Time', value: 'timezone_aware_export_ended_time', sortable: true, width: '15%', options:[], cssClass:'w2', title: 'Export Ended Time', hideInlineFilter: true }
                ],
                // actions: ['edit','execute','publish'],
                actions:[],//if we need conditional action in row then provide key
                sorting_type: SERVER_SIDE,
                filterType: SERVER_SIDE,
                paginationType: SERVER_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                itemkey:'id',
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true,
                disableDefaltSorting:true
            },
            //  flag:true,
             snackbar:false,
             snackbartext:'',
             colorValue:'success',
             snackbartimeout: Snackbar_Default_Timeout,
             showEnvType:false,
             valid:true,
             dbTypefltrAry:[],
             nameTypefltrAry:[],
            'envTypefltrAry':[],
            'chip1':true,
            'searchEnv':"",
            'showname':false,
            'showDBType':false,
             chips: [],
             items: [],
            envIpJson : {
                "filter": [],
                "sort": [{
                "column_name": "",
                "type": ""
                }],
                "page": 1,
                "page_size": 10
            },
            isloading:false,
            menu:false,
            edit:"",
            // perPage: 5,
            // perPageArray: [5, 10, 50, 'all'],
            // page: 1,
            // totalPages:1
        }
    },
    watch:{
        '$store.state.pendingDownloadStatusUpdate':{
            handler(newVal){
                this.manageDownloadStatus(_.cloneDeep(newVal.body))
            }
        }
    },
    mounted() {
        this.tableList.actions=[
            {'text':'onReportDownload','key':"status",cmpValue:'success', selectType:"single", role:true,index:1,directDownload:true},
            {'text':'delete','key':"id", selectType:"multiple", role:true,index:2}];
     this.getFileExports(this.envIpJson);
     this.clearNotification()
    },
    
    methods: {
        clearNotification(){
            let data = {
                'username':this.$session.get('email'),
                'client_id':this.$session.get('client_id'),
                'notification_for':'file_download'
            }
            postToServer(this,config.DATA_VIEWER_URL+'/mark_as_unread',data).then(response=>{

            }).catch(error=>{

            })
        },
        getFileExports(envIpJson) {
            let _this = this;
            _this.loader = true;
            var client_id = this.$session.get('client_id')
            var input_data = {
                    "client_id": client_id,
                    "filter": envIpJson.filter,
                    "page": envIpJson.page,
                    "page_size": envIpJson.page_size,
                    "sort": envIpJson.sort,}
                postToServer(this, config.DATA_VIEWER_URL + GET_ALL_FILE_EXPORT_DATA , input_data
                ).then(response => {
                _this.loader = false;
                if(response){
                    _this.tableList.rows = response.result;
                    _this.tableList.total_count =response.total; 
                }else{
                    _this.tableList.rows = [];
                    _this.tableList.total_count = 0; 
                }
                }).catch(error_response => {
                _this.loader = false;
                if(error_response){
                    this.loader = null 
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = error_response;
                    }
                    else {
                    this.snackbar = true
                    this.colorValue = 'error'
                    this.snackbartext = SERVER_ERROR;
                    }
                });
            },
            manageDownloadStatus(downloadRow){
                // first check date field
                // if(downloadRow.export_started_on){
                //     let timezoneAwareDateTime = this.getTimezoneAwareDateTime(downloadRow.export_started_on)
                //     downloadRow.timezone_aware_export_started_date = timezoneAwareDateTime[0]
                //     downloadRow.timezone_aware_export_started_time = timezoneAwareDateTime[1]
                // }
                // if(downloadRow.export_ended_on){
                //     let timezoneAwareDateTime = this.getTimezoneAwareDateTime(downloadRow.export_ended_on)
                //     downloadRow.timezone_aware_export_ended_date = timezoneAwareDateTime[0]
                //     downloadRow.timezone_aware_export_ended_time = timezoneAwareDateTime[1]
                // }

                if(downloadRow.export_ended_on)
                    return this.getFileExports(this.envIpJson)
                // check object exists in the grid or not.If yes then update that row else add new row
                let objExists = _.find(this.tableList.rows,['id',downloadRow.id])
                if(objExists){
                    let keys = Object.keys(objExists)
                    for(let i=0;i<keys.length;i++){
                        if(downloadRow[keys[i]])
                            objExists[keys[i]] = downloadRow[keys[i]]
                    }
                }
                else
                    // this.$set(this.tableList.rows,0,downloadRow)
                    return this.getFileExports(this.envIpJson)

            },
            getTimezoneAwareDateTime(datetimeObj){
                var date_without_miliseconds = datetimeObj.split('.')[0]
                var moment_obj = moment.utc(date_without_miliseconds, "YYYY-MM-DD HH:mm:ss")
                    
                var timezoned_formated_date = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format(this.$session.get('UI_date_format'))
                var timezoned_end_time = moment(moment_obj).tz(this.$session.get('time_zone_preference')).format('HH:mm:ss')
                return [timezoned_formated_date,timezoned_end_time]
            },
            onFileDownload(record){
                var _this = this
                let fileDetails = {"id":record.id,'client_id':this.$session.get('client_id'),'user_name':this.$session.get('email')}
                let url = config.DATA_VIEWER_URL +'/get_file_path_by_id'
                _this.loader = true
                postToServer(_this,url,fileDetails).then(response=>{
                    _this.loader = false
                    var downloadUrl = config.DATA_VIEWER_URL + "/get_file_download?path=" + response.file_path;
                    window.open(downloadUrl, '_blank');
                }).catch(error=>{
                    _this.loader = false
                })
            },
            filterData(filterArray, pageno) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.filter=filterArray;
                inputJson.page=1
                this.getFileExports(inputJson);
            },
            SortBy: function (sortJson) {
               let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.sort = sortJson
                this.getFileExports(inputJson);
            }, 
            onPageChange(page, perPage) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getFileExports(inputJson);
            },

            onPerPageChange(perPage, page) {
                let _this = this;
                let inputJson = _this.envIpJson;
                inputJson.page = page;
                inputJson.page_size = perPage;
                this.getFileExports(inputJson);
            },
            onDelete(records){
                var _this = this
                var id_list = []
                var files = []
                 _.forEach(records,function(obj){
                    id_list.push(obj.id)
                    files.push({'filepath':obj.file_path,'filename':obj.file_name})
                })

                let delete_data = {"id_list":id_list,'files':files,'client_id':this.$session.get('client_id')}
                // let delete_data = {"id_list":id_list,'client_id':this.$session.get('client_id'),'user_name':this.$session.get('email')}
                let url = config.DATA_VIEWER_URL +'/delete_file_export_data'
                _this.loader = true
                postToServer(_this,url,delete_data).then(response=>{
                    _this.loader = false
                    this.getFileExports(_this.envIpJson)
                }).catch(error=>{
                    _this.loader = false
                })
            }
        }
    }
</script>
<style scoped>
.smaller{
    width:20px;
    height:20px;
    font: 10px
}
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>