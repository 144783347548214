/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report_download_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M1.984.53a.399.399 0 00-.396.396v3.175c0 .218.179.397.396.397h.133v-.265h-.133a.13.13 0 01-.132-.132V.926a.13.13 0 01.132-.132h2.382a.13.13 0 01.132.132v3.175a.13.13 0 01-.132.132h-.133v.265h.133c.217 0 .397-.18.397-.397V.926a.399.399 0 00-.397-.397H1.984zm.397.793v.266h1.588v-.266H2.38zm0 .795v.263h1.588v-.263H2.38zm-1.852.527V5.82a.265.265 0 00.264.263h4.764a.265.265 0 00.263-.263V2.645h-.529v2.912H1.06V2.645h-.53zm2.38.265v.771h-.524a.15.15 0 00-.013 0c-.088.007-.138.087-.093.15l.402.572.401.574c.047.067.165.067.212 0l.402-.574.401-.572c.047-.066-.01-.15-.104-.15H3.44v-.77h-.53z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
