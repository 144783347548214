/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Menu_Develop': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M32 4a10.919 10.919 0 00-10.919 10.919A10.919 10.919 0 0032 25.838a10.919 10.919 0 0010.919-10.919A10.919 10.919 0 0032 4zm0 7.28a3.64 3.64 0 013.64 3.639 3.64 3.64 0 01-3.64 3.64 3.64 3.64 0 01-3.64-3.64 3.64 3.64 0 013.64-3.64z"/><path pid="1" d="M22.543 20.379l-6.594 11.42c-.85-.89-1.64-1.847-2.345-2.881a3.64 3.64 0 10-6.01 4.105 29.911 29.911 0 004.603 5.276L5.244 50.342l.393 8.63c.294 1.1.241 1.191 1.484.858l7.58-4.03 6.783-11.75A29.967 29.967 0 0056.81 33.397a3.64 3.64 0 10-5.945-4.197 22.674 22.674 0 01-18.705 9.606 22.668 22.668 0 01-6.976-1.16L32 25.838l-9.457-5.46zM53.196 40.6c-.173.156-.325.337-.5.49a31.769 31.769 0 01-6.288 4.245c-.798.41-1.632.742-2.462 1.083l4.912 8.507 7.58 4.029c1.243.333 1.19.24 1.484-.858l.393-8.63z"/><path pid="2" d="M41.016 19.502l-9.457 5.46 6.81 11.795c1.034-.34 2.087-.635 3.052-1.13a20.85 20.85 0 004.126-2.787c.721-.624 1.299-1.412 1.934-2.138z"/>'
  }
})
