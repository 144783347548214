<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <li>
                  <select v-model="selected_env" v-on:change="GetAllRoleList(),setEnv()" style="-webkit-appearance: menulist" autofocus ref="envname">
                    <option :value="{}">Select Environment</option>
                    <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.env_name}}</option>
                  </select>
              </li>
              <li>User Role Mapping</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <edc-data-grid :dataList="tableList" @onNew="onNew" @onDelete="onDelete" @onSave="onSave" @onCancel="onCancel" @onValueUpdate="onValueUpdate"></edc-data-grid>
    
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>

<script>
import { post as postToServer } from '../../methods/serverCall.js';
import { get as getToServer } from '../../methods/serverCall.js';
import config from '../../config.json'
import { SERVER_ERROR } from '../../data/client_message.js'
import getUserRole from '../../methods/GetUserRole.js'
import { GET_ALL_USERS_LIST, GET_USER_ROLE_MAPPING, GET_USER_ENV_ROLE_MAPPING, GET_ALL_ROLES, GET_ENVIRONMENTAL_ROLES, MANAGE_USER_ROLE_MAPPING, DELETE_USER_ROLE_MAPPING, GET_ROLES_BY_TYPE, MANAGE_ENV_USER_ROLE_MAPPING, ADD_ENV_USER_ROLE_MAPPING } from '../../data/url_constants.js'
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION} from "../../data/macros.js"
import _ from 'lodash'
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getEnvListForDataviewer} from '../../methods/EnvironmentList.js'

export default {
  data() {
    return {
      snackbar:false,
      snackbartext:'',
      colorValue:'error',
      loader:false,
      snackbartimeout: Snackbar_Default_Timeout,
      loading: false,
      userList:[],
      selected_env:{},
      EnvironmentList:[],
      administratorRoleList:[],
      environmentalRoleList:[],
      envIpJson: {
        "filter": [],
        "sort": [{
            "column_name": "",
            "type": ""
        }],
        "page": 1,
        "page_size": 5
      },
      tableList:{
        headers: [
          {text: 'User', value: 'username', width:"50%", option:[], 'item_text':'username', 'item_value':'username', useAutoComplete:true},
          // { text: 'Role Type', value: 'role_type', width: '25%', title: 'Security Role Type', option:[], 'item_text':'role_type', 'item_value':'role_type', useAutoComplete:true, isRoleMapping:true},
          {text: 'Role', value: 'role', width:"50%", option:[], 'item_text':'role', 'item_value':'role', useAutoComplete:true},
          // {text: 'Environment', value: 'env_name', width:"25%", option:[], 'item_text':'name', 'item_value':'name', useAutoComplete:true},
        ], 
        actions: [],//if we need conditional action in row then provide key
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        total_count: 0,
        is_row_editable:true,
        rows: [],
        selected_rows: [],
        syncHeaderScroll:false,
        disableDefaltSorting:true,
        itemkey:'unique_id',
        is_grid_inline_edit:true,
        hideFilter:true,
        hideExport:true,
        hideShowColumns:true
      },
    }
  },
  watch:{
        selected_env(newvalue) {
				  if(!newvalue.id){
            this.tableList.showNew = false

          }
          else{
            delete this.tableList.showNew
          }
			},
    },
  mounted() {
    this.userRole = this.$session.get('user_role_mapping');
    this.tableList.headers[1].option = [{"role_type":"object_based"}]
    getEnvListForDataviewer(this)
    this.tableList.actions = [
      {'text':'edit','key':"id", selectType:"single", role:true, index:1},
      {'text':'new','key':"id", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,CREATE_ACTION), index:2},    
      {'text':"save","key":"is_row_editable",selectType:"single", index:3},
      {'text':"cancel","key":"is_row_editable",selectType:"single", index:4},
      {'text':'delete','key':"id", selectType:"single", role:true, index:5}];
    if(this.tableList.paginationType == CLIENT_SIDE)
      this.envIpJson.page_size="*";
    this.GetUserList();
    if(this.$session.get('selected_env')){
            this.selected_env = this.$session.get('selected_env')
        }
    // this.GetEnvironmentalRoleList();
    if(this.$session.get('selected_env')){
      this.selected_env = this.$session.get('selected_env')
      }
      // Using showNew attribute to hide add new button when env not selected
    else    
      this.tableList.showNew = false
    
    this.GetAllObjectBasedRoleList();
    this.GetAllRoleList();
     
  },

  methods: {
    GetAllRoleList() {
     var client_id = this.$session.get('client_id')
      var data = {"client_id":client_id,
                  "env_id": this.selected_env.id,
                  "env_name": this.selected_env.name,
                  "product_name":window.sessionStorage.getItem('product')}
      var url = config.Security_URL + "/get_object_based_user_role_mapping"
      postToServer(this, url, data).then(Response => {
        if(Response){
              Response.forEach(function(obj,idx){
                obj['unique_id'] = idx+1
              })
              this.tableList.rows = Response;
        }
      }).catch(error_response => {
        this.tableList.rows = [];
      }); ;
    },
    setEnv(){
            this.$session.set('selected_env',this.selected_env)
        },
    GetUserList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id": client_id,
                  "product_name" :window.sessionStorage.getItem('product')}
      var get_user_url = config.Security_URL + GET_ALL_USERS_LIST
      postToServer(_this, get_user_url, data).then(response => {
      _this.userList = response;
      _this.tableList.headers[0].option = response;
      }).catch(error_response => {
      }); 
    },

    // onValueUpdate(record) {
    //   if(record.role_type === "administrator"){
    //     this.tableList.headers[2].option = this.administratorRoleList;
    //     this.tableList.headers[3].option =[]
    //   }
    //   else{
    //     this.tableList.headers[2].option = this.environmentalRoleList;
    //     this.tableList.headers[3].option = this.EnvironmentList
    //   }
    // },

    GetAdministratorRoleList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id":client_id, "role_type":'administrator',
                  "product_name" :window.sessionStorage.getItem('product')}
      var url = config.Security_URL + GET_ROLES_BY_TYPE
      postToServer(_this, url, data).then(response => {
        this.administratorRoleList = response;
        this.tableList.headers[2].option = response;
        administratorRoleList.map(function(obj){
        obj.isselected = false
      })
      }).catch(error_response => {
      }); 
    },
    GetAllObjectBasedRoleList() {
      var _this = this
      var client_id = this.$session.get('client_id')
      var data = {"client_id":client_id,
                  "env_id": this.selected_env.id,
                  "env_name": this.selected_env.name,
                  "product_name":window.sessionStorage.getItem('product')}
      var url = config.Security_URL + "/get_all_object_based_roles";
      postToServer(_this, url, data).then(response => {
        if(response){
              this.tableList.headers[1].option = response;
              this.administratorRoleList = response;
        }
      }).catch(error_response => {
      }); 
    },
    GetEnvironmentalRoleList() {
      var _this =this;
      var client_id = _this.$session.get('client_id');
      var data = {"client_id": client_id, "role_type": 'environmental',
                  "product_name" :window.sessionStorage.getItem('product')}
      var url = config.Security_URL + GET_ROLES_BY_TYPE
      postToServer(_this, url, data).then(response => {
      this.environmentalRoleList = response;
      this.tableList.headers[2].option = response;
        environmentalRoleList.map(function(obj){
            obj.isselected = false
        })
      }).catch(error_response => {
      }); 
    },

    // GetEnvironmentList() {
    //   environmentList(this).then(response=>{
    //     if(response)
    //       this.EnvironmentList = response
    //       this.tableList.headers[3].option = response
    //   },response=>{
    //       this.ShowErrorMessage=true
    //       this.ErrorMessage=response
    //   })
    // },

    onNew() {
      let obj = _.find(this.tableList.rows,["is_row_editable",true])
      if(obj)
      {
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'First fiilup the open row';
        return
      }
      this.tableList.rows.unshift({
        'username':'',
        'role_type':'',
        'role':'',
        'env_name':'',
        'is_row_editable': true,
      });
    },

    onCancel() {
      this.GetAllRoleList();
    },

    onSave(record) {
      if(!record.username || !record.role || (!record.env_name && record.role_type === 'environmental')){
        this.snackbar = true
        this.colorValue = 'error'
        this.snackbartext = 'All fields are required';
        return false
      }
      
      let role_list = this.administratorRoleList
      let env_id = ''
      if(record.role_type === 'environmental'){
        role_list = this.environmentalRoleList
        env_id = _.find(this.EnvironmentList,["name",record.env_name]).id
      }

      let userData = {
        'env_role_id':record.id,
        'client_id':this.$session.get('client_id'),
        'role_id':_.find(role_list,['role',record.role]).role_id,
        'role_name':record.role,
        'user_id':_.find(this.userList,['username',record.username]).user_id,
        'username':record.username,
        'env_id':this.selected_env.id,
        'env_name':this.selected_env.name,
        'role_type':"object_based",
        "product_name" :window.sessionStorage.getItem('product')
      }

      record.id ? this.updateUser(userData) : this.saveUser(userData);            
    },

    saveUser(userData) {
      var url = config.Security_URL + ADD_ENV_USER_ROLE_MAPPING
      postToServer(this, url, userData).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "Role added successfully";
        this.GetAllRoleList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    updateUser(userData) {  
      var url = config.Security_URL + MANAGE_ENV_USER_ROLE_MAPPING
      postToServer(this, url, userData).then(Response => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = "User updated successfully";
        this.GetAllRoleList();
      }).catch(error_response => {
        if (error_response) {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }else{
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = error_response;
        }
      });
    },

    onDelete(record) {
      let inputJson = {
        'client_id':this.$session.get('client_id'),
        'username':record.username,
        'user_id':record.user_id,
        'role_name':record.role,
        "env_id": this.selected_env.id,
        'role_id':record.role_id,
        'role_type':"object_based",
        'env_role_id':record.env_role_id,
        'user_role_id':record.user_role_id,
        "product_name" :window.sessionStorage.getItem('product')}
      let url = config.Security_URL + DELETE_USER_ROLE_MAPPING              
      postToServer(this, url, inputJson).then(response  => {
        this.snackbar = true
        this.colorValue = 'success'
        this.snackbartext = 'User Role deleted successfully';
        this.GetAllRoleList();
      }).catch(error_response => {
        this.GetAllRoleList();
        if(error_response){
          this.snackbar = true;
          this.colorValue = 'error';
          this.snackbartext = error_response;
        } else {
          this.snackbar = true
          this.colorValue = 'error'
          this.snackbartext = SERVER_ERROR;
        }
      });               
    },
  }
}
</script>
<style scoped>

</style>