<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="8" class="EDC-Col">
                <ul class="breadcrumb breadcrumbUL">
                    <li>
                        <select v-model="selected_env" v-on:change="getAllRole(),setEnv()" style="-webkit-appearance: menulist" autofocus ref="envname">
                            <option :value="{}">Select Environment</option>
                            <option v-for="(env, index) in EnvironmentList" :value="env" :key="index">{{env.env_name}}</option>
                        </select>
                    </li>
                    <li>Security Role</li>
                </ul>    
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>

        <edc-data-grid :dataList="tableList" @onDownload="onDownload" @onNew="addRole" @onEdit="onEdit" @onDelete="onDelete" @ondblClick="onEdit" @onCopy="onCopy"></edc-data-grid>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
    <v-dialog v-model="show_copy_dialog" width="400" scrollable>
        <v-form ref="copy_form">
        	<v-col cols="12" class="EDC-Col">
	          	<v-card elevation-1>
	            	<v-row no-gutters class="EDC-Row">
	             		<v-toolbar dark dense absolute style="width:100%">
                      <v-toolbar-title class="EDC-ToolbarTitle">Copy Role To Environment</v-toolbar-title>
                    <v-spacer></v-spacer>
	                		<!-- <span><b>Ticket ID: {{ task_name }}</b></span> -->
                      <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="show_copy_dialog=false" title="Close"></svgicon>
	              		</v-toolbar>
	            	</v-row>
	            	<v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-top:60px !important;padding-left:3px !important;"  justify="Left">
	              		<span>Select environment to copy to</span>
	            	</v-row> 
                    <v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-left:3px !important;"   justify="Left">
	              		<v-autocomplete  clearable dense v-model="copy_to_environment" item-value="id" item-text="name" :items="EnvironmentList" return-object  hide-details :rules="environmentRule"></v-autocomplete>
	            	</v-row> 
                <v-row no-gutters class="EDC-Row EDC-RowsSpacing"  justify="center">
	              		<vc-button itemText="Copy" @click.native="copyToEnvironment()"></vc-button>
	            	</v-row> 
	          	</v-card>
	        </v-col>
        </v-form>
    </v-dialog>
    <loading-panel :loader="loader"></loading-panel>
    </div>
</template>

<script>
import moment from 'moment'
import config from '../../config.json'
import { get as getToServer, deleteFromServer, post as postToServer } from '../../methods/serverCall.js';
import getUserRole from '../../methods/GetUserRole.js'
import {SERVER_ERROR} from '../../data/client_message.js'
import { GET_ALL_ROLE, EXPORT_ALL_ROLES, DELETE_SECURITY_ROLE} from '../../data/url_constants.js';
import {CLIENT_SIDE, SERVER_SIDE} from '../../data/macros.js'
import {getEnvironmentList, getEnvListForDataviewer} from '../../methods/EnvironmentList.js'
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import {SECURITY_SERVICE,CREATE_ACTION,READ_ACTION, UPDATE_ACTION, DELETE_ACTION} from "../../data/macros.js"
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
// import data from './metaData.js'
export default {
    name: 'DemoGrid',
    
    data: function () {
        return {
            userRole:'',
            selected_env:{},
            EnvironmentList:[],
            show_copy_dialog:false,
            copy_to_environment:'',
            copy_id:'',
            environmentRule:[
				v => !!v || 'User comments are required'
            	],
            tableList: {
                headers: [
                    { text: 'Role Name', value: 'role', width: '30%', title: 'Security Role Name' },
                    //  { text: 'Role Type', value: 'role_type', width: '20%', title: 'Security Role Type' },
                    // { text: 'Product Name', value: 'product_name', width: '10%', title: 'Security Product Name' },
                    { text: 'Created By', value: 'created_by', width: '30%', title: 'Created By' },
                    { text: 'Created Date', value: 'timezone_aware_created_date', width: '20%', title: 'Created Date' },
                    { text: 'Created Time', value: 'timezone_aware_created_time', width: '20%', title: 'Created Time' }

                    // { text: 'Environment Type', value: 'type', width: '20%' },
                ],
                actions:[],
                sorting_type: CLIENT_SIDE,
                filterType: CLIENT_SIDE,
                paginationType: CLIENT_SIDE,
                total_count: 0,
                rows: [],
                selected_rows: [],
                syncHeaderScroll:false,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true
            },
            //  flag:true,
            snackbar:false,
            snackbartext:'',
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            showEnvType:false,
            valid:true, 
            sorting : config.SORTING_TYPE,
            envIpJson : {
                    "filter": [],
                    "sort": {
                        "column": "",
                        "type": ""
                    },
                    "page": 1,
                    "page_size": 5
                },
            isloading:false,
            menu:false,
            edit:"",
            loader:false,
            perPage: 5,
            page: 1,
        }
    },
    watch:{
        selected_env(newvalue) {
				if(!newvalue.id){
                    this.tableList.showNew = false

                }
                else{
                    delete this.tableList.showNew
                }
			},
    },
    mounted() {
        this.userRole = this.$session.get('user_role_mapping');
        this.tableList.actions=[{'text':'new','key':"role_id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,CREATE_ACTION),index:1},    
                
                {'text':'edit','key':"role_id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,UPDATE_ACTION),index:2},
                {'text':'delete','key':"role_id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,DELETE_ACTION),index:3},
                {'text':'copy','key':"role_id", selectType:"single", role:this.userRole.is_superadmin || getUserRole(this.userRole,SECURITY_SERVICE,CREATE_ACTION),index:4}
                ];
        if(this.$session.get('selected_env')){
            this.selected_env = this.$session.get('selected_env')
            this.copy_to_environment = this.selected_env
        }
        // Using showNew attribute to hide add new button when env not selected
        else    
            this.tableList.showNew = false

        this.getAllRole();
        this.getEnvironmentList();
    },
    methods: {
        getEnvironmentList() {
            let _this = this;
            getEnvListForDataviewer(this,false).then(response=>{
                    if(response){
                        _this.EnvironmentList = response;
                    }
                },response=>{
                    // this.snackbar = true;
                    // this.colorValue = "error";
                    // this.snackbartext = response;
                })
        },
        setEnv(){
            this.$session.set('selected_env',this.selected_env)
        },
        copyToEnvironment(){
            if(this.$refs.copy_form.validate()) {
                let _this = this;
                this.$router.push({ name: 'ManageObjRole', params: { copy_env: _this.copy_to_environment, from:'copy', role_id: _this.copy_id }})
            }
        },
        onDownload(downloadParams, filterArray){
            let _this = this; 
            _this.loader=true;
            let filter=[];
            filterArray.map((obj, index)=>{
                obj.stringArray.map((stringObj, stringIndex)=>{
                    let temp = {"column":obj.value,"value": String(stringObj.text).trim()};
                        filter.push(cloneDeep(temp));
                })
            });
            let inputJson={
                "recordType":downloadParams.recordType,
                "row_option": downloadParams.recordType,
                "client_id": this.$session.get('client_id'),
                "env_id": "",
                "product_name" :window.sessionStorage.getItem('product'),
                "file_format": downloadParams.fileType,
                "min_range": downloadParams.minRange|| null,
                "max_range": downloadParams.maxRange||null,
                "filter": filter
            }
            var url = config.Security_URL+EXPORT_ALL_ROLES;    
            postToServer(this, url, inputJson).then(s_Response => {
                var url = config.Security_URL + "/static/" + s_Response;
                window.open(url, '_blank');
                _this.loader=false;
            }).catch(objError => {
                _this.loader=false;
                if(objError){
                  _this.loader = null
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = objError;
                }
                else {
                  _this.snackbar = true
                  _this.colorValue = 'error'
                  _this.snackbartext = SERVER_ERROR;
                }
          })
        },
        getAllRole() {
            var _this = this; 
            var client_id = this.$session.get('client_id')
            var data = {"client_id":client_id,
                        "env_id": this.selected_env.id,
                        "env_name": this.selected_env.name,
                        "product_name":window.sessionStorage.getItem('product')}
            var url = config.Security_URL + "/get_all_object_based_roles";
            this.loader=true;
            postToServer(this, url, data).then(Response => {
                _this.loader=false;
                if(Response)
                    this.tableList.rows = Response;
            }).catch(DatasourceError => {
                _this.loader=false;
                if (DatasourceError) {
                    this.tableList.rows = []; 
                }else {
                    this.tableList.rows = [];
                  }
                });
        },
        onCopy(record){
            this.show_copy_dialog = true
            this.copy_id = record.role_id
        },
        addRole(){
            let _this = this;
        _this.$router.push('/ManageObjRole');
        
        },
        onEdit:function(record){
            let _this = this;
            debugger
            if(record.role === "superadmin" && record.role_type === "administrator"){
                this.snackbar = true
                this.colorValue = 'error'
                this.snackbartext = 'User cannot edit superadmin role';
                return;
            }
            // this.$router.push({ name: 'manageenvironment', params: { environment_id: record.id, type:'edit' }})
            this.$router.push({ name: 'ManageObjRole', params: { role_id: record.role_id, role_type: record.role_type }});
        },
        onDelete:function(record){
            let _this = this
            _this.loader = true;
            var client_id = this.$session.get('client_id');
            var role_id = record.role_id
            var delete_object_url = config.Security_URL + "/delete_object_based_security_role" 
            let data = {"role_id":role_id,
                        "client_id":client_id,
                        "product_name" :window.sessionStorage.getItem('product')}
            postToServer(_this, delete_object_url, data).then(response => {
            _this.snackbar = true
            _this.loader = false;
            _this.colorValue = 'success'
            _this.snackbartext = response;
            _this.getAllRole()
            }).catch(error_response => {
            _this.loader = false;
            if(error_response){
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = error_response;
            }
            else {
                _this.snackbar = true
                _this.colorValue = 'error'
                _this.snackbartext = SERVER_ERROR;
                }
            });
        }
    }
}
</script>
<style scoped>
/* tr:nth-child(even) {background-color: #E8E8E8;}
.blurOpacity{
    opacity: 0;
}
.showInput {
    visibility: inherit !important;
    width: 50% !important;
}

.srch-ip {
    width: 0px;
    background: white;
    color: black;
    float: right;
    transition: all 1s;
    visibility: hidden;
}

table {
    border: 2px solid grey;
    border-radius: 3px;
    background-color: #fff;
    cursor: pointer;
}

thead {
    line-height: 30px;
    transition: all 10s;
}

th {
    background-color: grey;
    color: rgba(255, 255, 255, 0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
}

.srch-icon {
    font-size: 16px;
    color: white;
    float: right;
    margin-top: 4%;
} */
</style>