
<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
                <li>
                <router-link to="/objectmanagement">Object list</router-link>
              </li>
              <li>Select Environments to Publish To</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>      
    </v-row>

    <v-container>
      <v-form ref="form" v-model="valid">
        <v-card elevation-1>
          <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
            <v-row class="EDC-Row" style="padding-top:20px !important; padding-left:10px !important">
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                <v-autocomplete clearable :items="EnvironmentList" item-text="target_env_name" item-value="env"  v-model="Environment" :rules="env_list_rules" label="Select Environment to Publish To" return-object @input="getPublishData" dense class="EDC-UIComponent"></v-autocomplete>
              </v-col>
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing" v-if="showInstanceOption">
                <v-autocomplete clearable autocomplete :items="InstanceEnvironmentList" item-text="target_env_name" item-value="env"  v-model="InstanceEnvironment" label="Select Instance Environment to Publish" return-object dense class="EDC-UIComponent"></v-autocomplete>
              </v-col>
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                <v-textarea name="input-7-1" label="Request Name" v-model="request_name" :rules="request_name_rules" rows=1 dense class="EDC-UIComponent" style="margin-top:-4px !important"></v-textarea>
              </v-col>
            </v-row>

            <v-row class="EDC-Row">
              <v-col cols="12" class="EDC-Col">
                <edc-data-grid @onRowSelected="onRowSelected" :dataList="tableList"></edc-data-grid>
              </v-col>
            </v-row>

            <v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-right:10px !important" justify="end">
              <vc-button itemText="Publish" @click.native="submit"></vc-button>
              <vc-button itemText="Cancel" @click.native="onCancel"></vc-button>
            </v-row>
          </v-col>
        </v-card>
      </v-form>
    </v-container>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    <!-- <simplert ref="simplert" :use-radius="true" :use-icon="true"/> -->
    <v-dialog v-model="firstPopUp" width="450" persistent>
        <v-card class="EDC-Container">
              <v-row class="EDC-Row Row-Padding">
                  <v-toolbar dense class="EDC-Toolbar">
                      <v-toolbar-title>{{confirmationHeader}}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <!-- <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn> -->
                  </v-toolbar>
              </v-row>
              <v-row class="EDC-Row Row-Padding">
                  <v-col class="EDC-Col" align="left">
                      Exporting {{totalImports}} items from <b>Local</b> to <b>Instance</b>
                  </v-col>
              </v-row>
              <v-row class="EDC-Row">
                  <v-col class="EDC-Col" align="left" style="font-size:16px">
                      {{subDescriptionMsg}}
                  </v-col>
              </v-row>
              <v-row class="EDC-Row">
                  <v-col class="EDC-Col" align="left">
                      <v-list>
                          <v-list-item @click="clickOnImportOption(1)">
                            <v-list-item-content>
                              <v-list-item-title>{{firstMessage}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="clickOnImportOption(2)">
                            <v-list-item-content>
                              <v-list-item-title>Skip these objects</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="clickOnImportOption(3)">
                            <v-list-item-content>
                              <v-list-item-title>Let me decide for each object</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </v-list>
                  </v-col>
              </v-row>
          </v-card>
    </v-dialog>
    <v-dialog v-model="secondPopUp" width="700">
      <v-card class="EDC-Container">
          <v-row class="EDC-Row Row-Padding">
              <v-toolbar dense class="EDC-Toolbar">
                  <v-toolbar-title>{{userActionHeader}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-btn icon>
                      <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn> -->
              </v-toolbar>
          </v-row>
          <v-row class="EDC-Row Row-Padding">
              <v-col class="EDC-Col" align="left" style="font-size:14px;color:blue">
                  Which object do you want to import?
              </v-col>
          </v-row>
          <v-row class="EDC-Row">
              <v-col class="EDC-Col" align="left" cols="5">
                  <b>Objects in the repository</b>
              </v-col>
              <v-col class="EDC-Col" align="left" cols="7">
                  <v-row class="EDC-Row">
                      <v-col class="EDC-Col" cols="1" align="left">
                          <v-checkbox small dense v-model="importAllChk" style="margin:0px;padding:0px" hide-details color="primary-lighten2" label="" class="Checkbox-Options" title="Import all objects" @change="toggleItemsToImport"></v-checkbox>
                      </v-col>
                      <v-col class="EDC-Col checkbox-text-custom" align="left">
                          <b>New Objects</b>
                      </v-col>
                  </v-row>
              </v-col>
          </v-row>
          <v-row class="EDC-Row scrollbar" style="max-height:400px !important;overflow-x:hidden !important;overflow-y:scroll !important;" id="style-2">
              <v-col class="EDC-Col" align="left" cols="12">
                  <template v-for="(item,idnx) in replaceObject.object_details">
                      <v-row class="EDC-Row Row-Padding" :key="idnx+'newobjrw'" style="padding-left:4px !important;">
                          <v-col class="EDC-Col" cols="5">
                              <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="3" align="left">
                                      Id
                                  </v-col>
                                  <v-col class="EDC-Col" align="left">
                                      {{item.old_object_details.object_id}}
                                  </v-col>
                              </v-row>
                              <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="3" align="left">
                                      Name
                                  </v-col>
                                  <v-col class="EDC-Col" align="left">
                                      {{item.old_object_details.object_name}}
                                  </v-col>
                              </v-row>
                              <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="3" align="left">
                                      Version
                                  </v-col>
                                  <v-col class="EDC-Col" align="left">
                                      {{item.old_object_details.object_version}}
                                  </v-col>
                              </v-row>
                              <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="3" align="left">
                                      Published
                                  </v-col>
                                  <v-col class="EDC-Col" align="left">
                                      {{item.Object_in_env}}
                                  </v-col>
                              </v-row>
                          </v-col>
                          <v-col class="EDC-Col" cols="7">
                              <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="1" align="left">
                                      <v-checkbox small dense v-model="item.isSelected" style="margin:0px;padding:0px" hide-details color="primary-lighten2" label="" class="Checkbox-Options" @change="itemSelectionChanged"></v-checkbox>
                                  </v-col>
                                  <v-col class="EDC-Col checkbox-text-custom" cols="2" align="left">
                                      Id
                                  </v-col>
                                  <v-col class="EDC-Col checkbox-text-custom" align="left">
                                      {{item.new_object_details.object_id}}
                                  </v-col>
                              </v-row>
                              <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="1" align="left">
                                      
                                  </v-col>
                                  <v-col class="EDC-Col" cols="2" align="left">
                                      Name
                                  </v-col>
                                  <v-col class="EDC-Col" align="left">
                                      {{item.new_object_details.object_name}}
                                  </v-col>
                              </v-row>
                              <!-- <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="1" align="left">
                                      
                                  </v-col>
                                  <v-col class="EDC-Col" cols="2" align="left">
                                      Version
                                  </v-col>
                                  <v-col class="EDC-Col" align="left">
                                      {{item.new_object_details.object_version}}
                                  </v-col>
                              </v-row> -->
                              <v-row class="EDC-Row">
                                  <v-col class="EDC-Col" cols="1" align="left">
                                      
                                  </v-col>
                                  <v-col class="EDC-Col" cols="2" align="left">
                                      Version
                                  </v-col>
                                  <v-col class="EDC-Col" align="left">
                                      <!-- <v-checkbox small dense v-model="item.create_new_version" style="margin:0px;padding:0px" hide-details color="primary-lighten2" label="Create New Version" :disabled="item.is_object_published" class="Checkbox-Options"></v-checkbox> -->
                                      <v-radio-group  v-model="item.create_new_version" hide-details dense :disabled="item.is_object_published">
                                          <v-radio hide-details dense class="radioClass" :color="colorCode" :label="'Overwrite Version '+item.new_object_details.object_version" :value="false"></v-radio>
                                          <v-radio hide-details dense class="radioClass" :color="colorCode" label="Create New Version" :value="true"></v-radio>
                                      </v-radio-group>
                                  </v-col>
                              </v-row>
                          </v-col>
                      </v-row>
                      <v-row class="EDC-Row" :key="idnx+'divider'">
                          <v-divider></v-divider>
                      </v-row>
                  </template>
              </v-col>
          </v-row>
          <v-row class="EDC-Row">
              <v-col class="EDC-Col" cols="12" align="right">
                  <vc-button itemText="Continue" @click.native="userSelectedObjectsForImport"></vc-button>
                  <vc-button itemText="Cancel" @click.native="secondPopUp=false"></vc-button>
              </v-col>    
          </v-row>
      </v-card>
  </v-dialog>

  </div>
</template>
<script>
// import Breadcrumb from "../Breadcrumbs.vue";
import Simplert from 'vue2-simplert'
import config from '../../config.json';
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { ENVIRONMENTLIST_BY_CLIENT, CREATE_NEW_JOB } from '../../data/url_constants.js';
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js';
import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
import _ from 'lodash';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
     name: 'Schedule',
     components: {
    //  Simplert
  },
  mounted () {
      this.getEnvironmentList()
    },
    data() {
      return {
        firstPopUp:false,
        confirmationHeader:"Replace or Skip Objects",
        totalImports: '',
        subDescriptionMsg:"The repository has ",
        firstMessage:"Replace the objects in the destination",
        secondPopUp:false,
        userActionHeader:'',
        importAllChk:false,
        replaceObject:{},
        create_new_version:false,
        copiedType:'replace',// in case of override it will be override,
        replace:false,
        loader:false,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        e1: 400,
        search: '',
        valid: false,
        Environment: {},
        EnvironmentList: [],
        policy_list: [],
        EnvironmentListRules: [v => !!v || 'Environment is required.'],
        selected: [],
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        tableList: {
          headers: [
            { text: 'Object ID', value: 'object_id',},
              { text: 'Object Name', value: 'object_name', },
              { text: 'Environment Name', value: 'env_name' },
              { text: 'Object Type', value: 'object_type', },
              { text: 'Object Ver.', value: 'object_revision',  align:'right'},
              { text: 'Workflow', value: 'publish_workflow_name'},
              { text: 'Remark', value: 'remark' }
          ],
          actions:[],//if we need conditional action in row then provide key
          sorting_type: CLIENT_SIDE,
          filterType: CLIENT_SIDE,
          paginationType: CLIENT_SIDE,
          total_count: 0,
          rows: [],
          itemkey:'edc_id',
          select_rows: true,
          hideRowInformation:true,
          hidePerPageArray:true,
          hidePagination:true,
          hideDownload:true,
          DefaultSelectAll:false,
          perPage:100
        },
        currentStage:{},
        request_name: 'publish objects',
        env_list_rules: [
          v => !!v || 'Environment is required.'
        ],
        request_name_rules: [
          v => !!v || 'Request Name is required.'
        ],
        objectJsonForInstancePublish:'',
        InstanceEnvironmentList:[{'id':'8888','target_env_id':'0','target_env_name':'Only Export To Repository','name':'Only Export To Repository'}],
        InstanceEnvironment:{'id':'8888','target_env_id':'0','target_env_name':'Only Export To Repository','name':'Only Export To Repository'},
        initialRequestObjects:[]
      }
    },
  props: {
    msg: String
  },
  computed: {
    showInstanceOption(){
      // return this.Environment && this.Environment.length === 1 && this.Environment[0].is_edc_instance
      return this.Environment && this.Environment.is_edc_instance
    }
  },
  methods: {
    onRowSelected(selectedRows){
            this.selected = selectedRows;
        },
    submit() {
      if (this.$refs.form.validate()){
        this.publishTemplate()
      }
          },
    onCancel(){
      this.$router.push({ name: 'processdefinitionlist'})
    },
      getEnvironmentList () {
        var req_data = {'source_env': this.$route.params.publish_data.from_env.env_id,
        "client_id":this.$session.get("client_id"),"fetch_instance_details":true, 
        "rule_for": window.sessionStorage.getItem('product')}
        postToServer(this, config.PUBLISHER_URL + '/get_next_environments', req_data).then(
          response => {
            if(response){
            this.EnvironmentList = response
            } else {
               this.tableList.rows = [];
               this.errorMsg = response
            }
          },response => {
            this.ShowErrorMessage=true
            this.ErrorMessage=response
          }).catch(EnvironmentError => {
            if(EnvironmentError){
                 this[l]  = false
                 this.loader = null 
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = EnvironmentError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
        },
        getPublishData () {
          var _this = this
          _this.objectJsonForInstancePublish = ''
          if (!this.Environment.id)
            return false
          // if(this.Environment.length > 1){
          //   let isInstanceSelected = _.find(this.Environment, ["is_edc_instance",true])
          //   if(isInstanceSelected){
          //     alert(" You cannot select environments and instance together. Either select one of them")
          //     return
          //   }
          // }
          // if(_this.showInstanceOption)
          //   _this.getPublishRuleForInstance()
            this.loader = true;
            this.selectedRows = []
            var publish_data = _.cloneDeep(this.$route.params.publish_data);
            var selected_envs = [this.Environment]
            let targetList = []
            for(let i=0;i<selected_envs.length;i++){
              targetList.push({"env_id":selected_envs[i].target_env_id,
              "env_name":selected_envs[i].target_env_name,
              "publish_workflow_id":selected_envs[i].publish_workflow_id,
              "publish_workflow_name":selected_envs[i].publish_workflow_name})
            }
            var req_data = {'source_env': this.$route.params.publish_data.from_env.env_id, 
            'target_list':targetList, 'publish_data': publish_data,
            "is_target_edc_instance":_this.showInstanceOption,'product': window.sessionStorage.getItem('product')}
            postToServer(_this, config.PUBLISHER_URL + '/get_publish_data', req_data).then(
          response => {
            let row_data = response
            if(_this.showInstanceOption){
              row_data= response.object_data
              _this.objectJsonForInstancePublish = response.object_json
            }
            if(row_data){
            let id = 1
            row_data.map(obj=>{
              // let selected_env = _.find(selected_envs, ['id',obj.env_id])
              // if(selected_env){
              //   obj.publish_workflow_name = selected_env.publish_workflow_name
              //   obj.publish_workflow_id = selected_env.publish_workflow_id
              // }
              obj.edc_id = id
              id++
            })
            _this.tableList.rows = row_data
            this.tableList.DefaultSelectAll = true
            this.loader = null
            } else {
              this.tableList.rows = [];
              this.errorMsg = response
              this.loader = null
            }
          },response => {
            this.loader = null
            this.ShowErrorMessage=true
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(EnvironmentError => {
            if(EnvironmentError){
                 this[l]  = false
                 this.loader = null 
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = EnvironmentError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
        },
      publishTemplate () {
        var _this = this
        if(this.showInstanceOption){
          this.publishIntoTheInstance(false)
          return
        }
        this.loader = true
        var data = _.cloneDeep(this.$route.params.publish_data);
        var unqiueSelected = []
        var selectedObjectId = []
        for(let j=0;j<this.selected.length;j++){
          if(selectedObjectId.indexOf(this.selected[j].object_id) === -1 &&
           selectedObjectId.indexOf(this.selected[j].object_revision) === -1){
            selectedObjectId.push(this.selected[j].object_id)
            unqiueSelected.push(this.selected[j])
          }
        }
        data['object_data'] = unqiueSelected
        data['target_object_list'] = [this.Environment]
        data['request_name'] = this.request_name
        data["product_name"] = window.sessionStorage.getItem('product')
        postToServer(this, config.PUBLISHER_URL + '/publish', data).then(response => {
            this.loader = false
            if(response){
              this.$cookies.set('published', true)
              setTimeout(()=>{
                this.$router.push({ name: 'PublishRequestDetails', params: {
                  'publish_id': response,
                  'client_id': _this.$session.get("client_id")}})
              }, 1000)
            } else {
               this.errorMsg = response
            }
          },response => {
            this.loader = false
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response
          }).catch(EnvironmentError => {
            this.loader = false
            if(EnvironmentError){
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = EnvironmentError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
        },
        publishIntoTheInstance(is_from_confirm){
          var _this = this
          debugger
          let data_to_send = {"object_details": _this.objectJsonForInstancePublish,"object_list":_this.selected,
          "client_id":_this.$session.get("client_id"),
          "added_by":_this.$session.get("email"),
          "consul_ip":_this.Environment.consul_ip,
          "instance_name": _this.Environment.instance_name,
          'target_env':_this.InstanceEnvironment,
          'source_env': this.$route.params.publish_data.from_env.env_id,
          'source_env_name': this.$route.params.publish_data.from_env.env_name,
          "old_request_data": _this.initialRequestObjects}
          _this.loader = true
          if(!is_from_confirm)
            data_to_send["object_check"] = true
            data_to_send['replace'] = true
          let url = config.PUBLISHER_URL +'/publish_to_instance'
          postToServer(_this,url,data_to_send).then(response=>{
            _this.loader = false
            debugger
            if(response.message && response.message.indexOf('will get replaced') != -1){
              _this.initialRequestObjects = response.old_request_data
              _this.ShowConfirm(response)
            } else if(response){
              this.$cookies.set('published', true)
              debugger
              setTimeout(()=>{
                this.$router.push({ name: 'PublishRequestDetails', params: {
                  'publish_id': response,
                  'client_id': _this.$session.get("client_id")}})
              }, 1000)
            }
            else {
              this.firstPopUp = false
              this.secondPopUp = false
            _this.snackbar = true
            _this.colorValue = 'success'
            _this.snackbartext = "Objects Exported Successfully";
            setTimeout(()=>{
              _this.$router.push({ name: 'processdefinitionlist'})
            },2000)
            }
          }).catch(Error=>{
              this.loader = false
              this.snackbar = true
              this.colorValue = 'error'
              this.snackbartext = Error;
          })

        },
        ShowConfirm(response){
          let _this = this
              this.replaceObject = response.data
              if (_this.replaceObject.replaceable && !_this.replaceObject.create_new_version){
                  _this.firstMessage = "Replace "+String(_this.replaceObject.replaceable)+" objects in the destination"
              }
              else if(!_this.replaceObject.replaceable && _this.replaceObject.create_new_version){
                  _this.firstMessage = "Create new version for "+String(_this.replaceObject.create_new_version)+" objects in the destination"
              }
              else if(_this.replaceObject.replaceable && _this.replaceObject.create_new_version){
                  _this.firstMessage = "Replace "+String(_this.replaceObject.replaceable)+" and create new version for "+String(_this.replaceObject.create_new_version)+" objects in the destination"
              }
              _this.firstPopUp = true
              _this.subDescriptionMsg ="The repository has "+_this.replaceObject.objects_exist+' objects with the same id'
              _this.totalImports = this.replaceObject.total_count
              _this.replaceObject.object_details.forEach((obj)=>{
                obj.create_new_version = obj.is_object_published
                obj.isSelected = false
            })
          // let confirmFn = function () {
          //   this.$parent.publishIntoTheInstance(true)
          // }
          // let obj = {
          //   title: 'Warning',
          //   message: message,
          //   type: 'info',
          //   useConfirmBtn: true,
          //   onConfirm: confirmFn
          // }
          // this.$refs.simplert.openSimplert(obj)
        },
        getPublishRuleForInstance(){
          var _this = this
          // this means already loaded instance Rule
          if(!_this.InstanceEnvironmentList.length > 2)
            return
          var req_data = {
            "client_id":_this.$session.get("client_id"),"added_by":_this.$session.get("email"),"consul_ip":_this.Environment.consul_ip,'source_env': 0
          }
          postToServer(_this,config.PUBLISHER_URL+'/get_rules_of_instance',req_data).then(response=>{
            _.forEach(response,(obj)=>{
              _this.InstanceEnvironmentList.push(obj)
            })
          }).catch(error=>{
            alert('errr')
          })
        },
        clickOnImportOption(optionIndex){let _this = this
            _this.replace = true
            if(optionIndex ===1){
                if(this.copiedType === 'replace'){
                    var obj_details = this.replaceObject.object_details
                    obj_details.forEach((obj)=>{
                        if(obj.create_new_version){
                            obj.new_object_details.action = 'create_new_version'
                        }else{
                            obj.new_object_details.action = 'override'
                        }})
                    var new_obj_data = []
                    for(var new_object_details in this.replaceObject.object_details){
                        new_obj_data.push(this.replaceObject.object_details[new_object_details].new_object_details)
                    }
                    _this.selected = new_obj_data
                    _this.publishIntoTheInstance(true)
                }else{
                    // create new version
                }
            } else if(optionIndex ===2){
                //skip all
                this.replaceObject.object_details.forEach((obj)=>{
                    obj.new_object_details.action = 'skip'
                })
                var new_obj_data = []
                    for(var new_object_details in this.replaceObject.object_details){
                        new_obj_data.push(this.replaceObject.object_details[new_object_details].new_object_details)
                    }
                    _this.selected = new_obj_data
                    _this.publishIntoTheInstance(true)
            }
            if(optionIndex ===3){
                this.userActionHeader = this.replaceObject.object_details.length + ' Total object conflicts'
                this.secondPopUp = true
            }
          },
        toggleItemsToImport(toggleValue){
            let _this = this
            _this.replaceObject.object_details.forEach((obj)=>{
                obj.isSelected = toggleValue
            })
        },
        itemSelectionChanged(){
            let _this = this
            let selectedObjects = _.filter(_this.replaceObject.object_details,(obj)=>{
                return !obj.isSelected
            })
            _this.importAllChk = selectedObjects.length?false:true
        },
        userSelectedObjectsForImport(){
            let _this = this
            let selectedObjects = _.filter(_this.replaceObject.object_details,(obj)=>{
                return obj.isSelected === true
            })
            selectedObjects.forEach((obj)=>{
                if(obj.create_new_version){
                    obj.new_object_details.action = 'create_new_version'
                }else{
                    obj.new_object_details.action = 'override'
                }})
            var new_obj_data = []
            for(var new_object_details in selectedObjects){
                new_obj_data.push(this.replaceObject.object_details[new_object_details].new_object_details)
            }
            _this.selected = new_obj_data
            _this.publishIntoTheInstance(true)
            if(!selectedObjects.length){
                this.replaceObject.object_details.forEach((obj)=>{
                    obj.new_object_details.action = 'skip'
                })
                var new_obj_data = []
                    for(var new_object_details in this.replaceObject.object_details){
                        new_obj_data.push(this.replaceObject.object_details[new_object_details].new_object_details)
                    }
                    _this.selected = new_obj_data
                    _this.publishIntoTheInstance(true)
                // alert('Atleast select one object')
                // return
            }
        },
    }
}
</script>
<style scoped>
 .radioClass >>> label{
    top: 5px !important;
} 
.radioClass >>> i{
    font-size: 30px
}
.customCheckbox >>> label{
    top: 5px !important;
}
/* .customCheckbox >>> i{
    top: 5px !important;
} */
.pointer{
    cursor: pointer;
}
.pointer >>> span{
    cursor: pointer;
}
.custWidth{
    width: 55px;
}
.customCheckbox >>> label{
    top: 5px !important;
}

.EDC-UIComponent{
  margin: 0px !important;
  padding: 0px !important;
}
</style>


<!-- <template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
                <li>
                <router-link to="/objectmanagement">Object list</router-link>
              </li>
              <li>Select Environments to Publish To</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>      
    </v-row>

    <v-container>
      <v-form ref="form" v-model="valid">
        <v-card elevation-1>
          <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
            <v-row class="EDC-Row" style="padding-top:20px !important; padding-left:10px !important">
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                <v-autocomplete clearable autocomplete :items="EnvironmentsForPublish" item-text="target_env_name" item-value="target_env_id"  v-model="Environment" :rules="env_list_rules" label="Select Environment to Publish" return-object @input="getPublishData" dense class="EDC-UIComponent"></v-autocomplete>
              </v-col>
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                <v-textarea name="input-7-1" label="Request Name" v-model="request_name" :rules="request_name_rules" rows=1 dense class="EDC-UIComponent" style="margin-top:-4px !important"></v-textarea>
              </v-col>
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing"></v-col>
            </v-row>

            <v-row class="EDC-Row">
              <v-col cols="12" class="EDC-Col">
                <edc-data-grid @onRowSelected="onRowSelected" :dataList="tableList"></edc-data-grid>
              </v-col>
            </v-row>

            <v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-right:10px !important" justify="end">
              <vc-button itemText="Publish" @click.native="submit"></vc-button>
              <vc-button itemText="Cancel" @click.native="onCancel"></vc-button>
            </v-row>
          </v-col>
        </v-card>
      </v-form>
    </v-container>

    <loading-panel :loader="loader"></loading-panel>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import config from '../../config.json';
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { ENVIRONMENTLIST_BY_CLIENT, CREATE_NEW_JOB } from '../../data/url_constants.js';
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js';
import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
import _ from 'lodash';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getEnvironmentList} from '../../methods/EnvironmentList.js'

export default {
     name: 'Schedule',
     components: {
  },
  mounted () {
      this.getEnvironmentList();
    },
    data() {
      return {
        loader:false,
        outlineColor:BTN_COLOR,
        colorCode:COLOR_CODE,
        e1: 400,
        search: '',
        valid: false,
        Environment: {},
        EnvironmentList: [],
        EnvironmentsForPublish:[],
        policy_list: [],
        EnvironmentListRules: [v => !!v || 'Environment is required.'],
        selected: [],
        snackbar:false,
        snackbartext:'',
        colorValue:'error',
        snackbartimeout: Snackbar_Default_Timeout,
        tableList: {
          headers: [
            { text: 'Object ID', value: 'object_id', sortable: false, width: '40%'},
              { text: 'Object Name', value: 'object_name', sortable: false, width: '40%'},
              { text: 'Object Type', value: 'object_type', sortable: false, width: '20%' },
              { text: 'Object Ver.', value: 'object_revision', sortable: false, width: '10%', dataType:'int'},
              { text: 'Workflow', value: 'publish_workflow_name', sortable: false, width: '30%' },
              { text: 'Remark', value: 'remark', sortable: false, width: '30%', hideInlineFilter: true }
          ],
          actions:[],//if we need conditional action in row then provide key
          sorting_type: CLIENT_SIDE,
          filterType: CLIENT_SIDE,
          paginationType: CLIENT_SIDE,
          total_count: 0,
          rows: [],
          itemkey:'edc_id',
          select_rows: true,
          hideRowInformation:true,
          hidePerPageArray:true,
          hidePagination:true,
          hideDownload:true,
          DefaultSelectAll:false,
          perPage:100,
          hideFilter:true,
          hideExport:true,
          hideShowColumns:true
        },
        currentStage:{},
        request_name: 'publish objects',
        env_list_rules: [
          v => !!v || 'Environment is required.'
        ],
        request_name_rules: [
          v => !!v || 'Request Name is required.'
        ]
      }
    },
  // watch:{
  //   EnvironmentList:{
  //     handler(newvalue){
  //       for(let i=0;i<newvalue.length;i++){
  //         if(newvalue[i].id != this.$route.params.publish_data.from_env.env_id)
  //           this.EnvironmentsForPublish.push(newvalue[i])
  //       }
  //     }
  //   }
  // },
  props: {
    msg: String
  },
  methods: {
    onRowSelected(selectedRows){
            this.selected = selectedRows;
        },
    submit() {
      if (this.$refs.form.validate()){
        this.publishTemplate()
      }
          },
    onCancel(){
      this.$router.push({ name: 'processdefinitionlist'})
    },
    getEnvironmentList () {
        var req_data = {'source_env': this.$route.params.publish_data.from_env.env_id,
                        "rule_for": window.sessionStorage.getItem('product')}
        postToServer(this, config.PUBLISHER_URL + '/get_next_environments', req_data).then(
          response => {
            if(response){
              for(var i=0; i<response.length; i++){
                this.EnvironmentsForPublish.push(response[i])
              }
            } else {
               this.tableList.rows = [];
               this.errorMsg = response
            }
          }).catch(response => {
            if(response){
                 this.loader = null 
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = response;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
        },
        getPublishData () {
          if (!this.Environment)
            return false
            this.loader = true;
            this.selectedRows = []
            debugger
            var publish_data = _.cloneDeep(this.$route.params.publish_data);
            var selected_env = this.Environment
            var req_data = {'source_env': this.$route.params.publish_data.from_env.env_id, 
            'target_env': this.Environment.target_env_id, 'publish_data': publish_data, 'product': window.sessionStorage.getItem('product')}
            postToServer(this, config.PUBLISHER_URL + '/get_publish_data', req_data).then(
          response => {
            if(response){
            let id = 1
            response.map(obj=>{
              obj.publish_workflow_name = selected_env.publish_workflow_name
              obj.publish_workflow_id = selected_env.publish_workflow_id
              obj.edc_id = id
              id++
            })
            this.tableList.rows = response
            this.tableList.DefaultSelectAll = true
            this.selectedRows = response
            this.loader = null
            } else {
              this.tableList.rows = [];
              this.errorMsg = response
              this.loader = null
            }
          },response => {
            this.loader = null
            this.ShowErrorMessage=true
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(EnvironmentError => {
            if(EnvironmentError){
                 this[l]  = false
                 this.loader = null 
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = EnvironmentError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
      },
      publishTemplate () {
        this.loader = true
        var data = _.cloneDeep(this.$route.params.publish_data);
        this.Environment['target_env_id'] = this.Environment.target_env_id
        data['object_data'] = this.selected
        data['target_env'] = this.Environment
        data['request_name'] = this.request_name
        data["product_name"] = window.sessionStorage.getItem('product')
        postToServer(this, config.PUBLISHER_URL + '/publish', data).then(response => {
            this.loader = false
            if(response){
              this.$cookies.set('published', true)
              setTimeout(()=>{
                this.$router.push({ name: 'PublishRequestDetails', params: {'publish_id': response}})
              }, 1000)
            } else {
               this.errorMsg = response
            }
          },response => {
            this.loader = false
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response
          }).catch(EnvironmentError => {
            this.loader = false
            if(EnvironmentError){
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = EnvironmentError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
        },
    }
}
</script>
<style scoped>
 .radioClass >>> label{
    top: 5px !important;
} 
.radioClass >>> i{
    font-size: 30px
}
.customCheckbox >>> label{
    top: 5px !important;
}
/* .customCheckbox >>> i{
    top: 5px !important;
} */
.pointer{
    cursor: pointer;
}
.pointer >>> span{
    cursor: pointer;
}
.custWidth{
    width: 55px;
}
.customCheckbox >>> label{
    top: 5px !important;
}

.EDC-UIComponent{
  margin: 0px !important;
  padding: 0px !important;
}
</style>
 -->
