/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dependency_add': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M5.556.794v.793H.793V.794zM2.125 2.118a.261.261 0 01.127.037l1.324.793a.265.265 0 010 .455l-1.324.793a.265.265 0 01-.4-.227V3.44H.792V2.118h.53v.792h.53v-.529c0-.149.124-.268.273-.263zm3.432.527l-.001 1.06H3.968l.001-1.06zm-.266.265H4.235l-.001.53H5.29zm.265 1.852v.794H.793v-.794z"/>'
  }
})
