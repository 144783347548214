import {
    SERVER_SIDE
  } from '../data/macros.js'

var tblObj ={
    headers:[],
    caption:'',
    itemkey:'',
    rows:[],
    total_count:0,
    unique_id:'',
    hideCaption:true,
    tableLoading:true,
    selected_rows:[],
    tableDescription:'',
    sorting_type: SERVER_SIDE,
    filterType: SERVER_SIDE,
    paginationType: SERVER_SIDE,
    is_in_tab:true,
    showContextMenu:true,
    hideNonSelectedRows:true,
    hideRowTimer:null,
    hideRowDelay:3000, // kept 1 second less compare serverCallDelay
    updateInlineFilter:{},
    summaryFooter:{},
    loadSummary:false,
    primary_keys:[],
    availableViewList:[{'id':0,"object_id":"new","object_name":"new"}],
    selectedView:'GV01',
    showDescriptionAsHeader:true,
    gridFor:'dataviewer',
    hideShowColumns:true,
    inputJson:{},
    hideFilter:true,
    mediaObjDetails:[],
    hasAttachment:true,
    actualRowCount:0,
    showCustomizationOpt:true,
    actions:[],
    showPlus:true,
    perPage:10,
	showMenuForOtherDSTable:false,
	datasourceIndex:-1,
    scrollCordinates:{"horizontalCord":0,"verticalCord":0},
    cols:12,
    colsAfterCompact:0,
    isCompacted:false, // this property used in edcdatagrid.vue to hide some of the grid props on grid compact
    isDummy:false,
    showFetchedInTimer:true,
    fetchTimeDetails:'',
    dynamicMenuItem:[],
    keepFirstColFixed:true,
    keepSecondColFixed:false,
    stepType:''
}

export function getDataviewerTbleObj(){
    let dataviewerTblObj = _.cloneDeep(tblObj)
    dataviewerTblObj['gridToCompare'] = _.cloneDeep(tblObj)
    return dataviewerTblObj
}

var revisedTableObj ={
    headers:[],
    caption:'',
    itemkey:'',
    rows:[],
    total_count:0,
    unique_id:'',
    hideCaption:true,
    tableLoading:true,
    selected_rows:[],
    tableDescription:'',
    sorting_type: SERVER_SIDE,
    filterType: SERVER_SIDE,
    paginationType: SERVER_SIDE,
    is_in_tab:true,
    showContextMenu:true,
    hideNonSelectedRows:true,
    hideRowTimer:null,
    hideRowDelay:3000, // kept 1 second less compare serverCallDelay
    updateInlineFilter:{},
    summaryFooter:{},
    loadSummary:false,
    primary_keys:[],
    showDescriptionAsHeader:true,
    gridFor:'dataviewer',
    hideShowColumns:true,
    inputJson:{},
    hideFilter:true,
    mediaObjDetails:[],
    hasAttachment:true,
    actualRowCount:0,
    showCustomizationOpt:true,
    actions:[],
    showPlus:true,
    perPage:10,
	showMenuForOtherDSTable:false,
	datasourceIndex:-1,
    scrollCordinates:{"horizontalCord":0,"verticalCord":0},
    cols:12,
    colsAfterCompact:0,
    isCompacted:false, // this property used in edcdatagrid.vue to hide some of the grid props on grid compact
    isDummy:false,
    showFetchedInTimer:true,
    fetchTimeDetails:'',
    dynamicMenuItem:[],
    keepFirstColFixed:true,
    keepSecondColFixed:false,
    stepType:''
}

export function getRevisedTableObject(){
    return _.cloneDeep(revisedTableObj)
}