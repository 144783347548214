/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attachment': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M5.027.794a1.856 1.856 0 00-2.585.424L.59 3.797a1.327 1.327 0 00.303 1.846c.591.424 1.422.288 1.846-.303L4.591 2.76a.798.798 0 00-.181-1.108.796.796 0 00-1.075.144.266.266 0 00-.033.038L1.604 4.2a.265.265 0 10.43.309l1.698-2.364a.26.26 0 01.369-.06.26.26 0 01.06.368L2.31 5.032a.79.79 0 01-1.108.18.79.79 0 01-.182-1.107l1.852-2.579a1.319 1.319 0 011.846-.303c.596.429.731 1.25.303 1.847L3.938 4.578a.265.265 0 10.43.309L5.45 3.378A1.856 1.856 0 005.027.794z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
