/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Time': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g _fill="none" _stroke="#000" stroke-linecap="round"><circle pid="0" cx="32" cy="32" r="22.957" stroke-width="2.087" stroke-linejoin="round"/><path pid="1" d="M32 16v16l12 12" stroke-width="2"/></g>'
  }
})
