/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'context_menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M2.184 1.059a.265.265 0 00-.266.264.265.265 0 00.266.266H5.49a.265.265 0 00.264-.266.265.265 0 00-.264-.264zM.92 1.853a.264.264 0 00-.26.263V3.705c0 .204.225.33.4.226l1.325-.793a.265.265 0 000-.455L1.06 1.89a.261.261 0 00-.141-.037zm2.475 1.321a.265.265 0 00-.264.266.265.265 0 00.264.264H5.6a.265.265 0 00.265-.264.265.265 0 00-.265-.266zM2.91 5.292a.265.265 0 00-.264.265.265.265 0 00.264.264h2.647a.265.265 0 00.263-.264.265.265 0 00-.263-.265z"/>'
  }
})
