<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="12" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
                <li>
                <router-link to="/objectmanagement">Object list</router-link>
              </li>
              <li>Select Datasource to Publish</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>      
    </v-row>

    <v-container>
      <v-form ref="form" v-model="valid">
        <v-card elevation-1>
          <v-col cols="12" class="EDC-Col EDC-ColsSpacing">
            <v-row class="EDC-Row" style="padding-top:20px !important; padding-left:10px !important">
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                <v-autocomplete clearable autocomplete multiple :items="DatasourceForPublish" item-text="target_ds_name" item-value="ds"  v-model="Datasource" :rules="ds_list_rules" label="Select Datasource to Publish" return-object @blur="getPublishData" dense class="EDC-UIComponent"></v-autocomplete>
              </v-col>
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
                <v-textarea name="input-7-1" label="Request Name" v-model="request_name" :rules="request_name_rules" rows=1 dense class="EDC-UIComponent" style="margin-top:-4px !important"></v-textarea>
              </v-col>
              <v-col cols="4" class="EDC-Col EDC-ColsSpacing"></v-col>
            </v-row>

            <v-row class="EDC-Row">
              <v-col cols="12" class="EDC-Col">
                <edc-data-grid @onRowSelected="onRowSelected" :dataList="tableList"></edc-data-grid>
              </v-col>
            </v-row>

            <v-row no-gutters class="EDC-Row EDC-RowsSpacing" style="padding-right:10px !important" justify="end">
              <vc-button itemText="Publish" @click.native="submit"></vc-button>
              <vc-button itemText="Cancel" @click.native="onCancel"></vc-button>
            </v-row>
          </v-col>
        </v-card>
      </v-form>
    </v-container>
    <loading-panel :loader="loader"></loading-panel>
    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>
  </div>
</template>
<script>
import config from '../../config.json';
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import { ENVIRONMENTLIST_BY_CLIENT, CREATE_NEW_JOB } from '../../data/url_constants.js';
import { SERVER_ERROR } from '../../data/client_message.js';
import {CLIENT_SIDE, SERVER_SIDE} from './../../data/macros.js';
import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
import _ from 'lodash';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
import {getEnvironmentList} from '../../methods/EnvironmentList.js'
export default {
     name: 'Schedule',
     components: {
  },
  mounted () {
      this.getDatasourceList();
    },
    data() {
        return{
          loader: false,
          request_name: 'publish objects',
          valid: false,
          Datasource:[],
          snackbar:false,
          colorValue:'error',
          snackbartimeout: Snackbar_Default_Timeout,
          snackbartext:'',
          DatasourceForPublish: [],
          tableList: {
            headers: [
              { text: 'Object ID', value: 'object_id', sortable: false, width: '20%'},
              { text: 'Object Name', value: 'object_name', sortable: false, width: '20%'},
              { text: 'Datasource Name', value: 'ds_name', sortable: false, width: '20%'},
              { text: 'Object Type', value: 'object_type', sortable: false, width: '20%' },
              { text: 'Object Ver.', value: 'object_revision', sortable: false, width: '10%', dataType:'int'},
              { text: 'Remark', value: 'remark', sortable: false, width: '20%' },
          ],
          actions:[],//if we need conditional action in row then provide key
          sorting_type: CLIENT_SIDE,
          filterType: CLIENT_SIDE,
          paginationType: CLIENT_SIDE,
          total_count: 0,
          rows: [],
          itemkey:'object_id',
          select_rows: true,
          hideRowInformation:true,
          hidePerPageArray:true,
          hidePagination:true,
          hideDownload:true,
          DefaultSelectAll:false,
          perPage:100,
          hideFilter:true,
          hideExport:true,
          hideShowColumns:true
        },
          ds_list_rules: [
          v => !!v || 'Datasource is required.'
        ],
          request_name_rules: [
          v => !!v || 'Request Name is required.'
          ]
        }
    },
    methods: {
      onRowSelected(selectedRows){
            this.selected = selectedRows;
        },
      submit() {
        if (this.$refs.form.validate()){
          this.publishTemplate()
      }
          },
      onCancel(){
        this.$router.push({ name: 'processdefinitionlist'})
    },
    getDatasourceList(){
      var object_data = {'publish_data': this.$route.params.publish_data} 
      postToServer(this,config.PUBLISHER_URL + '/get_next_datasources', object_data).then(responsedata=>{
        if(responsedata){
          this.DatasourceForPublish = responsedata
            }
           }).catch(responsedata => {
             this.snackbar = true
             this.colorValue = 'error'
             this.snackbartext = responsedata;
           })
        },
    getPublishData () {
          if (!this.Datasource)
            return false
            this.loader = true;
            this.selectedRows = []
            debugger
            var publish_data = _.cloneDeep(this.$route.params.publish_data);
            var req_data = {'target_list':this.Datasource, 'publish_data': publish_data}
            postToServer(this, config.PUBLISHER_URL + '/get_publish_data_ds', req_data).then(
          response => {
            if(response){
              debugger
            this.tableList.rows = response
            this.tableList.DefaultSelectAll = true
            this.loader = null
            } else {
              this.tableList.rows = [];
              this.errorMsg = response
              this.loader = null
            }
          },response => {
            this.loader = null
            this.ShowErrorMessage=true
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response;
          }).catch(response => {
            if(response){
                 this[l]  = false
                 this.loader = null 
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = response;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
        },

        publishTemplate () {
        this.loader = true
        var data = _.cloneDeep(this.$route.params.publish_data);
        data['object_data'] = this.selected
        data['target_object_list'] = this.Datasource
        data['request_name'] = this.request_name
        data["product_name"] = window.sessionStorage.getItem('product')
        postToServer(this, config.PUBLISHER_URL + '/publish', data).then(response => {
            this.loader = false
            if(response){
              this.$cookies.set('published', true)
              setTimeout(()=>{
                this.$router.push({ name: 'PublishRequestDetails', params: {'publish_id': response}})
              }, 1000)
            } else {
               this.errorMsg = response
            }
          },response => {
            this.loader = false
            this.snackbar = true
            this.colorValue = 'error'
            this.snackbartext = response
          }).catch(EnvironmentError => {
            this.loader = false
            if(EnvironmentError){
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = EnvironmentError;
                }
                else {
                  this.snackbar = true
                  this.colorValue = 'error'
                  this.snackbartext = SERVER_ERROR;
                }
          })
        },
    }
}




</script>