<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <ul class="breadcrumb" style="padding-left:0px !important;">
                    <li>Select Data View Mode </li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="EDC-Row" align="center" style="height:80vh;">
            <v-col class="EDC-Col" cols="12">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" cols="1"></v-col>
                    
                    <v-col class="EDC-Col" cols="10">
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col" cols="4">
                                <svgicon :class="{'svg-icon-h1':true, 'svg-fill-h1':true, 'svg-h1-border':displayMode!='Single', 'svg-h1-border-highlight':displayMode==='Single'}" name="pathfinder_normal_view"  :original="true" title="Show Single Table Data In Single Grid" @mouseover.native="displayMode='Single'" @click.native="redirectToDataviewer('single')"></svgicon>
                            </v-col>
                            <v-col class="EDC-Col EDC-DashboardRowSpacing" cols="4">
                                <svgicon :class="{'svg-icon-h1':true, 'svg-fill-h1':true, 'svg-h1-border':displayMode!='Split', 'svg-h1-border-highlight':displayMode==='Split'}" name="pathfinder_split_view"  :original="true" title="Split Page Into 2 Parts To Show 2 Tables In 2 Seprate Grids" @mouseover.native="displayMode='Split'" @click.native="redirectToDataviewer('split')"></svgicon>
                            </v-col>
                            <v-col class="EDC-Col" cols="4">
                                <svgicon :class="{'svg-icon-h1':true, 'svg-fill-h1':true, 'svg-h1-border':displayMode!='Merged' , 'svg-h1-border-highlight':displayMode==='Merged'}" class="svg-icon-h1 svg-fill-h1" name="pathfinder_merged_view"  :original="true" title="Show Two Tables Data In Single Grid" @mouseover.native="displayMode='Merged'" @click.native="redirectToDataviewer('merged')"></svgicon>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="EDC-Col" cols="1"></v-col>
                </v-row>
                <v-row class="EDC-Row EDC EDC-DashboardRowSpacing">
                    <v-col class="EDC-Col" cols="1"></v-col>
                    <v-col class="EDC-Col" cols="10">
                        <v-row class="EDC-Row">
                            <v-col class="EDC-Col" cols="4">
                                <span :class="{'normal-font':displayMode!='Single', 'bold-font':displayMode==='Single'}">Single Grid</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="4">
                                <span :class="{'normal-font':displayMode!='Split', 'bold-font':displayMode==='Split'}">Split Grids</span>
                            </v-col>
                            <v-col class="EDC-Col" cols="4">
                                <span :class="{'normal-font':displayMode!='Merged', 'bold-font':displayMode==='Merged'}">Merged Grid</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="EDC-Col" cols="1"></v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .bold-font {
        font-size: 18px;
        font-weight: bold;
    }

    .normal-font {
        font-size: 14px;
        font-weight: normal;
    }
</style>
<script>
    import {mapActions} from 'vuex'
    export default {
        name:'DataviewerLanding',
        components:{
            
        },
        props:{

        },
        data(){
            return{
                displayMode:'Single'
            }
        },
        mounted(){
            this.setDataviewerGridObject({})
            this.setDataviewerProps({})
            if(this.$route.params && this.$route.params.isFromArchivist)
                this.redirectToDataviewer(this.$route.params.opentType)
        },
        methods:{
            ...mapActions(['setDataviewerGridObject','setDataviewerProps']),
            redirectToDataviewer(type='Single'){
                this.$router.push({name:"Dataviewer",params:{'opentType':type}})
            }
        }
    }
</script>
