<template>
  <div>
    <v-row class="EDC-Row">
      <v-col cols="8" class="EDC-Col">
        <v-row class="EDC-Row breadcrumbRow">
          <v-col cols="4" class="EDC-Col">
            <ul class="breadcrumb breadcrumbUL">
              <router-link to="/rolelist">Security Role</router-link>
            </ul>
          </v-col>
          <v-col cols="8"></v-col>
        </v-row>
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>

    <v-container>
      <v-card elevation-1>
        <v-col cols="12" class="EDC-Col">
          <v-row no-gutters align="start" class="EDC-Row EDC-TopRow">
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              <v-text-field label="Name of Role" v-model="role_name" @blur="untitle_environment" class="EDC-UIComponent" autocomplete="off" hide-details dense></v-text-field>
              <!-- <input v-model="role_name" type="text" autofocus :disabled="$route.params.type == 'edit'" @blur="untitle_environment"> -->
            </v-col>
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing">
              <!-- <v-autocomplete :items="action_type" item-text="name" label="Role Type" class="EDC-UIComponent" @change="onRadioBtnChange"></v-autocomplete> -->
              <v-radio-group v-model="radioGroup" :disabled="isDisabled" row @change="onRadioBtnChange" class="EDC-UIComponent" hide-details dense>
                <v-radio class="radioClass" :color="colorCode" label="Administrator" value="administrator" dense/>
                <v-radio class="radioClass" :color="colorCode" label="Environmental" value="environmental" dense/>    
              </v-radio-group>
            </v-col>
            <v-col cols="4" class="EDC-Col EDC-ColsSpacing EDC-ColDescription">
              <v-textarea v-model="roleInput.role_description" label="Description" value rows="1" class="EDC-UIComponent" hide-details dense/>
            </v-col>
          </v-row>

          <v-divider class="EDC-UIComponent"/>

          <v-row no-gutters class="EDC-Row EDC-GridRow">
            <v-col class="EDC-Col" cols="12">
              <edc-data-grid :dataList="tableList"></edc-data-grid>
            </v-col>
            <!-- <edc-data-grid :dataList="EnvTableList" v-if="radioGroup ==='environmental'"></edc-data-grid> -->
          </v-row>

          <!-- <v-row no-gutters align="center" class="EDC-Row">
            <v-expansion-panel focusable>
              <v-card-text>
                <v-col>
                  <template v-for="(item, index) in roleInput.service_details">
                    <v-col :key="item.text" style="padding:0px;padding-top:3px;padding-bottom:3px">
                      <v-row no-gutters>
                        <v-col cols="3">
                          <v-checkbox v-model="item.is_default" :label="item.display_name" :indeterminate="checkindeterminate(item.action_details)" :color="colorCode" class="customCheckbox" hide-details @change="selectDefaultAction(item)"/>
                        </v-col>
                        <v-col cols="9">
                          <v-row no-gutters>
                            <v-col v-for="(role, index) in item.action_details" :key="index" cols="3">
                              <v-checkbox :key="index" v-model="role.is_map" class="customCheckbox" :color="colorCode" :label="role.display_name" :value="role.is_map" @change="checkSelectAll(item.action_details, item)"/>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-divider v-if="index + 1 < roleInput.service_details.length" :key="index"/>
                  </template>
                </v-col>
              </v-card-text>
            </v-expansion-panel>
          </v-row> -->

          <v-row no-gutters class="EDC-Row EDC-ButtonRow" justify="end">
            <vc-button itemText="Save" @click.native="saveRole"></vc-button>
            <vc-button itemText="Cancel" @click.native="cancel"></vc-button>
          </v-row>

        </v-col>
      </v-card>
    </v-container>

    <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

  </div>
</template>
<script>
import { post as postToServer } from "./../../methods/serverCall.js";
import { get as getToServer } from "./../../methods/serverCall.js";
import config from "../../config.json";
import { SERVER_ERROR } from "../../data/client_message.js";
import {
  SERVICE_ACTION_DETAILS,
  SET_ROLE_ACTION
} from "../../data/url_constants.js";
import { COLOR_CODE, BTN_COLOR } from "@/data/macros.js";
import {Snackbar_Default_Timeout} from '@/constants/constants.js'

export default {
  data() {
    return {
      radioGroup: "administrator",
      outlineColor: BTN_COLOR,
      colorCode: COLOR_CODE,
      roleInput: {
        role_description: "",
        service_details: []
      },
      administratorServiceList:[],
      environmentalServiceList:[],
      service_ids_to_exculde:['scheduler','policy_service','engine_service','job_calendar','job_plan_service','process_approval_service','coordinator_service'],
      snackbar: false,
      snackbartext: "",
      colorValue: "error",
      snackbartimeout: Snackbar_Default_Timeout,
      loader: null,
      loading: false,
      selectAll: false,
      action_type: "",
      isDisabled:false,
      role_name: "Untitled Role Name",
      role_type: "administrator",
      tableList:{
        headers: [
          { text:'Service Details', value:'display_name', title:'Service Details', width:'300px'},
          { text:'All', value:'all', title:'All', useCheckbox:true, sortable:false,toggleRow:true,useIndeterminateState:false},
          { text:'View', value:'read', title:'View', useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Create', value:'create', title:'Create',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Update', value:'update', title:'Update',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Delete', value:'delete', title:'Delete', useCheckbox:true, sortable:false,toggleRow:true}
        ],
        actions: [],
        caption:'adminRole',
        sorting_type: '',
        filterType: '',
        paginationType: '',
        total_count: 0,
        rows: [],
        selected_rows:[],
        showCreateReport:true,
        disableDefaltSorting:true,
        hideItemPerPage:true,
        hideActions:true,
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        hideSelect:true,
        hideRowInfo:true,
        hidePagination:true,
        itemkey:'display_name',
        hideFilter:true,
        hideExport:true,
        hideShowColumns:true
      },
      adminList: {
        headers: [
          { text:'Service Details', value:'display_name', title:'Service Details', width:'300px'},
          { text:'All', value:'all', title:'All', useCheckbox:true, sortable:false,toggleRow:true,useIndeterminateState:false},
          { text:'View', value:'read', title:'View', useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Create', value:'create', title:'Create',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Update', value:'update', title:'Update',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Delete', value:'delete', title:'Delete', useCheckbox:true, sortable:false,toggleRow:true}
        ],
        actions: [],
        caption:'adminRole',
        sorting_type: '',
        filterType: '',
        paginationType: '',
        total_count: 0,
        rows: [],
        selected_rows:[],
        showCreateReport:true,
        disableDefaltSorting:true,
        hideItemPerPage:true,
        hideActions:true,
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        hideSelect:true,
        hideRowInfo:true,
        hidePagination:true,
        itemkey:'display_name'
      },
      EnvTableList: {
        headers: [
          { text:'Service Details', value:'display_name', title:'Service Details', width:'300px'},
          { text:'All', value:'all', title:'All',  useCheckbox:true, sortable:false,toggleRow:true,useIndeterminateState:false},
          { text:'View', value:'read', title:'View',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Create', value:'create', title:'Create',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Update', value:'update', title:'Update',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Delete', value:'delete', title:'Delete', useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Execute', value:'execute', title:'Execute',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Publish', value:'publish', title:'Publish',  useCheckbox:true, sortable:false,toggleRow:true},
          { text:'Unpublish', value:'unpublish', title:'Unpublish', useCheckbox:true, sortable:false,toggleRow:true}
        ],
        actions: [],
        sorting_type: '',
        filterType: '',
        paginationType: '',
        total_count: 0,
        rows: [],
        selected_rows:[],
        showCreateReport:true,
        disableDefaltSorting:true,
        hideItemPerPage:true,
        hideActions:true,
        hideExport:true,
        hideShowColumns:true,
        hideFilter:true,
        hideSelect:true,
        hideRowInfo:true,
        hidePagination:true,
        itemkey:'display_name',
        caption:'envRole',
      },
    };
  },
  mounted() {
    // this.tableList =_.cloneDeep(adminList)
    if(this.$route.params && this.$route.params.role_id){
    this.action_type = this.$route.params.role_type;
      this.radioGroup = this.$route.params.role_type;
      this.isDisabled=true;
    }
    this.getAllServices();
  },
  methods: {
    onRadioBtnChange(action_type) {
      this.action_type = action_type;
      this.getAllServices();
    },
    cancel () {
     this.$router.push('/rolelist');
  
    },
    checkSelectAll(action_details, item) {
      let count = 0;
      action_details.map(function(obj) {
        if (obj.is_map) {
          count++;
        }
      });
      if (count == action_details.length) item.is_default = true;
      else item.is_default = false;
    },
    checkindeterminate(action_details) {
      let found = false;
      let count = 0;
      action_details.map(function(obj) {
        if (obj.is_map) {
          found = true;
          count++;
        }
      });
      if (found && count !== action_details.length) return true;
      return false;
    },
    resetAllAction(val) {
      let _this = this;
      _this.roleInput.service_details.map(item => {
        item.isSelected = val ? true : false;
        item.action_details.map(action => {
          action.is_map = val ? true : false;
        });
      });
    },
    selectAllAction(obj) {
      obj.action_details.map(action => {
        action.is_map = obj.isSelected ? true : false;
      });
    },

    selectDefaultAction(item) {
      item.action_details.map(action => {
        action.is_map = item.is_default == true ? true : false;
      });
    },

    saveRole() {
      var _this = this
      var atleast_one_service_selected = false
      this.roleInput = {}
      var dataList = _this.tableList
      var RoleObject = {}
      var allowed_action = []
      if(this.radioGroup === 'environmental'){
        allowed_action = ["create","read","update","delete","execute","publish","unpublish"]
        RoleObject = _.cloneDeep(_this.environmentalServiceList)
      }
      else{
        allowed_action = ["create", "read", "update", "delete"]
        RoleObject = _.cloneDeep(_this.administratorServiceList)
      }

      RoleObject.service_details.forEach(function(rowdata){
        rowdata.action_details.forEach(function(obj){
          obj.is_map = false
        })
      })
      RoleObject.service_details.forEach(function(rowdata){
        var service_obj = _.find(dataList.rows,["display_name",rowdata.display_name])
        if(!service_obj)
          return
        rowdata.action_details.forEach(function(obj){
          obj.is_map = false
          if(service_obj[obj.action]){
            obj.is_map = true
            atleast_one_service_selected = true
          }
        })

      })

      console.log('RoleObject',RoleObject)

      if (!atleast_one_service_selected) {
        // this.$toasted.error("Select atleast one service");
        this.colorValue = "error";
        this.snackbartext = "Select atleast one service";
        this.snackbar = true;
        return;
      }
      if(this.$route.params.role_id)
        this.roleInput['role_id'] = this.$route.params.role_id
      this.roleInput['service_details'] = RoleObject.service_details
      this.roleInput["client_id"] = this.$session.get("client_id");
      this.roleInput["user_id"] = this.$session.get("user_id");
      this.roleInput["created_by"] = this.$session.get("email");
      this.roleInput["action_type"] = this.radioGroup;
      this.roleInput["role_name"] = this.role_name;
      this.roleInput["product_name"] = window.sessionStorage.getItem('product');
      // var client_id = this.$session.get('client_id');
      // var user_id = this.$session.get('user_id');
      let url = config.Security_URL + SET_ROLE_ACTION;
      postToServer(this, url, this.roleInput)
        .then(response => {
          this.snackbar = true;
          this.colorValue = "success";
          this.snackbartext = "Role save Successfully";
          this.$router.push("/rolelist")
        })
        .catch(DatasourceError => {
          if (DatasourceError) {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = DatasourceError;
          } else {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = SERVER_ERROR;
          }
        });
    },
    untitle_environment() {
      if (!this.role_name.trim()) {
        this.role_name = "Untitled Role Name";
      }
    },
    getAllServices() {
      // if(this.$route.params.role_type){
      //     this.action_type = this.$route.params.role_type
      // }
      var _this =this
      if(this.radioGroup === 'environmental' && this.EnvTableList.rows.length > 0){
        _this.tableList = _.cloneDeep(_this.EnvTableList)
      }
      if(this.radioGroup === 'administrator' && this.adminList.rows.length > 0){
         _this.tableList = _.cloneDeep(_this.adminList)
      }

      var request_data = {
        client_id: this.$session.get("client_id"),
        role_id: this.$route.params.role_id,
        product_name:window.sessionStorage.getItem('product'),
        action_type: this.radioGroup
      };
      this.loading = true;
      var url = config.Security_URL + SERVICE_ACTION_DETAILS;

      postToServer(_this, url, request_data)
        .then(Response => {

          // this.loading = false;
          // var data_list = []
          // var all_action = ["create", "view", "update", "delete"]
          // Response.service_details.forEach(function(rowdata){
          //   var temp_list = {}
          //   temp_list['display_name'] = rowdata.display_name
          //   var check_all = true
          //   all_action.forEach(function(action){
          //     var mapped_action = rowdata.action_details.filter((item) => item.action === action)[0]
          //     if (mapped_action){
          //       temp_list[action] = mapped_action.is_map
          //       if (mapped_action.is_map == false){
          //         check_all = false
          //       }
          //     }
          //     else{
          //        temp_list[action] =false 
          //        check_all = false
          //     }
          //   });
          //   temp_list["all"] = check_all
          //   temp_list["is_row_editable"] = true
          //   data_list.push(temp_list)
          // });
          // this.tableList.rows = data_list
          // this.tableList.rows = Response.service_details
          _this.roleInput.role_description = Response.role_description
          if(_this.$route.params.role_id){
            _this.role_name = Response.role_name
            ? Response.role_name
            : "Untitled Role Name";
          }
          if(_this.radioGroup === 'administrator'){
          _this.administratorServiceList = Response
          _this.CreateTableDataForAdminitratorRole(_.cloneDeep(Response))
          }
          else{
            _this.environmentalServiceList = Response
            _this.CreateEnvironmentalRole(_.cloneDeep(Response))
          }
        })
        .catch(error_response => {
          this.loading = false;
          if (error_response) {
            this.snackbar = true;
            this.colorValue = "error";
            this.snackbartext = error_response;
            this.loader = null;
          } else {
            this.loader = null;
            this.colorValue = "error";
            this.snackbartext = LOGIN_FAILED_MESSAGE;
            this.snackbar = true;
          }
        });
    },
    CreateTableDataForAdminitratorRole(data){
      console.log('data',data)
      var _this = this
      var dataList = []
      var allowed_action = ["create", "read", "update", "delete"]
      data.service_details.forEach(function(rowdata){
        if(_this.service_ids_to_exculde.indexOf(rowdata.service_id)>-1)
          return
        var tempRow = {"all":false,"read":false,"create":false,"update":false,"delete":false,"default_actions":[],"service_actions":[],"selected_actions":[],"is_row_editable":true}
        tempRow['display_name'] = rowdata.display_name
        rowdata.action_details.forEach(function(action_data){
          if(allowed_action.indexOf(action_data.action)>-1){
            tempRow["service_actions"].push(action_data.action)
            tempRow[action_data.action]=false
            if(action_data.is_map){
              tempRow[action_data.action]=true
              tempRow["selected_actions"].push(action_data.action)
            }

            if(action_data.is_default)
              tempRow["default_actions"].push(action_data.action)
          }
          else{
            console.log('ignoreserivce',rowdata.service_id)
            console.log('ignoreaction',action_data.action)
          }
        })
        if(tempRow['service_actions'].length > 0 && tempRow['service_actions'].length === tempRow['selected_actions'].length)
          tempRow['all']=true
        
        dataList.push(tempRow)
      })
      console.log(dataList)
      this.adminList.rows = dataList
      this.tableList = _.cloneDeep(this.adminList)
    },
    CreateEnvironmentalRole(data){
      console.log('envdata',data)
      var dataList= []
      var _this = this
      var allowed_action = ["create","read","update","delete","execute","publish","unpublish"]
      data.service_details.forEach(function(rowdata){
      // there should not be any envirnomental role for the coordinator service.Because it should get pass through the engine only. 
      if(_this.service_ids_to_exculde.indexOf(rowdata.service_id)>-1)
          return
      var tempRow = {"all":false,"read":false,"create":false,"update":false,"delete":false,"execute":false,"publish":false,"unpublish":false, "default_actions":[],"service_actions":[],"selected_actions":[],"is_row_editable":true}
      tempRow['display_name'] = rowdata.display_name
      rowdata.action_details.forEach(function(action_data){
        if(allowed_action.indexOf(action_data.action)>-1){
          tempRow["service_actions"].push(action_data.action)
          tempRow[action_data.action]=false
            if(action_data.is_map){
              tempRow[action_data.action]=true
              tempRow["selected_actions"].push(action_data.action)
            }

          if(action_data.is_default)
            tempRow["default_actions"].push(action_data.action)
        }
        else{
          console.log('ignoreserivce',rowdata.service_id)
          console.log('ignoreaction',action_data.action)
        }
      })
      if(tempRow['service_actions'].length > 0 && tempRow['service_actions'].length === tempRow['selected_actions'].length)
        tempRow['all']=true
      dataList.push(tempRow)
      console.log(dataList)
      })
      this.EnvTableList.rows = dataList
       this.tableList = _.cloneDeep(this.EnvTableList)
    }
  }
};
</script>
<style scoped>
.customCheckbox >>> label {
  top: 5px !important;
}
.v-expansion-panel >>> .v-expansion-panel__header {
  padding-bottom: 0px !important;
}

.EDC-UIComponent {
  margin: 0px !important;
  padding: 0px !important;
}

.radioClass >>> label {
  top: 3px !important;
}

.EDC-TopRow {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.EDC-ColDescription {
  margin-top: -7px !important;
}

.EDC-GridRow {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.EDC-ButtonRow {
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}
</style>