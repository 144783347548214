<template>
    <v-container class="EDC-Row">
        <v-row class="EDC-Row">
            <v-col class="EDC-Col">
                <!-- <edc-data-grid 
                 
                :id="tableObj.unique_id" 
                 @rowCopyToClipboard= "rowCopyToClipboard"                          
                @checkForDynamicMenu="checkForDynamicMenu" 
                 
                > -->
                <edc-data-grid  gridFor="pathfinder" :dataList="tableGridObj" @closeGrid="closePanel()" 
                :gridViews="gridViews" :selectedViewProp="selectedView" :accessError="accessError" :keepFirstColFixed="keepFirstColFixed"
                :keepSecondColFixed="keepSecondColFixed" :availableDatasources="datasourceForBackend" 
                @changeGridView="customizeGridView" @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" 
                @onPerPageChange="onPerPageChange" @onSort="onSort" @onClickAdvanceFilter="onClickAdvanceFilter"
                :tooltipObj="tooltipObj" @mouseEnterOnTd="loadMoreInfoOfTd" @mouseLeaveTd="mouseLeaveTd"
                @loadMediaAttachmentDetails="loadMediaAttachmentDetails" :downloadLimit="downloadLimit" 
                @onDownload="onDownload" :revisedPerPage="perPage" :allTableDescriptions="allTableDescriptions"
                @contextMenuClick="onContextMenuClick" @onRowSelected="onRowSelected">
                    
                </edc-data-grid>
            </v-col>
        </v-row>
        <loading-panel :loader="loader"></loading-panel>
        <v-snackbar v-model="snackbar" :right=true :top=true :timeout=snackbartimeout :color='colorValue'>
            {{ snackbartext }}
        </v-snackbar>

        <v-dialog v-model="showUDCViewer"  style="overflow-y:none" :width="800" eager>
            <udc-viewer :title="lookupTitle" :udcGridObj="udcGridObj" :loader="lookupLoader" 
            @getMoreUDCData="getUDCDetails" :udcHeader="lookupHeader" 
            @applyUDCFilter="applyUDCFilter" @closeUDCViewer="showUDCViewer=false;lookupLoader=false">
            </udc-viewer>
        </v-dialog>

        <v-dialog v-model="showFileViewer"  style="overflow-y:none" :width="fileViewerWidth" eager>
            <file-lookup-viewer :title="lookupTitle" :fileGridObj="fileGridObj" :loader="lookupLoader"
            @getMoreFILEData="getFILEDetails" :fileHeader="lookupHeader" @applyFILEFilter="applyFileFilter"
            :lookupFor="lookupFor" @closeFILEViewer="showFileViewer=false;lookupLoader=false">
            </file-lookup-viewer>
        </v-dialog>

        <v-dialog v-model="showValueViewer"  style="overflow-y:none" :width="300" eager>
            <value-viewer :title="lookupTitle" :valueHeader="lookupHeader" :loader="lookupLoader" 
            :lookups="valueLookupList" @applyVALUEFilter="applyVALUEFilter" 
            @closeVALUEViewer="showValueViewer=false;lookupLoader=false">
            </value-viewer>
        </v-dialog>

        <v-dialog v-model="showCustomViewer" persistent style="overflow-y:visible !important" width="850" height="800" eager>
            <customize-data-view :objectForCustomize="objectForCustomizeView" 
            @closePanel="showCustomViewer=false"  
            :selectedEnvironment='selectedEnvironment' @applyCustomization="customizeGridView"></customize-data-view>
        </v-dialog>

        <v-dialog v-model="showDocumentViewer" persistent style="overflow-y:hidden" width="600" :height="460"  eager>
            <edc-document-viewer :documentObject="edcDocumentObject" :documentList="edcDocumentList" 
            @closePanel="showDocumentViewer=false" @documentSelectionChange="loadAttachment" 
            :rowDetails="edcMediaRowDetails"></edc-document-viewer>
        </v-dialog>

    </v-container>
</template>
<style scoped>

</style>
<script>
import {getDataList,getTableObj} from './../../constants/pathfinder.js'
import { v4 as EDCuuidv4 } from 'uuid';
import { post, post as postToServer } from './../../methods/serverCall.js';
import config from '../../config.json'
import {mixinPathfinder} from '../../mixins/pathfinder.js'
import {loopupMixin} from '../../mixins/lookup.js'
import {mediaObjectMixin} from '../../mixins/mediaobject.js'
import * as JDEFormatterObj from "../../methods/JDEFormatter.js"
import * as datagridObj from '../../methods/pathfinder/datagrid.js'
import {UpdateFilter,onPageChange,onPerPageChange,onSort} from '../../methods/pathfinder/datagrid.js'
import {customizeGridView} from '../../methods/pathfinder/gridview.js'
import * as gridviewObj from '../../methods/pathfinder/gridview.js'
import * as commonFuncObj from "../../methods/commonFunc.js"
import CustomizeDataView from "./customizedataview.vue"
import * as dateFormatObj from "../../methods/DateFormat.js"


export default({
    name:"PathFinderDataGrid",
    components:{
        'customize-data-view':CustomizeDataView,
    },
    mixins:[mixinPathfinder,loopupMixin,mediaObjectMixin],
    props:{
        gridKey:{
            type:String,
            default:EDCuuidv4(),
        },
        tableRequestObject:{
            type:Object,
            default:()=>{
                return getTableObj()
            }
        },
        datasourceObject:{
            type:Object,
            default:()=>{
                return {

                }
            }
        },
        externalFilters:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        perPage:{
            type:Number,
            default:10
        },
        childrenDataList:{
            // not sure this prop is required or not.
            type:Array,
            default:()=>{
                return []
            }
        },
        securityObject:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        secondaryDatasourceId:{
            type:Number,
            default:-1
        },
        resetMergedData:{
            type:Boolean,
            default:false
        },
        openModeType:{
            type:String,
            default:'single'
        }
    },
    data(){
        return{
            tableGridObj:getDataList(),
            currentDatasource:{},
            // from below the key 'filter' is for parentFilters and filter_data for inline filters
            tableRequestOtherProps:{
                "filter": [],
                "filter_data":[],
                "sort": [],
                "page": 1,
                "page_size": 10
            },
            primaryKeys:[],
            copyOftableRequestOtherProps:{},
            mediaObjDetails:[],
            gridViews:[],
            accessError:'',
            gridviewProps:{},
            nodeFilters:[],
            selfJoins:[],
            parentSubqueryJoin:[],
            mergeTableDetails:[],
            joinForDescription:[],
            datasourceIndex:0,
            keepFirstColFixed:true,
            keepSecondColFixed:false,
            showCustomViewer:false,
            objectForCustomizeView:{},
            selectedView:'',
            contextId:'self',
            downloadLimit:"1000000",
            allTableDescriptions:[],
            childrenJoins:[],
            childrenNodeFilters:{},
            childSelfJoins:{},
            availableChildren:[],
            openedChildren:[],
            filterObj:{
                's_parentheses': '',
                'column_name': '',
                'operator': '_eq_',
                'v_type1': 'Value',
                'v_type2': 'Value',
                'value1': '',
                'value2':'',
                'e_parentheses': ''
            },
            loadChildCallTimer:null,
            serverCallDelay:1000,
            businessViewDetails:{},
            archivistObjectDetails:{},
            reportingTableDetails:{}
        }
    },
    computed:{
        isTableSelected(){
            return !_.isEmpty(this.tableRequestObject) && !_.isEmpty(this.tableRequestObject.tableName)
        },
        isEnvironmentDetailsAvailble(){
            return !_.isEmpty(this.selectedEnvironment)
        },
        isDatasourceInformationAvailble(){
            return !_.isEmpty(this.selectedDatasource)
        },
        makeADBCall(){
            return this.isTableSelected && this.isEnvironmentDetailsAvailble && this.isDatasourceInformationAvailble
        },
        tableName(){
            if(this.isTableSelected)
                return this.tableRequestObject.tableName
            return ''
        },
        objectDescription(){
            if(this.isTableSelected)
                return this.tableRequestObject.objectDescription || this.tableRequestObject.tableName
            return ''
        },
        tableProps(){
            return {
                "node_filters":this.nodeFilters, "self_join":this.selfJoins, 
                "merge_tables_detail":this.mergeTableDetails, "media_object_details":this.mediaObjDetails,
                "primary_keys":this.primaryKeys,"datasource_index":this.datasourceIndex,
                "parent_subquery_join":this.parentSubqueryJoin
            }
        },
        isBusinessViewCall(){
            return !_.isEmpty(this.businessViewDetails)
        },
        isArchivistCall(){
            return !_.isEmpty(this.archivistObjectDetails)
        },
        isArchivistWorkTableCall(){
            return this.isArchivistCall && this.archivistObjectDetails.request_for === 'worktable'
        },
        isReportingTable(){
            return this.isTableSelected && this.tableRequestObject.isReportingTable === true
        },
    },
    watch:{
        'tableRequestObject':{
            handler(newValue, oldValue){
                this.fetchTableData()
            }
        },
        'datasourceObject':{
            handler(newValue){
                this.manageDatasourceObject(newValue)
            }
        },
        'externalFilters':{
            handler(newValue){
                this.tableGridObj.rows = []
                this.tableGridObj.total_count = 0
                this.tableGridObj.actualRowCount = 0
                this.parentSubqueryJoin = []
                this.tableRequestOtherProps =_.cloneDeep(this.copyOftableRequestOtherProps)
                if(!_.isEmpty(newValue)){
                    this.getTableData(true, {}, true)
                }
            }
        },
        'perPage':{
            handler(newValue,oldValue){

            }
        },
        'securityObject':{
            handler(newValue){
                this.manageSecurity()
            }
        },
        'childrenDataList':{
            handler(newValue){
                this.tableGridObj.childrenDataList = newValue
            }
        },
        'openModeType':{
            handler(newVal){

            }
        },
        'secondaryDatasourceId':{
            handler(newVal){

            }
        },
        'resetMergedData':{
            handler(newVal){
                if(newVal){
                    var _this = this
                    setTimeout(()=>{
                        _this.getTableData(true,{"is_fresh_request":true})
                    },100)
                }
            }
        }
    },
    mounted(){
        this.tableGridObj.perPage = this.perPage
        this.tableRequestOtherProps.page_size = this.perPage
        this.copyOftableRequestOtherProps = _.cloneDeep(this.tableRequestOtherProps)
        this.manageSecurity()
        this.manageDatasourceObject(this.datasourceObject)
        this.initiateTableRequest()
    },
    methods:{
        UpdateFilter,
        onPageChange,
        onPerPageChange,
        onSort,
        customizeGridView,
        manageSecurity(){
            this.isSuperAdmin = this.securityObject.isSuperAdmin || false
            this.securityModelByEnv = this.securityObject.securityModelByEnv || []
        },
        manageDatasourceObject(datasourceObject){
            this.selectedDatasource = {}
            this.selectedEnvironment = {}
            this.dsList = []
            this.datasourceForBackend = []
            this.accessError = ''
            if(!_.isEmpty(datasourceObject)){
                if(datasourceObject.selectedDatasource)
                    this.selectedDatasource = datasourceObject.selectedDatasource
                if(datasourceObject.selectedEnvironment)
                    this.selectedEnvironment = datasourceObject.selectedEnvironment
                if(datasourceObject.dsList)
                    this.dsList = datasourceObject.dsList
                if(datasourceObject.datasourceForBackend)
                    this.datasourceForBackend = datasourceObject.datasourceForBackend
            }
        },
        initiateTableRequest(counter=0){
            var _this = this
            let tableData = getDataList()
            tableData['itemkey'] = 'rn'
            _this.tableGridObj = tableData
            _this.tableRequestOtherProps = _.cloneDeep(_this.copyOftableRequestOtherProps)
            if(!_this.makeADBCall){
                if(counter === 2){
                    alert("Required information for making a database call is missing.")
                    return
                }
                counter++
                setTimeout(()=>{
                    _this.initiateTableRequest(counter)
                },500)
            } 
            else
                _this.fetchColumnsDetails()
        },
        getCommonRequestData(requestSpecificData={},addDSInfo=true){
            var _this = this
            let requestData = {}
            requestData['client_id'] = _this.clientId
            requestData['env_id'] = _this.selectedEnvironment.id
            requestData['table_name'] = _this.tableName
            requestData['is_super_admin'] = _this.isSuperAdmin
            requestData['security_model'] = _this.securityModelByEnv
            requestData['open_type'] = _this.openModeType
            requestData['secondary_datasource_id'] =_this.secondaryDatasourceId
            if(_this.currentDatasource && _this.currentDatasource.db_type)
                requestData['db_type'] = _this.currentDatasource.db_type
            if(addDSInfo){
                requestData['ds_info'] = _this.currentDatasource
                requestData['all_available_datasources'] = this.datasourceForBackend
                requestData['user_id'] = this.userId
            }
            Object.assign(requestData,requestSpecificData)
            return requestData
        },
        manageExternalFilter(){
            var newValue = this.externalFilters
            this.tableRequestOtherProps['urlToCall'] = '/get_table_data'
            if(!_.isEmpty(newValue)){
                this.nodeFilters = newValue.nodeFilters
                this.selfJoins = newValue.selfJoins
                if(newValue.code===400){
                    // It means there is not all join columns in the parent table.We have to create subquery
                    this.tableRequestOtherProps['load_parent_filters'] = true
                    this.tableRequestOtherProps['parent_details'] = newValue.parent_details
                    this.tableRequestOtherProps['join'] = newValue.join
                    if(newValue.parent_details.isBusinessViewCall){
                        this.tableRequestOtherProps['urlToCall'] ='/get_table_data_with_inner_query'
                    }
                }
                else{
                    this.tableRequestOtherProps['filter'] = newValue.parentFilters
                }
            }
        },
        fetchColumnsDetails(){
            var _this = this
            _this.datasourceIndex = _this.tableRequestObject.datasourceIndex
            _this.currentDatasource = _this.datasourceForBackend[_this.datasourceIndex]
            _this.mergeTableDetails = _this.tableRequestObject.mergeTableDetails
            _this.nodeFilters = _this.tableRequestObject.nodeFilters || []
            _this.businessViewDetails = _this.tableRequestObject.business_view_details
            _this.archivistObjectDetails = _this.tableRequestObject.archivistObj
            var requestData = _this.getCommonRequestData(_.cloneDeep(_this.tableRequestObject))
            let endPointToCall = '/get_columns_details'
            if(_this.isBusinessViewCall){
                endPointToCall = '/get_columns_details_for_business_view'
                requestData['tables_details'] = _this.businessViewDetails.tables_details
                requestData['business_view_columns'] = _this.businessViewDetails.business_view_columns
            } else if(_this.isArchivistWorkTableCall || _this.isReportingTable){
                endPointToCall = '/fetch_worktable_columns'
                requestData['archivist_obj'] = _this.archivistObjectDetails
                requestData['is_reporting_table'] = _this.isReportingTable
            }
            var new_mainlist_obj = _this.tableGridObj
            new_mainlist_obj.perPage = _this.perPage
            _this.loader = true
            postToServer(_this, config.DATA_VIEWER_URL+endPointToCall,requestData).then(response=>{
                _this.loader = false
                var columnList = response.column_details
                _this.reportingTableDetails = response.reporting_table_details

                _this.primaryKeys = _.map(response.primary_keys,"column_name")
                _this.mediaObjDetails = response.media_details
                datagridObj.applyPrimaryTableProps(_this, new_mainlist_obj, columnList, requestData)
                _.forEach(columnList,function(colObj){
                    colObj = JDEFormatterObj.formatColumn(colObj,true,colObj['column_db_type'])
                    if(colObj.hasOwnProperty('datasource_index') === false)
                        colObj['datasource_index'] = _this.datasourceIndex
                })
                new_mainlist_obj.headers = columnList
                new_mainlist_obj.all_headers = _.cloneDeep(columnList)
                new_mainlist_obj.datasourceIndex = _this.datasourceIndex
                if(_this.tableRequestObject.associatedBusinessObjectName){
                    new_mainlist_obj.associatedBusinessObjectName = _this.tableRequestObject.associatedBusinessObjectName
                    new_mainlist_obj.associatedBusinessObjectId = _this.tableRequestObject.associatedBusinessObjectId
                }
                gridviewObj.getGridViewList(_this)
                _this.getTableData(true,{"is_fresh_request":true})
                setTimeout(()=>{
                    _this.getChildrenDetails()
                },1000)
                let tableDescription = _this.tableRequestObject.tableDescription || columnList[0].table_description
                _this.tableGridObj.tableDescription = _this.createTableHeading(_this,tableDescription,_this.tableName, _this.mergeTableDetails)
                
            }).catch(error=>{
                _this.loader = false
                // _this.closePanel(error)
                _this.accessError = error
            })
            _this.getDownloadLimit()
        },
        getTableData(fetch_count=true, otherProps={}, load_recursively=false){
            /* 
            :fetch_count = we dont need to call count every time.We will call count only if
                1. Is new call
                2. On any kind of filters changed
            */
            var _this = this
            var new_mainlist_obj = _this.tableGridObj
            this.manageExternalFilter()
            Object.assign(otherProps,_.cloneDeep(_this.tableRequestOtherProps))
            Object.assign(otherProps,_.cloneDeep(_this.tableProps))
            Object.assign(otherProps,_.cloneDeep(_this.gridviewProps))
            var requestData = _this.getCommonRequestData(otherProps)
            requestData['all_columns'] = new_mainlist_obj.all_headers
            requestData['column_details'] = new_mainlist_obj.headers
            requestData['context_id'] = _this.contextId
            requestData['is_business_view_req'] = this.isBusinessViewCall
            requestData['business_view_details'] = this.businessViewDetails
            var is_fresh_request = otherProps.is_fresh_request || false
            var is_grid_view_selection_changed = otherProps.is_grid_view_selection_changed || false
            _this.loader = true
            var requestSentAt = new Date()
            postToServer(_this,config.DATA_VIEWER_URL +this.tableRequestOtherProps['urlToCall'],requestData).then(response=>{
                let responseReceivedAt=new Date()
                _this.loader = false
                if(is_fresh_request || is_grid_view_selection_changed){
                    let columnList = response.columns_to_show_on_grid
                    _.forEach(columnList,function(colObj){
                        if(!colObj.is_temp_col)
                            return
                        colObj = JDEFormatterObj.formatColumn(colObj,true,colObj['column_db_type'] || _this.selectedDatasource.db_type)
                        if(colObj.hasOwnProperty('datasource_index') === false)
                            colObj['datasource_index'] = _this.datasourceIndex
                    })

                    new_mainlist_obj.headers = response.columns_to_show_on_grid
                    gridviewObj.setGridviewProps(_this,response,new_mainlist_obj)
                }
                new_mainlist_obj.rows = response.data

                if(response.data.length)
                    new_mainlist_obj.total_count = response.data[0].total_count
                else
                    new_mainlist_obj.total_count = 0
                
                if(is_fresh_request || fetch_count){
                    new_mainlist_obj['actualRowCount'] = new_mainlist_obj.total_count
                    if(response.page_limit > new_mainlist_obj.total_count){
                        new_mainlist_obj['showPlus'] = false
                    } else{
                        new_mainlist_obj['showPlus'] = true
                        // call fetch count here
                        datagridObj.applyTableProps(_this,response)
                        _this.getRecordCount(requestData)
                    }
                    _this.getSummaryData(requestData)
                }
                datagridObj.showResponseTime(response.query_executed_in, requestSentAt, responseReceivedAt, new_mainlist_obj)
                _this.loadMediaObjectDetails()

                if(load_recursively && new_mainlist_obj.rows.length){
                    _this.loadChildList(_.cloneDeep(_this.childrenDataList), new_mainlist_obj.rows, new_mainlist_obj.headers)
                }
            }).catch(error=>{
                _this.loader = false
                _this.showError(error)
            })

        },
        assignGridviewProps(response){
            var _this = this
            _this.gridviewProps = _.cloneDeep(response.gridview_props)
        },
        getChildrenDetails(){
            var _this = this
            var requestData = {
                "env_id":_this.selectedEnvironment.id,
                "is_for_table":true,
                "datasource_id":_this.currentDatasource.business_data.datasource_id,
                "object_id":_this.tableName,
                "object_name":_this.tableName,
                "is_from_ui":true,
                "client_id":_this.clientId,
                "datasource_index":_this.tableGridObj.datasourceIndex,
                "unique_id":_this.tableRequestObject.uniqueId,
                "all_ds_info":_this.datasourceForBackend
            }
            if(this.isBusinessViewCall){
                if(_.isEmpty(this.businessViewDetails.drilldown_details))
                    return
                requestData['request_for'] = "BV"
                requestData['drilldown_details'] = this.businessViewDetails.drilldown_details
            }
            else if(this.isArchivistWorkTableCall){
                /* drilldown for the dataitem search step table is not implemented bcoz its not straighforward
                    that menu option we have to show on the basic on the 
                        
                        selected rows
                        column selected for the search (alias vs all related aliases vs custom aliases)
                        If primary keys are selected
                        If counts are selected

                    Need to discuss the flow before implementation
                */
                requestData['request_for'] ='WRKTBL'
                requestData['all_current_table_columns'] = _this.tableGridObj.all_headers
                requestData['archivist_obj'] = _this.archivistObjectDetails 
            }
            else if(this.isReportingTable){
                requestData['request_for'] ='RTTBL'
                requestData['all_current_table_columns'] = _this.tableGridObj.all_headers
                requestData['reporting_table_details'] = _this.reportingTableDetails
            }
            postToServer(_this, config.DATA_VIEWER_URL+'/get_children_for_table',requestData).then(response=>{
                if(response && response.joins){
                    _this.childrenJoins = response.joins
                    _this.availableChildren = response.menu_items
                    _this.tableGridObj.children = _this.availableChildren
                    _this.childrenNodeFilters = response.node_filters
                    _this.childSelfJoins = response.self_joins
                    _this.fetchtableDescriptions(response.all_referenced_tables)
                }
            }).catch(error=>{
                
            })
        },
        getRecordCount(requestData){
            var _this = this
            var requestData = _this.getCommonDataForCountAndSummary(requestData)
            requestData["db_object_type"] = _this.tableRequestObject.objectType
            postToServer(_this,config.DATA_VIEWER_URL +'/get_record_count',requestData).then(response=>{
                _this.tableGridObj.actualRowCount = response.data.count_1
                _this.tableGridObj['showPlus'] = false
            }).catch(error=>{
                _this.showError(error)
            })
        },
        getSummaryData(requestData){
            var _this = this
            if(!this.gridviewProps.show_summary_row)
                return
            var requestData = _this.getCommonDataForCountAndSummary(requestData)
            postToServer(_this,config.DATA_VIEWER_URL +'/get_summary_row',requestData).then(response=>{
                _this.tableGridObj['summaryFooter'] = response.data
            }).catch(error=>{
                _this.showError(error)
            })
        },
        getCommonDataForCountAndSummary(requestData){
            var _this = this
            Object.assign(requestData,_.cloneDeep(_this.tableRequestOtherProps))
            Object.assign(requestData,_.cloneDeep(_this.tableProps))
            Object.assign(requestData,_.cloneDeep(_this.gridviewProps))
            // headers get changed based on the column selection
            requestData['column_details'] = _this.tableGridObj.headers
            return requestData
        },
        closePanel(error){
            this.$emit('closePanel', this.tableRequestObject.uniqueId,error)
        },
        getColumnValueInProperCase(column_name,db_type){
            if(!db_type)
                db_type = this.currentDatasource.db_type
            if(db_type === 'oracle' || db_type === 'db2i')
                return column_name.toLowerCase()
            return column_name.toUpperCase()
        },
        onDownload(downloadParams,selectedRows){
            var _this = this
            let createFilterUsingRows = false
            if(downloadParams.recordType === 'selected_rows'){
                createFilterUsingRows = true
            }
            let objectId = this.tableName
            let objectName = this.objectDescription
            var requestData = _this.getCommonRequestData({})
            Object.assign(requestData,_.cloneDeep(_this.tableRequestOtherProps))
            Object.assign(requestData,_.cloneDeep(_this.tableProps))
            Object.assign(requestData,_.cloneDeep(_this.gridviewProps))
            requestData['all_columns'] = _this.tableGridObj.all_headers
            requestData['column_details'] = _this.tableGridObj.headers
            requestData['context_id'] = _this.contextId
            requestData['is_business_view_req'] = this.isBusinessViewCall
            requestData['business_view_details'] = this.businessViewDetails

            // download specific request
            requestData['download_props'] = downloadParams
			requestData['associate_object_id'] = objectId
			requestData['associate_object_name'] = objectName
			requestData['create_filter_using_row'] = createFilterUsingRows
            requestData['selected_rows'] = selectedRows
            requestData['open_type'] = _this.openModeType
            _this.loader = true
            postToServer(_this, config.DATA_VIEWER_URL + '/download_pathfinder_data', requestData).then(response => {
                _this.loader = false
            }).catch(error=>{
                _this.loader = false
                _this.showError(error)
            })
            setTimeout(function(){
                _this.loader = false
                _this.snackbar = true
                _this.colorValue = 'success'
                _this.snackbartext = 'Your request has been successfully submitted. Please check download status under My Download menu.'
            },1000)
        },
        getDownloadLimit(){
            var _this = this
            let requestData = {"client_id":this.clientId}
            postToServer(_this,config.DATA_VIEWER_URL+'/get_download_options',requestData).then(response=>{
                this.downloadLimit = response.rounded_limit_in_mil
            }).catch(error=>{

            })
        },
        createTableHeading(_this, tableDescription,tableName,mergeTableDetails){
            if(this.isBusinessViewCall){
                return this.businessViewDetails.business_view_name + " ("+this.businessViewDetails.business_view_id+")"
            }
            if(this.isArchivistWorkTableCall){
                return this.archivistObjectDetails.output_table_reference +" ("+this.archivistObjectDetails.step_type+")"
            }
            if(!tableDescription){
                tableDescription = tableName
            }
            else
                tableDescription = tableDescription+ "("+_this.tableName+")"

            if(mergeTableDetails && mergeTableDetails.merged_tables){
                for(let i=0;i<mergeTableDetails.merged_tables.length;i++){
                    let curentTable = mergeTableDetails.merged_tables[i]
                    tableDescription = tableDescription+" / "
                    if(curentTable.table_description)
                        tableDescription = tableDescription+curentTable.table_description+" ("+curentTable.table_name+")"
                    else
                    tableDescription = tableDescription+" ("+curentTable.table_name+")"
                }
            }
            return tableDescription
        },
        fetchtableDescriptions(tableList){
            let _this = this
            var job_data = {
                "client_id":_this.clientId,"env_id":this.selectedEnvironment.id,
                "object_librarian_data":this.currentDatasource.object_librarian_data,
                "acceleration_type":this.currentDatasource.acceleration_type,"table_list":tableList
            }
            let url = config.AGENT_API_URL +'/get_table_description'
            postToServer(_this, url,job_data).then(response => {
                if(response)
                    _this.allTableDescriptions = _this.allTableDescriptions.concat(response)
                
            }).catch(JobStepError => {

            })
        },
        loadChildList(childDataList,selectedRows, currentHeaders){
            let childList = []
            childDataList.forEach((obj)=>{
                let tableRequestObject = obj.tableRequestObject
                tableRequestObject.table_name = tableRequestObject.tableName
                tableRequestObject.node_id = tableRequestObject.uniqueId
                childList.push(tableRequestObject)
            })
            this.onContextMenuClick(childList, selectedRows, currentHeaders)
        },
        onContextMenuClick(childrenToLoad, selectedRows, currentHeaders, loadType){
            var _this =this
            // clearInterval(_this.loadChildCallTimer)
            debugger;
            if(!childrenToLoad || !childrenToLoad.length)
                return
            for(let i=0;i<childrenToLoad.length;i++){
                let currentTable = childrenToLoad[i]
                let tableName = currentTable.table_name || currentTable.tableName
                let nodeFilters = _this.childrenNodeFilters[currentTable.node_id] || []
                let selfJoins = _this.childSelfJoins[currentTable.node_id] || []

                let joinProps = _this.getJoinProps(currentTable.node_id, tableName, currentHeaders)
                let parentFiltersJson = {}
                if(joinProps.code === 404){
                    continue
                } else if(joinProps.code === 400){
                    parentFiltersJson = {
                        "code":400,
                        "parent_details":{
                            "headers":this.tableGridObj.headers,
                            "input_json":this.tableRequestOtherProps,
                            "view_filter":this.gridviewProps.view_filters,
                            "view_having":this.gridviewProps.view_having,
                            "table_name":this.tableName,
                            "selected_rows":selectedRows,
                            "datasource_index":this.tableGridObj.datasourceIndex,
                            "join":joinProps.join,
                            "isBusinessViewCall":this.isBusinessViewCall,
                            "businessViewDetails":this.businessViewDetails,
                            "gridviewProps":this.gridviewProps
                        },
                        "nodeFilters":nodeFilters,
                        "selfJoins":selfJoins
                    }
                } else{
                    // here assumption is allJfromCols,allJtoCols,conditionObj will have same column sequence
                    let parentFilters = []
                    let allJfromCols = joinProps.allJfromColumns
                    let allJtoCols = joinProps.allJtoColumns
                    let conditionObj = joinProps.condition
                    let jfromColumnObjects = _this.getColumnObjectFromAllHeader(allJfromCols,currentHeaders)
                    if(jfromColumnObjects.length){
                        for(let i=0;i<jfromColumnObjects.length;i++){
                            let filterObj = _this.createFilter(selectedRows, jfromColumnObjects[i], allJtoCols[i],'_in_',conditionObj[i])
                            if(i === jfromColumnObjects.length-1)
                                delete filterObj['operation']
                            parentFilters.push(filterObj)
                        }
                        // console.log('parentFilters', parentFilters)
                        // now we need to emit the childDataList Event from here
                    }
                    parentFiltersJson = {"parentFilters":parentFilters,"code":2000, "nodeFilters":nodeFilters, "selfJoins":selfJoins}
                }
                if(!_.isEmpty(parentFiltersJson))
                    this.$emit('loadMyChild', currentTable.node_id,currentTable,parentFiltersJson,loadType)
            }
        },
        onRowSelected(selectedRows,loadedChilds,parentHeader){
            var _this = this
            clearInterval(_this.loadChildCallTimer)
            ///// clearMyChildren causing the issue for reloading the child. 
            // thats why I am clearing the child once parentFiltered get refrehsed in containr
            // this.$emit('clearMyChildren', this.tableRequestObject.uniqueId)

            /////  commented below code, we dont want load data unless user exeplictily click on the menu
            // if(selectedRows && selectedRows.length && loadedChilds.length){
                // _this.loadChildCallTimer = setTimeout(function(){
                //     _this.loadChildList(loadedChilds, selectedRows, parentHeader)
                // },_this.serverCallDelay)
            // } 
        },
        createFilter(allRows, columnObj, columnNameForFilter, operator='_in_', conditionObj={}, filterObj={}){
            // this function will create. This function designed in a way where i will create IN filter using column value
            // allRows should be an array object
            // columnObj.column_name & columnNameForFilter might be different because in case of join they might be different
            var _this = this
            if(_.isEmpty(filterObj))
            {
                filterObj = _.cloneDeep(this.filterObj)
                // here assumption is if user passed filter object it means there are predefined values in filter
                filterObj['column_name'] = columnNameForFilter
                filterObj['operator'] = operator
                filterObj['s_parentheses'] = '('
                filterObj['e_parentheses'] = ')'
            }
            let valueArray = []
            for(let i=0;i < allRows.length; i++){
                let currentRow = allRows[i]
                let value1 = currentRow[_this.getColumnValueInProperCase(columnObj.column_name,columnObj.database_type)]
                if(value1 === null){
                    value1 = "0"
                }
                else{
                    if (conditionObj){
                        // here we will check the datatype of to and from column if that not match then we need to
                        // write conversion.
                        // Write now we will do the conversion if jfrom (parent col) is number and jto is character
                        if(conditionObj.jfrom_datatype != conditionObj.jto_datatype){
                            if(commonFuncObj.isNumericDataType(conditionObj.jto_datatype)){
                                value1 = commonFuncObj.removeNonNumericText(value1)
                            }
                        }
                    }
                    if(columnObj.is_jde_date){
                        value1 = dateFormatObj.convertDateToJde(value1,this.$session.get('UI_date_format'))
                    }
                    else if(commonFuncObj.isNumericDataType(columnObj.data_type))
                    {
                        value1 = commonFuncObj.replaceText(value1)
                    }
                }
                if(valueArray.indexOf(value1) === -1)
                    valueArray.push(value1)
            }
            filterObj['operation'] = false
            if(operator === '_in_'){
                filterObj['value1'] = valueArray
            }
            else
            filterObj['value1'] = valueArray[0]
            
            return filterObj
        },
        getJoinProps(nodeId, tableName, currentHeaders){
            var _this = this
            let join = _.find(_this.childrenJoins,(obj)=>{
                return obj.jto_id === nodeId && obj.jto === tableName
            })
            if(!join){
                return {"code":404}
            }
            let allJfromColumns = _.map(join.condition,"from_column")

            let allOnlyHeaders = _.map(currentHeaders,"column_name")

            let allJtoColumns = _.map(join.condition,"to_column")

            if(!allJfromColumns.every(item=> allOnlyHeaders.includes(item))){
                return {"code":400,"join":join}
            }
            return {"condition":join.condition, "allJfromColumns":allJfromColumns, "allJtoColumns":allJtoColumns}
        },
        getColumnObjectFromAllHeader(columns, allHeaders){
            let columnObjects = []
            for(let i=0;i<columns.length;i++){
                let colObj = _.find(allHeaders, ["column_name",columns[i]])
                if(colObj){
                    columnObjects.push(colObj)
                }
                // exepecting all columns will be in the allHeader that why not writting else FOR NOW
            }
            return columnObjects
        },
        getInnerQueryForDrillDown(){

        }
    }
})
</script>