/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'New_old1': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" cx="32.063" cy="32" r="28"/><rect pid="1" width="8" height="40" x="28" y="12" rx="4" ry="4" _fill="#fff"/><rect pid="2" y="-52" x="28" height="40" width="8" transform="rotate(90)" rx="4" ry="4" _fill="#fff"/>'
  }
})
