<template>
	<v-card class="rounded-card">
		<v-row class="EDC-Row">
			<v-toolbar dense flat  class="EDC-Toolbar" elevation="1" style="border-radius:0px !important;">
				<v-toolbar-title>{{title +' search'}}</v-toolbar-title>
			 <v-spacer></v-spacer>
                <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
			</v-toolbar>
		</v-row>
		<v-row class="EDC-Row" style="padding-top:8px !important;" v-if="errorInDataFetch" self-align="center" justify="center">
		{{errorInDataFetch}}
		</v-row>
		<v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:8px !important;">
			<v-col class="EDC-Col">
				<edc-data-grid gridFor="FileLookup" @onRowSelected="onRowSelected" :dataList="FILEDataList" @UpdateFilter="UpdateFilter" @onPageChange="onPageChange" @onPerPageChange="onPerPageChange" @radioSelectionChange="onRowSelected" @onSort="onSort"></edc-data-grid>
			</v-col>
		</v-row>
		<v-row class="EDC-Row EDC-RowsSpacing">
			<v-col class="EDC-Col" self-align="center">
				<vc-button style="margin-top:5px;" :disabled="!selectedRows.length" itemText="Select" @click.native="applyFILEFilter()"></vc-button>
			</v-col>
		</v-row>
	    <loading-panel :loader="loader"></loading-panel>

	</v-card>
</template>
<script>
	import {CLIENT_SIDE,SERVER_SIDE,EDC_GRID_PROPS} from '../data/macros.js'
	export default {
		name:'FILELookupViewer',
		props:{
			title:{
				type:String,
				default:''
			},
			firstHeader:{
				type:String,
				default:''
			},
			loader:{
				type:Boolean,
				default:false
			},
			rows:{
				type:Array,
				default:()=>{
					return []
				}
			},
			fileHeader:{
				type:Object,
				default:()=>{
					return {}
				}
			},
			gridUniqueID:{
				type:String
			},
			fileGridObj:{
				type:Object
			},
			lookupFor:{
				type:String,
				default:'FILE'
			},
			errorInDataFetch:{
				type:String,
				default:''
			}
		},
		data(){
			return {
				FILEDataList:{
					headers:[],
			      	itemkey:'rn',
			      	rows:[],
			      	total_count:0,
			      	hideCaption:true,
			      	tableLoading:true,
			      	selected_rows:[],
			      	tableDescription:'',
			      	sorting_type: SERVER_SIDE,
			      	filterType: SERVER_SIDE,
			      	paginationType: SERVER_SIDE,
			      	is_in_tab:true,
			      	hideToggle:true,
			      	hideActions:true,
			      	hideFilter:true,
			      	hideExport:true,
			      	hideShowColumns:true,
			      	hideSelect:true,
			      	showRadio:true,
			      	useWrapping:false
				},
				selectedRows:[],
				gridProps:{
			      "filter": [],
			      "filter_data":[],
			      "sort": [],
			      "page": 1,
			      "page_size": 10
			    },
			    tableHeader:[],
			}
		},
		watch:{
			'title':{
				handler(newValue){
					this.errorInDataFetch = ''
				},
			},
			'lookupFor':{
				handler(newValue){
					this.errorInDataFetch = ''
				}
			},
			'loader':{
				handler(newValue){

				}
			},
			'errorInDataFetch':{
				handler(newValue){

				}
			},
			fileGridObj:{
				handler(newValue){
					if(newValue && newValue.rows){
						if(!this.tableHeader.length){
							this.FILEDataList.headers = newValue.headers
							this.tableHeader = newValue.headers
							this.gridProps = _.cloneDeep(EDC_GRID_PROPS)
						}
						this.FILEDataList.total_count = newValue.total_count
						this.FILEDataList.rows = newValue.rows
					}
					else{
						this.FILEDataList.headers =[]
						this.FILEDataList.total_count = 0
						this.FILEDataList.rows = []
						this.tableHeader = []
						this.gridProps = _.cloneDeep(EDC_GRID_PROPS)
					}
					

				}
			},
			gridUniqueID:{
				handler(newValue){
					
				}
			},
			fileHeader:{
				handler(newValue){
					this.gridProps =_.cloneDeep(EDC_GRID_PROPS)
				}
			}
		},
		mounted(){
			// this.loader = true
		},
		methods:{
			UpdateFilter(filters){
				this.gridProps['filter'] = filters
				this.$emit('getMoreFILEData',this.fileHeader,this.gridProps)
			},
			onRowSelected(selectedRow){
				this.selectedRows = []
				if(selectedRow && (selectedRow.rn || selectedRow.total_count))
					this.selectedRows =_.cloneDeep([selectedRow])
			},
			applyFILEFilter(){
				this.$emit('applyFILEFilter',this.selectedRows,this.fileHeader.column_name,this.fileGridObj.column_to_pass,this.gridProps)
			},
			onPageChange(pageNumber,perPage){
				this.gridProps['page'] = pageNumber
				this.gridProps['page_size'] = perPage
				this.$emit('getMoreFILEData',this.fileHeader,this.gridProps)
			},
			onPerPageChange(perPage,pageNumber){
				this.gridProps['page'] = pageNumber
				this.gridProps['page_size'] = perPage
				this.$emit('getMoreFILEData',this.fileHeader,this.gridProps)
			},
			onSort(sortJson){
				this.gridProps['sort'] = sortJson
				this.$emit('getMoreFILEData',this.fileHeader,this.gridProps)
			},
			closePanel(){
				this.$emit('closeFILEViewer')
			}
		}
	}
</script>
<style>
	.v-toolbar{
		height:24px;
	}
	.v-toolbar__content{
		height:24px;
	}
	.v-toolbar__title {
		font-size:15px;
	}
</style>