<template>
    <div>
        <v-row class="EDC-Row">
            <v-col cols="10" class="EDC-Col">
                <v-row class="EDC-Row">
                    <v-col cols="12">
                        <ul class="breadcrumb">
                            <li v-bind:style="{ 'margin-top': '4px !important' }">Clear Cache For</li>
                            <li v-bind:style="{ 'margin-top': '4px !important' }">
                                <select v-model="Cache" v-on:change="getAvailableDs()" style="-webkit-appearance: menulist" autofocus ref="cache">
                                    <option :value="{}">Select Option</option>
                                    <option v-for="(ds, index) in DatasourceList" :value="ds" :key="index">{{ds.datasource_name}}</option>
                                </select>
                            </li>
                            <vc-button itemText="Cache for client" @click.native="onDelete()" v-bind:style="{ 'margin-top': '0px !important' }"></vc-button>
                            <vc-button itemText="Cache for Datasource" @click.native="onDelete()" :disabled="disableAction()" v-bind:style="{ 'margin-top': '0px !important' }"></vc-button>
                        </ul>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <edc-data-grid v-if="Cache.datasource_id == '0'" key="objgrid1" :dataList="zeroIdList" @onDelete="onDelete" ></edc-data-grid>
        <edc-data-grid v-else key="objgrid2" :dataList="tableList" @onDelete="onDelete"></edc-data-grid>
        
        <loading-panel :loader="loader"></loading-panel>

        <v-snackbar v-model="snackbar" :color="colorValue" :timeout="snackbartimeout" id="edcmessage" top right>{{ snackbartext }}</v-snackbar>

    </div>
</template>

<script>
import config from '../../config.json'
import { post as postToServer } from './../../methods/serverCall.js';
import { get as getToServer } from './../../methods/serverCall.js';
import {Snackbar_Default_Timeout} from '@/constants/constants.js'
export default {
    name: 'DemoGrid',
    data: function () {
        return {
            DatasourceList:[],
            Cache: {},
            userRole: {},
            snackbar:false,
            snackbartext:'',
            loader: false,
            colorValue:'success',
            snackbartimeout: Snackbar_Default_Timeout,
            zeroIdList: {
                headers: [
                    { text: 'Datasource Name', value: 'datasource_name', sortable: false, width: '100%', options:[], cssClass:'w16', title:'Datasource Name' },
                ],
                actions: [],//if we need conditional action in row then provide key
                // sorting_type: SERVER_SIDE,
                // filterType: SERVER_SIDE,
                // paginationType: SERVER_SIDE,
                total_count: 0,
                rows: [],
                itemkey:'datasource_name',
                select_rows:true,
                selected_rows:[],
                disableDefaltSorting:true,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true
            },
            tableList: {
                headers: [
                    { text: 'Table Name', value:'tbl_name', sortable: false, width: '50%', options:[], cssClass:'w16', title:'Table Name' },
                    { text: 'Cache For', value:'col_name', sortable: false, width: '50%', options:[], cssClass:'w16', title:'Cache For' },
                ],
                actions: [],//if we need conditional action in row then provide key
                // sorting_type: SERVER_SIDE,
                // filterType: SERVER_SIDE,
                // paginationType: SERVER_SIDE,
                total_count: 0,
                rows: [],
                itemkey:'id',
                select_rows:true,
                selected_rows:[],
                disableDefaltSorting:true,
                hideFilter:true,
                hideExport:true,
                hideShowColumns:true
            }
        }
    },
    mounted(){
        this.userRole = this.$session.get('user_role_mapping');
        this.zeroIdList.actions = [{'text':'delete','key':'datasource_name', selectType:"multiple",role:this.userRole.is_superadmin,index:1}]
        this.tableList.actions = [{'text':'delete','key':'id', selectType:"multiple",role:this.userRole.is_superadmin,index:1}]
        this.getAvailableDs();
        this.getDatasourceList();
    },
    methods:{
        getAvailableDs(){
            this.getDatasourceList();
            if (this.Cache.datasource_id != '0' && this.Cache.datasource_id != null){
                this.get_tables(this.Cache.datasource_id)
            }
        },
        getDatasourceList(){
			    var data_source_endpoint = config.DATA_SOURCE_URL + '/get_datasource_by_client/'+this.$session.get('client_id')
			    getToServer(this, data_source_endpoint, true).then(datasourceResponse => {
                    var rows = []
			    	datasourceResponse.forEach((obj)=>{
                        this.DatasourceList.push(obj.datasource_info)
                        rows.push(obj.datasource_info)
                    })
                    this.zeroIdList.rows = rows
                    this.zeroIdList.total_count = length
			    }).catch(error_response => {
			        
			    });
            },
        get_tables(ds_id){
            var data_to_send = {
                'client_id': this.$session.get('client_id'),
                'ds_id': ds_id
            }
            var url = config.EDC_CACHE_SERVICE_URL + '/get_tables_for_ds'
            postToServer(this, url, data_to_send, true).then(tableresponse =>{
                var data = tableresponse
                if(data){
                    this.tableList.rows = data
                }
            })
        },
        onDelete:function(record){
            let _this = this;
            _this.loader = true;
            var request_data = {'data_list': record, 'ds_id': _this.Cache.datasource_id, 'client_id': _this.$session.get('client_id')}
            var url = config.EDC_CACHE_SERVICE_URL + '/clean_table_cache'
            postToServer(this, url, request_data, true).then(cacheresponse =>{
                if (cacheresponse){
                    _this.loader = false
                    this.snackbar = true,
                    this.colorValue = 'success',
                    this.snackbartext = cacheresponse
                    if (record && 'tbl_name' in record[0]){
                        this.get_tables(_this.Cache.datasource_id)
                    }
                    else{
                        this.getAvailableDs()
                    }
                }
                else{
                     _this.loader = false
                    this.snackbar = true,
                    this.colorValue = 'error',
                    this.snackbartext = cacheresponse
                }
            }).catch(error_response => {
                _this.loader=false
                this.snackbar = true,
                this.colorValue = 'error',
                this.snackbartext = error_response
            })
        },
        disableAction(){
            if (this.Cache.datasource_id){
                return false
            }
            else{
                return true
            }
        }
    }
}
</script>
