/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'columns_show_hide_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" d="M1.588 8.467h.794v.794h-.794z"/><path pid="1" d="M1.786 7.013l-.992 1.85.992 1.854M4.564 7.013l.992 1.85-.992 1.854" _fill="none" _stroke="#000" stroke-width=".529"/><path pid="2" d="M2.778 8.467h.794v.794h-.794zM3.969 8.467h.794v.794h-.794z"/><path pid="3" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M.596 2.051v3.77h1.588V2.05zm.133.131H2.05v3.506H.729zM4.168 2.051v3.77h1.586V2.05zM2.38 2.051v3.77H3.97V2.05zM3.582.371l-.535.762-.332-.26-.164.207.443.348a.132.132 0 00.19-.03l.615-.874zM5.37.371l-.536.762L4.5.873l-.162.207.441.348a.132.132 0 00.19-.03l.615-.874z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
