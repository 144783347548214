var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{EDCHeaderContainer:!_vm.isGrayTheme,EDCHeaderContainerGray:_vm.isGrayTheme}},[_c('v-row',{staticClass:"mb-12 topLines",attrs:{"align":"center","dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"EDC-Row"},[(!_vm.isGrayTheme)?_c('v-col',{staticClass:"EDC-PageHeader primary--text",staticStyle:{"text-align":"left !important"},attrs:{"cols":"3"},on:{"click":function($event){_vm.routeUrl('/edcpathfinder');_vm.isSelected=''}}},[_c('span',{staticStyle:{"cursor":"pointer !important"},on:{"click":function($event){_vm.clearGrid();_vm.routeUrl('/edcpathfinder');_vm.isSelected=''}}},[_vm._v("PathFinder")])]):_c('v-col',{staticClass:"EDC-PageHeader graythmprimary--text",staticStyle:{"text-align":"left !important"},attrs:{"cols":"3"},on:{"click":function($event){_vm.routeUrl('/edcpathfinder');_vm.isSelected=''}}},[_c('span',{staticStyle:{"cursor":"pointer !important"},on:{"click":function($event){_vm.clearGrid();_vm.routeUrl('/edcpathfinder');_vm.isSelected=''}}},[_vm._v("PathFinder")])])],1)],1),_c('v-col',{attrs:{"cols":"7"}},[(!_vm.hideHeader)?_c('v-row',{staticClass:"mb-12",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{class:{normal:_vm.isSelected !== 'Archive',focus:_vm.isSelected == 'Archive'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Archive',focus:_vm.isSelected == 'Archive','primary--text':_vm.isSelected==='Archive' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Archive'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Archive' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Archive' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Archive'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"3%"},attrs:{"name":"Menu_Develop","original":true}}),_c('span',[_vm._v("Develop")])],1)]}}],null,false,4073793512)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDevlop),expression:"showDevlop"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.archive),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],1),(_vm.isMenuBelongToCurrentProduct('configure'))?_c('v-col',{class:{normal:_vm.isSelected !== 'Configure',focus:_vm.isSelected == 'Configure'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Configure',focus:_vm.isSelected == 'Configure','primary--text':_vm.isSelected==='Configure' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Configure'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Configure' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Configure' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Configure'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"1%"},attrs:{"name":"Menu_Configure","original":true}}),_c('span',[_vm._v(" Configurations")])],1)]}}],null,false,1958296747)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfigure),expression:"showConfigure"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_vm._l((_vm.topNavigation.configure),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRuleMenu),expression:"showRuleMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Rules")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,3834642199)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.rule),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRuleMenu),expression:"showRuleMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Agent")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,2193607795)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.agent),function(tile,index3){return (tile.role)?_c('v-list-item',{key:index3,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],2)],1)],1):_vm._e(),(_vm.isMenuBelongToCurrentProduct('admin'))?_c('v-col',{class:{normal:_vm.isSelected !== 'Admin',focus:_vm.isSelected == 'Admin'}},[_c('v-menu',{staticClass:"menuCss",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{normal:_vm.isSelected !== 'Admin',focus:_vm.isSelected == 'Admin','primary--text':_vm.isSelected==='Admin' && !_vm.isGrayTheme,'secondary--text':_vm.isSelected!='Admin'  && !_vm.isGrayTheme,'graythmprimary--text':_vm.isSelected==='Admin' && _vm.isGrayTheme,'graythmsecondary--text':_vm.isSelected!='Admin' && _vm.isGrayTheme},attrs:{"slot":"activator","text":"","small":""},on:{"click":function($event){_vm.isSelected='Admin'}},slot:"activator"},on),[_c('svgicon',{staticStyle:{"margin-bottom":"3%"},attrs:{"name":"User_Admin","original":true}}),_c('span',[_vm._v(" Admin")])],1)]}}],null,false,150901159)},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdmin),expression:"showAdmin"}],staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_vm._l((_vm.topNavigation.admin),function(tile,index){return (tile.role)?_c('v-list-item',{key:index,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSecurityMenu),expression:"showSecurityMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Security")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,590563420)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.security),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSecurityMenu),expression:"showSecurityMenu"}],staticClass:"menuCss",staticStyle:{"width":"100%"},attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-tile-title',[_vm._v("Object Based Security")]),_c('v-list-tile-action',{staticClass:"justify-end"},[_c('v-icon',[_vm._v("play_arrow")])],1)],1)]}}],null,false,3450976376)},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.topNavigation.objsecurity),function(tile,index2){return (tile.role)?_c('v-list-item',{key:index2,attrs:{"id":"menulistitemhover"},on:{"click":function($event){return _vm.routeUrl(tile.url)}}},[_c('v-list-tile-title',{attrs:{"id":tile.name},on:{"click":function($event){return _vm.routeUrl(tile.url);}}},[_vm._v(_vm._s(tile.name))])],1):_vm._e()}),1)],1)],2)],1)],1):_vm._e()],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{staticClass:"mb-12",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticStyle:{"text-align":"right !important","padding-right":"20px !important"},attrs:{"cols":"11"}},[(_vm.notificationCount)?_c('v-badge',{attrs:{"color":"green","content":_vm.notificationCount.toString(),"overlap":"","offset-y":"40","right":"","bottom":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"slot":"activator","elevation":"0","fab":"","text":"","x-small":"","id":"smallbtn"},on:{"click":function($event){return _vm.routeUrl('/fileexport')}},slot:"activator"},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"My Notifications","id":"smallicon"}},[_vm._v("notifications_active")])])]}}],null,false,1564297804)})],1):_vm._e(),(_vm.notificationCount < 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"elevation":"0","fab":"","text":"","x-small":"","id":"smallbtn"},on:{"click":function($event){return _vm.routeUrl('/fileexport')}}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"My Notifications","id":"smallicon"}},[_vm._v("notifications_active")])])]}}],null,false,4173705868)}):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"My Settings","id":"smallicon"}},[_vm._v("language")])])]}}])}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","text":"","x-small":"","id":"smallbtn","title":_vm.getUserTitle()}},on),[_c('span',{class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"id":"profile"}},[_vm._v(_vm._s(_vm.initials))])])]}}])},[_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},[_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"My Downloads"},on:{"click":function($event){return _vm.routeUrl('/fileexport')}}},[_vm._v("My Downloads")])],1),(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Edit Profile"},on:{"click":function($event){return _vm.routeUrl('/userprofile')}}},[_vm._v("Edit Profile")])],1):_vm._e(),(!_vm.hideHeader)?_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Change Password"},on:{"click":function($event){return _vm.routeUrl('/changepassword')}}},[_vm._v("Change Password")])],1):_vm._e(),_c('v-list-item',[_c('v-list-tile-title',{attrs:{"id":"Sign out"},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Sign out")])],1)],1)],1),_c('v-menu',{attrs:{"offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"border":"0px !important"},attrs:{"fab":"","text":"","x-small":"","id":"smallbtn"}},on),[_c('i',{staticClass:"material-icons",class:{'graythmprimary--text':_vm.isGrayTheme,'primary--text':!_vm.isGrayTheme},attrs:{"title":"Applications","id":"smallicon"}},[_vm._v("apps")])])]}}])},[(_vm.applicationList.length)?_c('v-list',{staticClass:"EDC-MenuList",staticStyle:{"cursor":"pointer"}},_vm._l((_vm.applicationList),function(item,idx){return _c('v-list-item',{key:item.product_name},[_c('v-list-tile-title',{attrs:{"id":item.product_name},on:{"click":function($event){return _vm.redirectProduct(item)}}},[_vm._v(_vm._s(item.product_name))])],1)}),1):_vm._e()],1)],1)],1)],1),_c('v-snackbar',{attrs:{"right":true,"top":true,"timeout":_vm.snackbartimeout,"color":_vm.colorValue},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbartext)+" ")]),_c('simplert',{ref:"simplert",attrs:{"useRadius":true,"useIcon":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }