import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        generalDownloadNotification:{},
        pendingDownloadStatusUpdate:{},
        themeType:'',
        sameComponentRedirectObj:{},
        secondaryCountUpdate:{},
        dataviewerGridObject:{},
        dataviewerProps:{},
        archivistRedirectionObj:{},
        availableDatasourcesForMergeView:{},
        pathfinderObject:{},
        resetGridCount:1
    },
    mutations:{
        setGeneralDownloadNotification(state, obj) {
            state.generalDownloadNotification = obj;
        },
        setPendingDownloadStatusUpdate(state, obj){
            state.pendingDownloadStatusUpdate = obj;
        },
        setthemeInfo(state,theme){
            state.themeType = theme
        },
        setsameComponentRedirectObj(state,obj){
            state.sameComponentRedirectObj = obj
        },
        setSecondaryCountUpdate(state,obj){
            state.secondaryCountUpdate =obj
        },
        setDataviewerGridObject(state,obj){
            state.dataviewerGridObject = obj
        },
        setDataviewerProps(state,obj){
            state.dataviewerProps = obj
        },
        setArchivistRedirectionObj(state,obj){
            state.archivistRedirectionObj = obj
        },
        setAvailableDatasourcesForMergeView(state,obj){
            state.availableDatasourcesForMergeView = obj
        },
        setPathfinderObject(state,obj){
            state.pathfinderObject = obj
        },
        setResetGridCount(state,value){
            state.resetGridCount = value
        }
    },
    actions:{
        setGeneralDownloadNotification:(context,obj)=>{
            context.commit('setGeneralDownloadNotification',obj)
        },
        setPendingDownloadStatusUpdate:(context,obj)=>{
            context.commit('setPendingDownloadStatusUpdate',obj)
        },
        setthemeInfo(context,theme){
            context.commit('setthemeInfo',theme)
        },
        setsameComponentRedirectObj(context,obj){
            context.commit('setsameComponentRedirectObj',obj)
        },
        setSecondaryCountUpdate(context,obj){
            context.commit('setSecondaryCountUpdate',obj)
        },
        setDataviewerGridObject(context,obj){
            context.commit('setDataviewerGridObject', obj)
        },
        setDataviewerProps(context,obj){
            context.commit('setDataviewerProps', obj)  
        },
        setArchivistRedirectionObj(context,obj){
            context.commit('setArchivistRedirectionObj', obj)
        },
        setAvailableDatasourcesForMergeView(context, obj){
            context.commit('setAvailableDatasourcesForMergeView', obj)
        },
        setPathfinderObject(context,obj){
            context.commit('setPathfinderObject', obj)
        },
        setResetGridCount(context,val){
            context.commit('setResetGridCount', val)
        }
    }
})