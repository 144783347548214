<template>
    <div>
        <v-footer padless fixed :height="30">
            <v-card class="footer-flex">
                <v-row>
                    <v-col cols="6" class="footer-col">
                        <v-card-text class="footer-text-left">&copy; Copyright {{new Date().getFullYear()}} | All Rights Reserved</v-card-text>
                    </v-col>

                    <v-col cols="6" class="footer-col">
                        <v-card-text class="footer-text-right">Powered by Essentio</v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
    export default {
        name: "customFooter",
    }
</script>

<style scoped>
    .footer-flex {
        height: auto;
        width:100%;
        padding: 0px !important;
        background-color: #4B4B4B;
        border-radius: 0px !important;
    }

    .footer-flex-gray {
        height: auto;
        width:100%;
        padding: 0px !important;
        background-color: #FF0000;
        border-radius: 0px !important;
    }


    .footer-col {
        /* padding: 5px; */
    }

    .footer-text-left {
        padding: 0px;
        padding-left: 35px !important;
        color: white;
        text-align: left;
    }

    .footer-text-right {
        padding: 0px;
        padding-right: 35px !important;
        color: white;
        text-align: right;
    }
</style>
