/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Report_Download': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M54.961 43.964c0 6.083-4.931 11.014-11.014 11.014-6.083 0-11.014-4.93-11.014-11.014 0-6.082 4.931-11.014 11.014-11.014 6.083 0 11.014 4.932 11.014 11.014zM43.28 55h-30.47C10.699 55 9 53.287 9 51.16V12.752c0-2.128 1.699-3.84 3.809-3.84h30.47a3.81 3.81 0 013.809 3.84L47 34" _fill="none" _stroke="#000" stroke-width="2"/><path pid="1" d="M11.707 29.053l2.36 3.036 6.07-4.72 4.72 6.07 3.036-2.36L37 24l2.36 3.036 2.173-5.342 2.174-5.343-5.714.79-5.713.788 2.36 3.035-9.107 7.08-2.36-3.035-2.36-3.036z"/><path pid="2" d="M16 16h12M16 40h12M16 48h14" _fill="none" _stroke="#000" stroke-width="2" stroke-linecap="round"/><path pid="3" d="M44 51l3-4 3-4H38l3 4z"/><path pid="4" d="M45 46v-9h-2v9z"/>'
  }
})
