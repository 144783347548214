var moment = require("moment-timezone")

export function getCustmisedHeader(column_list,onlySelectedColumns){
    // this function is used to re-arange the column order. We need to show data in the order which user has specified in the customized view. Logic is if we have the only selected columns, then we are arranging the columns by that order
    let header = _.cloneDeep(column_list)
    let orderedHeader = [] // user might changed the sequnce in the selected col
    if (onlySelectedColumns && onlySelectedColumns.length){
        _.forEach(onlySelectedColumns,function(obj){
            let colObj = {}
            if(obj.col_alias)
            colObj = _.find(header,['value',obj.col_alias.toLowerCase()])

            if(_.isEmpty(colObj))
            colObj = _.find(header,['column_name',obj.column_name])

            if(!_.isEmpty(colObj)){
            colObj.func = ''
            colObj.func = obj.func
            }
            // some of the useful properties are bind to the onlySelectColumn, that we need to pass to the grid.for example show_Desc
            orderedHeader.push(_.merge(colObj,obj))
        })
        return orderedHeader
    }
    return header
}

export function showResponseTime(query_executed_in,requestSentAt, responseReceivedAt,gridObj){
    if(!query_executed_in)
      return
    let currentTime = new Date()
    let roundTripTime =  moment(currentTime).diff(moment(requestSentAt), 'milliseconds')
    let uiTimeDiff = moment(currentTime).diff(moment(responseReceivedAt), 'milliseconds')
    let fetchTimeDetails = ' Fetched In '+Math.round(roundTripTime)+'ms (+'+Math.round(query_executed_in)+'ms)'
    gridObj['fetchTimeDetails'] = fetchTimeDetails
}


export function applyPrimaryTableProps(_this, gridObj, columnList, requestData){
    gridObj['tableDescription'] = columnList[0].table_description
    gridObj.caption = requestData.table_name
    gridObj['table_name'] = requestData.table_name
    gridObj['tooltipObj'] = {}
    gridObj['unique_id'] = requestData['uniqueId']
    gridObj.children = []
    gridObj.node_id= requestData['uniqueId']
    gridObj.parent_id = requestData['parent_id']
    gridObj.node_filters = []
    gridObj.self_join = []
    gridObj.datasourceName = _this.currentDatasource.business_data.datasource_name
}

export function UpdateFilter(filterArray){
    this.tableRequestOtherProps['page'] = 1
    this.tableRequestOtherProps["filter_data"] = filterArray
    this.getTableData(true)
}

export function onPageChange(pageNumber,perPage){
    this.tableRequestOtherProps["page"] = pageNumber
    this.tableRequestOtherProps["page_size"] = perPage
    this.getTableData(false)
}

export function onPerPageChange(perPage,pageNumber){
    this.tableRequestOtherProps["page"] = pageNumber
    this.tableRequestOtherProps["page_size"] = perPage
    this.getTableData(false)
}

export function onSort(sortJson){
    this.tableRequestOtherProps["sort"] = sortJson
    this.getTableData(false)
}

export function resetTableProps(_this){
    _this.nodeFilters = {}
    _this.selfJoins = {}
    _this.mergeTableDetails = {}
    _this.businessViewDetails = {}
}

export function applyTableProps(_this,response){
    resetTableProps(_this)
    _this.nodeFilters = response.node_filters
    _this.selfJoins = response.self_join
    _this.mergeTableDetails = response.merge_table_details
    _this.businessViewDetails = response.business_view_details || {}
    _this.parentSubqueryJoin = response.parent_subquery_join
}