/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'downloading_action': {
    width: 52.756,
    height: 52.757,
    viewBox: '0 0 52.756 52.757',
    data: '<path pid="0" d="M26.106 29.059l10.523-10.523c1.047-1.024.813-1.746-.649-1.746h-5.873L30.108 4a4 4 0 00-8 0l-.001 12.789h-5.876c-1.462-.001-1.693.723-.646 1.747l10.521 10.523z"/><path pid="1" d="M49.027 25.77h-6.049c-.554 0-1 .447-1 1v17.939H10.78V26.77a1 1 0 00-1-1H3.731a1 1 0 00-1 1v20.464a5.53 5.53 0 005.524 5.522h36.248a5.53 5.53 0 005.523-5.522V26.77a.997.997 0 00-.999-1z"/>'
  }
})
