<template>
	<v-card>
		<v-row class="EDC-Row">
			<v-toolbar dense flat class="EDC-Toolbar" elevation="1">
				<v-toolbar-title>{{'VALUE lookup for alias ' + title}}</v-toolbar-title>
			<v-spacer></v-spacer>
                <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="closePanel" title="Close"></svgicon>
			</v-toolbar>
				<!-- <span style="float:right !important;text-align: right;padding-right:2%;padding-top:2%;"><v-icon small title="close" clickable @click="closePanel()">close</v-icon></span> -->
		</v-row>
		<v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:8px !important;">
			<v-col class="EDC-Col EDC-ColsSpacing" self-align="center">
				<v-autocomplete clearable autocomplete style="margin-top:10px;" :items="lookups" v-model="selectedValue" label="Select VALUE lookup"  solo return-object  hide-details></v-autocomplete>
			</v-col>
		</v-row>
		<v-row class="EDC-Row EDC-RowsSpacing" style="padding-top:8px !important;">
			<v-col class="EDC-Col" self-align="center">
				<vc-button  itemText="Select" :disabled="!selectedValue" @click.native="applyVALUEFilter()"></vc-button>
			</v-col>
		</v-row>
	    <loading-panel :loader="loader"></loading-panel>

	</v-card>
</template>
<script>
	export default {
		name:'VALUElookupViewer',
		data(){
			return {
				selectedValue:null, // we can not but '' as this is also one VALUE lookup
			}
		},
		props:{
			title:{
				type:String,
				default:''
			},
			loader:{
				type:Boolean,
				default:false
			},
			lookups:{
				type:Array,
				default:()=>{
					return []
				}
			},
			valueHeader:{
				type:Object,
				default:()=>{
					return {}
				}
			},
			gridUniqueID:{
				type:String
			},
		},
		watch:{
			title:{
				handler(newvalue){
					this.selectedValue = null
				}
			},
			lookups:{
				handler(newvalue){
					this.selectedValue = null
				}
			},
			valueHeader:{
				handler(newvalue){
					this.selectedValue = null
				}
			},
			gridUniqueID:{
				handler(newValue){
					this.selectedValue = null
				}
			}
		},
		mounted(){

		},
		methods:{
			applyVALUEFilter(){
				this.$emit('applyVALUEFilter',this.valueHeader.column_name,this.selectedValue)
			},
			closePanel(){
				this.$emit('closeVALUEViewer')
			}
		}
	}
</script>