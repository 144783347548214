/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'item_deactivate': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M2.91.53a.264.264 0 00-.264.264v1.488l1.058.836V.794A.264.264 0 003.44.529h-.53zm-.794.515a2.4 2.4 0 00-.551.382l.419.331c.043-.036.086-.072.132-.104v-.609zm2.117 0v.61a1.845 1.845 0 01.596 2.352l.42.332c.195-.344.307-.741.307-1.164 0-.932-.54-1.738-1.323-2.13zM.877 1.22l-.164.207 4.684 3.705.163-.207L.877 1.22zm.205.835a2.348 2.348 0 00-.29 1.121 2.385 2.385 0 002.381 2.381c.604 0 1.155-.228 1.576-.6l-.42-.333a1.847 1.847 0 01-1.156.404 1.847 1.847 0 01-1.672-2.641l-.42-.332zm1.564 1.238v.412c0 .147.118.265.264.265h.53c.017 0 .032-.007.049-.01l-.843-.667z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
