<template>
	<v-container class="EDC-Row">
		<v-row class="EDC-Row" style="padding-bottom:2px !important;">
			<v-col class="EDC-Col text-truncate" cols="2" align="left" style="display: inline-block;">
				<v-row class="EDC-Row">
					<v-col class="EDC-Col" cols="2" v-if="showNewVersion">
						<svgicon :class="{'svg-icon-graph-toolbar':true , 'svg-fill-graph-toolbar':displayMode !='Single', 'svg-fill-graph-toolbar-active':displayMode ==='Single'}" 
						name="pathfinder_normal_view" @click="modeChanged('Single')" :original="true" title="Single"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="2">
						<svgicon :class="{'svg-icon-graph-toolbar':true , 'svg-fill-graph-toolbar':displayMode !='Split', 'svg-fill-graph-toolbar-active':displayMode ==='Split'}"
						 name="pathfinder_split_view" @click="modeChanged('Split')" 
						:original="true" title="Split"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="2">
						<svgicon :class="{'svg-icon-graph-toolbar':true , 'svg-fill-graph-toolbar':displayMode !='Merged', 'svg-fill-graph-toolbar-active':displayMode ==='Merged'}" 
						name="pathfinder_merged_view" title="Merged" @click="modeChanged('Merged')"></svgicon>
					</v-col>
					<v-col class="EDC-Col" cols="1" align="left">
						<v-divider vertical class="borderProps"></v-divider>
					</v-col>
					<v-col class="EDC-Col" cols="4" style="padding-top:4px !important;" align="start">
						<a class="EDC-Link" @click="$emit('startOver')">Start Over</a>
					</v-col>
				</v-row>
			</v-col>
			<v-col class="EDC-Col" v-if="jobId">
				Related Job Id: <b>{{jobId}}</b>
			</v-col>
			<v-col class="EDC-Col" cols="2">

			</v-col>
			<v-dialog v-model="showDatasourceSelection" width="480" persistent>
				<v-card>
					<v-row class="EDC-Row">
						<v-col class="EDC-Col" align="left">
							<v-toolbar dense flat tile dark>
								<v-toolbar-title style="width:100% !important;"> Select Secondary Datasource For Merge </v-toolbar-title>
								<svgicon class="svg-icon-grid svg-fill-grid" style="cursor:pointer;fill:white !important;"
								 name="cancel_v2"  :original="true" @click="showDatasourceSelection=false"></svgicon>
							</v-toolbar>
						</v-col>
					</v-row>
					<v-row class="EDC-Row">
						<v-col align="left" cols="4">
							Primary Datasource:
						</v-col>
						<v-col align="left">{{ primaryDatasource.datasource_name }}</v-col>
					</v-row>
					<v-row class="EDC-Row">
						<v-col align="left" cols="4">
							Select Datasource:
						</v-col>
						<v-col align="left">
							<v-autocomplete class="EDC-SearchBox" clearable outlined style="border-radius:24px;" autoomplete="off" :items="availableDatasources" 
							item-text="ds_name"
							v-model="selectedDatasource" label="Select Datasource"
							return-object  hide-details >
						</v-autocomplete>
						</v-col>
					</v-row>
					<v-row class="EDC-Row">
						<v-col>
							<vc-button itemText="Save" :disabled="!selectedDatasource" @click.native="switchToMergeMode"></vc-button>
							<vc-button itemText="Cancel" @click.native="showDatasourceSelection=false"></vc-button>
						</v-col>
					</v-row>
				</v-card>
			</v-dialog>
		</v-row>
		<simplert :useRadius="true" :useIcon="true" ref="simplert">
        </simplert>
	</v-container>
</template>
<style scopped>
	.borderProps{
		border-color: rgba(0, 0, 0, .5) !important;
		border-width: thin;
	}

	.EDC-SearchBox >>> label{
		font-size: 14px !important;
		font-weight: bold;
	}

	.EDC-SearchBox >>> input{
		font-size: 16px !important;
		font-weight: bold;
	}

	.EDC-SearchBox >>> .v-list-item__title {
		font-size: 16px !important;
		font-weight: bold !important;
	}

</style>
<script>
	import Simplert from 'vue2-simplert'
	export default {
		name:'edcGraphToolbar',
		components:{
			'simplert':Simplert
		},
		props:{
			redoCount:{
				type:Number,
				default:0
			},
			undoCount:{
				type:Number,
				default:0
			},
			showAutoArrange:{
				type:Boolean,
				default:false
			},
			showNewVersion:{
				type:Boolean,
				default:true
			},
			breadscrumString:{
				type:String,
				default:'test string'
			},
			jobId:{
				type:Number,
				default:0
			}
		},
		data(){
			return{
				displayMode:'Single',
				primaryDatasource:{},
				availableDatasources:[],
				selectedDatasource:{},
				showDatasourceSelection:false,
				tempCode:''
			}
		},
		computed:{
			isBusinessViewOpened(){
				return !_.isEmpty(this.$store.state.dataviewerProps) && 
				!_.isEmpty(this.$store.state.dataviewerProps.selected_bo_tbl) 
				&& this.$store.state.dataviewerProps.selected_bo_tbl.type === 'BV'
			},
			isSingleMode(){
				return this.displayMode === 'Single'
			},
			isSplitMode(){
				return this.displayMode === 'Split'
			},
			isMergedMode(){
				return this.displayMode === 'Merged'
			},
			leftSideObj(){
				let pathFinderObj = _.cloneDeep(this.$store.state.pathfinderObject)
				return pathFinderObj['left'] || {}
			},
			rightSideObj(){
				let pathFinderObj = _.cloneDeep(this.$store.state.pathfinderObject)
				return pathFinderObj['right'] || {}
			},
			leftSideHasChildren(){
				return this.leftSideObj && this.leftSideObj.dataList && this.leftSideObj.dataList.length && this.leftSideObj.dataList[0].childrenDataList && this.leftSideObj.dataList[0].childrenDataList.length > 0
			},
			rightSideHasChildren(){
				return this.rightSideObj && this.rightSideObj.dataList && this.rightSideObj.dataList.length && this.rightSideObj.dataList[0].childrenDataList && this.rightSideObj.dataList[0].childrenDataList.length > 0
			}
		},
		watch:{
			breadscrumString:{
				handler(newvalue){
					
				}
			},
			'$store.state.dataviewerProps':{
				handler(newvalue){
				
				}
    		},
			'$store.state.pathfinderObject':{
				handler(newvalue){
					
				}
			},
			'$store.state.resetGridCount':{
				handler(newvalue){
					this.$emit('startOver')
				}
			}
		},
		methods:{
			isEmptyGridObj(){
				let pathFinderObj = _.cloneDeep(this.$store.state.pathfinderObject)
				let leftSideObj = pathFinderObj['left'] || {}
				let rightSideObj = pathFinderObj['right'] || {}
				if(_.isEmpty(leftSideObj) && _.isEmpty(rightSideObj))
					return true
				return false
			},
			modeChanged(mode){
				/*
					We have 3 modes here Single,Split & Merge
					Switching the mode is not straithforward. We have to consider each scenario. The possible
					matrix are flows
					1) Single -> Spit ==== Without data -> OK , With data -> OK, With Children -> OK
					2) Single -> Merge === Without Data -> OK, With Data -> OK, With Children-> No (Children will get cleared)
					3) Split -> Single === Without data -> OK , With data -> OK, With Children -> OK
					4) Split -> Merge === Without data -> OK, With data -> Left OK, right No, With Children -> No (Children will get cleared)
					5) Merge -> Single === Without data -> OK , With data -> OK, With Children -> No (Children will get cleared)
					6) Merge -> Split === Without data -> OK , With data -> OK, With Children -> No (Children will get cleared)
				

					code to send respective of matrix:
					1 & 3 -> ND (No need of Data loading)
					2,5,6 -> CCLP (Clear Childs & Reload Parents)
					4 CCCRLLP (Clear Childs, Clear Righ & Reload Left Parents)

				*/
				this.tempCode = ''
				let userMessage = ''
				let code = 'NA'
				if(this.displayMode === mode){
					return
				}
				if(mode==="Merged" && this.leftSideObj.selectedObj && this.leftSideObj.selectedObj.type ==='BV'){
					alert("You cannot perform merge on the business view")
					return
				}
				if((this.isSingleMode && mode === 'Split') || (this.isSplitMode && mode === 'Single')){
					// matrix 1 & 3
				}
				else if(this.isSingleMode && mode === 'Merged'){
					if(!_.isEmpty(this.leftSideHasChildren)){
						userMessage = 'All child grids will get cleared & All parent grids will get reload with merge data'
						code = 'CCCRLLP'
					}
					if(!_.isEmpty(this.leftSideObj)){
						userMessage = 'All parent grids will get reload with merge data'
						code = 'CCCRLLP'
					}
				}
				else if (this.isSplitMode && mode==='Merged'){
					if(!_.isEmpty(this.leftSideObj) && !_.isEmpty(this.rightSideObj)){
						code ='CCCRLLP'
						userMessage = 'All right side data will get clear, from left all child grids will get clear & All parent grids will get reload with merge data'
					} else if(!_.isEmpty(this.leftSideObj)){
						code = 'CCLP'
						userMessage = 'All child grids will get clear & All parent grids will get reload with merge data'
					} else if(!_.isEmpty(this.rightSideObj)){
						code = 'CCLP'
						userMessage = 'All right side data will get clear'
					}
				}
				else if(this.isMergedMode){
					// matrix 5,6
					if(!_.isEmpty(this.rightSideObj)){
						code = 'CCCRLLP'
						userMessage = 'All right side data will get clear, from left all child grids will get clear & All parent grids will get reload from the primary datasource'
					}
					else if(!_.isEmpty(this.leftSideHasChildren)){
						code = 'CCCRLLP'
						userMessage = 'All child grids will get cleared & All parent grids will get reload from the primary datasource'
					}
					else if (!_.isEmpty(this.leftSideObj)){
						code = 'CCCRLLP'
						userMessage = 'Data will get reloaded from the primary datasource'
					}
				}
				else{
					alert('Matrix not handled for mode switching')
					return
				}
				if(userMessage){
					this.showConfirmation(userMessage, mode,code)
				} else{
					this.changeMode(mode,code)
				}
			},
			changeMode(mode,code){
				if(mode === 'Merged'){
					let availableDS = this.$store.state.availableDatasourcesForMergeView.availableDS
					if(!availableDS ||!availableDS.length){
						alert("No any datasource available for merge")
						return
					}
					else{
						this.tempCode = code
						this.availableDatasources = availableDS
						this.primaryDatasource = this.$store.state.availableDatasourcesForMergeView.selectedDS
						this.showDatasourceSelection = true
					}
				} else{
					this.displayMode = mode
					this.$emit('switchMode',mode.toLowerCase(),code)
				}
			},
			switchToMergeMode(){
				this.displayMode = 'Merged' 
				this.$emit('switchMode','merged',this.tempCode, parseInt(this.selectedDatasource.datasource_index))
				this.showDatasourceSelection = false
			},
			showConfirmation(message, mode, code){
				let confirmFn = function () {
					this.$parent.changeMode(mode,code)
				}
				let obj = {
					title: 'Confirmation',
					message: message,
					type: 'info',
					useConfirmBtn: true,
					onConfirm: confirmFn,
					customCloseBtnText:'Cancel',
					customConfirmBtnText:'Continue'
				}
				this.$refs.simplert.openSimplert(obj)
			}
		}
	}
</script>