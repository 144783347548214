<template>
    <v-container class="EDC-Container">
        <v-row class="EDC-Row">
            <v-col cols="12" class="EDC-Col">
                <v-card style="margin-bottom:4px !important;padding:4px !important;">
                    <edc-data-toolbar @switchMode="manageOpenMode" @startOver="startOver" 
                    :breadscrumString="breadscrumString" @userChangedViewOnTheFly="userChangedViewOnTheFly"
                    :jobId="jobId"></edc-data-toolbar>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="EDC-Row">
            <v-col class="EDC-Col" :cols="getGridCols('left')">
                <v-row class="EDC-Row">
                    <v-col :cols="expandTo==='left'?'9':'12'" class="EDC-Col">
                        <edc-pathfinder :key="'grid1'" :whichSide="isSplitMode?'left':'wholepage'" 
                        :openModeType="openModeType" :isCompacted="expandTo==='left'?true:false" 
                        :startOverCount="startOverCount" :userActionToPerform="userActionToPerform"
                         :changeModeProps="changeModeProps"></edc-pathfinder>
                    </v-col>
                    <v-col cols="1" v-if="expandTo==='left'" class="EDC-Col" align-self="center" align="center">
                        <svgicon class="svg-icon-grid svg-fill-grid" name="expand_panel_right" title="Expand Left Grid" @click.native="expandTo?expandTo='':expandTo='right'" v-if="expandTo!='right'"></svgicon>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="EDC-Col" :cols="getGridCols('right')" v-if="isSplitMode">
                <v-row class="EDC-Row">
                    <v-col class="EDC-Col" :cols="expandTo==='right'?'3':'1'" align-self="center" align="center" v-if="isSplitMode && expandTo!='left'">
                        <svgicon class="svg-icon-grid svg-fill-grid" name="expand_panel_right" title="Expand Left Grid" @click.native="expandTo?expandTo='':expandTo='right'" v-if="!expandTo"></svgicon><br>
                        <svgicon class="svg-icon-grid svg-fill-grid" name="expand_panel_left"  title="Exapand Right Grid" @click.native="expandTo?expandTo='':expandTo='left'" v-if="expandTo!='left'"></svgicon>
                    </v-col>
                    <v-col class="EDC-Col" :cols="expandTo==='right'?'9':expandTo==='left'?'12':'11'">
                        <edc-pathfinder :key="'grid2'" :whichSide="'right'" :openModeType="openModeType" 
                        :isCompacted="expandTo==='right'?true:false" :startOverCount="startOverCount"></edc-pathfinder>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <simplert :useRadius="true" :useIcon="true" ref="simplert">
        </simplert>
    </v-container>
</template>
<style scoped>

</style>

<script>
import {mixinPathfinder} from '../../mixins/pathfinder.js'
import pathfinder from  './edcPathFinderContainer.vue'
import datatoolbar from '../../views/edcDataToolbar.vue'
import Simplert from 'vue2-simplert'

export default {
    name:'DataviewerContainer',
    components:{
        'edc-pathfinder':pathfinder,
        'edc-data-toolbar':datatoolbar,
        'simplert':Simplert
    },
    mixins:[mixinPathfinder],
    data(){
        return{
            openModeType:this.$route.params.opentType || 'single',
            expandTo:'',
            startOverCount:1,
            breadscrumString:'',
            userActionToPerform:'',
            jobId:0,
            setPreviousMode:false,
            changeModeProps:{},
        }
    },
    watch:{
        '$store.state.pathfinderObject':{
            handler(newVal){

            }
        }
    },
    mounted(){
        this.setPathfinderObject({})
        if(this.isDataviewerGridObjLoaded){
            this.openModeType = this.$store.state.dataviewerGridObject.openModeType
        }
        // in case of archivist call
        if(this.$route.params.jobId)
            this.jobId = this.$route.params.jobId
    },
    methods:{
        getGridCols(whichSideGrid){
            if(!this.isSplitMode)
                return 12
            
            if(!this.expandTo)
                return 6
            if(whichSideGrid === 'left'){
                if(this.expandTo === 'left')
                    return 1
                else
                    return 11
            } 
            if(whichSideGrid === 'right'){
                if(this.expandTo === 'right')
                    return 1
                else
                    return 11
            }
        },
        startOver(){
            if(this.isEmptyGridObj()){
                return
            }
            let confirmFn = function () {
                this.$parent.startOverCount++
            }
            let obj = {
                title: 'Start Over',
                message: 'Are you sure you want to start over?',
                type: 'info',
                useConfirmBtn: true,
                onConfirm: confirmFn,
                customCloseBtnText:'Cancel',
                customConfirmBtnText:'Start Over'
            }
            this.$refs.simplert.openSimplert(obj)
        },
        createBreadScrumString(){
            for(let i=0;i<this.$store.state.dataviewerGridObject.gridData.length;i++){
                let currentObj = this.$store.state.dataviewerGridObject.gridData[i]
                if(this.breadscrumString)
                    this.breadscrumString = this.breadscrumString+'=>'
                this.breadscrumString = this.breadscrumString + currentObj.tableDescription 

            }
        },
        manageOpenMode(mode, codeForLeftSide, mergedDatasourceIndex){
            /*
                We have 3 modes here Single,Split & Merge
                Switching the mode is not straithforward. We have to consider each scenario. The possible
                matrix are flows
                1) Single -> Spit ==== Without data -> OK , With data -> OK, With Children -> OK
                2) Single -> Merge === Without Data -> OK, With Data -> OK, With Children-> No (Children will get cleared)
                3) Split -> Single === Without data -> OK , With data -> OK, With Children -> OK
                4) Split -> Merge === Without data -> OK, With data -> Left OK, right No, With Children -> No (Children will get cleared)
                5) Merge -> Single === Without data -> OK , With data -> OK, With Children -> No (Children will get cleared)
                6) Merge -> Split === Without data -> OK , With data -> OK, With Children -> No (Children will get cleared)
            

                code to send respective of matrix:
                1 & 3 -> NA (No action needed)
                2,5,6 -> CCLP (Clear Childs & Reload Parents)
                4 CCCRLLP (Clear Childs, Clear Righ & Reload Left Parents)

            */
            this.openModeType = mode
            this.changeModeProps = {
                "openModeType":mode,
                "codeForLeftSide":codeForLeftSide,
                "mergedDatasourceIndex":mergedDatasourceIndex
            }
        },
        userChangedViewOnTheFly(mode, actionNeedToPerform){
            var _this = this
            this.manageOpenMode(mode)
            this.userActionToPerform = actionNeedToPerform
            _this.setTimeout(()=>{
                this.userActionToPerform = ''
            }, 500)
            
        }
    }
}
</script>