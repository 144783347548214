/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'User_Admin': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M7.832 30.646c-.293.01-.53.026-.53.026-1.817.057-5.059 7.68-5.146 12.078-.096 4.853 3.277 12.513 5.147 13.555H44.905c-2.735-3.39-6.526-8.445-8.155-12.3-.915-2.164-1.689-5.447-2.297-8.624-2.068.735-4.166 1.257-6.346 1.258-6.746 0-12.722-4.962-19.398-5.967-.232-.035-.583-.034-.877-.026z"/><circle pid="1" cx="29.957" cy="17.83" r="14"/><path pid="2" d="M40 36.286s.968 6.397 2.5 9.169c1.786 3.231 7.5 8.152 7.5 8.152s5.714-4.92 7.5-8.152c1.532-2.772 2.5-9.169 2.5-9.169s-3.261 1.485-5 1.485c-1.739 0-5-1.485-5-1.485s-3.261 1.485-5 1.485c-1.739 0-5-1.485-5-1.485zm10 3.988s1.63.742 2.5.742c.87 0 2.5-.742 2.5-.742s-.484 3.197-1.25 4.583c-.893 1.616-3.75 4.078-3.75 4.078z"/>'
  }
})
