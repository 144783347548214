/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Ping': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M52.008 15.355a1 1 0 00-.746 1.666c7.562 8.701 7.522 21.635-.096 30.288a1 1 0 101.5 1.322c8.27-9.393 8.315-23.475.105-32.92a1 1 0 00-.763-.356zm-40.1.03a1 1 0 00-.763.355c-8.21 9.446-8.165 23.527.105 32.92a1 1 0 101.5-1.32c-7.618-8.653-7.658-21.586-.096-30.287a1 1 0 00-.746-1.668zm35.08 2.603a1 1 0 00-.742 1.668 18.988 18.988 0 01-.08 25.02 1 1 0 101.5 1.322c6.946-7.889 6.983-19.721.088-27.654a1 1 0 00-.764-.356 1 1 0 01-.002 0zm-30.062.032a1 1 0 00-.766.353c-6.895 7.933-6.856 19.765.09 27.654a1 1 0 101.5-1.322 18.988 18.988 0 01-.08-25.02 1 1 0 00-.744-1.665zm25.047 2.603a1 1 0 00-.744 1.666 14.99 14.99 0 01-.063 19.754 1 1 0 101.5 1.32c5.622-6.385 5.653-15.963.072-22.384a1 1 0 00-.765-.356zm-20.032.03a1 1 0 00-.763.355c-5.581 6.42-5.55 16 .072 22.385a1 1 0 101.5-1.32 14.988 14.988 0 01-.063-19.753 1 1 0 00-.746-1.668zM32 24a8 8 0 00-8 8 8 8 0 007 7.93V56h2V39.928A8 8 0 0040 32a8 8 0 00-8-8z"/>'
  }
})
