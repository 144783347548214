/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Job_Terminate': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" cx="32.172" cy="32.085" r="24.712" _fill="none" _stroke="#000" stroke-width="2"/><rect pid="1" width="24" height="24" x="20" y="20" rx="4" ry="4"/>'
  }
})
