/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document_unpublish_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M1.587 6.085v-.668a1.588 1.588 0 001.454-1.61c-.252.58-.823.954-1.454.955v-.794l-.661.53-.662.529.662.529zm3.198-.265c.426 0 .76-.367.76-.793V1.322c0-.426-.334-.793-.76-.793H1.562c-.426 0-.76.367-.76.793v1.853h.528V1.322c0-.16.11-.264.232-.264h3.223c.123 0 .23.104.23.264v3.705c0 .16-.107.264-.23.264H3.44v.529zM4.498 4.76v-.53h-.795v.53zm0-1.056v-.53H3.44v.53zm0-1.06v-.528h-2.38v.529z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
