/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document_report_view_v2': {
    width: 24,
    height: 24,
    viewBox: '0 0 6.35 6.35',
    data: '<path pid="0" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;white-space:normal;shape-padding:0;isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1" d="M1.588.53a.798.798 0 00-.794.793V2.91h.529V1.323c0-.151.113-.265.265-.265h3.175c.15 0 .264.114.264.265v3.704a.258.258 0 01-.264.265h-.265v.529h.265a.798.798 0 00.793-.794V1.323a.798.798 0 00-.793-.794H1.588zm.264 1.059v.529h2.646v-.53H1.852zm1.853 1.056v.53h.793v-.53h-.793zm-1.191.53C1.6 3.175.86 3.836.529 4.498.86 5.159 1.6 5.82 2.514 5.82c.913 0 1.653-.662 1.984-1.323-.33-.662-1.071-1.323-1.984-1.323zm0 .661a.661.661 0 01.661.662.661.661 0 01-.661.661.661.661 0 01-.662-.661.661.661 0 01.662-.662zm.132.133a.265.265 0 00-.265.264.265.265 0 00.265.265.265.265 0 00.264-.265.265.265 0 00-.264-.264z" color="#000" font-weight="400" font-family="sans-serif" overflow="visible"/>'
  }
})
